import { useEffect } from "react";
import { useState } from "react";
import { checkInternetConnectionStatus, getNetworkConfig, updateWifiCredentials } from "../../scripts/network";
import Button from "../UiComponents/Button";
import Dropdown from "../UiComponents/Dropdown";
import InputField from "../UiComponents/InputField";
import { networkInterfaces } from "./networkInterfaces";
import setupSteps from "./setupSteps";
import { RowContainer, Header, InputFieldContainer, SectionContainer, InfoTextContainer, SetupContainer } from "./setupStyles";

const networkConnectionStates = {
    NONE: 0,
    CONNECTING: 1,
    CONNECTED: 2,
    NOT_CONNECTED: 3
}

const NetworkSetup = props => {
    const [networkConnectionState, setNetworkConnectionState] = useState(networkConnectionStates.NONE);
    const [ssid, setSsid] = useState(null);
    const [wifiPassword, setWifiPassword] = useState(null);

    useEffect(() => {
        checkInternetConnection();
    }, [props.networkInterface])

    const testWifiCredentials = async () => {
        setNetworkConnectionState(networkConnectionStates.CONNECTING);
        try {
            const res = await updateWifiCredentials(ssid, wifiPassword);
            await fetchNetworkConfig();
            setNetworkConnectionState(res.connected === true ? networkConnectionStates.CONNECTED : networkConnectionStates.NOT_CONNECTED);
        } catch (error) {
            setNetworkConnectionState(networkConnectionStates.NOT_CONNECTED);
        }
    }

    const checkInternetConnection = async () => {
        try {
            setNetworkConnectionState(networkConnectionStates.CONNECTING);
            const connectionStatus = await checkInternetConnectionStatus(props.networkInterface?.interfaceName);
            await fetchNetworkConfig();
            setNetworkConnectionState(connectionStatus.connected === true ? networkConnectionStates.CONNECTED : networkConnectionStates.NOT_CONNECTED);
        } catch (error) {
            setNetworkConnectionState(networkConnectionStates.NOT_CONNECTED);
        }
    }

    const fetchNetworkConfig = async () => {
        const networkConfig = await getNetworkConfig();
        props.setNetworkConfig(networkConfig);
    }

    return <SetupContainer>
        <Header>Hub setup - Network</Header>

        <SectionContainer>
            <label>Network interface</label>
            <RowContainer>
                <Dropdown
                    small
                    secondary
                    items={Object.keys(networkInterfaces).map(key => networkInterfaces[key])}
                    selectedItem={props.networkInterface}
                    onClick={(item) => props.setNetworkInterface(item)} />

                <Button
                    small
                    tertiary
                    onClick={() => checkInternetConnection()}>
                    Check connection
                </Button>

            </RowContainer>

            <RowContainer>
                <InfoTextContainer>
                    {networkConnectionState === networkConnectionStates.CONNECTING ?
                        <label>Connecting...</label>
                        : <></>}
                    {networkConnectionState === networkConnectionStates.CONNECTED ?
                        <label>Connected to network</label>
                        : <></>}
                    {networkConnectionState === networkConnectionStates.NOT_CONNECTED ?
                        <label>Not connected</label>
                        : <></>}
                </InfoTextContainer>
            </RowContainer>
        </SectionContainer>


        {props.networkInterface?.key === 'WIFI' ? <>
            <SectionContainer>
                <label>Wifi credentials</label>
                <InputFieldContainer>
                    <label>SSID</label>
                    <InputField onChange={(e) => setSsid(e.target.value)} />
                </InputFieldContainer>

                <InputFieldContainer>
                    <label>Password</label>
                    <InputField onChange={(e) => setWifiPassword(e.target.value)} />
                </InputFieldContainer>

                <RowContainer>
                    <Button small secondary onClick={() => testWifiCredentials()}>Update wifi credentials</Button>
                </RowContainer>
            </SectionContainer>
        </> : <></>}

        <SectionContainer>
            <RowContainer>
                <Button
                    primary={networkConnectionState === networkConnectionStates.CONNECTED}
                    tertiary={networkConnectionState !== networkConnectionStates.CONNECTED}
                    onClick={() => props.setSetupStep(setupSteps.CUSTOMER_SETUP)}>
                    {networkConnectionState === networkConnectionStates.CONNECTED ? 'Continue' : 'Continue anyway'}
                </Button>
            </RowContainer>
        </SectionContainer>

    </SetupContainer>
}

export default NetworkSetup;