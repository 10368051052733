import React, { useState } from 'react';
import ToastMessage from '../../UiComponents/ToastMessage';
import Modal, { ModalContent } from '../../UiComponents/Modal';
import { FaExclamationTriangle } from 'react-icons/fa';
import theme from '../../../UI/theme';
import styled from 'styled-components';

const StyledToast = styled(ToastMessage)`
    cursor: auto;
`

const Message = styled.p`
    :hover, :active {
        cursor: pointer;
        color: ${theme.colors.mellowYellow};
    }
`

const Header = styled.h3`
    color: ${theme.colors.mellowYellow};
`

const ErrorMessage = props => {
    const [showDetails, setShowDetails] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState();

    const clickMessageHandler = (errorMessage) => {
        setShowDetails(true);
        setSelectedMessage(errorMessage);
    }

    const closeDetailsHandler = () => {
        setShowDetails(false);
        setSelectedMessage(null);
    }

    if (props.errorMessages?.length) {
        return <>

            <StyledToast backgroundColor={theme.colors.darkSpace80} margin={'0 0 20px 0'}>
                {props.errorMessages?.map((errorMessage, index) => {
                    return <Message key={index} onClick={() => clickMessageHandler(errorMessage)}>
                        <b><FaExclamationTriangle /></b>{errorMessage.message}
                    </Message>
                })}

            </StyledToast>

            {showDetails && selectedMessage ? <>
                <Modal show={showDetails && selectedMessage} close={() => closeDetailsHandler()}>
                    <ModalContent>
                        <Header>{selectedMessage.message}</Header>
                        <p>{selectedMessage.details}</p>
                    </ModalContent>
                </Modal>
            </> : <></>}

        </>
    } else {
        return <></>;
    }

}

export default ErrorMessage;