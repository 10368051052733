import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from '../../UiComponents/Modal';
import Button from '../../UiComponents/Button';
import soundtrackYourBrandLogo from '../logos/soundtrackYourBrand-logo-white.png';
import livequbeLogo from '../logos/liveqube-logo-white.png';
import spotifyLogo from '../logos/spotify-logo-white.png'
import { getProcessors } from "../../../scripts/processor";
import SoundTrackYourBrandSetup from './SoundtrackYourBrandSetup';

const StyledSetupButton = styled(Button)`
    max-width: 300px; 
    margin: 16px;
`

const ContentSetupContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 350px;
    overflow: auto;
    max-height: 550px;
`
const ContentLogo = styled.img`
    width: 200px;
    margin: 10px 10px;
    opacity: ${props => props.comming ? '0.2' : '1'};
`

const CenteredText = styled.div`
    text-align: center;
    margin: 10px;
`


const MusicPlayerSetup = props => {

    const [sources, setSources] = useState();
    const [showModal, setShowModal] = useState(false);
    const [provider, setProvider] = useState(false);
    const [setupState, setSetupState] = useState('CHOOSE_PROVIDER');

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        try {
            const processors = await getProcessors(props.customerId);
            var sourcesResult = []
            processors.forEach(processor => {
                if (processor.sources) {
                    sourcesResult = [...sourcesResult, ...processor.sources];
                }
            });
            setSources(sourcesResult);
        } catch (e) {
        }
    }

    const endSetup = () => {
        setSetupState('CHOOSE_PROVIDER');
        setShowModal(false);
    }

    return (<>
        <StyledSetupButton primary onClick={() => { setShowModal(true) }}>Create Music Player</StyledSetupButton>
        <Modal show={showModal} close={endSetup}>
            <ContentSetupContainer>
                {setupState === 'CHOOSE_PROVIDER' ? <>
                    <h3>Choose your music provider</h3>
                    <ContentLogo
                        src={soundtrackYourBrandLogo}
                        alt="Soundtrack your brand"
                        onClick={() => { setProvider('SoundtrackYourBrand'); setSetupState('PROVIDER_SETUP') }}
                    />
                    <ContentLogo
                        src={livequbeLogo}
                        alt="liveqube"
                        onClick={() => { }}
                        comming={true}
                    />
                    <ContentLogo
                        src={spotifyLogo}
                        alt="Spotify"
                        onClick={() => { }}
                        comming={true}
                    />
                    <br></br>
                    <CenteredText>Can't find your music provider?</CenteredText>
                    <CenteredText>Send a request to our support team at support@waved.no</CenteredText>
                </> : <></>}
                {setupState === 'PROVIDER_SETUP' ? <>
                    {provider === 'SoundtrackYourBrand' ? <>
                        <SoundTrackYourBrandSetup
                            customerId={props.customerId}
                            sources={sources}
                            setShowModal={setShowModal}
                            endSetup={endSetup}
                        />
                    </> : <></>}
                </> : <></>}
            </ContentSetupContainer>
        </Modal>
    </>
    )
}

export default MusicPlayerSetup;

