import React, { useContext } from 'react';
import Selector from '../../UiComponents/Selector';
import styled from 'styled-components';

const Container = styled.div`
    margin: 0 12px 12px 12px;
`

const ViewSelector = props => {
    return <Container>
        <Selector large items={props.tabs} selectedItem={props.selectedTab} onClick={newTab => props.setSelectedTab(newTab)} />
    </Container>
}

export default ViewSelector;



