import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "../../UI/theme";

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    gap: 10px;
`

export const ModalHeader = styled.label`
    font-size: 1.5rem;
`

export const ModalText = styled.label`
    color: ${theme.colors.textGray};
    cursor: inherit;
`

export const RowContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`

export const ModalLink = styled(Link)`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: ${theme.colors.textGray};
    cursor: pointer;
    box-sizing: border-box;
    background-color: ${theme.colors.darkSpace90};
    border: 1px solid transparent;
    padding: 5px;

    @media (hover:hover) {
        &:hover {
            border-color: ${theme.colors.textGray};
        }
    }
`