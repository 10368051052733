import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { HeaderRow, SortIconContainer, TableHeader } from "../support/SupportStyles";
import customerUseColumns from "./customerUseColumns";
import Button from "../../UiComponents/Button";

const CustomerSuccesTableHeader = props => {
    return <>

        <HeaderRow>
            <TableHeader>
                <SortIcon {...props} columnKey={'customerId'} />
            </TableHeader>
            <TableHeader>
                <SortIcon {...props} columnKey={'companyName'} />
            </TableHeader>

            {customerUseColumns.map((column, index) => {
                return <TableHeader key={index}>
                    <SortIcon
                        {...props}
                        key={index}
                        columnKey={column.key} />
                </TableHeader>
            })}

            <TableHeader></TableHeader>
            <TableHeader></TableHeader>
        </HeaderRow>

        <HeaderRow>
            <TableHeader>ID</TableHeader>
            <TableHeader>Name</TableHeader>
            {customerUseColumns.map((column, index) => {
                return <TableHeader key={index}>
                    {column.display}
                </TableHeader>
            })}

            <TableHeader>Impersonate</TableHeader>
            <TableHeader>UI</TableHeader>
        </HeaderRow>
    </>
}

export default CustomerSuccesTableHeader;

const SortIcon = props => {

    return <SortIconContainer sorted={props.sortProperty === props.columnKey} onClick={() => props.setSort(props.columnKey)}>
        {props.sortProperty === props.columnKey && props.sortAsc ? <FaChevronUp /> : <FaChevronDown />}
    </SortIconContainer>
}
