import React, { useState, useEffect, useRef } from 'react';
import LiveGraph from './LiveGraph';
import { getDefinedLogInterval, getDateTime } from '../../../../scripts/log';
import styled from 'styled-components';
import zoneTypes from '../zoneTypes';
import IntervalSelector from './IntervalSelector';
import { formatDateTimeLabel } from '../../../../scripts/common';

const Container = styled.div`
    margin-top: 10px;
`

const chartData = {
    labels: [],
    datasets: [
        {
            data: [],
        },
        {
            data: []
        },
        {
            data: []
        },
        {
            data: []
        },
        {
            data: []
        }
    ]
}

const N_DATA_POINTS = 41;

const LiveGraphContainer = props => {
    const [graphData, setGraphData] = useState(chartData);
    const [selectedInterval, setSelectedInterval] = useState(zoneTypes.intervals.LIVE);

    useEffect(() => {
        if (!props.hidden) {
            getDataHandler();
        }
    }, [props.customerId, props.hidden, selectedInterval]);

    useEffect(() => {
        if (!props.hidden && selectedInterval === zoneTypes.intervals.LIVE) {
            updateWithLiveValues(props.liveValues);
        }
    }, [props.liveValues]);

    const getDataHandler = async () => {
        try {
            var newData = await getDefinedLogInterval(props.customerId, props.objectID, selectedInterval, props.objectType);
            if (newData && newData.length) {
                var updatedData = JSON.parse(JSON.stringify(graphData));
                newData = newData.sort((a, b) => { return (getDateTime(a.time) > getDateTime(b.time)) ? 1 : -1; });
                if (newData.length > N_DATA_POINTS) {
                    const step = Math.floor(newData.length / N_DATA_POINTS);
                    newData = newData.filter((_, i) => i % step === 0);
                }
                updatedData.labels = newData.map(d => formatDateTimeLabel(getDateTime(d.time)));
                updatedData.datasets[0].data = getMovingAverage(newData.map(d => d.noise), 0);
                updatedData.datasets[1].data = getMovingAverage(newData.map(d => d.sysvol), 0);
                updatedData.datasets[2].data = getMovingAverage(newData.map(d => d.averageDecibel), 0);
                updatedData.datasets[3].data = getMovingAverage(newData.map(d => d.isRegulating), 0);
                updatedData.datasets[4].data = getMovingAverage(newData.map(d => d.isRegulatingAudioSource), 0);
                setGraphData(updatedData);
            }
        } catch (error) {

        }
    }

    const updateWithLiveValues = (liveValues) => {
        if (props.hubConnected && liveValues && !isNaN(parseInt(liveValues.sysvol))) {
            updateData(liveValues.noise, liveValues.averageDecibel, liveValues.sysvol, liveValues.isRegulating, liveValues.isRegulatingAudioSource, liveValues.time);
        }
    }

    const updateData = (noise, db, sysvol, isRegulating, isRegulatingAudioSource, newLabel) => {
        setGraphData(currentGraphData => {

            if (currentGraphData.datasets) {
                var updatedData = JSON.parse(JSON.stringify(currentGraphData));

                if (updatedData.datasets[0].data.length > N_DATA_POINTS) {
                    updatedData.labels.shift();
                    updatedData.datasets[0].data.shift();
                    updatedData.datasets[1].data.shift();
                    updatedData.datasets[2].data.shift();
                    updatedData.datasets[3].data.shift();
                    updatedData.datasets[4].data.shift();
                }
                updatedData.labels.push(formatDateTimeLabel(getDateTime(newLabel)));
                updatedData.datasets[0].data.push(noise);
                updatedData.datasets[1].data.push(sysvol);
                updatedData.datasets[2].data.push(db);
                updatedData.datasets[3].data.push(isRegulating);
                updatedData.datasets[4].data.push(isRegulatingAudioSource);
            }

            return updatedData;
        });
    }


    return <Container>
        <LiveGraph data={graphData} chartID={props.objectID} />
        <IntervalSelector selectedInterval={selectedInterval} setSelectedInterval={setSelectedInterval} />
    </Container>
}

export default LiveGraphContainer;


function getMovingAverage(data, width) {

    var movingAvg = data;
    const L = movingAvg.length;
    for (var i = 0; i < L; i++) {
        var avg;
        if (i >= width && i <= L - width - 1) {
            avg = data.slice(i - width, i + width + 1).reduce(sum) / (2 * width + 1);
        } else if (i < width) {
            avg = (data[i] + data[i + 1]) / 2;
        } else if (i > L - width - 1) {
            avg = (data[i - 1] + data[i]) / 2;
        }
        movingAvg[i] = Math.round(avg);
    }

    return movingAvg;
}

function sum(total, num) {
    return total + num;
}