import { useState } from 'react';
import AnimateHeight from "react-animate-height";
import styled from "styled-components";
import { ZoneHeader } from '../live/zone/StyledComponents/ZoneItemStyles';
import ExpandCollapseButton from "../UiComponents/buttons/ExpandCollapseButton";
import Card from "../UiComponents/Card";
import HeaderContainer from '../UiComponents/containers/HeaderContainer';
import KeyMetrics from './KeyMetrics';
import OverviewChartContainer from './OverviewChartContainer';

const MainContainer = styled.div`
    position: relative;

    :hover {
        cursor: pointer;
    }
`

const ZoneStatItem = props => {
    const [expanded, setExpanded] = useState(false);

    return <Card>

        <MainContainer onClick={() => setExpanded(s => !s)}>
            <HeaderContainer>
                <ZoneHeader>{props.zone.zoneName}</ZoneHeader>

                <ExpandCollapseButton showState={expanded} />
            </HeaderContainer>

            <KeyMetrics
                trendPeriod={props.trendPeriod}
                date={props.date}
                customerId={props.customerId}
                objectID={props.zone.zoneId}
                useOpeningHours={props.useOpeningHours}
                hierarchyLevel={props.hierarchyLevel} />

        </MainContainer>

        <AnimateHeight duration={500} height={expanded ? 'auto' : 0} >
            <OverviewChartContainer
                trendPeriod={props.trendPeriod}
                hierarchyLevel={props.hierarchyLevel}
                date={props.date}
                customerId={props.customerId}
                useOpeningHours={props.useOpeningHours}
                objectID={props.zone.zoneId}
                hidden={!expanded} />
        </AnimateHeight>
    </Card>
}

export default ZoneStatItem;