import { DeviceError } from "../styling/DeviceStyles";
import { connectedStatus, getHubDisplayStatus, getProcessorConnectedStatus, getProcessorErrorStatus, getSensorConnectedStatus } from "../utils/connectionStatus";

const HubAggregatedError = ({ hubStatus, sensors, processors }) => {

    const processorError = processors?.filter(processor => !getProcessorErrorStatus(processor) || !getProcessorConnectedStatus(processor)).length > 0;
    const disconnectedSensors = sensors?.filter(sensor => !getSensorConnectedStatus(sensor)).length;

    return <div>
        
        {hubStatus !== connectedStatus.HUB_NOT_CONNECTED && hubStatus !== connectedStatus.SERVER_NOT_CONNECTED ? <>
            {processorError ? <>
                <DeviceError>
                    <label>Error</label>
                    <div>Processor error</div>
                </DeviceError>
            </> : <></>}

            {disconnectedSensors > 0 ? <>
                <DeviceError type={'WARNING'}>
                    <label>Warning</label>
                    <div>{(sensors.length - disconnectedSensors)} / {sensors.length} sensors connected</div>
                </DeviceError>
            </> : <></>}
        </> : <>
            Hub {getHubDisplayStatus(hubStatus).toLowerCase()}
        </>}
    </div>
}

export default HubAggregatedError;

export const hasAggregatedError = (hubStatus, sensors, processors) => {
    const processorError = processors?.filter(processor => !getProcessorErrorStatus(processor) || !getProcessorConnectedStatus(processor)).length > 0;
    const disconnectedSensors = sensors?.filter(sensor => !getSensorConnectedStatus(sensor)).length;
    return hubStatus === connectedStatus.HUB_NOT_CONNECTED || processorError || disconnectedSensors > 0;
}