import { SensorIcon } from "../UiComponents/Icons"
import { HubIcon, ProcessorIcon, SourceIcon, SourceSelectorIcon, ZoneIcon } from "./styling/Icons"

export const installationTabs = [
    { key: 'HUB', display: 'Hubs', icon: HubIcon },
    { key: 'PROCESSOR', display: 'Processors', icon: ProcessorIcon },
    { key: 'SOURCE', display: 'Sources', icon: SourceIcon },
    { key: 'SOURCE_SELECTOR', display: 'Source selectors', icon: SourceSelectorIcon },
    { key: 'ZONE', display: 'Zones', icon: ZoneIcon },
    { key: 'SENSOR', display: 'Sensors', icon: SensorIcon }
]

export const testStates = {
    NONE: 0,
    LOADING: 1,
    SUCCESS: 2,
    FAILED: 3
}

export const loadingStates = {
    NONE: 0,
    LOADING: 1,
    SUCCESS: 2,
    FAILED: 3
}