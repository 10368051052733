import React, { useState } from 'react';
import InnerCard from '../../../UiComponents/containers/InnerCard';
import SensitivitySlider from './SensitivitySlider';
import ExpandCollapseButton from '../../../UiComponents/buttons/ExpandCollapseButton';
import styled from 'styled-components';
import AnimateHeight from 'react-animate-height';
import ZoneModeSettings from './ZoneModeSettings';
import ArrowButton from '../../../UiComponents/buttons/ArrowButton';
import theme from '../../../../UI/theme';
import InvisibleLink from '../../../UiComponents/InvisibleLink';
import { useRouteMatch } from 'react-router-dom';
import { TextMuted } from '../../../UiComponents/text/StyledText';
import ZoneSettingToggle from './ZoneSettingToggle';
import hierarchyTypes from '../device/hierarchyTypes';
import { postUserInteraction } from '../../../../scripts/userInteraction';
import { userInteractionTypes } from '../../../admin/customerDetails/userInteractionTypes';

const ItemContainer = styled.li`
    :not(:last-of-type) {
        margin-bottom: 8px;
    }
    display: flex;
`
const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`
const ItemTitle = styled.h4`
    margin: 8px 0;
`
const MainContainer = styled.div`
    &:hover {
        cursor: pointer;
    }
`
const StyledCard = styled(InnerCard)`
    padding: 6px 16px;
    flex: 1;
`

const SettingContainer = styled.div`
    margin: 20px 0;
`

const ChangeOrderContainer = styled.div`
    margin-right: 6px;
    >button:first-child {
        margin-bottom: 10px;
    }
`

const ToggleList = styled.ul`
    list-style: none;
    padding: 20px 10px 10px 10px;
    color: ${theme.colors.darkSpace20};

    >li:first-child {
        border-top: 1px solid ${theme.colors.darkSpace70};
    }

    >li {
        border-bottom: 1px solid;
        border-color: ${theme.colors.darkSpace70};
        padding: 14px 0;
    }

`

const NavigationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    :hover {
        cursor: pointer;
    }

    h4 {
        margin: 0;
    }
`

const ZoneSettingsItem = props => {
    const [expanded, setExpanded] = useState(false);
    const match = useRouteMatch();
    const processorMapValues = props.zone?.processorMapValues?.split(',');

    const updateZoneWithUserInteractionHandler = (zoneId, updatedAttributes, userInteraction) => {
        props.updateZoneHandler(zoneId, updatedAttributes);
        postUserInteraction(props.zone.customerId, userInteraction);
    }

    return <ItemContainer>
        {props.editOrder ? <>
            <ChangeOrderContainer>
                <ArrowButton direction={'UP'} onClick={() => props.changeOrder(props.zone, -1)} />
                <ArrowButton direction={'DOWN'} onClick={() => props.changeOrder(props.zone, 1)} />
            </ChangeOrderContainer>
        </> : <></>}

        <StyledCard>
            <MainContainer onClick={() => setExpanded(s => !s)}>
                <TitleContainer>
                    <ItemTitle>{props.zone.zoneName}</ItemTitle>
                    <div>
                        <ExpandCollapseButton showState={expanded} color={theme.colors.darkSpace60} />
                    </div>
                </TitleContainer>
            </MainContainer>

            <AnimateHeight duration={500} height={expanded ? 'auto' : 0}>

                <ToggleList>
                    {props.zone.shouldRegulate ? <>
                        <li><ZoneSettingToggle
                            title={'Zone regulation'}
                            checked={props.zone.isRegulating}
                            onToggle={(newState) => updateZoneWithUserInteractionHandler(
                                props.zone.zoneId,
                                { isRegulating: newState },
                                {
                                    zoneId: props.zone.zoneId,
                                    setting: userInteractionTypes.isRegulating.key,
                                    fromValue: +!newState,
                                    toValue: +newState
                                })} />
                        </li>

                        {props.zone.hierarchyType !== hierarchyTypes.MASTER.value ?
                            <li><ZoneSettingToggle
                                title={'Mood sustain'}
                                checked={props.zone.moodSustain}
                                onToggle={(newState) => updateZoneWithUserInteractionHandler(
                                    props.zone.zoneId,
                                    { moodSustain: newState },
                                    {
                                        zoneId: props.zone.zoneId,
                                        setting: userInteractionTypes.moodSustain.key,
                                        fromValue: +!newState,
                                        toValue: +newState
                                    })} />
                            </li>
                            : <></>}

                        <li><ZoneSettingToggle
                            title={'Automatically reset zone settings each day'}
                            checked={props.zone.autoResetZoneSettings}
                            onToggle={(newState) => props.updateZoneHandler(props.zone.zoneId, { autoResetZoneSettings: newState })} />
                        </li>
                    </> : <></>}

                    <li><ZoneSettingToggle
                        title={'Hide zone from home'}
                        checked={props.zone.hide}
                        onToggle={(newState) => props.updateZoneHandler(props.zone.zoneId, { hide: newState })} />
                    </li>

                    {props.zone.shouldRegulate ?
                        <li>
                            <InvisibleLink to={`${match.url}/calibratezone/${props.zone.zoneId}`}>
                                <NavigationContainer >
                                    <div>
                                        <h4>Zone calibration</h4>
                                        <TextMuted>Empty: {processorMapValues?.[0]}, Medium: {processorMapValues?.[1]}, Full: {processorMapValues?.[2]}</TextMuted>
                                    </div>
                                    <ArrowButton color={theme.colors.darkSpace60} />
                                </NavigationContainer>
                            </InvisibleLink>
                        </li>
                        : <></>}

                </ToggleList>

                {props.zone.shouldRegulate ?
                    <>
                        {props.user.isAdmin ? <>
                            <SettingContainer>
                            <SensitivitySlider zone={props.zone} updateZoneHandler={props.updateZoneHandler} />
                        </SettingContainer>
                        </> : <></>}
                        

                        <SettingContainer>
                            {props.zoneModes && props.zoneModes.length ? <>
                                <ZoneModeSettings
                                    customerId={props.customerId}
                                    zone={props.zone}
                                    zoneModes={props.zoneModes}
                                    setZoneModes={props.setZoneModes}
                                    updateZoneHandler={props.updateZoneHandler}
                                    getZoneModes={props.getZoneModes}
                                />
                            </> : <></>}
                        </SettingContainer>
                    </>
                    : <></>}

            </AnimateHeight>
        </StyledCard>
    </ItemContainer>
}

export default ZoneSettingsItem;