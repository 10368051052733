import React, { useState } from 'react';
import StringField from './StringField';
import { cellStates } from '../table/tableTypes';
import serverConnection from '../../../scripts/server/ServerConnection';
import { FieldButton, MainContainer, ResultContainer, ResultValue } from './styling/FieldStyles';
import FieldTestResult from './components/FieldTestResult';
import { testStates } from '../installationTypes';

const AddressField = props => {
    const [state, setState] = useState(testStates.NONE);
    const [testResult, setTestResult] = useState();

    const testAddress = async () => {
        setTestResult(null);
        setState(testStates.TESTING);
        const testData = {
            processorId: props.processorId,
            type: props.type,
            address: props.address,
            customerId: props.customerId,
            hubId: props.hubId
        }
        try {
            const res = await serverConnection.hubEventHandler.sendEvent(
                props.hubId,
                props.customerId,
                'PROCESSOR_ADDRESS_TEST',
                testData
            );

            if (res.status === 'OK') {
                setState(testStates.SUCCESS);
                setTestResult(res.value);
            } else {
                setState(testStates.FAILED);
            }
        } catch (err) {
            setState(testStates.FAILED);
            setTestResult(err);
        }
    }

    const onAddressChange = (value) => {
        if (state !== testStates.NONE) {
            setState(testStates.NONE);
            setTestResult(null);
        }
        props.setAddress(value);
    }
    return <>
        <MainContainer>
            <StringField
                value={props.address}
                setValue={(value) => onAddressChange(value)}
                error={props.error}
                disabled={props.disabled}
                cellState={props.cellState}
            />

            {!props.disabled ? <>
                {state !== testStates.TESTING ? <>
                    <FieldButton tertiary small onClick={testAddress}>Test</FieldButton>
                </> : <></>}
            </> : <></>}
        </MainContainer>

        <FieldTestResult disabled={props.disabled} state={state} testResult={testResult} close={() => setState(testStates.NONE)}/>
    </>
}

export default AddressField;
