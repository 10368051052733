import { Link } from 'react-router-dom';
import React from 'react';
import Button from '../../../UiComponents/Button';
import styled from 'styled-components';

const Container = styled.div`
    margin: 10px 0;
`

const SetupLink = props => {

    return <Container>
        <Link to={'/setup'}>
            <Button tertiary mute >Set up new hub</Button>
        </Link>
    </Container>
}

export default SetupLink;