import { FaSave } from "react-icons/fa"
import styled, { css } from "styled-components"
import theme from "../../../UI/theme"
import { ListActionButton } from "./styles/ActionStyles"

const StyledButton = styled(ListActionButton)`
    ${props => !props.disabled && css`
        color: ${theme.colors.darkSpace};
        svg {
            color: ${theme.colors.darkSpace};
        }
        background-color: ${theme.colors.greenEnergy};
    `}
`

const SaveButton = ({ isEdited, saveHandler }) => {
    return <StyledButton disabled={!isEdited()} onClick={saveHandler}>
        <FaSave />
        <label>Save</label>
    </StyledButton>
}

export default SaveButton;