import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/venue';

const getLiveVenueActivity = async (customerId) => {
    var res = await axios.get(`${hostname}${path}/liveVenueActivity?customerId=${customerId}`, {withCredentials: true});
    if (res.data.error) {
        console.log(res.data.error);
        return false;
    } else if (res.data.value) {
        return res.data.value;
    } else {
        return false;
    }
}

export {
    getLiveVenueActivity
}