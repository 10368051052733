import { FaRegQuestionCircle } from "react-icons/fa"
import styled from "styled-components"
import theme from "../../UI/theme"

const InfoText = styled.span`
    display: none;
    max-width: 200px;
    overflow-wrap: break-word;
    color: ${theme.colors.textGray};
`

const Container = styled.div`
    position: absolute;
    display: inline-flex;
    align-items: top;
    color: ${theme.colors.greenEnergy};
    width: 20px;
    line-height: 20px;
    gap: 5px;
    padding: 5px;
    border-radius: 5px;
    z-index: 120;
    overflow: hidden;

    & > svg {
        overflow: visible;
    }

    transition: all .2s ease;

    &:hover {
        background-color: ${theme.colors.darkSpace80};
        width: fit-content;
        max-width: 200px;

        &> ${InfoText} {
            display: block;
        }
    }
`

const InfoButton = props => {
    return <Container>
        <FaRegQuestionCircle />
        <InfoText>{props.children}</InfoText>
    </Container>
}

export default InfoButton;