import React from 'react';
import { getDeviceNumber } from '../../../../scripts/common';
import styled from 'styled-components';
import Card from '../../../UiComponents/Card';
import InnerCard from '../../../UiComponents/containers/InnerCard';
import ArrowButton from '../../../UiComponents/buttons/ArrowButton';
import theme from '../../../../UI/theme';
import { HubIcon, SensorIcon } from '../../../UiComponents/Icons';
import { TextMuted } from '../../../UiComponents/text/StyledText';
import SetupLink from './SetupLink';
import InvisibleLink from '../../../UiComponents/InvisibleLink';
import { useRouteMatch } from 'react-router-dom';

const List = styled.ul`
    list-style: none;
    padding: 0;
    margin-bottom: 0;

    li:not(:last-of-type) {
        margin-bottom: 12px;
    }
`

const StyledCard = styled(InnerCard)`
    padding: 6px 16px;
`

const DEVICE_LAST_UPDATED_LIMIT = 10;

const DeviceSettingsOverview = props => {
    return <Card>
        <h3>Device settings</h3>
        <List>
            {props.hubs.map(hub => {
                return <HubGroupItem
                    key={hub.hubId}
                    hub={hub}
                    zones={props.zones?.filter(zone => zone.hubId === hub.hubId)}
                    sensors={props.sensors?.filter(sensor => sensor.hubId === hub.hubId)}
                />
            })}
        </List>
        <SetupLink />
    </Card>
}

const ItemContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    &:hover {
        cursor: pointer;
    }
`

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    svg {
        margin-right: 8px;
    }
    h4 {
        margin: 16px 0;
    }
`

const InfoItem = styled.div`
    margin-bottom: 12px;
`

const HubGroupItem = props => {
    let match = useRouteMatch();
    return <li>
        <StyledCard>
            <InvisibleLink to={`${match.url}/device/${props.hub.hubId}`}>
                <ItemContainer>
                    <div>
                        <FlexContainer>
                            <HubIcon height={20} />
                            <h4>Hub {getDeviceNumber(props.hub)}</h4>
                        </FlexContainer>
                        <InfoItem>{props.zones?.length} {pluralForm('zone', props.zones?.length)}
                            <div>
                                <TextMuted>
                                    {props.zones?.map((zone, i) => {
                                        return <span key={zone.zoneId}>
                                            {zone.zoneName}{i < props.zones?.length - 1 ? ', ' : ''}
                                        </span>
                                    })}
                                </TextMuted>
                            </div>
                        </InfoItem>

                        <InfoItem>{props.sensors?.length} {pluralForm('sensor', props.sensors?.length)}
                            <div>
                                <TextMuted>
                                    {countConnectedSensors(props.sensors)} connected
                            </TextMuted>
                            </div>
                        </InfoItem>
                    </div>
                    <div>
                        <ArrowButton color={theme.colors.darkSpace60} />
                    </div>
                </ItemContainer>
            </InvisibleLink>
        </StyledCard>
    </li>
}

function countConnectedSensors(sensors) {
    var count = sensors?.filter(sensor => sensor.isConnected && sensor.secondsSinceLastUpdate < DEVICE_LAST_UPDATED_LIMIT)?.length;
    return count === sensors?.length ? 'All' : count.toString();
}

function pluralForm(label, length) {
    if (length > 1) {
        label = label + 's';
    }
    return label;
}

export default DeviceSettingsOverview;