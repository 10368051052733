import { FaChevronCircleDown, FaChevronCircleLeft, FaChevronCircleRight, FaChevronCircleUp, FaMapMarker } from "react-icons/fa";
import styled from "styled-components";
import theme from "../../../../UI/theme";
import IconButton from "../../../UiComponents/buttons/IconButton";
import useLongPress from "../../../../hooks/UseLongPress";

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    height: fit-content;
`

const GridItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    aspect-ratio: 1;
`

const ArrowButton = styled(IconButton)`
    margin: 0;
    padding: 2px;
    min-height: fit-content;
    aspect-ratio: 1;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
`

const CenterLabel = styled.label`
    font-size: ${theme.fontSize.large};
    color: ${theme.colors.textGray};
`

const CalibrationPointMover = props => {
    const moveX = (angle) => props.movePointsHandler('sysvol', angle, props.indices);
    const moveY = (angle) => props.movePointsHandler('measuredDb', angle, props.indices);

    return <Container>
        <GridItem></GridItem>
        <GridItem>
            <ArrowButton>
                <FaChevronCircleUp onClick={() => moveY(0.5)}  {...useLongPress(() => moveY(1), 200)} />
            </ArrowButton>
        </GridItem>
        <GridItem></GridItem>
        <GridItem>
            <ArrowButton>
                <FaChevronCircleLeft onClick={() => moveX(-0.5)} {...useLongPress(() => moveX(-1), 200)} />
            </ArrowButton>
        </GridItem>
        <GridItem><CenterLabel><FaMapMarker /></CenterLabel></GridItem>
        <GridItem>
            <ArrowButton>
                <FaChevronCircleRight onClick={() => moveX(0.5)} {...useLongPress(() => moveX(1), 200)} />
            </ArrowButton>
        </GridItem>
        <GridItem></GridItem>
        <GridItem>
            <ArrowButton>
                <FaChevronCircleDown onClick={() => moveY(-0.5)} {...useLongPress(() => moveY(-1), 200)} />
            </ArrowButton>
        </GridItem>
        <GridItem></GridItem>
    </Container>
}

export default CalibrationPointMover;