import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/event';

async function postEvent(hubId, customerId, command, payload) {
    const res = await axios.post(`${hostname}${path}?customerId=${customerId}&hubId=${hubId}`, { command, payload });
    return res.data;
}

export {
    postEvent
}