import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/source';

async function getSources(customerId) {
    const res = await axios.get(`${hostname}${path}/sources?customerId=${customerId}`);
    return res.data;
}

async function getSource(customerId, sourceId) {
    const res = await axios.get(`${hostname}${path}?customerId=${customerId}&sourceId=${sourceId}`);
    return res.data;
}

async function updateSource(customerId, source) {
    const res = await axios.put(`${hostname}${path}?customerId=${customerId}`, source);
    return res.data;
}

async function addSource(customerId, source) {
    const res = await axios.post(`${hostname}${path}?customerId=${customerId}`, source);
    return res.data;
}

async function deleteSource(customerId, sourceId) {
    const res = await axios.delete(`${hostname}${path}?customerId=${customerId}&sourceId=${sourceId}`);
    return res.data;
}


export {
    getSources,
    getSource,
    updateSource,
    addSource,
    deleteSource
}