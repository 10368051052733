import { FaRegHdd, FaServer, FaVectorSquare } from "react-icons/fa";
import { GiJackPlug } from "react-icons/gi";
import { SensorIcon } from "../../UiComponents/Icons";
import WaInput from "../../UiComponents/Icons/WaInput";

export const HubIcon = FaRegHdd;
export const ProcessorIcon = FaServer;
export const SourceSelectorIcon = WaInput;
export const ZoneIcon = FaVectorSquare;
export const SourceIcon = GiJackPlug

export const getItemIcon = (itemName) => {
    switch (itemName) {
        case 'HUB':
            return HubIcon;
        case 'PROCESSOR':
            return ProcessorIcon;
        case 'SOURCE_SELECTOR':
            return SourceSelectorIcon;
        case 'ZONE':
            return ZoneIcon;
        case 'SENSOR':
            return SensorIcon;
        default:
            break;
    }
}