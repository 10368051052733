import { getDeviceNumber } from "../../../../../scripts/common";
import theme from "../../../../../UI/theme";
import { SensorIcon } from "../../../../UiComponents/Icons";
import { NetworkAnalysisContainer, NetworkHeader, NodeList, SensorInfoContainer } from "./networkAnalysisStyles";
import NodeItem from "./NodeItem";

const NetworkAnalysisResults = props => {
    const hierarchy = getSensorHierarchy(props.sensors);
    const noParents = getSensorsWithoutParents(props.sensors);

    return <NetworkAnalysisContainer>
        <NetworkHeader>Network analysis results</NetworkHeader>
        <NodeList>
            {hierarchy?.children?.map((sensor, index) => {
                return <NodeItem key={index} sensor={sensor} />
            })}
        </NodeList>

        {noParents?.length ? <>
            <NetworkHeader>Sensors without parents in this network</NetworkHeader>
            <NodeList>
                {noParents?.map((sensor, index) => {
                    return <SensorInfoContainer key={index}>
                        <SensorIcon height={'15px'} color={theme.colors.textGray} />
                        <span>{`Sensor ${getDeviceNumber(sensor)} - Parent node: ${sensor.parentNodeId}`}</span>
                    </SensorInfoContainer>
                })}
            </NodeList>
        </> : <></>}

    </NetworkAnalysisContainer>
}

export default NetworkAnalysisResults;

const getSensorHierarchy = (sensors) => {
    if (!sensors || !sensors.length) {
        return null;
    }
    var hierarchy = { ipAddress: 0, children: [] };
    hierarchy.children = getChildren(hierarchy, sensors);
    return hierarchy;
}

const getChildren = (parent, sensors) => {
    var children = sensors.filter(sensor => parseInt(sensor.parentNodeId) === parseInt(parent.ipAddress));
    children.sort((a, b) => (a.nodeType > b.nodeType) ? 1 : ((b.nodeType > a.nodeType) ? -1 : 0));
    children.forEach(child => {
        child.children = getChildren(child, sensors);
    })
    return children;
}

const getSensorsWithoutParents = (sensors) => {
    var noParents = sensors.filter(sensor => {
        const parentNodeId = parseInt(sensor.parentNodeId);
        if (isNaN(parentNodeId) || !sensors.some(s => parseInt(s.ipAddress) === parentNodeId || parentNodeId === 0)) {
            return true;
        }
        return false;
    })

    return noParents;
}