import styled from "styled-components";
import theme from "../../UI/theme";
import { FaPhone, FaEnvelope } from 'react-icons/fa';

const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: fill-available;
    color: ${theme.colors.darkSpace50};
    align-items: center;
    margin: 30px 0 30px 0;
    justify-content: center;
`

const LinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
`

const Link = styled.a`
    font-size: ${theme.fontSize.small};
    color: ${theme.colors.darkSpace30};
    text-decoration: none;
    margin-left: 5px;

    &:hover {
        text-decoration: underline;
    }
`

const Footer = props => {
    return <FooterContainer>
        <LinkContainer>
            <FaPhone />
            <Link href="tel:+4722222233"> +47 22 22 22 33</Link>
        </LinkContainer>
        <LinkContainer>
            <FaEnvelope />
            <Link href="mailto: support@waved.no"> support@waved.no</Link>
        </LinkContainer>
    </FooterContainer>
}

export default Footer;