import { useState } from "react";
import { FaVolumeUp } from "react-icons/fa";
import styled from "styled-components";
import Button from "../../UiComponents/Button";
import ButtonContainer from "../../UiComponents/containers/ButtonContainer";
import Modal from "../../UiComponents/Modal";
import { TextMuted } from "../../UiComponents/text/StyledText";

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const UnmuteButton = styled(Button)`
    padding: 8px 12px;
    margin: 0;
    display: flex;
    align-items: center;
`

const UnmuteZoneButton = props => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    return <>
        <Container>
            <UnmuteButton primary onClick={(e) => { e.stopPropagation(); setShowConfirmModal(true); }}>Unmute&nbsp;&nbsp;<FaVolumeUp /></UnmuteButton>
        </Container>

        <Modal show={showConfirmModal} close={() => setShowConfirmModal(false)}>
            <span>Are you sure you want to unmute this zone?</span>
            <TextMuted>This action can not be undone</TextMuted>

            <ButtonContainer>
                <Button primary onClick={() => props.onUnmute()}>Unmute</Button>
                <Button secondary onClick={() => setShowConfirmModal(false)}>Cancel</Button>
            </ButtonContainer>
        </Modal>
    </>
}

export default UnmuteZoneButton;