import React, {useContext} from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import SensorDetailView from '../components/admin/sensor/SensorDetailView';
import WebSocketView from '../components/websocket/WebSocketView';
import UserContext from '../hooks/UserContext';



const AdminView = props => {
    let match = useRouteMatch();
    const user = useContext(UserContext);

    if (user.user.role === 'admin' || true) {
        return <div>
        <Switch>
            <Route path={`${match.path}/sensor-detail/:initialSensorID?`}>
                <SensorDetailView customerId={props.customerId} user={user}/>
            </Route>

            <Route path={`${match.path}/websocket`}>
                <WebSocketView customerId={props.customerId} user={user}/>
            </Route>


            <Route path={`${match.path}`}>
                <div>
                    Admin view
                </div>
            </Route>
        </Switch>
    </div>
    } else {
        return <div>
            Admin only
        </div>
    }
    
}

export default AdminView;