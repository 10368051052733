import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { createCustomer, getCustomerByEmail } from '../../scripts/datasource';
import { localSetup } from '../../scripts/hub';
import { checkInternetConnectionStatus } from '../../scripts/network';
import Button from '../UiComponents/Button';
import Checkbox from '../UiComponents/Checkbox';
import Dropdown from '../UiComponents/Dropdown';
import InputField from '../UiComponents/InputField';
import { networkInterfaces } from './networkInterfaces';
import setupSteps from './setupSteps';
import { RowContainer, Header, InfoTextContainer, SectionContainer, SetupContainer } from './setupStyles';

const hubSetupStates = {
    NONE: 0,
    SETTING_UP: 1,
    SETUP_COMPLETE: 2,
    SETUP_FAILED: 3
}

const CustomerSetup = props => {
    const history = useHistory();
    const match = useRouteMatch();
    const [hubSetupState, setHubSetupState] = useState(hubSetupStates.NONE);
    const [isNewCustomer, setIsNewCustomer] = useState(true);
    const [enterCustomerId, setEnterCustomerId] = useState(false);
    const [selectedCustomerId, setSelectedCustomerId] = useState();
    const [hubHasInternet, setHubHasInternet] = useState(true);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [customer, setCustomer] = useState();

    useEffect(() => {
        checkHubInternetConnection();

        let internetCheckInterval = setInterval(() => {
            checkHubInternetConnection();
        }, 3000);

        return () => {
            clearInterval(internetCheckInterval);
        }
    }, []);

    const resolveCustomer = async () => {
        if (isNewCustomer === true) {
            if (password === confirmPassword) {
                let newCustomer = await createCustomer(email, password);
                setCustomer(newCustomer);
            }
        } else {
            try {
                let oldCustomer = await getCustomerByEmail(email);
                setCustomer(oldCustomer);
            } catch (error) {
                setCustomer(null);
            }
        }
    }

    const setupHub = async () => {
        setHubSetupState(hubSetupStates.SETTING_UP);
        let setupParameters = {
            customerId: enterCustomerId === false ? customer?.customerId : selectedCustomerId,
            networkInterface: props.networkInterface?.key
        }

        try {
            await localSetup(setupParameters);
            setTimeout(() => {
                setHubSetupState(hubSetupStates.SETUP_COMPLETE);
                if (props.networkInterface === networkInterfaces.WIFI) {
                    let wifiConfig = props.networkConfig?.[networkInterfaces.WIFI.interfaceName];
                    if (wifiConfig) {
                        let wifiAddress = wifiConfig?.address;
                        if (wifiAddress?.split('.')?.length === 4) {
                            window.location.replace(`http://${wifiAddress}:4000/installation`)
                            return;
                        }
                    }
                }
                history.push('/installation');
                window.location.reload();
            }, 4000);
        } catch (error) {
            setHubSetupState(hubSetupStates.SETUP_FAILED);
        }
    }

    const checkHubInternetConnection = async () => {
        try {
            const res = await checkInternetConnectionStatus();
            setHubHasInternet(res.connected);
        } catch (error) {
            setHubHasInternet(false);
        }
    }

    return <SetupContainer>
        <Header>Hub setup - Customer</Header>

        <SectionContainer>
            {hubHasInternet ? <RowContainer>
                <Checkbox checked={enterCustomerId} onChange={() => setEnterCustomerId(value => !value)} />
                <label>Enter existing customer ID manually</label>
            </RowContainer> : <></>}

            {enterCustomerId === true || hubHasInternet === false ? <>
                <InfoTextContainer>
                    {enterCustomerId === true ?
                        <label>Please enter customer ID manually.</label> :
                        <label>Hub is not connected to internet. Please enter customer ID manually.</label>
                    }
                </InfoTextContainer>
                <label>Customer ID</label>
                <InputField
                    type='number'
                    onChange={(e) => setSelectedCustomerId(Number(e.target.value))}
                    min={1} />
            </> : <>
                {customer ? <>
                    <label>Customer</label>
                    <InfoTextContainer>
                        <label>Customer: {customer?.companyName}</label>
                        <label>CustomerId: {customer?.customerId}</label>
                        <label>E-mail: {customer?.email}</label>
                    </InfoTextContainer>

                    <RowContainer>
                        <Button tertiary small onClick={() => setCustomer(null)}>Change customer</Button>
                    </RowContainer>

                </> : <>
                    <RowContainer>
                        <Checkbox checked={isNewCustomer} onChange={() => setIsNewCustomer(value => !value)} />
                        <label>New customer</label>
                    </RowContainer>

                    <label>E-mail</label>
                    <InputField value={email ?? ''} onChange={(e) => setEmail(e.target.value)} />

                    {isNewCustomer === true ? <>
                        <label>Password</label>
                        <InputField type='password' value={password ?? ''} onChange={(e) => setPassword(e.target.value)} />

                        <label>Confirm password</label>
                        <InputField type='password' value={confirmPassword ?? ''} onChange={(e) => setConfirmPassword(e.target.value)} />
                    </> : <></>}

                    <RowContainer>
                        <Button small primary onClick={() => resolveCustomer()}>{isNewCustomer ? 'Create customer' : 'Load customer'}</Button>
                    </RowContainer>
                </>}
            </>}

        </SectionContainer>

        {hubSetupState === hubSetupStates.SETTING_UP || hubSetupState === hubSetupStates.SETUP_FAILED ? <SectionContainer>
            <InfoTextContainer>
                <label>{hubSetupState === hubSetupStates.SETTING_UP ? 'Setting up...' : 'Setup failed'}</label>
            </InfoTextContainer>
        </SectionContainer> : <></>}

        <SectionContainer>
            <RowContainer>
                <Button
                    tertiary
                    disabled={hubSetupState === hubSetupStates.SETTING_UP}
                    onClick={() => props.setSetupStep(setupSteps.NETWORK_SETUP)}>
                    Back
                </Button>
                <Button
                    primary
                    disabled={
                        !props.networkInterface?.key ||
                        !(enterCustomerId ? selectedCustomerId : customer?.customerId) ||
                        hubSetupState === hubSetupStates.SETTING_UP}
                    onClick={() => setupHub()}>
                    Set up hub
                </Button>
            </RowContainer>
        </SectionContainer>

    </SetupContainer>
}

export default CustomerSetup;