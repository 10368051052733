import styled, {keyframes} from 'styled-components';
import theme from '../../UI/theme';

const appear = keyframes`
    from {
        transform:  translateY(10px);
    }
    to {
        transform:  translateY(0);
    }
`

const ToastMessage = styled.div`
    padding: 10px 20px;
    margin: ${props => props.margin ?? '10px'};
    border-radius: 5px;
    background-color: ${props => props.backgroundColor || theme.colors.darkSpace90};
    box-shadow: 0px 2px 4px ${theme.colors.darkSpace90};
    animation: ${appear} 0.2s linear;
    
    cursor: pointer;

    p {
        margin: 4px;
    }

    svg {
        margin-right: 8px;
    }
`

export default ToastMessage;