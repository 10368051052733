import styled from 'styled-components';
import trendTypes from "../statistics/trendTypes";
import DayPicker from "../UiComponents/datepicker/DayPicker";
import Selector from "../UiComponents/Selector";
import Card from '../UiComponents/Card';
import theme from '../../UI/theme';
import PeriodStepper from '../UiComponents/datepicker/PeriodStepper';
import AnimateHeight from 'react-animate-height';
import { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const PeriodPickerCard = styled(Card)`
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    margin-bottom: 20px;

    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        top: 85px;
    }
`

const PeriodSelectorContainer = styled.div`
    margin-bottom: 10px;
`

const ExpandButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.textGray};
    font-size: ${theme.fontSize.larger};
    background-color: ${theme.colors.darkSpace90};
    border: none;
    border: 1px solid ${theme.colors.grayBorder};
    border-bottom: 1px solid ${theme.colors.darkSpace};
    border-width: 1px 0;

    width: fill-available;
    margin: 10px -10px -10px -10px;
    padding: 5px 0;
    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        margin: 20px -20px -20px -20px;
    }

    &:hover {
        cursor: pointer;
        @media only screen and (min-width: ${theme.screenSizes.medium}px) {
            background-color: ${theme.colors.grayBorder};
        }
        
        @media (hover: hover) {
            svg {
                transform: scale(1.2);
            }
        }
    }

    &:active {
        svg {
            transform: scale(1.2);
        }
        background-color: ${theme.colors.grayBorder};
    }
`

const PeriodPicker = props => {
    const [expanded, setExpanded] = useState(false);

    return <PeriodPickerCard>
        <AnimateHeight duration={500} height={expanded ? 'auto' : 0}>
            <PeriodSelectorContainer>
                <Selector
                    items={props.periods}
                    selectedItem={props.period}
                    onClick={item => props.setPeriod(item)}
                    width={'0'}
                    flex={'1 1 0px'} />
            </PeriodSelectorContainer>
        </AnimateHeight>

        <PeriodStepper date={props.date} setDate={props.setDate} period={props.period.key} />

        <AnimateHeight duration={500} height={expanded ? 'auto' : 0}>
            {props.period.key === trendTypes.periods.DAY ?
                <DayPicker date={props.date} setDate={props.setDate} />
                : <></>}

        </AnimateHeight>

        <ExpandButton onClick={() => setExpanded(previous => { return !previous })}>
                {expanded ? <FaChevronUp /> : <FaChevronDown />}
        </ExpandButton>

    </PeriodPickerCard>
}


export default PeriodPicker;