const { postEvent } = require("./event");

function getMappedValue(percentValue, mapParams) {
    var mappedValue = percentValue;
    if (!mapParams) {
        mapParams = [40, 60, 80];
    }

    const [lp, mp, hp] = mapParams;
    if (lp && mp && hp) {

        const lw = 40;
        const mw = 60;
        const hw = 80;

        const a = (mp - lp) / (mw - lw);
        const b = lp - a * lw;
        const c = (hp - mp) / (hw - mw);
        const d = hp - c * hw;

        if (percentValue > mw) {
            mappedValue = c * percentValue + d;
        } else {
            mappedValue = a * percentValue + b;
        }
    } else {
        console.log('No map parameters');
    }

    if (mappedValue > 100) mappedValue = 100;
    if (mappedValue < 0 || percentValue <= 0) mappedValue = 0;

    return mappedValue;
}

function getReverseMappedValue(value, mapParams) {
    var percentValue = value;
    if (!mapParams) {
        mapParams = [40, 60, 80];
    }

    const [lp, mp, hp] = mapParams;
    if (lp && mp && hp) {
        const lw = 40;
        const mw = 60;
        const hw = 80;

        const a = (mp - lp) / (mw - lw);
        const b = lp - a * lw;
        const c = (hp - mp) / (hw - mw);
        const d = hp - c * hw;

        if (value > mp) {
            percentValue = (value - d) / c;
        } else {
            percentValue = (value - b) / a;
        }
    }

    // if (percentValue > 100) percentValue = 100;
    if (percentValue < 0) percentValue = 0;

    return percentValue;
}

function mappedValuesToArray(mapString) {
    return mapString.split(",").map(Number);
}

async function setAbsoluteVolumeDirect(customerId, hubId, zoneId, value, preventEventLog = false) {
    try {
        await postEvent(hubId, customerId, 'SET_ABSOLUTE_VOLUME_DIRECT', { zoneId: zoneId, value: value, preventEventLog: preventEventLog });
    } catch (error) {
        console.log('Could not change zone volume');
    }
}

const [lw, mw, hw] = [40, 60, 80] // change to [20,50,80] when ready
function getMappingConstants(calibrationDecibels) {
    const [lp, mp, hp] = calibrationDecibels;
    const a = (mp - lp) / (mw - lw);
    const b = lp - a * lw;
    const c = (hp - mp) / (hw - mw);
    const d = hp - c * hw;
    return [a, b, c, d];
}

function getMappedVolume(calibrationSysvols, percentValue) {
    var mappedVolume = null;
    if (!calibrationSysvols) {
        calibrationSysvols = [40, 60, 80];
    }

    try {
        const [lp, mp, hp] = calibrationSysvols;
        const [a, b, c, d] = getMappingConstants([lp, mp, hp]);
        if (percentValue > mw) {
            mappedVolume = c * percentValue + d;
        } else {
            mappedVolume = a * percentValue + b;
        }

        if (mappedVolume > 100) {
            mappedVolume = 100;
        } else if (mappedVolume < 0 || percentValue <= 0) {
            mappedVolume = 0;
        }
    } catch (err) {
        console.log('Error - missing calibration points', err);
    }
    return mappedVolume;
}

function getReverseMappedVolume(calibrationSysvols, volume) {
    var percentValue = null;
    if (!calibrationSysvols) {
        calibrationSysvols = [40, 60, 80];
    }

    try {
        var [lp, mp, hp] = [40, 60, 80];
        if (calibrationSysvols?.length === 3) {
            [lp, mp, hp] = calibrationSysvols;
        }
        const [a, b, c, d] = getMappingConstants([lp, mp, hp]);

        if (volume > mp) {
            percentValue = (volume - d) / c;
        } else if (volume > lp) {
            percentValue = (volume - b) / a;
        } else {
            percentValue = lw / lp * volume;
        }

        if (percentValue < 0) {
            percentValue = 0;
        }

    } catch (err) {
        console.log('Error - missing calibration points', err);
    }

    return percentValue;
}

function getSystemVolume(wavedvolume, calibrationSysvols, calibrationMethod) {
    if (calibrationMethod === 1) {
        return getMappedVolume(calibrationSysvols, wavedvolume);
    } else if (calibrationMethod === 0) {
        return getMappedValue(wavedvolume, calibrationSysvols);
    }
}

function getWavedVolume(systemVolume, calibrationSysvols, calibrationMethod) {
    if (calibrationMethod === 1) {
        return getReverseMappedVolume(calibrationSysvols, systemVolume);
    } else if (calibrationMethod === 0) {
        return getReverseMappedValue(systemVolume, calibrationSysvols);
    }
}

export {
    getMappedValue,
    getReverseMappedValue,
    mappedValuesToArray,
    setAbsoluteVolumeDirect,
    getMappedVolume,
    getReverseMappedVolume,
    getSystemVolume,
    getWavedVolume
}