
import { postEvent } from "../../../scripts/event";
import { addSource, updateSource } from "../../../scripts/source";
import { saveObjects } from "../tableUtils";

const saveUpdatedSources = async (changedSources, sources, processors, customerId) => {
    prepareForSave(changedSources, sources);
    const uniqueHubIds = getUniqueHubIds(changedSources, processors);
    await saveObjects(changedSources, uniqueHubIds, customerId, 'UPDATE_SOURCES', (customerId, object) => updateSource(customerId, object));
}

const addNewSource = async (props, processors, customerId) => {
    try {
        const processor = processors.find(processor => processor.processorId === props.processorId);
        const hubId = processor.hubId;
        const newSource = {
            ...props,
            customerId
        }
        await addSource(customerId, newSource);
        await postEvent(hubId, customerId, 'UPDATE_SOURCES');
    } catch (err) {
        console.log('Failed to add new sourceSelector', err);
    }
}

const getNextAddress = (objects, processors) => {
    try {
        if (processors.length === 1) {
            return objects.length ? Math.max(...objects.map(object => object.address)) + 1 : 1
        }
    } catch (err) {
        return null;
    }
}

export {
    saveUpdatedSources,
    addNewSource,
    getNextAddress
}

const getUniqueHubIds = (sources, processors) => {
    const uniqueHubIds = [];
    sources.forEach(source => {
        const processor = processors.find(processor => processor.processorId === source.processorId);
        if (processor && !uniqueHubIds.includes(processor.hubId)) {
            uniqueHubIds.push(processor.hubId);
        }
    })
    return uniqueHubIds;
}

const prepareForSave = (changedSources, sources) => {
    changedSources.forEach(changedSource => {
        const source = sources.find(source => source.sourceSelectorId === changedSource.sourceSelectorId);
        if (source && !changedSource.processorId) {
            changedSource.processorId = source.processorId;
        }
    })
}