import { Layer, Path, Rectangle, Size, Point } from 'paper';
import heatmapTypes from '../container/heatmapTypes';
import {getDataColor} from './colorUtils';

const dataTypes = {};
dataTypes[heatmapTypes.dataTypes.AVG_DB] = 'averageDecibel';
dataTypes[heatmapTypes.dataTypes.AVG_DIFF_DB] = 'averageDiff';
dataTypes[heatmapTypes.dataTypes.AVG_FIL_DB] = 'AvgFilDB';

const K = 5; // K nearest neighbors constant
const DATA_LAYER_ALPHA = 0.7;
const NEAR_LIMIT = 0.1;
const GRANULARITY = 51;
const MAX_FLUCT = 5;
const FLUCT_STEPS = 5;
const DEFAULT_DATA_TYPE = dataTypes[heatmapTypes.dataTypes.AVG_DB];

class DataLayer {
    constructor(outerWallElement) {
        this.sensorData = [];
        this.gridPoints = [];
        this.isFluctuating = false;
        this.outerWallPath = null;
        this.dataType = DEFAULT_DATA_TYPE;
        this.layer = new Layer();
        this.layer.name = 'Data layer';
    }

    initializeGrid(outerWallElement, sensors) {
        if (outerWallElement) {
            this.outerWallElement = outerWallElement;
            this.createOuterWallPath();
            this.getGridWeights(sensors);
        }
    }

    createOuterWallPath() {
        this.outerWallPath = new Path({});
        this.outerWallPath.addSegments(this.outerWallElement.geometry.map(point => new Point(point.x, point.y)));

    }

    setDataMode(dataMode) {
        this.dataMode = dataMode;
        if (this.dataMode != heatmapTypes.dataModes.LIVE) {
            if (this.isFluctuating) {
                this.stopFluctuating();
            }
        }
    }

    setDataType(dataTypeName) {
        this.dataType = dataTypes[dataTypeName];
        this.stopFluctuating();
    }

    getGridWeights(sensors) {
        this.gridPoints = [];
        if (!sensors || sensors.length == 0 || !this.outerWallPath) {
            return;
        }
        sensors = sensors.filter(sensor => sensor.isOnMap);
        this.sensors = sensors;
        var x_start = this.outerWallPath.bounds.topLeft.x;
        var x_end = this.outerWallPath.bounds.bottomRight.x;
        var y_start = this.outerWallPath.bounds.topLeft.y;
        var y_end = this.outerWallPath.bounds.bottomRight.y;
        
        // find gridsize
        var L = x_end - x_start;
        if (y_end- y_start > L) {
            L = y_end- y_start;
        }

        var gridSize = L/GRANULARITY;

        var x_arr = arange(x_start, x_end, gridSize);
        var y_arr = arange(y_start, y_end, gridSize);
        this.gridSize = gridSize + 0.005; //add some offset to avoid white lines between rectangles

        x_arr.forEach(x => {
            y_arr.forEach(y => {

                var gridPoint = {};
                gridPoint.x = x;
                gridPoint.y = y;

                // check if contained within outerwall
                var topLeft = new Point(x, y);
                var topRight = new Point(x + this.gridSize, y);
                var bottomLeft = new Point(x, y + this.gridSize);
                var bottomRight = new Point(x + this.gridSize, y + this.gridSize);
                
                var bounds = [topLeft, topRight, bottomLeft, bottomRight];
                
                var containedPoints = 0;
                bounds.forEach(point => {
                    if (this.outerWallPath.contains(point)) {
                        containedPoints++;
                    }
                });

                if (containedPoints === 0) {
                    return;
                } else if (containedPoints < 4) {
                    gridPoint.intersectFlag = true;
                }


                var allNeighbors = [];
                sensors.forEach(sensor => {
                    var neighbor = {};
                    neighbor.sensorId = sensor.id;
                    neighbor.delta = Math.sqrt((x - sensor.coordinates.x) ** 2 + (y - sensor.coordinates.y) ** 2);
                    allNeighbors.push(neighbor);
                });

                // find nearest neighbors
                allNeighbors.sort((a, b) => a.delta > b.delta ? 1 : -1);

                var nearestNeighbors = allNeighbors.slice(0, K);
                var veryNearNeighbor = false;
                nearestNeighbors.forEach(neighbor => {
                    if (neighbor.delta <= NEAR_LIMIT) {
                        veryNearNeighbor = neighbor;
                    } else {
                        neighbor.weight = 1 / neighbor.delta ** 2;
                    }
                });

                // if has a very near neighbor -> set its weight to 1 and others to 0
                if (veryNearNeighbor) {
                    nearestNeighbors.forEach(neighbor => {
                        if (neighbor.sensorId === veryNearNeighbor.sensorId) {
                            neighbor.weight = 1;
                        } else {
                            neighbor.weight = 0.01;
                        }
                    })
                }


                gridPoint.nearestNeighbors = nearestNeighbors;
                this.gridPoints.push(gridPoint);

            });
        });

    }

    setSensorData(newSensorData) {
        if (this.sensorData && this.sensorData.length) {
            // Update current sensor data
            newSensorData.forEach(newSensor => {
                var currentSensorData = this.sensorData.find(s => s.sensorId === newSensor.sensorId);
                if (currentSensorData) {
                    currentSensorData.averageDecibel = newSensor.averageDecibel;
                    currentSensorData.averageDiff = newSensor.averageDiff || newSensor.averageDiff;
                    currentSensorData.AvgFilDB = currentSensorData.averageDecibel - currentSensorData.averageDiff;
                    currentSensorData.ignore = newSensor.ignore;
                    
                }
            });
        } else {
            this.sensorData = [...newSensorData];
        }

        if (!this.outerWallPath) {
            return;
        }

        if (this.dataMode === heatmapTypes.dataModes.LIVE) {

            if (!this.isFluctuating) {
                this.startFluctuating();
            }

        } else {
            if (this.isFluctuating) {
                this.stopFluctuating();
            }
            // display data directly if not fluctuating
            this.getDataPoints(this.sensorData);
            this.displayDataPoints();
        }
    }

    getDataPoints(sensorData) {

        // loop through all gridpoints and compute value
        this.gridPoints.forEach(gridPoint => {
            var weightValue = 0;
            var weightSum = 0;
            gridPoint.nearestNeighbors.forEach(neighbor => {
                var sensorDataPoint = sensorData.find(s => s.sensorId === neighbor.sensorId && !s.ignore);
                if (sensorDataPoint) {
                    var dataPointValue = sensorDataPoint[this.dataType];
                    weightValue += dataPointValue * neighbor.weight;
                    weightSum += neighbor.weight;
                }
            })

            var gridValue = weightValue / weightSum;
            gridPoint.value = gridValue;
        });

    }

    displayDataPoints() {
        this.layer.activate();
        // loop through gridpoints and draw rectangles
        var rectSize = new Size(this.gridSize, this.gridSize);

        this.gridPoints.forEach(gridPoint => {
            
            var color = gridPoint.value ? getDataColor(gridPoint.value) : 'lightgray'; // show gray if no value;

            if (!gridPoint.path) {
                var point = new Point(gridPoint.x, gridPoint.y);
                var rectangle = new Rectangle(point, rectSize);
                var path = new Path.Rectangle(rectangle);
                if (gridPoint.intersectFlag) {
                    var intersectpath = path.intersect(this.outerWallPath);
                    path.remove();
                    intersectpath.sendToBack();
                    gridPoint.path = intersectpath;
                } else {
                    path.sendToBack();
                    gridPoint.path = path;
                }
            }
            
            gridPoint.path.fillColor = color;
            gridPoint.path.fillColor.alpha = DATA_LAYER_ALPHA;
            gridPoint.path.strokeColor = color;
            gridPoint.path.strokeColor.alpha = 0.3;
            gridPoint.path.strokeWidth = 0.001;

        });

    }

    startFluctuating() {
        this.isFluctuating = true;
        clearInterval(this.fluctuateInterval);
        this.sensorData.forEach(sensor => {
            // Only set fluctdata if it has not already been set

            var fluctNumber = getFluctuation(sensor[this.dataType]);
            sensor.fluctData = {
                prevFluctNumber: sensor[this.dataType],
                currentFluctNumber: sensor[this.dataType],
                nextFluctNumber: fluctNumber,
                fluctCount: 0
            }

        })

        this.fluctuateInterval = setInterval(() => {

            this.sensorData.forEach(sensor => {
                var { prevFluctNumber, nextFluctNumber, fluctCount } = sensor.fluctData;
                var value = (prevFluctNumber + (nextFluctNumber - prevFluctNumber) * fluctCount / FLUCT_STEPS);
                sensor.fluctData.currentFluctNumber = value;
                if (++sensor.fluctData.fluctCount === FLUCT_STEPS) {
                    sensor.fluctData.fluctCount = 0;
                    sensor.fluctData.prevFluctNumber = value;
                    var dataPointValue = sensor[this.dataType];
                    sensor.fluctData.nextFluctNumber = getFluctuation(dataPointValue);
                }
            })

            // create sensordata object with fluct numbers
            var fluctSensorData = [];
            this.sensorData.forEach(sensor => {
                var data = { ...sensor };
                data[this.dataType] = sensor.fluctData.currentFluctNumber;
                fluctSensorData.push(data);
            })

            this.getDataPoints(fluctSensorData);
            this.displayDataPoints();
        }, 500);
    }

    stopFluctuating() {
        clearInterval(this.fluctuateInterval);
        this.isFluctuating = false;
        this.getDataPoints(this.sensorData);
        this.displayDataPoints();
    }

    clearData() {
        clearInterval(this.fluctuateInterval);
        this.isFluctuating = false;
        this.gridPoints.forEach(gridPoint => {
            if (gridPoint.path) {
                gridPoint.path.remove();
            }
        });
    }
}

export default DataLayer;

function linspace(startValue, stopValue, cardinality) {
    var arr = [];
    var step = (stopValue - startValue) / (cardinality - 1);
    for (var i = 0; i < cardinality; i++) {
        arr.push(startValue + (step * i));
    }
    return arr;
}

function arange(start, stop, step) {
    var arr = [];
    var n_steps = (stop-start)/step;
    for (var i=0; i<n_steps; i++) {
        arr.push(start + i*step);
    }
    return arr;
}

function getFluctuation(value) {
    return (Math.random() * 2 - 1) * MAX_FLUCT + value;
}

