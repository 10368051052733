import { getDeviceNumber } from "../../../../../scripts/common";
import { SensorIcon } from "../../../../UiComponents/Icons";
import { TextMuted } from "../../../../UiComponents/text/StyledText";
import deviceTypes from "../deviceTypes";
import { ListItem, LQILabel, NodeList, SensorInfoContainer } from "./networkAnalysisStyles";

const NodeItem = props => {
    const nodeType = deviceTypes.nodeTypes.find(nodeType => nodeType.key === parseInt(props.sensor.nodeType));

    return <ListItem>
        <SensorInfoContainer>
            <SensorIcon height={'15px'} color={nodeType?.displayColor} />
            <div>
                <span>{`Sensor ${getDeviceNumber(props.sensor)}`}</span>
                <LQILabel>LQI: {props.sensor.lqi}</LQILabel>
                <TextMuted>{nodeType?.display}</TextMuted>
            </div>
        </SensorInfoContainer>

        {props.sensor?.children?.length ?
            <NodeList>
                {props.sensor?.children?.map((child, index) => {
                    return <NodeItem key={index} sensor={child} />
                })}
            </NodeList>
            : <></>}
    </ListItem>
}

export default NodeItem;