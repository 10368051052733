import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from '../../../UI/theme';
import IconLogo from '../../UiComponents/IconLogo';
import Modal, { ModalContent } from '../../UiComponents/Modal';

const MasterVolumeContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    color: ${theme.colors.textGray};
`

const MasterVolume = styled.span`
    color: ${props => props.isRegulating ? theme.colors.greenEnergy : theme.colors.textGray};
`
const Flex = styled.div`
    display: flex;
    align-items: center;
`

const StyledLogo = styled(IconLogo)`
    width: ${theme.fontSize.large};
    height: ${theme.fontSize.large};
    margin-right: 10px;
    
    ${props => props.disabled && css`
        rect {
            fill: ${theme.colors.darkSpace70};
        }
    `}
`

const MasterZoneInfo = props => {
    const [showModal, setShowModal] = useState(false);

    return <><div>
        <MasterVolumeContainer>
            <Flex onClick={(e) => { e.stopPropagation(); setShowModal(true); }}>
                {props.isRegulating ? <>
                    {/* {<StyledLogo />} */}
                </> : <></>}
            Master regulation {props.isRegulating ? 'on' : 'off'}
            </Flex>
        </MasterVolumeContainer>
        <MasterVolumeContainer>
            <span>Volume</span>
            <Flex>

                <MasterVolume >{props.sysvol}%</MasterVolume></Flex>
        </MasterVolumeContainer>
    </div>

        {showModal ? <>
            <Modal show={showModal} close={() => setShowModal(false)}>
                {props.isRegulating ? <>
                    <ModalContent>
                        <h2>Master regulation</h2>
                        <p>Master regulation is turned on. It can be turned off using global regulation or by turning off regulation in all slave zones</p>
                    </ModalContent>
                </> : <></>}
            </Modal>
        </> : <></>}
    </>
}

export default MasterZoneInfo;