import Paper, { Path, Rectangle, CompoundPath, Point, Segment, PointText, Size, Group } from "paper";
import heatmapTypes from "../container/heatmapTypes";
import CanvasController from "./CanvasController";
import theme from '../../../UI/theme';

class Zone {
    constructor(zone, outerWallElement, color = 'rgba(255, 153, 255, 0.5)') {
        this.outerWallElement = outerWallElement;
        this.color = color;
        this.cornerPointsLists = [];
        this.cornerPathsLists = [];
        this.innerPaths = [];
        this.currentRectangle = null;
        this.zone = null;
        this.croppedZone = null;
        this.floorElementID = zone.floorElementId;
        this.mousedownPoint = null;
        this.mode = Zone.Modes.ADD;
        this.zoneName = zone.zoneName;
        this.geometry = zone.geometry || {};
        this.labelGroup = new Group();

        if (zone.geometry && zone.geometry.paths && zone.geometry.paths.length > 0) {
            this.zone = new Path();
            zone.geometry.paths.forEach(path => {
                var segments = path.map(point => new Segment(new Point(point.x, point.y)));
                var path = new Path(segments);
                path.closePath();
                this.zone = this.zone.unite(path);
                path.remove();
            });
            this.drawCroppedZone();
            this.zone.fillColor = this.color;
            this.drawLabel();
        }

        this.deselect();
    }

    addEventHandlers() {
        Paper.view.element.style.setProperty('cursor', 'crosshair');

        Paper.view.onMouseDown = (event) => {
            if (this.active) {
                this.mousedownPoint = event.point;
                this.mode = event.modifiers.control || event.modifiers.command  ? Zone.Modes.SUBTRACT : Zone.Modes.ADD;
                this.currentRectangle = new Rectangle(this.mousedownPoint, this.mousedownPoint);
            }
        }

        Paper.view.onMouseDrag = (event) => {
            if (this.active) {
                this.currentRectangle = new Rectangle(this.mousedownPoint, event.point);
                this.drawCurrentRectangle();
            }
        }

        Paper.view.onMouseUp = (event) => {
            if (this.active) {
                if (this.mode === Zone.Modes.ADD) {
                    if (!this.zone) {
                        this.zone = new Path.Rectangle(this.currentRectangle);
                        this.zone.fillColor = this.color;
                        this.zone.fillColor.alpha = 0.2;
                    } else {
                        var newRect = new Path.Rectangle(this.currentRectangle);
                        this.zone.fillColor = 'transparent';
                        this.zone = this.zone.unite(newRect).reduce();
                        this.zone.fillColor = this.color;
                        this.zone.fillColor.alpha = 0.2;
                        newRect.remove();
                    }
                } else if (this.mode === Zone.Modes.SUBTRACT) {
                    if (this.zone) {
                        var subtractRect = new Path.Rectangle(this.currentRectangle);
                        this.zone.fillColor = 'transparent';
                        this.zone = this.zone.subtract(subtractRect).reduce();
                        this.zone.fillColor = this.color;
                        this.zone.fillColor.alpha = 0.2;
                        subtractRect.remove();
                    }
                }

                this.drawCroppedZone();
                this.updateCornerPoints();
                this.drawCornerNodes();
                this.mousedownPoint = null;
                this.currentRectangle = null;

                this.updateZonePlacementState();
                this.drawLabel();
            }
        }

        
        // Label group event listeners
        this.labelGroup.onMouseDrag = (event) => {
            if (this.active) {
                this.labelGroup.position = this.labelGroup.position.add(event.delta);
                event.stopPropagation();
            }
        }
        this.labelGroup.onMouseUp = (event) => {
            if (this.active) {
                this.geometry.labelPosition = {x: this.labelGroup.position.x, y: this.labelGroup.position.y};
                event.stopPropagation();
                this.updateZonePlacementState();
            }
        }

        this.labelGroup.onMouseEnter = (event) => {
            if (this.active) {
                Paper.view.element.style.setProperty('cursor', 'move');
            }
        }
        this.labelGroup.onMouseLeave = (event) => {
            if (this.active) {
                Paper.view.element.style.setProperty('cursor', 'crosshair');
            }
        }
        // ----------------------------
        

        Paper.view.onMouseEnter = (event) => {
            if (this.active) {
                Paper.view.element.style.setProperty('cursor', 'crosshair');
            }
        }
    }

    updateCornerPoints() {
        this.cornerPointsLists = [];
        if (this.zone instanceof Path) {
            this.cornerPointsLists = [this.zone.segments.map(seg => seg.point)];
        } else if (this.zone instanceof CompoundPath) {
            this.zone.children.forEach(childPath => {
                this.cornerPointsLists.push(childPath.segments.map(seg => seg.point));
            });
        }
    }

    updateZonePlacementState() {
        var paths = []
        if (this.zone instanceof Path) {
            paths.push(this.zone.segments.map(seg => ({ x: seg.point.x, y: seg.point.y })));
        } else if (this.zone instanceof CompoundPath) {
            paths = this.zone.children.map(child => child.segments.map(seg => ({ x: seg.point.x, y: seg.point.y })));
        }
        CanvasController.canvasCallback(heatmapTypes.actions.ZONE_PLACED,
            {
                id: this.floorElementID,
                geometry: {
                    paths,
                    labelPosition: this.geometry.labelPosition
                }
            });
    }

    drawCurrentRectangle() {
        var currentRectPath = new Path.Rectangle(this.currentRectangle).removeOnDrag().removeOnUp();
        currentRectPath.fillColor = this.mode === Zone.Modes.ADD ? this.color : 'gray';
        currentRectPath.fillColor.alpha = 0.2;
    }

    drawCornerNodes() {
        this.cornerPathsLists.forEach(cornerList => {
            cornerList.forEach(corner => {
                corner.remove();
            });
        });
        this.cornerPathsLists = [];
        var segmentIndexes = [];
        var tolerance = 0.001;

        this.cornerPointsLists.forEach((pointArray) => {
            var newCompoundPath = [];

            pointArray.forEach((point) => {
                var nodeHandle = new Path.Circle(point.x, point.y, 0.2);
                nodeHandle.fillColor = 'white';
                nodeHandle.strokeColor = 'blue';
                nodeHandle.strokeWidth = 0.05;

                nodeHandle.onMouseDown = (event) => {
                    if (this.active) {
                        event.stopPropagation();

                        if (this.zone instanceof Path) {
                            this.zone.segments.forEach((seg, index) => {
                                if (seg.point.isClose(nodeHandle.position, tolerance)) {
                                    segmentIndexes.push([0, index]);
                                }
                            });
                        } else if (this.zone instanceof CompoundPath) {
                            this.zone.children.forEach((child, childIndex) => {
                                child.segments.forEach((seg, segIndex) => {
                                    if (seg.point.isClose(nodeHandle.position, tolerance)) {
                                        segmentIndexes.push([childIndex, segIndex]);
                                    }
                                });
                            });
                        }
                    }
                };

                nodeHandle.onMouseDrag = (event) => {
                    if (this.active) {
                        event.stopPropagation();
                        nodeHandle.position = event.point;
                        segmentIndexes.forEach((indexPair) => {
                            if (this.zone instanceof Path) {
                                this.zone.segments[indexPair[1]].point = nodeHandle.position;
                            } else if (this.zone instanceof CompoundPath) {
                                this.zone.children[indexPair[0]].segments[indexPair[1]].point = nodeHandle.position;
                            }
                        });
                        this.drawCroppedZone();
                        

                    }
                };

                nodeHandle.onMouseUp = (event) => {
                    if (this.active) {
                        event.stopPropagation();
                        segmentIndexes = [];
                        this.updateZonePlacementState();
                    }
                };

                nodeHandle.onMouseEnter = (event) => {
                    Paper.view.element.style.setProperty('cursor', 'move');
                }
    
                nodeHandle.onMouseLeave = (event) => {
                    Paper.view.element.style.setProperty('cursor', this.active ? 'crosshair' : null);
                }

                newCompoundPath.push(nodeHandle);
            })

            this.cornerPathsLists.push(newCompoundPath);
        })
    }

    removeCornerNodes() {
        this.cornerPointsLists = [];
        this.cornerPathsLists.forEach(paths => paths.forEach(path => path.removeSegments()));
        this.cornerPathsLists = [];
    }

    drawCroppedZone() {
        var outerWallPath = new Path();
        outerWallPath.addSegments(this.outerWallElement.geometry.map(point => new Point(point.x, point.y)));
        outerWallPath.closePath();
        if (this.croppedZone) {
            this.croppedZone.remove();
        }
        this.croppedZone = this.zone.intersect(outerWallPath);
        this.croppedZone.strokeColor = this.color;
        this.croppedZone.strokeColor.alpha = 1;
        this.croppedZone.strokeWidth = 0.2;
        this.croppedZone.dashArray = [0.2, 0.4];
        this.croppedZone.fillColor = this.color;
        this.croppedZone.strokeCap = 'round';
        this.croppedZone.insertAbove(this.zone);
        outerWallPath.remove();
    }

    drawLabel() {
        if (this.croppedZone) {
            if (this.labelGroup) {
                this.labelGroup.removeChildren();
            }
            var point = this.croppedZone.bounds.center;
            if (this.geometry && this.geometry.labelPosition) {
                point = new Point(this.geometry.labelPosition.x, this.geometry.labelPosition.y);
            }
            var text = new PointText(point);
            text.justification = 'center';
            text.fillColor = 'white';
            text.fontSize=1;
            text.content = this.zoneName;
            text.fontFamily = 'Karelia';
            text.name = 'ZONE_NAME';
            
            // apply scaling because paper does not work well with font-sizes below 1
            text.scale(0.6);

            this.labelGroup.addChild(text);
            this.addLabelBackground(this.labelGroup.bounds);
            this.labelGroup.insertAbove(this.croppedZone);
        }
    }

    showZoneData(zoneData) {
        if (this.labelGroup) {
            var zoneDataText = this.labelGroup.children.find(child => child.name === 'ZONE_DATA');
            if (!zoneDataText) {
                var zoneName = this.labelGroup.children.find(child => child.name === 'ZONE_NAME');
                if (zoneName) {
                    var point = new Point(zoneName.bounds.center);
                    point.y += 1.6;
        
        
                    var text = new PointText(point);
                    text.justification = 'center';
                    text.fillColor = 'white';
                    text.fontSize=1.6;
                    text.content = zoneData;
                    text.fontFamily = 'Karelia';
                    text.name = 'ZONE_DATA';
                    text.scale(0.6);
                    this.labelGroup.addChild(text);

                    // remove existing bounding path
                    var boundingPath = this.labelGroup.children.find(child => child.name === 'LABEL_RECT');
                    boundingPath.remove();

                    this.addLabelBackground(this.labelGroup.bounds);
                }
            } else {
                zoneDataText.content = zoneData;
            }
        }
    }

    addLabelBackground(bounds) {

        // add new bounding path
        var boundingRect = new Rectangle(bounds);
        boundingRect.height += 0.8;
        boundingRect.width += 0.8;
        boundingRect.center = bounds.center;
        var radius = new Size(0.1,0.1)
        var boundingPath = new Path.Rectangle(boundingRect, radius);
        boundingPath.fillColor = 'black';
        boundingPath.fillColor.alpha = 0.3;
        boundingPath.name = 'LABEL_RECT';
        this.labelGroup.addChild(boundingPath);
        boundingPath.sendToBack();
    }

    removeLabel() {
        if (this.labelGroup) {
            this.labelGroup.remove();
        }
    }

    activateLabel() {
        
        var labelRect = this.labelGroup.children.find(child => child.name === 'LABEL_RECT');
        if (labelRect) {
            labelRect.strokeColor = theme.colors.activeBlue;
            labelRect.strokeWidth = 0.1;
        }

    }

    deactivateLabel() {
        var labelRect = this.labelGroup.children.find(child => child.name === 'LABEL_RECT');
        if (labelRect) {
            labelRect.strokeColor = null
            labelRect.strokeWidth = null;
        }
    }

    remove() {
        this.active = false;
        this.removeCornerNodes();

        if (this.currentRectangle) {
            this.currentRectangle = null;
        }

        if (this.zone instanceof Path) {
            this.zone.removeSegments();
        } else if (this.zone instanceof CompoundPath) {
            this.zone.children.forEach(child => {
                child.remove();
            });
            this.zone.removeChildren();
        }

        this.croppedZone.remove();
        this.removeLabel();

        CanvasController.canvasCallback(heatmapTypes.actions.ZONE_REMOVED, { id: this.floorElementID });
    }

    deselect() {
        this.active = false;
        this.removeCornerNodes();

        if (this.currentRectangle) {
            this.currentRectangle = null;
        }

        if (this.zone) {
            this.zone.visible = true;
            this.drawCroppedZone();
            this.zone.visible = false;
        }
        this.deactivateLabel();
        Paper.view.element.style.setProperty('cursor', null);
    }

    select() {
        this.active = true;
        if (this.zone) {
            this.zone.visible = true;
            this.drawCroppedZone();
            this.updateCornerPoints();
            this.drawCornerNodes();
            this.activateLabel();
        }
        this.addEventHandlers();
    }

    deactivate() {
        this.deselect();
        if (this.croppedZone) {
            this.croppedZone.remove();
        }
    }

    drawInnerPath() {
        if (this.croppedZone) {
            var paths = []; 
            if (this.croppedZone instanceof Path) {
                paths.push(this.croppedZone);
            } else if (this.croppedZone instanceof CompoundPath) {
                paths = this.croppedZone.children;
            }
            paths.forEach(path => {
                var innerPoints = [];
                var segments = path.segments;
                if (!segments) {
                    return;
                }
                var L = segments.length;
                segments.forEach((segment, i) => {
                    var prevSegment = segments[i-1];
                    var nextSegment = segments[i+1];
                    if (i == 0) {
                        prevSegment = segments[L-1];
                    } else if (i == L-1) {
                        nextSegment = segments[0];
                    }
                    
                    var v = getUnitVector(prevSegment, segment);
                    var u = getUnitVector(nextSegment, segment);
                    
                    var dir = getUnit(u, v);
                    var downScale = 0.01;
                    var point = segment.point;
                    var innerX = point.x + dir.x*downScale;
                    var innerY = point.y + dir.y*downScale;
                    var innerPoint = new Point(innerX, innerY);
                    var direction = 1;
                    if (!this.croppedZone.contains(innerPoint)) {
                        // try the other way
                        innerX = point.x - dir.x*downScale;
                        innerY = point.y - dir.y*downScale;
                        innerPoint = new Point(innerX, innerY);
                        direction = -1;
                    } 
                    var a = 0.3;
                    var offset = a/Math.sqrt(1-(dir.x*v.x + dir.y*v.y)**2);
                    var offsetInnerX = point.x + direction*dir.x*offset;
                    var offsetInnerY = point.y + direction*dir.y*offset;
                    var offsetPoint = new Point(offsetInnerX, offsetInnerY);
                    innerPoints.push(offsetPoint);
        
                });
    
                var segments = innerPoints.map(point => new Segment(point));
                var innerPath = new Path(segments);
                innerPath.closePath();
                innerPath.strokeColor = 'white';
                innerPath.strokeColor.alpha = 0.3;
                innerPath.strokeWidth = 0.1;
                this.innerPaths.push(innerPath);
            })
        }
            
    }

    removeInnerPath() {
        if (this.innerPaths) {
            this.innerPaths.forEach(path => {
                path.remove();
            })
        }
    }
}

export default Zone;

Zone.Modes = {
    ADD: 'ADD',
    SUBTRACT: 'SUBTRACT'
}

function getUnitVector(prevSegment, segment) {
    var v1x = prevSegment.point.x - segment.point.x;
    var v1y = prevSegment.point.y - segment.point.y;
    var v1Len = Math.sqrt(v1x**2 + v1y**2);
    v1x = v1x/v1Len;
    v1y = v1y/v1Len; 
    return {x: v1x, y: v1y};
}

function getUnit(u,v) {
    var wx = u.x + v.x;
    var wy = u.y + v.y;
    var L = Math.sqrt(wx**2 + wy**2);
    return {x: wx/L, y: wy/L};
}