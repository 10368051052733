import React, { useState } from 'react';
import InputField from '../../../UiComponents/InputField';
import Button from '../../../UiComponents/Button';
import { FaPlus } from 'react-icons/fa';
import Dropdown from '../../../UiComponents/Dropdown';
import { getProcessorDropdownItems, getSelectedProcessorDropdownItem } from './Processor/processorUtils';

const NewZoneCreator = props => {
    const [state, setState] = useState('NONE');
    const [newZone, setNewZone] = useState({});
    const [error, setError] = useState();

    function getDefaultProcessorId() {
        if (props.processors.length === 1) {
            return props.processors[0].processorId;
        }
    }

    function updateNewZone(field, value) {
        var updatedZone = { ...newZone };
        updatedZone[field] = value;

        if (updatedZone.zoneName && updatedZone.objectAddress) {
            setError('');
        }
        setNewZone(updatedZone);
    }
    async function addZoneHandler() {
        if (newZone.zoneName && newZone.objectAddress) {
            setState('LOADING');
            var zoneObject = { ...newZone };
            zoneObject.hubId = props.hubId;

            try {
                await props.addZone(zoneObject);
                setState('NONE');
            } catch (err) {
                setState('FAILED');
            }

        } else {
            setError('Please fill out required information');
        }
    }

    function setStateHandler(state) {
        if (state === 'CREATE_ZONE') {
            setNewZone({
                zoneName: '',
                objectAddress: '',
                processorId: getDefaultProcessorId()
            })
        }
        setState(state)
    }

    return <>
        <div className="divider"></div>

        {state === 'NONE' ? <>
            <div>
                <Button disabled={props.readOnly} secondary icon onClick={() => setStateHandler('CREATE_ZONE')}>
                    <span><FaPlus /></span>
                    <span>Add new zone</span>
                </Button>
            </div>
        </> : <></>}

        {state === 'CREATE_ZONE' ? <>
            <div>
                <h4>
                    Zone Name:
                        <div>
                        <InputField small value={newZone.zoneName || ''} onChange={(e) => updateNewZone('zoneName', e.target.value)} />
                    </div>
                </h4>
                <h4>
                    Address:
                        <div>
                        <InputField small value={newZone.objectAddress || ''} onChange={(e) => updateNewZone('objectAddress', e.target.value)} />
                    </div>
                </h4>

                <h4>
                    Processor
                        <div>
                        <Dropdown small
                            items={getProcessorDropdownItems(props.processors)}
                            onClick={(item) => updateNewZone('processorId', item.value)}
                            selectedItem={getSelectedProcessorDropdownItem(props.processors, newZone.processorId)}
                        />
                    </div>
                </h4>
                {error ? <>{error}</> : <></>}
                <div>
                    <Button primary margin onClick={addZoneHandler}>Create</Button>
                    <Button secondary margin onClick={() => setState('NONE')}>Cancel</Button>
                </div>
            </div>
        </> : <> </>}

        {state === 'LOADING' ? <>
            <div>
                <div>Creating zone...</div>
            </div>
        </> : <></>}

        {state === 'SUCCESS' ? <>
            <div>
                <h4>Zone successfully added</h4>
                <Button primary onClick={() => setState('NONE')}>Continue</Button>
            </div>
        </> : <></>}

        {state === 'FAILED' ? <>
            <div>
                <h4>Could not create new zone</h4>
                <div>
                    <Button primary margin onClick={addZoneHandler}>Retry</Button>
                    <Button secondary margin onClick={() => setState('NONE')}>Cancel</Button>
                </div>
            </div>
        </> : <></>}

    </>;
}

export default NewZoneCreator;
