import { FaTrash } from "react-icons/fa"
import IconButton from "../../UiComponents/buttons/IconButton"
import styled from 'styled-components';
import Modal, { ModalContent } from "../../UiComponents/Modal";
import Button from "../../UiComponents/Button";
import { useState } from "react";
import { loadingStates } from "../installationTypes";
import NeutralSpinner from "../../UiComponents/Animations/NeutralSpinner";

const Container = styled.div`
    display: flex;
    justify-content: center;
`

const DeleteButton = ({ onClick }) => {
    const [showModal, setShowModal] = useState(false);

    return <>
        <Container>
            <IconButton onClick={() => setShowModal(true)}><FaTrash /></IconButton>
        </Container>
        <DeleteModal show={showModal} close={() => setShowModal(false)} onClick={onClick} />
    </>
}

export const DeleteListButton = ({ onClick }) => {
    const [showModal, setShowModal] = useState(false);

    return <>
        <Button secondary small onClick={() => setShowModal(true)}>Delete</Button>
        <DeleteModal show={showModal} close={() => setShowModal(false)} onClick={onClick} />
    </>
}

const DeleteModal = ({ show, close, onClick }) => {
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);

    const onDelete = async () => {
        try {
            const timeout = setTimeout(() => {
                setLoadingState(loadingStates.LOADING);
            }, 500);
            
            await onClick();
            clearTimeout(timeout);
            close();
            setLoadingState(loadingStates.SUCCESS);
        } catch (err) {
            setLoadingState(loadingStates.FAILED);
        }
    }

    return <>
        <Modal show={show} close={close}>
            <ModalContent>
                <h4>Are you sure you want to delete this item?</h4>

                {loadingState !== loadingStates.LOADING ? <>
                    <Button primary autoFocus onClick={onDelete}>Delete</Button>
                    <Button secondary onClick={close}>Cancel</Button>
                </> : <></>}

                {loadingState === loadingStates.LOADING ? <>
                    <NeutralSpinner />
                </> : <></>}

            </ModalContent>
        </Modal>
    </>
}


export default DeleteButton;