import React, { useEffect, useState } from 'react';
import { useSubscription } from '../hooks/useSubscription';
import { subscriptionsTypes } from '../scripts/subscription/subscriptionTypes';
import Group from '../components/panel/Group';
import styled from 'styled-components';
import theme from '../UI/theme';
import hierarchyTypes from '../components/settings/venue/device/hierarchyTypes';
import { getCustomer } from '../scripts/datasource';
import Footer from '../components/navigation/Footer';
import { updateElementInListState } from '../scripts/state/stateUtils';
import MusicPlayerList from '../components/panel/musicPlayer/MusicPlayerList';
import StartSetupLink from '../components/live/StartSetupLink';
import GoToSettingsLink from '../components/live/GoToSettingsLink';

const PanelViewContainer = styled.div`
    width: fill-available;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        max-width: 70em;
    }

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        margin-bottom: 94px;
    }
`

const Header = styled.label`
    color: ${theme.colors.darkSpace30};
    width: fill-available;
    text-align: center;
    line-height: 1.4rem;
    height: 1.4rem;

    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        height: 3rem;
        line-height: 3rem;
        font-size: ${theme.fontSize.larger};
    }

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        margin-top: 10px;
    }
`

const PanelView = props => {
    const [hubs, setHubs] = useSubscription(subscriptionsTypes.hubs);
    const [processors, setProcessors] = useSubscription(subscriptionsTypes.processors);
    const [schedules, setSchedules] = useSubscription(subscriptionsTypes.schedules);
    const [zones, setZones] = useSubscription(subscriptionsTypes.zones);
    const [groups, setGroups] = useState();
    const [sourceSelectors, setSourceSelectors] = useSubscription(subscriptionsTypes.sourceSelectors);
    const [sources, setSources] = useSubscription(subscriptionsTypes.sources);
    const [musicPlayers, setMusicPlayers] = useSubscription(subscriptionsTypes.musicPlayers);
    const [customer, setCustomer] = useState();

    const hasZonesWithoutGroup = true;

    useEffect(() => {
        getData();
    }, [props.customerId]);

    const getData = async () => {
        let customerResult = await getCustomer(props.customerId);
        setCustomer(customerResult);
    }

    const groupExists = (groupId) => {
        if (!groupId) {
            return false;
        } else {
            return true;
        }
    }

    const setZone = (zoneId, updatedProps) => {
        updateElementInListState(zones, setZones, zoneId, updatedProps, 'zoneId');
    }

    if (hubs?.length === 0) {
        return <PanelViewContainer>
            <StartSetupLink />
        </PanelViewContainer>
    } else if (zones?.length === 0) {
        return <PanelViewContainer>
            <GoToSettingsLink
                customerId={props.customerId}
                hub={hubs?.length === 1 ? hubs[0] : null}
            />
        </PanelViewContainer>
    }

    if (zones && sourceSelectors) {
        return <PanelViewContainer>
            <Header>{customer?.companyName}</Header>

            {groups?.map((group, index) => {
                <Group
                    key={index}
                    customerId={props.customerId}
                    sourceSelectors={sourceSelectors.filter(item => item.groupId === group.groupId)}
                    zones={zones.filter(zone => zone.groupId === group.groupId && zone.hierarchyType !== hierarchyTypes.MASTER.value)}
                    setZone={setZone}
                    processors={processors}
                    sources={sources}
                    hubs={hubs}
                    schedules={schedules}
                />
            })}

            {hasZonesWithoutGroup ? <Group
                key={'ZonesWithoutGroup'}
                customerId={props.customerId}
                sourceSelectors={sourceSelectors.filter(item => !groupExists(item.groupId))}
                zones={zones.filter(zone => !groupExists(zone.groupId) && zone.hierarchyType !== hierarchyTypes.MASTER.value)}
                setZone={setZone}
                processors={processors}
                sources={sources}
                hubs={hubs}
                schedules={schedules}
            /> : <></>}

            {musicPlayers?.length > 0 ?
                <MusicPlayerList musicPlayers={musicPlayers} />
                : <></>}

            <Footer />
        </PanelViewContainer>
    } else {
        return <></>
    }
}

export default PanelView;