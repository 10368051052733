import React from 'react';
import source_selector from './images/source_selector.png'
import vibe_button from './images/vibe_button.png'
import regulation_toggle from './images/regulation_toggle.png'
import mute_toggle from './images/mute_toggle.png'
import live_view_menu_item from './images/live_view_menu_item.png'
import styled from 'styled-components';
import { SensorIcon } from '../UiComponents/Icons';

const StyledImage = styled.img`
    padding: 20px 0;
    width: ${props => props.width || '50px'};
`
const Container = styled.div`
    max-height: 70vh;
    overflow-y: auto;
`


const PanelViewNotification = props => {
    return <Container>
        <h3>Updated home view</h3>
        <p>
            The panel will now replace the old home view. You can still find the old version in the menu under the live icon:
        </p>
        <StyledImage src={live_view_menu_item} width={'80px'}/>
        <h2>What's new?</h2>
        <div>
            <h3>Vibe</h3>
            <div>
                <StyledImage src={vibe_button} width={'80%'} />
            </div>
            <p>Control the vibe to be more or less music dominated by pressing the buttons.</p>

            <h3>Regulation on/off</h3>
            <div>
                <StyledImage src={regulation_toggle} />
            </div>
            <p>Turn automatic regulation on or off by pressing the button with the Waved logo.</p>

            <h3>Source</h3>
            <div>
                <StyledImage src={source_selector} />
            </div>
            <p>If your zone or group of zones have multiple available sources, this can be selected by pressing the source button to the left.</p>

            <h3>Mute</h3>
            <p>You can now set mute on or off in a zone by pressing the mute toggle</p>
            <div>
                <StyledImage src={mute_toggle} />
            </div>


            <h3>Global/group controls</h3>
            <p>You can set regulation on/off or mute for an entire group by pressing the buttons in the group control section at the top of the zone list.</p>

            <h3>Feedback</h3>
            <p>We'll continue to improve the functionality in the upcoming months. Please don't hesitate to give us feedback.</p>
        </div>
    </Container>
}

const panelViewNotification = {
    startDate: new Date(2022, 10, 25),
    endDate: new Date(2022, 11, 15),
    content: PanelViewNotification
}

export default panelViewNotification;