import React, {useState, useEffect} from 'react';
import styled, {css} from 'styled-components';
import {FaArrowAltCircleRight, FaArrowAltCircleLeft} from "react-icons/fa";
import heatmapTypes from './heatmapTypes';
import theme from '../../../UI/theme';
import {TextMuted} from '../../UiComponents/text/StyledText';

const BankItemName = styled.span`
    font-weight: bold;
    margin-right: 10px;
    color: ${theme.colors.darkSpace20};
`

const PlaceButton = styled.button`
    background-color: transparent;
    color: ${props => props.primary ? theme.colors.greenEnergy : 'darkgray'};
    border: none;
    font-size: 24px;

    &:hover {
        cursor:pointer;
    }
`

const ListItem = styled.li`
    padding: 10px 6px;
    border-bottom: 1px solid ${theme.colors.darkSpace70};
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover{
        cursor: pointer;
        background-color: ${theme.colors.grayHover};
    }

    ${props => props.isMarked && css`
        background-color: ${theme.colors.hightlightBlue};
    `}

    &:last-child {
        border-bottom: none;
    }
`

const FlexDiv = styled.div`
    display: flex;
    align-items: center;
`

const BankItem = props => {
    const item = props.item;
    const [placeState, setPlaceState] = useState(item.placed ? heatmapTypes.elementStates.PLACED : heatmapTypes.elementStates.NOT_PLACED);

    
    useEffect(() => {
        setPlaceState(item.placed ? heatmapTypes.elementStates.PLACED : heatmapTypes.elementStates.NOT_PLACED);
    }, [item.placed]);
    

    const placeHandler = (event) => {
        event.stopPropagation();
        props.placeItem(item);
        setPlaceState(heatmapTypes.elementStates.PLACING);
    }

    const removeHandler = (event) => {
        event.stopPropagation();
        props.removeItem();
    }

    const markItem = (event) => {
        if (placeState === heatmapTypes.elementStates.PLACED) {
            props.markItem(item);
        }
    }


    return <ListItem onClick={markItem} isMarked={props.isMarked}>

        <FlexDiv>
        {props.colorMarker ? <>
                <div>
                    {props.colorMarker}
                </div>
            </> : <></>}
            <div>
                <div>
                    <BankItemName>
                        {props.displayName}
                    </BankItemName>
                </div>
                <div>
                    <TextMuted>
                    {placeState == heatmapTypes.elementStates.NOT_PLACED ? <>
                        Not placed
                    </> : <></>}

                    {placeState == heatmapTypes.elementStates.PLACING ? <>
                        Placing
                    </> : <></>}
                    
                    {placeState == heatmapTypes.elementStates.PLACED ? <>
                        {props.floor ? props.floor.name : 'Not placed'}
                    </> : <></>}
                        
                    </TextMuted>
                </div>
            </div>
        </FlexDiv>

        <FlexDiv>

            {placeState == heatmapTypes.elementStates.NOT_PLACED ? <>
                <PlaceButton onClick={(event) => placeHandler(event)} primary><FaArrowAltCircleRight /></PlaceButton>
            </> : <></>}

            
            {placeState == heatmapTypes.elementStates.PLACED ? <>
                <PlaceButton onClick={(event) => removeHandler(event)}><FaArrowAltCircleLeft /></PlaceButton>
            </> : <></>}

            {props.blinkButton?
                <div>
                    {props.blinkButton}
                </div>
            :<></>}

        </FlexDiv>
    </ListItem>
}

export default BankItem;


