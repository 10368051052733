import React, { useRef } from 'react';
import CountUp from 'react-countup';

const CountingNumber = props => {
    const startValue = useRef(0);
    
    return <>
        <CountUp 
            start={startValue.current}
            end={props.value}
            onEnd={() => {startValue.current = props.value}}
        />
    </>
}

export default CountingNumber;
