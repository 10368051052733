import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InputField from '../UiComponents/InputField';
import Button from '../UiComponents/Button';
import Dropdown from '../UiComponents/Dropdown';
import theme from '../../UI/theme';
import { deleteMusicPlayer, updateMusicPlayer } from '../../scripts/musicPlayer';
import { getProcessors } from '../../scripts/processor';

const ContentSettingsContainer = styled.div`
`

const StyledInputField = styled(InputField)`
    
    
`

const StyledDropdown = styled(Dropdown)`
    background-color: ${theme.colors.darkSpace70};
    color: white;
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 250px;
    margin-bottom: 20px;
    gap: 20px;
`

const StyledDeleteButton = styled(Button)`
    
`

const ContentSettings = (props) => {

    const [displayName, setDisplayName] = useState(props.musicPlayer.displayName);
    const [defaultVolume, setDefaultVolume] = useState('');
    const [targetError, setTargetError] = useState('');
    const [updateError, setUpdateError] = useState();
    const [deletingPlayer, setDeletingPlayer] = useState(false);
    const [sources, setSources] = useState();
    const [selectedSource, setSelectedSource] = useState();
    const [currentPlayerVolume, setCurrentPlayerVolume] = useState();
    const [changesMade, setChangesMade] = useState(false);

    useEffect( () => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const processors = await getProcessors(props.musicPlayer.customerId);
            var sourcesResult = []
            processors.forEach(processor => {
                if (processor.sources) {
                    sourcesResult = [...sourcesResult, ...processor.sources];
                }
            });
            sourcesResult.forEach(source => {
                source.label = source.displayName;
            })
            setSources(sourcesResult);
            setSelectedSource(sourcesResult.find(s => {return s.id === props.musicPlayer.sourceId}));
        } catch (e) {
            console.log(e);
            setSources(['Missing Data']);
            setSelectedSource(['Missing Data']);
        }  
    } 

    const updateMusicPlayerHandler = () => {
        const update = {
            musicPlayerId: props.musicPlayer.musicPlayerId,
            displayName: displayName || props.musicPlayer.displayName,
        }
        if (selectedSource) {
            update.sourceId = selectedSource.id;
            update.sourceDisplayName = selectedSource.displayName;
        }
        if (defaultVolume) {
            update.defaultVolume = defaultVolume;
        }
        try {
            updateMusicPlayer(props.musicPlayer.customerId, update);
            setChangesMade(false);
        } catch (e) {
            setUpdateError('Error while updating music player');
        }
        
        
    } 

    const displayNameChangeHandler = (targetValue) => {
        setChangesMade(true);
        setDisplayName(targetValue);
    }

    const defaultVolumeChangeHandler = (targetValue) => {
        setChangesMade(true);
        if (targetValue == ''){
            setDefaultVolume('');
            setTargetError('');
        } else if (targetValue < 1 ){
            setDefaultVolume(1);
            setTargetError('Default Volume out of range, minimum default volume is 1');
        } else if (targetValue > 16) {
            setDefaultVolume(16);
            setTargetError('Default Volume out of range, maximum default volume is 16');
        }else {
            setDefaultVolume(targetValue);
            setTargetError('');
        }
    }

    const deletePlayer = () => {
        deleteMusicPlayer(props.musicPlayer.customerId, props.musicPlayer.musicPlayerId);
    }

    const confirmDeletePlayer = () => {
        setDeletingPlayer(true);
    }

    const cancelDeletePlayer = () => {
        setDeletingPlayer(false);
    }

    const close = () => {
        setDefaultVolume('');
        setDisplayName(props.musicPlayer.displayName);
        setSelectedSource(sources.find(s => {return s.id === props.musicPlayer.sourceId}));
        setDeletingPlayer(false);
        setTargetError('');
        setUpdateError();
        setChangesMade(false);
        props.close();
    }

    return (<>
        {props.show?
            <ContentSettingsContainer>{/*<Modal show={props.show} close={close}>*/}
                <h3>Player Settings</h3>
                {deletingPlayer?
                <>
                    <p>Are you sure you want to delete this music player?</p>
                    <p>Player name: {props.musicPlayer.displayName}</p>
                    <Button primary onClick={deletePlayer}>Delete Music Player</Button>
                    <br></br>
                    <Button secondary onClick={cancelDeletePlayer}>Cancel</Button>
                </>
                :
                <>
                    <p>Name</p>
                    <StyledInputField
                        value={displayName}
                        onChange={e => displayNameChangeHandler(e.target.value)}
                    />
                    <p>Default Volume</p>
                    <StyledInputField
                        placeholder={props.musicPlayer.defaultVolume}
                        value={defaultVolume}
                        onChange={e => defaultVolumeChangeHandler(e.target.value)}
                    />
                    <div>Current Player Volume: {props.currentMusic.volume}</div>
                    <p>Connected Source</p>
                    <StyledDropdown
                        items={sources}
                        selectedItem={selectedSource}
                        onClick={(item) => {
                            setChangesMade(true);
                            setSelectedSource(item);
                        }}
                    />
                    <p>{targetError}</p>
                    {changesMade?
                        <ButtonContainer>
                            <Button primary onClick={updateMusicPlayerHandler}>Save</Button>
                            <Button secondary onClick={close}>Cancel</Button>
                        </ButtonContainer>
                    :<></>}
                    
                    {updateError?
                        <p>{updateError}</p>
                    :<></>}
                    <StyledDeleteButton tertiary onClick={confirmDeletePlayer}>Delete Music Player</StyledDeleteButton>
                </>}
            {/*</Modal>*/}</ContentSettingsContainer>
        :<></>}
    </>);
}

export default ContentSettings;