import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { replaceZeroValues } from '../../../../scripts/common';
import theme from '../../../../UI/theme';
import { Chart } from 'chart.js';

const Container = styled.div`
    height: 200px;
    canvas {
        max-width: 600px;
        min-width: 200px;

        @media only screen and (min-width: ${theme.screenSizes.medium}px) {
            min-width: 300px;
        }
    }
`

const chartData = {
    labels: [],
    datasets: []
};

const options = {
    maintainAspectRatio: false,
    animation: false,
    scales: {
        x: {
            display: true,
            min: 1,
            max: 100,
            ticks: {
                maxRotation: 0,
                color: theme.colors.textGray,
                maxTicksLimit: 20
            },
            grid: {
                display: true,
                drawTicks: true,
                color: `${theme.colors.textGray}20`,
            }
        },
        y: {
            display: true,
            min: 20,
            max: 90,
            ticks: {
                color: theme.colors.textGray
            },
            grid: {
                display: true,
                drawBorder: true,
                borderColor: `${theme.colors.textGray}20`,
                drawTicks: true,
                color: `${theme.colors.textGray}20`,
            }
        }
    },

    pointHitRadius: 7,
}

const defaultChartConfig = {
    type: 'line',
    data: chartData,
    options
}

const Graph = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);
    const chartConfig = useRef(JSON.parse(JSON.stringify(defaultChartConfig)));

    useEffect(() => {
        if (props.data && props.data.datasets && props.data.labels) {
            chartConfig.current.data.datasets = [];
            props.data.datasets.forEach(dataset => {
                chartConfig.current.data.datasets.push({
                    data: replaceZeroValues(dataset.data),
                    type: 'line',
                    label: dataset.label,
                    fill: false,
                    tension: 0.3,
                    backgroundColor: dataset.color,
                    borderColor: dataset.color,
                    pointRadius: 1,
                })
            })
            chartConfig.current.data.labels = props.data.labels;

            if (chartInstance) {
                chartInstance.update();
            }
        }
    }, [props.data])


    var width;
    if (window.innerWidth <= theme.screenSizes.small) {
        width = Math.round(window.innerWidth * 0.6);
    }

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            if (chartInstance) {
                chartInstance.destroy();
            }
            const newChartInstance = new Chart(
                chartContainer.current,
                {
                    ...chartConfig.current,
                });
            setChartInstance(newChartInstance);
        }
    }, [chartContainer]);

    return (
        <Container>
            <canvas id={props.chartID} ref={chartContainer} width={width} />
        </Container>
    );
}

export default Graph;

