import React from 'react';
import { connectionTypes, processorTypes } from '../../../settings/venue/device/AudioSystem/processorTypes';
import { hubConnectionTypes } from '../../../settings/venue/device/hubTypes';
import ChoiceField from '../../fields/ChoiceField';

const processorInterfaces = [{
    value: 'eth0',
    label: 'Ethernet'
}, {
    value: 'wlan0',
    label: 'Wifi'
}];

const InterfaceChoiceField = ({ processor, updateObject, hub, cellControl }) => {
    const currentInterface = processorInterfaces.find(i => i.value === processor.interface);
    return <ChoiceField
        selectedItem={currentInterface}
        items={processorInterfaces}
        cellControl={cellControl}
        setValue={(value) => updateObject({ interface: value })}
        disabled={disableInterfaceField(processor, hub)}
    />
}


export default InterfaceChoiceField;

export const hideInterfaceField = (processors, hubs) => {
    let hide = true;
    processors.forEach(processor => {
        const hub = hubs.find(hub => hub.hubId === processor.hubId);
        if (!disableInterfaceField(processor, hub)) {
            hide = false;
        }
    });
    return hide;
}

export const disableInterfaceField = (processor, hub) => {
    const processorType = processorTypes[processor.type];
    return processorType?.connection !== connectionTypes.TCP || hub?.connectionType === hubConnectionTypes.ETHERNET;
}

