
import Paper, { Layer, Point } from "paper";
import Sensor from './Sensor';


var gridSpace = 1;
class SensorLayer {
    constructor() {
        this.layer = new Layer();
        this.active = false;
        Sensor.layer = this.layer;
        Sensor.sensorData = null;
        this.layer.name = 'Sensor Layer';
    }



    addEventHandlers() {
        Paper.view.onMouseDown = (event) => {
            if (this.active) {
                
            }
        }
        Sensor.list.forEach(sensor => {
            sensor.addEventHandlers();
        });
    }

    drawSensors(sensors) {
        this.layer.removeChildren();
        Sensor.initList(sensors);
    }

    findClosestPoint(point) {
        var xVal = point.x;
        var yVal = point.y;
        if (point.x % gridSpace < gridSpace / 2) {
            xVal -= (point.x % gridSpace);
        } else {
            xVal += gridSpace - (point.x % gridSpace);
        }
        if (point.y % gridSpace < gridSpace / 2) {
            yVal -= (point.y % gridSpace);
        } else {
            yVal += gridSpace - (point.y % gridSpace);
        }
        var newPoint = new Point(xVal, yVal);
        return newPoint;
    }

    activate() {
        this.layer.activate();
        this.active = true;
        this.addEventHandlers();
    }

    deactivate() {
        this.active = false;
        Sensor.list.forEach(sensor =>{
            sensor.removeEventHandlers();
        });
    }

    initSetupMode() {
        Sensor.list.forEach(sensor => {
            sensor.removeStatusPath();
        })
    }
}

export default SensorLayer;