import React, { useState, useEffect } from 'react';
import OverviewChartContainer from '../../statistics/OverviewChartContainer';
import Card from '../../UiComponents/Card';
import { TextMuted } from '../../UiComponents/text/StyledText';
import AppModePieChartContainer from './graph/AppModePieChartContainer';
import BatteryLevelGraphContainer from './graph/BatteryLevelGraphContainer';
import SensorLiveGraphContainer from './graph/SensorLiveGraphContainer';
import styled from 'styled-components';
import { getDeviceNumber } from '../../../scripts/common';
import Button from '../../UiComponents/Button';
import { updateSensor } from '../../../scripts/sensor';
import { postEvent } from '../../../scripts/event';

const Section = styled.div`
    margin-bottom: 20px;
`
const ColumnContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    > div{
        width: 45%;
        min-width: 20em;
        max-width: 35em;
    }
    > div:first-child {
        margin-right: 40px;
    }
`

const SensorDetail = props => {
    const [otaEnable, setOtaEnabled] = useState(props.sensor?.enableOta);

    useEffect(() => {
        setOtaEnabled(props.sensor?.enableOta);
    }, [props.sensor]);

    const enableOtaHandler = async (toValue) => {
        try {
            var pickedSensor = (({ sensorId, customerId, hubId }) => ({ sensorId, customerId, hubId }))(props.sensor);
            var updatedSensor = await updateSensor(props.customerId, { ...pickedSensor, enableOta: toValue });
            await postEvent(props.sensor.hubId, props.customerId, 'SET_SENSOR_ENABLE_OTA', { sensorId: props.sensor.sensorId, enableOta: toValue });
            setOtaEnabled(updatedSensor.enableOta);
        } catch (error) {

        }
    }

    return <Card>
        <ColumnContainer>
            <div>
                <h3>Sensor {getDeviceNumber({ ...props.sensor, sensorId: props.sensor?.id })}</h3>
                <div>
                    <ul>
                        <li><TextMuted>ID: {props.sensor?.sensorId}</TextMuted></li>
                        <li><TextMuted>Version: {props.sensor?.version}</TextMuted></li>
                        <li><TextMuted>Battery level: {props.sensor?.batteryLevel}</TextMuted></li>
                        <li><TextMuted>App mode: {props.sensor?.appMode}</TextMuted></li>
                        <li><TextMuted>Sleep limit: {props.sensor?.sleepLimit}</TextMuted></li>

                        <li><TextMuted>Ota {otaEnable ? 'enabled' : 'disabled'}</TextMuted>
                            {otaEnable ? <>
                                <Button margin small secondary onClick={() => enableOtaHandler(0)}>Disable</Button>
                            </> : <>
                                    <Button margin small secondary onClick={() => enableOtaHandler(1)}>Enable</Button>
                                </>

                            }
                        </li>
                        {otaEnable ? <>
                            <li><TextMuted>Download percentage: {props.sensor?.otaDownloadPercentage || 0}%</TextMuted></li>
                        </> : <></>
                        }

                    </ul>
                </div>

                <Section>
                    <h4>Log</h4>
                    <SensorLiveGraphContainer
                        customerId={props.customerId}
                        objectID={props.sensor.sensorId}
                        objectType={'SENSOR'}
                        liveValues={null}
                    />
                </Section>
            </div>

            <div>
                <Section>
                    <h4>App mode distribution</h4>
                    <AppModePieChartContainer
                        customerId={props.customerId}
                        objectID={props.sensor.sensorId}

                    />
                </Section>

                <Section>
                    <h4>Battery level</h4>
                    <BatteryLevelGraphContainer
                        customerId={props.customerId}
                        sensorId={props.sensor.sensorId}
                    />
                </Section>

                <Section>
                    <h4>Decibel Trend</h4>
                    <OverviewChartContainer
                        customerId={props.customerId}
                        hierarchyLevel={'SENSOR'}
                        trendPeriod={'WEEK'}
                        date={new Date()}
                        objectID={props.sensor.sensorId}
                        minWidth={'300px'}
                    />
                </Section>
            </div>
        </ColumnContainer>
    </Card>
}

export default SensorDetail;