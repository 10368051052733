import React, { useState, useEffect, useRef } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import styled, { css } from 'styled-components';
import theme from '../../../../UI/theme';
import FadeIn from '../../../UiComponents/Animations/FadeIn';
import Button from '../../../UiComponents/Button';
import Overlay from '../../../UiComponents/Overlay';
import { cellStates } from '../../table/tableTypes';
import { DisplayField, editableColor } from '../Styles';

const Container = styled.div`
    position: relative;
`

const DropdowItem = styled.li`
    padding: 12px 4px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    ${props => props.active && css`
        background-color: ${theme.colors.darkSpace80};
    `}
`

const ListContainer = styled.div`
    
`

const DropdownList = styled.ul`
    position: absolute;
    width: 100%;
    max-height: 400px;
    overflow: auto;
    list-style: none;
    z-index: 100;
    animation: ${FadeIn} 0.1s;
    text-align: left;
    background-color: ${theme.colors.dropdownGray};
    color: white;
    font-size: 1em;
    border: none;
    border-radius: 0px;
    margin: 4px 0;
    padding: 4px 0;
    
    >li:hover {
        background-color: ${theme.colors.darkSpace80};
    }

    ${props => props.width && css`
        width: ${props.width};
    `}
`

const DropdownButton = styled.button`
    padding: 8px 4px;
    border: 0;
    color: ${editableColor};
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1em;
    background-color: transparent;

    :hover {
        cursor: pointer;
    }

    ${props => props.active && css`
        background-color: ${theme.colors.darkSpace90};
    `}
`

const ButtonLabel = styled.span`
    margin-right: 12px;
`

const CellDropdown = props => {
    const [show, setShow] = useState(false);
    const [activeIndex, _setActiveIndex] = useState(0);
    const activeIndexRef = useRef(activeIndex);

    const items = props.excludeNone ? props.items : [...props.items, { label: '- None -', value: null }];

    const setActiveIndex = (value) => {
        activeIndexRef.current = value;
        _setActiveIndex(value);
    }

    useEffect(() => {
        if (show) {
            setActiveIndex(getStartIndex(items));
        }
    }, [show])

    const getStartIndex = (items) => {
        let index = items.findIndex(item => item.value === props.selectedItem?.value);
        index = Math.max(index, 0);
        return index;
    }

    const keyCallback = (event) => {
        const key = event.key;
        if (['ArrowDown', 'ArrowUp'].includes(key)) {
            event.preventDefault();
            let nextIndex;
            if (key === 'ArrowDown') {
                nextIndex = Math.min(items.length - 1, activeIndexRef.current + 1);
            } else if (key === 'ArrowUp') {
                nextIndex = Math.max(0, activeIndexRef.current - 1);
            }
            if (nextIndex !== undefined) {
                setActiveIndex(nextIndex);
                return true;
            }
        }

        if (key === 'Enter') {

            const item = items[activeIndexRef.current];
            if (item) {
                clickHandler(item.value);
            }
            setShow(false);
        }
    }

    const addCallback = () => {
        if (props.cellControl.addCallback) {
            return props.cellControl?.addCallback(keyCallback);
        }
    }

    const removeCallback = (callbackId) => {
        if (props.cellControl.removeCallback) {
            return props.cellControl?.removeCallback(callbackId);
        }
    }

    useEffect(() => {
        let callbackId;
        if (props.cellControl.cellState === cellStates.ACTIVE) {
            callbackId = addCallback();
            setShow(true);
        } else {
            removeCallback(removeCallback)
            setShow(false);
        }
        return () => removeCallback(callbackId);
    }, [props.cellControl.cellState]);

    function clickHandler(value) {
        setShow(false);
        props.onClick(value);
    }

    function displayItems(items) {
        return items.map((item, ix) =>
            <DropdowItem key={ix}
                onClick={() => clickHandler(item.value)}
                active={ix === activeIndex}
            >
                {item.label}
            </DropdowItem>);
    }

    const active = (props.cellControl.cellState === cellStates.ACTIVE || props.cellControl.cellState === cellStates.FORM);
    return <>
        <Container>
            {!props.disabled ? <>
                <DropdownButton active={active} onClick={() => {active && setShow(s => !s)}} >
                    <ButtonLabel>{props.selectedItem ? props.selectedItem.label : '-- None --'}</ButtonLabel>
                    <FaChevronDown />
                </DropdownButton>
            </> : <>
                <DisplayField>N/A</DisplayField>
            </>}
            {show ?
                <>
                    <ListContainer >
                        <DropdownList ulPosition={props.ulPosition} type={props.type}>
                            {displayItems(items)}
                        </DropdownList>
                    </ListContainer>
                    <Overlay onClick={() => setShow(false)} />
                </>
                : <></>}
        </Container>
    </>
}

export default CellDropdown;