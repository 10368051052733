import styled from "styled-components";
import theme from "../../../UI/theme";
import MusicPlayerItem from "./MusicPlayerItem";

const Container = styled.div`
    background-color: ${theme.colors.darkSpace90};
    padding: 10px;
    display: flex;
    flex-direction: column;
    touch-action: manipulation;
    gap: 10px;
    border-radius: 5px;
`

const Header = styled.label`
    font-size: 1.5rem;
    margin: 0 10px;
`

const MusicPlayerList = props => {
    return <Container>
        <Header>Music</Header>

        {props.musicPlayers?.map((musicPlayer) => {
            return <MusicPlayerItem key={musicPlayer?.musicPlayerId} musicPlayer={musicPlayer} />
        })}
    </Container>
}

export default MusicPlayerList;