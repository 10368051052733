import React from 'react';
import { Range, getTrackBackground } from 'react-range';
import styled from 'styled-components';
import theme from '../../UI/theme';

const trackHeight = 40;

const HandleBar = styled.span`
    width: 2px;
    height: 12px;
    background-color: #aadb23;
    margin: 0 2px;
`

const Mark = styled.div`
    position: relative;
    top: ${Math.round(trackHeight*0.8)}px;
    font-size: 12px;
`

const Label = styled.div`
    position: relative;
    bottom: ${trackHeight}px;
    font-size: 12px;
`

const Container = styled.div`
`

const STEP = 1;
const MIN = 0;
const MAX = 100;
const rtl = false;

const RangeSlider = props => {
    const values = props.values;
    const rangeColor = props.color || theme.colors.greenEnergy;
    const backgroundColor = props.backgroundColor || theme.colors.grayBorder;

    const changeHandler = (newValues) => {
        if (props.onChange) {
            props.onChange(newValues);
        }
    }

    const changeCommittedHandler = (event) => {
        if (props.onChangeCommitted) {
            props.onChangeCommitted(values);
        }
    }


    return <Container>
        <Range
            draggableTrack
            values={values}
            step={STEP}
            min={MIN}
            max={MAX}
            rtl={rtl}
            onChange={(values) => {
                changeHandler(values);
            }}

            renderTrack={({ props, children }) => (
                <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    onMouseUp={changeCommittedHandler}
                    onTouchEnd={changeCommittedHandler}
                    style={{
                        ...props.style,
                        height: trackHeight + 'px',
                        display: 'flex',
                        width: '100%'
                    }}
                >
                    <div
                        ref={props.ref}
                        style={{
                            height: '100%',
                            width: '100%',
                            background: getTrackBackground({
                                values,
                                colors: [backgroundColor, rangeColor, backgroundColor],
                                min: MIN,
                                max: MAX,
                                rtl
                            }),
                            alignSelf: 'center'
                        }}
                    >
                        {children}
                    </div>
                </div>
            )}
            renderThumb={({ props, value, isDragged }) => (
                <div
                    {...props}

                    style={{
                        ...props.style,
                    }}
                >
                    <HandleBar />
                    <HandleBar />
                    <Label>{value}%</Label>
                </div>
            )}

            renderMark={({ props, index }) => (
                <div
                    {...props}
                >
                 {/*    {index === 0 || index === (MAX / STEP) ? <>
                        <Mark>
                            {index * STEP}%
                        </Mark>
                    </> : <></>} */}
                </div>
            )}
        />
    </Container>
}

export default RangeSlider;