import React, {useState} from 'react';
import { postEvent } from '../../../../scripts/event';
import Button from '../../../UiComponents/Button';
import Modal from '../../../UiComponents/Modal';

const RebootHubButton = props => {
    const [showModal, setShowModal] = useState(false);

    const restartHub = () => {
        postEvent(props.hubId, props.customerId, 'REBOOT');
        setShowModal(false);
    }

    return <><Button secondary onClick={() => setShowModal(true)}>
        Restart Hub
    </Button>
    
    <Modal show={showModal} close={() => setShowModal(false)}>
        <h3>Restart hub</h3>
        <p>This action will restart the hub. Are you sure you want to continue?</p>
        <div>
            <Button primary margin onClick={() => restartHub(props.hubId)}>Restart</Button>
            <Button secondary margin onClick={() => setShowModal(false)}>Cancel</Button>
        </div>
    </Modal>
    
    </>
}

export default RebootHubButton;