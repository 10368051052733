import React from 'react';
import styled from 'styled-components';
import ArrowButton from '../../UiComponents/buttons/ArrowButton';
import ZoneModeMeter from './ZoneModeMeter';
import { ContentLabelContainer } from './StyledComponents/ZoneItemStyles';
import { postUserInteraction } from '../../../scripts/userInteraction';
import { userInteractionTypes } from '../../admin/customerDetails/userInteractionTypes';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const ArrowButtonContainer = styled.div`
    /* padding: 4px 0; */
`

const ZoneModeController = props => {

    const controllerClickHandler = (event, newModeIndex) => {
        event.stopPropagation();
        changeZoneMode(newModeIndex);
    }

    const changeZoneMode = (newModeIndex) => {
        const oldZoneModeOrderIndex = props.zone.zoneModeOrderIndex;

        if (isNaN(parseInt(newModeIndex))) {
            newModeIndex = 0;
        }
        newModeIndex = Math.min(2, newModeIndex);
        newModeIndex = Math.max(-2, newModeIndex);

        props.updateZoneHandler(props.zone.zoneId, { zoneModeOrderIndex: newModeIndex });
        postUserInteraction(props.zone.customerId, {
            zoneId: props.zone.zoneId,
            setting: userInteractionTypes.modeID.key,
            fromValue: oldZoneModeOrderIndex,
            toValue: newModeIndex
        });
    }

    return <Container>
        <ContentLabelContainer>
            <span>Vibe</span>
        </ContentLabelContainer>
        <ArrowButtonContainer>
            <ArrowButton fontSize={'24px'} direction={'UP'} onClick={(event) => controllerClickHandler(event, parseInt(props.zone.zoneModeOrderIndex) + 1)} disabled={props.disabled} />
        </ArrowButtonContainer>
        <ZoneModeMeter value={props.zone.zoneModeOrderIndex} disabled={props.disabled} />
        <ArrowButtonContainer>
            <ArrowButton fontSize={'24px'} padding={'5px 5px 0 5px'} margin={'4px 4px 0 4px'} direction={'DOWN'} onClick={(event) => controllerClickHandler(event, parseInt(props.zone.zoneModeOrderIndex) - 1)} disabled={props.disabled} />
        </ArrowButtonContainer>

    </Container>

}

export default ZoneModeController;
