import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const sensorPath = '/v2/sensor';

async function getSensors(customerId) {
    const res = await axios.get(`${hostname}${sensorPath}/sensors?customerId=${customerId}`);
    return res.data;
}

async function updateSensor(customerId, sensor) {
    const res = await axios.put(`${hostname}${sensorPath}?customerId=${customerId}`, sensor);
    return res.data;
}

async function deleteSensor(customerId, hubId, sensorId) {
    const res = await axios.delete(`${hostname}${sensorPath}?customerId=${customerId}&hubId=${hubId}&sensorId=${sensorId}`);
    return res.data;
}

async function updateSensors(customerId, sensors) {
    const res = await axios.put(`${hostname}${sensorPath}/sensors?customerId=${customerId}`, sensors);
    return res.data;
}

async function getAllSensors() {
    const res = await axios.get(`${hostname}${sensorPath}/all`);
    return res.data;
}

export {
    getSensors,
    updateSensor,
    deleteSensor,
    updateSensors,
    getAllSensors
}