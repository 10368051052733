import { FaMinusCircle, FaPlusCircle } from "react-icons/fa"
import styled from "styled-components"
import useLongPress from "../../../../hooks/UseLongPress"
import theme from "../../../../UI/theme"
import IconButton from "../../../UiComponents/buttons/IconButton"

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: fit-content;
`

const GridItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`

const ArrowButton = styled(IconButton)`
    margin: 0;
    padding: 2px;
    min-height: fit-content;
    aspect-ratio: 1;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
`

const CenterLabel = styled.label`
    font-size: ${theme.fontSize.larger};
    color: ${theme.colors.textGray};
`

const CalibrationPointRotater = props => {
    const rotate = (angle) => props.rotatePointsHandler(angle, props.centerX, props.centerY, props.indices);

    return <Container>
        <GridItem><ArrowButton onClick={() => rotate(-props.angle)} {...useLongPress(() => rotate(-props.angle), 100)}><FaMinusCircle /></ArrowButton></GridItem>
        <GridItem><CenterLabel>{props.title}</CenterLabel></GridItem>
        <GridItem><ArrowButton onClick={() => rotate(props.angle)} {...useLongPress(() => rotate(props.angle), 100)}><FaPlusCircle /></ArrowButton></GridItem>
    </Container>
}

export default CalibrationPointRotater;