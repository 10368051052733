import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSubscription } from '../../hooks/useSubscription';
import { subscriptionsTypes } from '../../scripts/subscription/subscriptionTypes';
import theme from '../../UI/theme';
import VolumeSlider from './VolumeSlider';
import VibeButton from './VibeButton';
import { getZone, updateZone } from '../../scripts/zone';
import { postEvent } from '../../scripts/event';
import ZoneInfoModal from './ZoneInfoModal';
import { postUserInteraction } from '../../scripts/userInteraction';
import { userInteractionTypes } from '../admin/customerDetails/userInteractionTypes';
import { useContext } from 'react';
import UserContext from '../../hooks/UserContext';
import ZoneRegulationButton from './ZoneRegulationButton';
import ZoneVolume from './ZoneVolume';
import { getSystemVolume, getWavedVolume, mappedValuesToArray } from '../../scripts/volume';
import LiveChartContainer from './charts/LiveChartContainer';
import { MuteButton } from './MuteButton';
import ZoneSkeleton from './skeletons/ZoneSkeleton';
import WaVolumeOff from '../UiComponents/Icons/WaVolumeOff';
import WaVolumeUp from '../UiComponents/Icons/WaVolumeUp';

const ZoneContainer = styled.div`
    @keyframes scaleUpX {
        0% {
        transform: scaleX(0%);
        }
        100% {
        transform: scaleX(100%);
        }
    }

    background-color: ${theme.colors.darkSpace};
    display: grid;
    grid-template-columns: 20% auto 1fr 2.5fr auto auto;
    gap: 10px;
    align-items: center;
    padding: 10px;

    grid-column: 2;
    grid-column: ${props => props.groupHasSourceSelectors === false ? '1 / span 2' : '2'};

    @media only screen and (max-width: ${650}px) {
        grid-template-columns: 1fr auto 40px 40px;
    }
`

const ZoneHeader = styled.label`
    font-size: ${theme.fontSize.larger};
    white-space: nowrap;
    cursor: pointer;
    grid-column: 1;
    grid-row: 1;
    overflow: hidden;
    text-overflow: ellipsis;

    ${props => props.disabled && css`
        opacity: 40%;
        pointer-events: none;
    `}
`

const StyledZoneVolume = styled(ZoneVolume)`
    grid-row: 1;
    grid-column: 2;

    @media only screen and (max-width: ${650}px) {
        grid-column: 2;
    }
`

const StyledVolumeSlider = styled(VolumeSlider)`
    grid-column: 3 / span 2;

    @media only screen and (max-width: ${650}px) {
        grid-column: 1 / span 2;
        grid-row: 2;
    }
`

const StyledRegulationButton = styled(ZoneRegulationButton)`
    grid-row: 1;
    grid-column: 6;

    @media only screen and (max-width: ${650}px) {
        grid-column: 4;
        grid-row: 2;
    }
`

const StyledMuteButton = styled(MuteButton)`
    grid-row: 1;
    grid-column: 5;

    @media only screen and (max-width: ${650}px) {
        grid-column: 3;
        grid-row: 2;
    }
`

const StyledVibeButton = styled(VibeButton)`
    grid-row: 1;
    grid-column: 4;

    @media only screen and (max-width: ${650}px) {
        grid-column: 1 / span 2;
        grid-row: 2;
    }
`

const StyledLiveChart = styled(LiveChartContainer)`
    animation: 0.5s ease-out 0s 1 scaleUpX;

    max-height: 50px;
    height: 50px;
    grid-row: 1;
    grid-column: 3;
    background-color: ${theme.colors.darkSpace90};

    @media only screen and (max-width: ${650}px) {
        grid-column: 3 / span 2;
        grid-row: 1;
    }

    @media only screen and (max-width: ${theme.screenSizes.medium}px) {
        height: 40px;
        max-height: 40px;
    }
`

const Zone = props => {
    const calibrationSysvols = props.hub?.calibrationMethod === 1 ?
        props.zone?.calibrationPoints?.points?.map(point => point.sysvol) :
        mappedValuesToArray(props.zone?.processorMapValues);
    const [zoneLive, setZoneLive] = useSubscription(subscriptionsTypes.zoneLive, props.zone.zoneId);
    const [lastVolumeCommit, setLastVolumeCommit] = useState(0);
    const [systemVolume, setSystemVolume] = useState(!isNaN(zoneLive?.sysvol) ? getSystemVolume(zoneLive?.sysvol, calibrationSysvols, props.hub?.calibrationMethod) : null);
    const [volume, setVolume] = useState(!isNaN(zoneLive?.sysvol) ? zoneLive?.sysvol : 0);
    const [isChangingVolume, setIsChangingVolume] = useState(false);
    const [showZoneModal, setShowZoneModal] = useState(false);
    const user = useContext(UserContext);

    useEffect(() => {
        if (isChangingVolume === false && (new Date()).getTime() - lastVolumeCommit >= 2000) {
            setVolume(!isNaN(zoneLive?.sysvol) ? zoneLive?.sysvol : null);
            setSystemVolume(!isNaN(zoneLive?.sysvol) ? getSystemVolume(zoneLive?.sysvol, calibrationSysvols, props.hub?.calibrationMethod) : null);
        }
    }, [zoneLive?.sysvol]);

    const onVolumeChange = (value) => {
        setSystemVolume(Number(value));
        setVolume(getWavedVolume(value, calibrationSysvols, props.hub?.calibrationMethod));
    }

    const onVolumeCommitted = (value) => {
        setSystemVolume(Number(value));
        setVolume(getWavedVolume(value, calibrationSysvols, props.hub?.calibrationMethod));
        setIsChangingVolume(false);
    }

    const updateZoneWithEvent = async (zoneProps) => {
        props.setZone(props.zone.zoneId, zoneProps);
        try {
            await updateZone(props.zone.customerId, props.zone.zoneId, { ...zoneProps });
            await postEvent(props.zone.hubId, props.zone.customerId, 'GET_ALL_ZONE_SETTINGS');
        } catch (err) {
            props.setZone(props.zone.zoneId, await getZone(props.zone.customerId, props.zone.zoneId));
        }
    }

    const toggleRegulation = async (newState) => {
        if (user.isImpersonator && user.isImpersonating) {
            return;
        }

        await updateZoneWithEvent({ zoneId: props.zone.zoneId, hubId: props.zone.hubId, isRegulating: +newState });
        postUserInteraction(props.zone.customerId, {
            zoneId: props.zone.zoneId,
            setting: userInteractionTypes.isRegulating.key,
            fromValue: +!newState,
            toValue: +newState
        });
    }

    const toggleMute = async (newState) => {
        if (user.isImpersonator && user.isImpersonating) {
            return;
        }

        await updateZoneWithEvent({ zoneId: props.zone.zoneId, hubId: props.zone.hubId, mute: +newState });
        postUserInteraction(props.zone.customerId, {
            zoneId: props.zone.zoneId,
            setting: userInteractionTypes.mute.key,
            fromValue: +!newState,
            toValue: +newState
        });
    }

    if (props.zone) {
        return <>
            {props.zone && zoneLive ?
                <>
                    <ZoneContainer
                        groupHasSourceSelectors={props.groupHasSourceSelectors}
                        hasSourceSelector={props.hasSourceSelector}
                    >
                        <ZoneHeader
                            onClick={() => setShowZoneModal(true)}
                            disabled={!props.processorConnected}>
                            {props.zone.zoneName}
                        </ZoneHeader>

                        <StyledZoneVolume
                            disabled={!props.processorConnected}
                            zone={props.zone}
                            wavedVolume={volume}
                            systemVolume={systemVolume}
                            isRegulating={props.zone?.isRegulating && props.isRegulatingAudioSource}
                            shouldRegulate={props.zone?.shouldRegulate}
                        />

                        <StyledMuteButton
                            onClick={() => toggleMute(!props.zone?.mute)}
                            deactivated={!!props.zone?.mute}
                            disabled={!props.processorConnected}
                        >
                            {props.zone?.mute ? <WaVolumeOff /> : <WaVolumeUp />}
                            <label>{props.zone?.mute ? 'Muted' : 'On'}</label>
                        </StyledMuteButton>

                        {props.zone?.isRegulating && props.isRegulatingAudioSource ? <>
                            <StyledLiveChart
                                zone={props.zone}
                                zoneLive={zoneLive}
                                processorConnected={props.processorConnected} />

                            <StyledVibeButton
                                zone={props.zone}
                                updateZoneWithEvent={updateZoneWithEvent}
                                vibe={props.zone?.zoneModeOrderIndex}
                                disabled={!props.processorConnected}
                            />
                        </> : <>
                            <StyledVolumeSlider
                                volume={systemVolume ?? 0}
                                lastVolumeCommit={lastVolumeCommit}
                                setLastVolumeCommit={setLastVolumeCommit}
                                onChangeStarted={(value) => { setIsChangingVolume(true); }}
                                onChange={(value) => { onVolumeChange(value); }}
                                onChangeCommitted={(value) => { onVolumeCommitted(value); }}
                                disabled={!props.processorConnected}
                                zone={props.zone}
                            />
                        </>}

                        <StyledRegulationButton
                            onClick={() => toggleRegulation(!props.zone?.isRegulating)}
                            deactivated={!props.zone?.isRegulating || !props.isRegulatingAudioSource}
                            disabled={
                                !props.isRegulatingAudioSource ||
                                props.zone?.shouldRegulate === 0 ||
                                !props.processorConnected
                            }
                            isRegulating={props.zone?.isRegulating}
                            isRegulatingAudioSource={props.isRegulatingAudioSource}
                            visibility={props.zone?.shouldRegulate === 1 ? '' : 'hidden'}
                        />

                    </ZoneContainer>
                </>
                : <>
                    <ZoneSkeleton
                        hasSourceSelector={props.hasSourceSelector}
                        groupHasSourceSelectors={props.groupHasSourceSelectors} />
                </>
            }

            <ZoneInfoModal
                show={showZoneModal}
                close={() => setShowZoneModal(false)}
                zone={props.zone}
                schedules={props.schedules}
                updateZoneWithEvent={updateZoneWithEvent}
                processorConnected={props.processorConnected}
            />
        </>
    } else {
        return <></>
    }
}

export default Zone;