import React from "react";
import styled from "styled-components";
import { MdSkipNext, MdPlayArrow, MdPause} from 'react-icons/md';
import NeutralSpinner from '../UiComponents/Animations/NeutralSpinner';
import Button from "../UiComponents/Button";
import theme from "../../UI/theme";

const MusicControlContainer = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;
`

const MusicButton = styled.div`
    cursor: pointer;
    color: ${theme.colors.greenEnergy};
    width: 50px;
    height: 50px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    margin: 10px 0;
`
const Overlay = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: ${theme.colors.darkSpace80};
    opacity: 0.9;
    display: flex;
    justify-content: center; 
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    margin: -16px;
`


const MusicInfoDisplay = (props) => {

    switch (props.state){
        case 'LOADING':
            return (
                <Overlay>
                    <NeutralSpinner 
                        color={theme.colors.whiteSpace}
                        size={60}
                        speed={1.5}
                    />
                </Overlay>
                );
        case 'NO_DATA':
            return (
                <Overlay>
                    <h3>Your music player is sleeping</h3>
                    <Button primary onClick={props.wakeupPlayer}>Start Playing Music</Button>
                </Overlay>
            );
        default:
            return (
                <>
                    <MusicControlContainer>
                    {(props.userAction === 'PLAY' || props.userAction === 'PAUSE')?
                        <NeutralSpinner 
                            color={theme.colors.whiteSpace}
                            size={54}
                            speed={1.5}
                        />
                    :<>
                        {props.playState==='PLAYING'?
                            <MusicButton>
                                <MdPause fontSize="200px" onClick={props.pause} />
                            </MusicButton>
                        :<>
                            <MusicButton>
                                <MdPlayArrow fontSize="200px" onClick={props.play} />
                            </MusicButton>
                        </>}
                    </>}
                    {(props.userAction === 'NEXT')?
                        <NeutralSpinner 
                            color={theme.colors.whiteSpace}
                            size={54}
                            speed={1.5}
                        />
                    :
                        <MusicButton>
                            <MdSkipNext fontSize="200px" onClick={props.nextSong}/>
                        </MusicButton>
                    }
                    </MusicControlContainer>
                </>
            );        
    }
}

export default MusicInfoDisplay;