import React, { useRef, useEffect } from 'react';
import Paper from 'paper';
import heatmap from '../canvas/heatmap';
import styled from 'styled-components';
import theme from '../../../UI/theme';

const StyledCanvas = styled.canvas`
  border: none;
  width: 100%;
  background-color: ${props => props.backgroundColor || 'whitesmoke'};
  height: ${props => props.height || '60vh'};
`

const Canvas = props => {
  
  const canvasRef = useRef(null)
  
  useEffect(() => {
    const canvas = canvasRef.current;
    Paper.setup(canvas);
    var canvasAction = heatmap(props);
    props.setCanvasAction(() => canvasAction);
  }, []);
  
  return <StyledCanvas ref={canvasRef} {...props} id="canvas" resize="true" />
}

export default Canvas;