import React, {useState} from 'react';
import { postEvent } from '../../../../scripts/event';
import Button from '../../../UiComponents/Button';

const BlinkButtonField = ({sensor}) => {
    const [isBlinking, setIsBlinking] = useState(false);

    const sendCommand = (command) => {
        const payload = { sensorId: sensor.sensorId };
        postEvent(sensor.hubId, sensor.customerId, command, payload);
    }
    const handleBlink = (e) => {
        e.stopPropagation();
        if (isBlinking) {
            setIsBlinking(false);
            sendCommand("STOP_BLINK");
        } else {
            sendCommand("BLINK");
            setIsBlinking(true);
            setTimeout(() => {
                setIsBlinking(false);
            }, 90000)
        }
    }

    return <Button small secondary margin onClick={handleBlink}>{isBlinking ? 'Stop Blink' : 'Blink'}</Button>
}

export default BlinkButtonField;