import { postEvent } from "../../../scripts/event";
import { deleteSource } from "../../../scripts/source";
import CheckboxField from "../fields/CheckBoxField";
import NameField from "../fields/NameField";
import NumberField from "../fields/NumberField";
import ProcessorChoiceField from "../fields/ProcessorChoiceField";
import { DisplayField } from "../fields/Styles";
import { SourceIcon } from "../styling/Icons";
import { getDefaultId } from "../tableUtils";
import { addNewSource, getNextAddress, saveUpdatedSources } from "./sourceUtils";

const idField = 'sourceId';

export const getSourceTableProps = ({ sources, processors, customerId }) => {

    const getColumns = (sources) => [{
        key: 'name',
        displayName: 'Name',
        form: true,
        width: '150px',
        render: (source, updateSource, cellControl) => (
            <NameField
                object={source}
                updateObject={updateSource}
                objects={sources}
                idField={'sourceId'}
                nameField={'name'}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'sourceId',
        displayName: 'Id',
        width: '80px',
        readOnly: true,
        render: (source) => (
            <DisplayField>{source.sourceId}</DisplayField>
        )
    }, {
        key: 'processorId',
        displayName: 'Processor',
        width: '150px',
        form: true,
        getSuggested: () => getDefaultId(processors, 'processorId'),
        render: (source, updateSource, cellControl) => (
            <ProcessorChoiceField
                object={source}
                processors={processors}
                updateObject={updateSource}
                cellControl={cellControl}
            />
        )
    }, {
        key: 'address',
        displayName: 'Input number',
        width: '100px',
        form: true,
        getSuggested: () => getNextAddress(sources, processors),
        render: (source, updateSource, cellControl) => (
            <NumberField
                value={source.address}
                setValue={(value) => updateSource({ address: value })}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'regulate',
        displayName: 'Regulate',
        width: '60px',
        form: true,
        render: (source, updateSource, cellControl) => (
            <CheckboxField
                checked={source.regulate}
                setValue={() => updateSource({ regulate: +(!source.regulate) })}
                cellControl={cellControl}
            />
        )
    }];

    const saveObjects = async (changedSources) => {
        try {
            await saveUpdatedSources(changedSources, sources, processors, customerId);
        } catch (err) {
            console.log('Failed to update sources');
        }
    }

    const deleteObject = async (source) => {
        try {
            await deleteSource(customerId, source.sourceId);
            const processor = processors.find(processor => processor.processorId === source.processorId);
            await postEvent(processor.hubId, customerId, 'DELETE_SOURCE', { sourceId: source.sourceId });
        } catch (err) {
            console.log('Error failed to delete source');
        }
    }

    const addObject = processors.length ? (newSource) => addNewSource(newSource, processors, customerId) : null;

    const getListItemProps = (source) => {
        return {
            displayName: source.name,
            Icon: SourceIcon
        }
    }
    
    const getPlaceholder = () => {
        return <div>
            {processors.length ? <>
                <p>No sources have been added. Add the available sources for your processor(s)</p>
            </> : <>
                <p>Add a processor before adding sources</p>
            </>}
        </div>
    }

    const getActions = (source) => [];

    return {
        title: 'Sources',
        getColumns,
        parentObjects: sources,
        getListItemProps,
        idField,
        saveObjects,
        deleteObject,
        addObject,
        getActions,
        getPlaceholder
    }
}
