import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import { postEvent } from '../../scripts/event';
import Button from '../UiComponents/Button';

const SetupCompleteCard = (props) => {
    const history = useHistory();
    const [retrySetup, setRetrySetup] = useState(false);

    const postSetupEvent = async () => {
        var hubId;
        props.systemSensors.forEach(sensor => {
            if (sensor.type === 'HUB') {
                hubId = sensor.id;
            }
        });
        await postEvent(hubId, props.customerId, 'ADD_SENSORS');
        setRetrySetup(true);
    }

    const continuing = () => {

        if (props.adding) {
            props.addingSensorHandler('NONE');
        } else {
            history.push('/');
        }

    }

    return (
        <div>
            <div>
                { retrySetup ? <>
                    <h3>Retrying Setup</h3>
                    <p>The Hub is notified that it should set up your remaining sensors.</p>
                    <p> You may at any time check the status of your Hub and the Sensors under settings.</p>
                    <p>Should some sensors be missing, the problem might be that the sensors
                        are to far away from the Hub in the setup proccess. We recommend placing
                        the sensors closer to the hub and use the "Add Sensors" option under settings. 
                        Once you can see all your sensors in the list under settings, you may move the sensors back to their original position. 
                    </p>
                    <p>If you are unable to solve the problem please contact us at support@waved.no</p>
                    <Button primary onClick={() => history.push('/')}>Continue</Button>
                </>:<>
                    {props.networkType != 'ZIGBEE' ? <>
                        <h3>Setup Session Complete!</h3>
                        <p>Number of sensors that was initialized:</p>
                        <h4>{props.numSetupSensors}</h4>
                        <p>Your system has now got a total of:</p>
                        <h3>{props.systemSensors.filter(s => s.type === 'SENSOR').length} Sensors</h3>
                        <h3>1 Hub</h3>
                        <p>If this is correct, press continue. <br></br>If not you may press 'Retry Setup'</p>
                    </> : <>
                        <h3>Hub was set up successfully</h3>
                        <p>To connect your sensors, push the button on the side or power up your sensors.</p>
                        <p>The system will now connect and set up your sensors on a zigbee network. They will appear in the sensors view shortly.</p>
                    </>}

                    <Button primary onClick={() => continuing()}>Continue</Button>
                    <br></br>
                    <br></br>
                    
                    <Button secondary onClick={() => postSetupEvent()}>Retry</Button>
                </>}
                
            </div>
        </div>
    )
}

export default SetupCompleteCard;