import axios from 'axios';
import { getHostname } from './server/server'

const hostname = getHostname();
const clientPath = '/v2/client/'

const getCustomer = async (customerId) => {
    const res = await axios.get(`${hostname}${clientPath}?customerId=${customerId}`);
    return res.data;
}

const getCustomerByEmail = async (email) => {
    const res = await axios.get(`${hostname}${clientPath}/email?email=${email}`);
    return res.data;
}

const updateCustomer = async (customerId, customer) => {
    const res = await axios.put(`${hostname}${clientPath}?customerId=${customerId}`, customer);
    return res.data;
}

const createCustomer = async (email, password) => {
    const res = await axios.post(`${hostname}${clientPath}`, { email: email, password: password });
    return res.data;
}

const changeCustomerPassword = async (credentials) => {
    var result = await axios.post(`${hostname}${clientPath}/changeCustomerPassword?customerId=${credentials.customerId}`, { credentials });
    console.log(result.data.status);
    if (result.data.error) {
        console.log(result.data.error);
    }
    return result.data.status;
};

const getAllCustomers = async () => {
    var result = await axios.get(`${hostname}${clientPath}/getAllCustomers`);
    if (result.data.customers) {
        return result.data.customers;
    } else if (result.data.error) {
        console.log(result.data.error);
    }
    return [];
}


const getOpeningHours = async (customerId) => {
    var result = await axios.get(`${hostname}${clientPath}/openingHours?customerId=${customerId}`);
    if (result.data.hours) {
        return JSON.parse(result.data.hours);
    } else if (result.data.error) {
        console.log(result.data.error);
    }
    return [];
}

const setOpeningHours = async (customerId, hours) => {
    await axios.post(`${hostname}${clientPath}/openingHours?customerId=${customerId}`, { hours });
}

export {
    getCustomer,
    getCustomerByEmail,
    updateCustomer,
    createCustomer,
    changeCustomerPassword,
    getAllCustomers,
    getOpeningHours,
    setOpeningHours,
}


