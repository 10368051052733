import React, { useState } from 'react';
import { changeCustomerPassword } from '../../scripts/datasource';
import { changePassByVerifiedEmail } from '../../scripts/authentication';
import Button from '../UiComponents/Button';
import Card from '../UiComponents/Card';
import styled from 'styled-components';
import InputField from '../UiComponents/InputField';

const ChangePasswordForm = styled.form`
    display: flex;
    flex-direction: column;

    button {
        width: fit-content;
    }
`

const Input = styled(InputField)`
    margin: 4px 0 10px 0;
`

const ChangePWButton = styled(Button)`
    margin-bottom: 10px;
`

const ChangePasswordCard = (props) => {

    const [changeError, setChangeError] = useState(false);
    const [changeAttempted, setChangeAttempted] = useState(false);
    const [validationError, setValidationError] = useState();
    const [password, setPassword] = useState();
    const [confPass, setConfPass] = useState();

    const changePassword = async () => {
        if (password.length < 4) {
            setValidationError('The password must be at least 4 characters');
            return
        } else if (password !== confPass) {
            setValidationError('The passwords do not match');
            return;
        }
        var changed;
        if (props.verificationEmail) {
            // User is not logged in but has verified the email through a verification code
            changed = await changePassByVerifiedEmail(props.verificationEmail, password, props.verificationCode);
        } else {
            const credentials = {
                email: props.customer.email,
                customerId: props.customer.customerId,
                password: password
            }
            changed = await changeCustomerPassword(credentials);
        }

        setChangeAttempted(true);

        if (changed) {
            setChangeError(false);
        } else {
            setChangeError(true);
        }
    }

    const cancel = () => {
        props.cancelChange();
    }

    return (<Card>
        <ChangePasswordForm>

            {!changeAttempted ? <>
                <h1>Change password</h1>
                <Input
                    type="password"
                    placeholder="New password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} />
                <Input
                    type="password"
                    placeholder="Confirm new password"
                    value={confPass}
                    onChange={(e) => setConfPass(e.target.value)} />

                {validationError ? <p>{validationError}</p> : <></>}

                <ChangePWButton type={'button'} primary onClick={changePassword}>Change Password</ChangePWButton>
                <Button type={'button'} secondary onClick={cancel}>Cancel</Button>
            </> : <>
                {changeError ? <>
                    <h1>An error occurred</h1>
                    <p>
                        We were not able to update your password. Please try again or contact us at support@waved.no
                    </p>
                    <Button type={'button'} primary onClick={props.whenDone}>Close</Button>
                </>
                    : <>
                        <h1>Password successfully changed</h1>
                        <Button type={'button'} primary onClick={props.whenDone}>Continue to sign-in page</Button>
                    </>}
            </>}

        </ChangePasswordForm>
    </Card>
    )

}

export default ChangePasswordCard;