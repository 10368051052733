import React from 'react';
import styled from 'styled-components';
import {getDataColors, getDataParams} from '../../canvas/colorUtils';
import theme from '../../../../UI/theme';

const Container = styled.div`
    position: absolute;
    z-index: 8;
    right: 10px;
    bottom: 10px;
    color: ${theme.colors.darkSpace10};
    border-radius: 4px;
    padding: 8px 12px;
`

const BarContainer = styled.div`
    display: flex;
    justify-content: center;
`

const Bar = styled.div`
    height: 100px;
    width: 20px;
    border-radius: 4px;
    background: linear-gradient(0, ${getGradient()});
    opacity: 0.8;
    margin-right: 10px;
    box-shadow: gray 0px 0px 2px 0px
`

const LabelList = styled.div`
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const Title = styled.span`
    display: inline-block;
    margin: 10px 0px;
`

const DataLegend = props => {

    var labels = getLabels();

    return <Container >
        {/* <Title>Decibel scale</Title> */}
        <BarContainer>
            <Bar />
            <LabelList> 
                {labels.map((label, i) => {
                    return <div key={i}>{label}</div>;
                })}
            </LabelList>
        </BarContainer>
    </Container>
}

export default DataLegend;

function getGradient() {
    var dataColors = getDataColors();
    
    var L = dataColors.length;
    var simplifiedDataColors = dataColors.filter((_, i) => i%6===0 && i<=L-1);
    simplifiedDataColors.push(dataColors[L-1]);
    var SL = simplifiedDataColors.length;
    var gradientArr = simplifiedDataColors.map((color, i) => {
        return `${color} ${Math.round(i/SL*100)}%`;
    })
    
    var gradientString = gradientArr.join(',');
    return gradientString;
}

function getLabels() {
    var dataParams = getDataParams();
    return [dataParams.maxVol, dataParams.minVol];
}