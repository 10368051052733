import { useContext } from "react";
import UserContext from "../../hooks/UserContext";
import { postEvent } from "../../scripts/event";
import { postUserInteraction } from "../../scripts/userInteraction";
import { updateZone } from "../../scripts/zone";
import { userInteractionTypes } from "../admin/customerDetails/userInteractionTypes";
import WaVolumeOff from "../UiComponents/Icons/WaVolumeOff";
import WaVolumeUp from "../UiComponents/Icons/WaVolumeUp";
import PanelButton from "./PanelButton"

const GroupMuteButton = props => {
    const user = useContext(UserContext);

    const toggleGroupMute = async (newState) => {
        if (user.isImpersonator && user.isImpersonating) {
            return;
        }

        let hubIds = [];
        let promises = [];
        props.zones?.forEach(async (zone) => {
            if (!hubIds.includes(zone.hubId)) {
                hubIds.push(zone.hubId);
            }

            promises.push(updateZone(zone.customerId, zone.zoneId,
                {
                    zoneId: zone.zoneId,
                    hubId: zone.hubId,
                    mute: +newState
                }));
        });

        Promise.all(promises).then(async () => {
            for (let i = 0; i < hubIds.length; i++) {
                const hubId = hubIds[i];
                await postEvent(hubId, props.customerId, 'GET_ALL_ZONE_SETTINGS');
            }
        });

        postUserInteraction(props.customerId, {
            setting: userInteractionTypes.globalMute.key,
            fromValue: +!newState,
            toValue: +newState
        });
    }

    return <PanelButton
        onClick={() => toggleGroupMute(!props.groupMuted)}
        deactivated={props.groupMuted}
        disabled={props.disabled}
    >
        {props.groupMuted ?
            <WaVolumeOff /> :
            <WaVolumeUp />}
        <label>{props.groupMuted ? 'Muted' : 'On'}</label>
    </PanelButton>
}

export default GroupMuteButton;