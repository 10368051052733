import React, { useState } from 'react';
import InputField from '../../../../UiComponents/InputField';
import { TextMuted } from '../../../../UiComponents/text/StyledText';
import { isSameSubnet, validateIPaddress } from '../NetworkConfig/networkUtils';
import ValidationError from '../NetworkConfig/ValidationError';

const ProcessorIpField = props => {
    const [validationError, setValidationError] = useState();

    const updateProcessorIP = (value) => {
        
        var updatedValidationError;
        if (!validateIPaddress(value)) {
            updatedValidationError = { message: 'Not a valid ip address', type: 'ERROR' };
        } else {
            var networkConfig = props.hub.networkConfig;
            if (networkConfig) {
                var ipAddressesOnSameSubnet = Object.keys(networkConfig).filter(key => isSameSubnet(networkConfig[key].address, value)).length;
                if (ipAddressesOnSameSubnet === 0) {
                    updatedValidationError = { message: 'No ip addresses on this subnet', type: 'WARNING' };
                }
            }
        }
        setValidationError(updatedValidationError);
        props.updateProcessorHandler('ipAddress', value);
    }

    return <>
        <span>
            <TextMuted>IP:&nbsp;</TextMuted>
            <InputField small width={'10em'} value={props.updatedProcessor.ipAddress || ''} onChange={(e) => updateProcessorIP(e.target.value)} />
            <br />
            <ValidationError validationError={validationError} />
        </span>
    </>
}


export default ProcessorIpField;