import Paper, { Point } from "paper";
import heatmapTypes from '../container/heatmapTypes';
import FloorLayer from "./FloorLayer";
import SensorLayer from './SensorLayer';
import FurnitureLayer from "./FurnitureLayer";
import GridLayer from "./GridLayer";
import Sensor from "./Sensor";
import CanvasController from './CanvasController';
import ZoneLayer from "./ZoneLayer";
import DataLayer from './DataLayer';
import * as Hammer from 'hammerjs';

const heatmap = (props) => {

  // Global properties
  var activeTool = heatmapTypes.tools.NONE;
  CanvasController.canvasCallback = props.canvasCallback;
  CanvasController.heatmapType = props.heatmapType;

  var canvasSize = Paper.view.viewSize;
  var gridLayer = new GridLayer(canvasSize);
  var floorLayer = new FloorLayer();
  var zoneLayer = new ZoneLayer();
  var furnitureLayer = new FurnitureLayer();
  var sensorLayer = new SensorLayer();
  var dataLayer = new DataLayer();
  dataLayer.layer.insertBelow(floorLayer.layer);
  Sensor.dataLayer = dataLayer;

  var layers = [gridLayer, dataLayer, floorLayer, furnitureLayer, zoneLayer, sensorLayer];
  function stackLayers() {
    layers[0].layer.sendToBack();
    for (var i = 1; i < layers.length; i++) {
      layers[i].layer.insertAbove(layers[i - 1].layer);
    }
  }

  const view = Paper.view;
  view.zoom = 30;
  view.center = new Point(0, 0);
  var zoomLimits = {
    min: 10,
    max: 150
  };
  var panLimits = {
    x: 20,
    y: 20
  };
  var floorCenter = new Point(0, 0);
  var dragStartPoint;


  function onWheel(event) {
    event.preventDefault();
    var delta = event.deltaY;
    var zoomFactor = 1.1;
    var oldZoom = view.zoom;
    var zoomValue = 0;
    if (delta > 0) {
      zoomValue = oldZoom / zoomFactor;
    } else if (delta < 0) {
      zoomValue = oldZoom * zoomFactor;
    }
    if (zoomValue != 0 && zoomValue > zoomLimits.min && zoomValue < zoomLimits.max) {
      view.zoom = zoomValue;
    }
  }

  var canvas = document.getElementById('canvas');
  var hammertime;
  addPanEvents();
  if (CanvasController.heatmapType != heatmapTypes.heatmapTypes.MINI) {
    hammertime = new Hammer(canvas, { prevent_default: true });
    hammertime.on('pinchin pinchout', onPinch);
    hammertime.get('pinch').set({ enable: true });
    canvas.addEventListener('wheel', onWheel);
  }


  function onPinch(event) {
    event.preventDefault();
    var zoomFactor = 1.02;
    var oldZoom = view.zoom;
    var zoomValue = 0;
    if (event.scale > 1) {
      zoomValue = oldZoom * zoomFactor;
    } else if (event.scale < 1) {
      zoomValue = oldZoom / zoomFactor;
    }
    if (zoomValue != 0 && zoomValue > zoomLimits.min && zoomValue < zoomLimits.max) {
      view.zoom = zoomValue;
    }
  }

  function addPanEvents() {
    if (CanvasController.heatmapType === heatmapTypes.heatmapTypes.MINI) {
      return;
    }
    view.onMouseDrag = (event) => {
      if (!dragStartPoint) {
        dragStartPoint = event.point;
      }
      var newDelta = event.point.subtract(dragStartPoint);
      var newCenter = view.center.subtract(newDelta);
      if (Math.abs(newCenter.x - floorCenter.x) < panLimits.x && Math.abs(newCenter.y - floorCenter.y) < panLimits.y) {
        view.center = newCenter;
      }
    }
    view.onMouseUp = (event) => {
      dragStartPoint = null;
    }
  }

  function setZoomAndCenter(outerWallElement) {
    var zoomAndCenter = floorLayer.getZoomAndCenter(outerWallElement);
    if (zoomAndCenter) {
      var { zoom, center, bounds } = zoomAndCenter;
      view.zoom = zoom;
      view.center = center;
      zoomLimits.min = Math.round(zoom / 2);
      zoomLimits.max = zoom * 6;

      floorCenter = center;
      panLimits.x = bounds.width * 0.7;
      panLimits.y = bounds.height * 0.7;
    }
  }

  // Action handlers

  function canvasCleanup() {
    dataLayer.clearData();
  }

  function deactivateAll() {
    floorLayer.deactivate();
    sensorLayer.deactivate();
    furnitureLayer.deactivate();
    zoneLayer.deselectAllZones();
    stackLayers();
  }

  function floorMode() {
    deactivateAll();
    floorLayer.activate();
    floorLayer.layer.bringToFront();
  }

  function zoneMode(floorData) {
    deactivateAll()
    zoneLayer.activate();
    if (floorData.floorElements && floorData.activeFloor) {
      var activeFloor = floorData.activeFloor;
      var outerWallElement = floorData.floorElements.find(e => e.type === heatmapTypes.elementTypes.OUTERWALL && e.floorId === activeFloor);
      var zoneElements = floorData.floorElements.filter(e => e.type === heatmapTypes.elementTypes.ZONE);
      zoneLayer.initializeZones(zoneElements, outerWallElement, activeFloor, floorData.activeMode);
    }
  }

  function sensorMode() {
    deactivateAll();
    sensorLayer.activate();
  }

  function furnitureMode() {
    deactivateAll();
    furnitureLayer.activate();
    furnitureLayer.layer.bringToFront();
  }

  async function selectFloorHandler(floorData) {
    var activeFloor = floorData.activeFloor;
    floorLayer.initializeLayer();
    // draw wall elements
    var outerWallElement = floorData.floorElements.find(e => e.type === heatmapTypes.elementTypes.OUTERWALL && e.floorId === activeFloor);
    var innerWallElements = floorData.floorElements.filter(e => e.type === heatmapTypes.elementTypes.INNERWALL && e.floorId === activeFloor);
    floorLayer.initializeWalls(outerWallElement ? outerWallElement : {}, innerWallElements ? innerWallElements : []);

    // set zoom and center
    setZoomAndCenter(outerWallElement);

    var zoneElements = floorData.floorElements.filter(e => e.type === heatmapTypes.elementTypes.ZONE);
    zoneLayer.initializeZones(zoneElements, outerWallElement, activeFloor, floorData.activeMode);

    // draw sensors
    var sensors = floorData.floorElements.filter(e => e.type === heatmapTypes.elementTypes.SENSOR && e.floorId === activeFloor);
    sensorLayer.activate();
    sensorLayer.drawSensors(sensors);

    // add furnitures
    var furniture = floorData.floorElements.filter(e => e.type === heatmapTypes.elementTypes.FURNITURE && e.floorId === activeFloor);
    await furnitureLayer.initializeFurniture(furniture);


    Sensor.changeFloorHandler(outerWallElement);
    if (floorData.activeMode) {
      selectModeHandler(floorData.activeMode);
    }

    if (floorData.activeMode === heatmapTypes.modes.SETUP) {
      setActiveTool({ toolName: activeTool });
    }
  }

  async function selectModeHandler(modeName) {
    deactivateAll();
    addPanEvents();
    Sensor.activeMode = modeName;
    if (modeName === heatmapTypes.modes.DATA) {
      gridLayer.clear();
      Sensor.initDataLayer();
      await Sensor.initDataMode();
      zoneLayer.initDataMode();
    } else if (modeName === heatmapTypes.modes.SETUP) {
      gridLayer.draw();
      Sensor.removeData();
      zoneLayer.initSetupMode();
      sensorLayer.initSetupMode();
    }
  }

  function selectWallHandler(data) {
    if (data.highlight) {
      floorLayer.highlightWall(data.wallElement);
    } else {
      floorLayer.selectWall(data.wallElement);
    }
  }

  function deselectWallHandler(data) {
    if (data.highlight) {
      if (!data.isSelected) {
        floorLayer.lowlightWall(data.wallElement);
      }
    } else {
      floorLayer.deselectWall(data.wallElement);
    }
  }

  function deselectAllWallsHandler() {
    floorLayer.deactivateAllWalls();
  }

  function setActiveTool(floorData) {
    var tool = floorData.toolName;
    activeTool = tool;
    switch (tool) {
      case heatmapTypes.tools.FLOOR:
        floorMode();
        break;
      case heatmapTypes.tools.ZONE:
        zoneMode(floorData);
        break;
      case heatmapTypes.tools.SENSOR:
        sensorMode();
        break;
      case heatmapTypes.tools.FURNITURE:
        furnitureMode();
        break;
      case heatmapTypes.tools.FLOOR_GENERAL:
        deactivateAll();
        break;
      default:
        deactivateAll();
        break;
    }
  }


  function canvasAction(type, data) {
    switch (type) {
      case heatmapTypes.actions.TOOL_SELECT:
        setActiveTool(data);
        return;

      case heatmapTypes.actions.PLACE_SENSOR:
        Sensor.placeSensor(data);
        return;

      case heatmapTypes.actions.REMOVE_SENSOR:
        Sensor.removeSensor(data);
        return;

      case heatmapTypes.actions.MARK_SENSOR:
        Sensor.markSensor(data);
        return;

      case heatmapTypes.actions.UNMARK_ALL_SENSORS:
        Sensor.unmarkAllSensors(data);
        return;

      case heatmapTypes.actions.FLOOR_SELECT:
        selectFloorHandler(data);
        return;

      case heatmapTypes.actions.MODE_SELECT:
        selectModeHandler(data);
        return;

      case heatmapTypes.actions.WALL_SELECT:
        selectWallHandler(data);
        return;

      case heatmapTypes.actions.WALL_DESELECT:
        deselectWallHandler(data);
        return;

      case heatmapTypes.actions.DESELECT_ALL_WALLS:
        deselectAllWallsHandler(data);
        return;

      case heatmapTypes.actions.ADD_INNER_WALL:
        floorLayer.addInnerWall(data);
        return;

      case heatmapTypes.actions.ADD_OUTER_WALL:
        floorLayer.addOuterWall(data);
        return;

      case heatmapTypes.actions.COMPLETE_INNER_WALL:
        floorLayer.completeInnerWall(data);
        return;

      case heatmapTypes.actions.DELETE_WALL:
        floorLayer.deleteWall(data);
        return;

      case heatmapTypes.actions.PLACE_ZONE:
        zoneLayer.placeZone(data);
        return;

      case heatmapTypes.actions.REMOVE_ZONE:
        zoneLayer.removeZone(data);
        addPanEvents();
        return;

      case heatmapTypes.actions.ZONE_SELECT:
        zoneLayer.selectZone(data);
        return

      case heatmapTypes.actions.DESELECT_ALL_ZONES:
        zoneLayer.deselectAllZones(data);
        addPanEvents();
        return;

      case heatmapTypes.actions.ADD_FURNITURE:
        furnitureLayer.addFurniture(data);
        return;

      case heatmapTypes.actions.SENSOR_DATA:
        Sensor.showSensorData(data);
        return;

      case heatmapTypes.actions.ZONE_DATA:
        zoneLayer.showZoneData(data);
        return;

      case heatmapTypes.actions.SET_DISPLAY_METHOD:
        Sensor.setDisplayMethod(data);
        Sensor.initDataLayer();
        return;

      case heatmapTypes.actions.SET_DATA_MODE:
        Sensor.setDataMode(data);
        return;

      case heatmapTypes.actions.SET_DATA_TYPE:
        Sensor.setDataType(data);
        return;

      case heatmapTypes.actions.CANVAS_CLEANUP:
        canvasCleanup();
        return;

      default:
        return;
    }
  }

  // Initialize map
  Sensor.setDisplayMethod(heatmapTypes.displayMethods.CONTINUOUS);
  activeTool = props.activeTool || activeTool;
  selectFloorHandler({ activeFloor: props.activeFloor, floorElements: props.floorElements }).then(res => {
    selectModeHandler(props.activeMode || heatmapTypes.modes.DATA);
    Sensor.setDataMode(heatmapTypes.dataModes.LIVE);
  });

  return canvasAction;
};

export default heatmap;