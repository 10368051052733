import React from 'react';
import styled from 'styled-components';
import calibrationStates from './calibrationStates';
import { prepareGraphData } from './calibrationUtils';
import ResultGraph from './ResultGraph';

const Container = styled.div`
    margin: 20px 0;
`

const AutoCalibrationResult = props => {
    const graphData = prepareGraphData(props.calibration.result, props.zones);
    return <Container>
        <h4>{getStateMessage(props.calibration.state)}</h4>

        {props.calibration.result ? <>
            <ResultGraph data={graphData} />
        </> : <></>}

    </Container>
}

export default AutoCalibrationResult;

function getStateMessage(state) {
    let stateMessage = '';
    switch (state) {
        case calibrationStates.INITIALIZED:
            stateMessage = 'Starting calibration';
            break;
        case calibrationStates.RUNNING:
            stateMessage = 'Calibration is running';
            break;
        case calibrationStates.COMPLETED:
            stateMessage = 'Calibration completed'
            break;
        case calibrationStates.FAILED:
            stateMessage = 'Calibration failed'
            break;
        default:
            break;
    }
    return stateMessage;
}