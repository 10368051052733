import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/client';

async function getAppVersion(customerId) {
    try {
        const res = await axios.get(`${hostname}/v2/app/version?customerId=${customerId}`);
        if (res.data.version != undefined) {
            return res.data.version;
        }
    } catch (e) {
        console.log('Could not get app version');
        return false;
    }
}

async function getAppVersionTempMaster(customerId) {
    try {
        const res = await axios.get(`${hostname}/v2/appVersion?customerId=${customerId}`, { withCredentials: true });
        if (res.data.version != undefined) {
            return res.data.version;
        }
    } catch (e) {
        console.log('Could not get app version');
        return false;
    }
}

async function updateGoogleOpeningHours(customerId, googlePlaceId) {
    try {
        const res = await axios.post(`${hostname}${path}/newGoogleOpeningHours?customerId=${customerId}&googlePlaceId=${googlePlaceId}`, {});
        if (res.data.error) {
            return false;
        }
        if (res.data) {
            return res.data;
        }
    } catch (e) {
        console.log('Could not update google opening hours');
        return false;
    }
}

async function getCustomerErrors() {
    try {
        const res = await axios.get(`${hostname}${path}/getCustomerErrors`);
        if (res.data.error) {
            return false;
        }
        if (res.data) {
            return res.data;
        }
    } catch (error) {
        console.log('Could not get customer errors')
        return false;
    }
}

async function getCustomerUseSummaries() {
    try {
        const res = await axios.get(`${hostname}${path}/getCustomerUseSummaries`);
        if (res.data.error) {
            return false;
        }
        if (res.data) {
            return res.data;
        }
    } catch (error) {
        console.log('Could not get customer use summaries');
    }
}

export {
    updateGoogleOpeningHours,
    getAppVersion,
    getAppVersionTempMaster,
    getCustomerErrors,
    getCustomerUseSummaries
}