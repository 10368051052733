import React from 'react';
import styled, { css } from 'styled-components'
import { FaChevronRight, FaChevronLeft, FaChevronUp, FaChevronDown } from "react-icons/fa";
import theme from '../../../UI/theme';
import IconButton from './IconButton';

const StyledButton = styled(IconButton)`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    /* height: 35px;
    width: 50px; */
    border-radius: 12px;
    font-size: ${props=> props.fontSize || '16px'};
    padding: ${props=> props.padding || '5px'};
    margin: ${props=> props.margin || '4px'};
    color: ${props => props.color || theme.colors.textGray};
    background-color: ${props => props.backgroundColor || 'transparent'};
    transition: all 0.2s;

    ${props => props.disabled && css`
        color: ${theme.colors.darkSpace70};
    `}
    
`


const ArrowButton = props => {

    var Chevron;
    switch (props.direction) {
        case 'UP':
            Chevron = FaChevronUp
            break;
        case 'DOWN':
            Chevron = FaChevronDown
            break;
        case 'LEFT':
            Chevron = FaChevronLeft
            break;
        default:
            Chevron = FaChevronRight;
            break;
    }

    return <StyledButton {...props}>
        <Chevron />
    </StyledButton>
}

export default ArrowButton;