import React, { useState, useContext } from 'react';
import Button from '../UiComponents/Button';
import Modal, { ModalContent } from '../UiComponents/Modal';
import UserContext from '../../hooks/UserContext';
import absoluteVolumeNotification from './AbsoluteVolumeNotification';
import styled from 'styled-components';
import panelViewNotification from './PanelViewNotification';
import theme from '../../UI/theme';

/* ------------- EXAMPLE ---------------- 
    <FeatureNotification notificationKey={'ABSOLUTE_VOLUME_SLIDER'} />
*/

const ConfirmButtonContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 50px;
`

const FeatureNotification = props => {
    const [seenState, setSeenState] = useState(getSeenStateFromLocalStorage(props.notificationKey));
    const user = useContext(UserContext);

    const closeHandler = () => {
        localStorage.setItem(props.notificationKey, true);
        setSeenState(true);
    }

    var NotificationContent = getNotificationContentFromKey(props.notificationKey, new Date(user.customer.createdOn));

    if (NotificationContent && !seenState) {
        return <Modal show={!seenState} close={closeHandler}>
            <ModalContent>
                <div>
                    <NotificationContent />
                </div>
                <ConfirmButtonContainer>
                    <Button primary onClick={closeHandler}>OK</Button>
                </ConfirmButtonContainer>
            </ModalContent>
        </Modal>
    } else {
        return <></>
    }
}

export default FeatureNotification;

function getSeenStateFromLocalStorage(notificationKey) {
    var seenState = localStorage.getItem(notificationKey);
    return seenState === "true";
}


function getNotificationContentFromKey(key, customerCreatedDate) {
    var notification = null;
    switch (key) {
        case 'ABSOLUTE_VOLUME_SLIDER':
            notification = absoluteVolumeNotification;
            break;
        case 'PANEL_VIEW':
            notification = panelViewNotification;
            break;
        default:
            notification = null;
            break;
    }
    // check start and end date
    if (notification && notification.startDate > customerCreatedDate && notification.startDate < new Date() && notification.endDate > new Date()) {
        return notification.content;
    } else {
        return null;
    }
}


