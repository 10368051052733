import { postEvent } from '../../../scripts/event';
import { deleteProcessor } from '../../../scripts/processor';
import ReconnectProcessorButton from '../../settings/venue/device/AudioSystem/ReconnectProcessorButton';
import HubChoiceField from '../fields/HubChoiceField';
import NameField from '../fields/NameField';
import { ProcessorIcon } from '../styling/Icons';
import { getDefaultId, hideInList } from '../tableUtils';
import { getProcessorDisplayStatus, getProcessorStatus, getProcessorStatusColor } from '../utils/connectionStatus';
import { actionTypes } from '../utils/installationTypes';
import InterfaceChoiceField, { hideInterfaceField, disableInterfaceField } from './fields/InterfaceChoiceField';
import IpAddressField, { disableIpAddressField, hideIpAddressField } from './fields/IpAddressField';
import { addNewProcessor, saveUpdatedProcessors } from './processorUtils';
import ProcessorError from './ProcessorError';
import ProcessorScanButton from './actions/ProcessorScanButton';
import StatusField from '../fields/StatusField';
import ProcessorTypeField from './fields/ProcessorTypeField';

const idField = 'processorId';

export const getProcessorTableProps = ({ processors, hubs, customerId, serverStatus, displayMode }) => {

    const getColumns = (processors) => [{
        displayName: 'Name',
        key: 'name',
        form: true,
        render: (processor, updateProcessor, cellControl) => (
            <NameField
                object={processor}
                updateObject={updateProcessor}
                objects={processors}
                idField={idField}
                nameField={'name'}
                cellState={cellControl.cellState}
            />
        )
    }, {
        displayName: 'Status',
        key: 'status',
        hide: hideInList(displayMode),
        render: (hub) => {
            const listItemProps = getListItemProps(hub)
            return <StatusField
                displayStatus={listItemProps.displayStatus}
                statusColor={listItemProps.statusColor}
                error={listItemProps.error}
            />
        }
    }, {
        displayName: 'Hub',
        key: 'hubId',
        form: true,
        hideInItem: () => hubs.length < 2,
        hide: hubs.length < 2,
        getSuggested: () => getDefaultId(hubs, 'hubId'),
        render: (processor, updateProcessor, cellControl) => (
            <HubChoiceField
                hubId={processor.hubId}
                hubs={hubs}
                updateObject={updateProcessor}
                cellControl={cellControl}
            />
        )
    }, {
        displayName: 'Type',
        key: 'type',
        form: true,
        render: (processor, updateProcessor, cellControl) => (
            <ProcessorTypeField
                type={processor.type}
                setValue={(type) => updateProcessor({ type })}
                cellControl={cellControl}
            />
        )
    }, {
        displayName: 'Ip address',
        key: 'ipAddress',
        form: true,
        hideInItem: (processor) => disableIpAddressField(processor),
        hide: hideIpAddressField(processors),
        render: (processor, updateProcessor, cellControl) => (
            <IpAddressField
                processor={processor}
                updateObject={updateProcessor}
                hub={hubs.find(hub => hub.hubId === processor.hubId)}
                cellControl={cellControl}
            />
        )
    }, {
        displayName: 'Interface',
        key: 'interface',
        form: true,
        hideInItem: (processor) => disableInterfaceField(processor, hubs.find(hub => hub.hubId === processor.hubId)),
        hide: hideInterfaceField(processors, hubs),
        render: (processor, updateProcessor, cellControl) => (
            <InterfaceChoiceField
                processor={processor}
                updateObject={updateProcessor}
                hub={hubs.find(hub => hub.hubId === processor.hubId)}
                cellControl={cellControl}
            />
        )
    }, {
        displayName: 'Id',
        key: 'processorId',
        render: (processor) => <div>{processor.processorId}</div>
    }, {
        displayName: 'Reconnect',
        key: 'reconnect',
        render: (processor) => (
            <ReconnectProcessorButton
                processor={processor}
                hub={hubs.find(hub => hub.hubId === processor.hubId)}
            />
        )
    }];

    const getActions = (processor) => [{
        key: 'processorScan',
        type: actionTypes.LIST,
        render: () => (
            <ProcessorScanButton
                hubs={hubs}
                columns={getColumns(processors)}
                onSubmit={addObject}
                objects={processors}
            />
        )
    }]

    const getListItemProps = (processor) => {
        const hub = hubs.find(hub => hub.hubId === processor.hubId);
        const processorStatus = getProcessorStatus(processor, hub, serverStatus)
        return {
            displayName: processor.name,
            displayStatus: getProcessorDisplayStatus(processorStatus),
            statusColor: getProcessorStatusColor(processorStatus),
            error: processor.error && <ProcessorError error={processor.error} />,
            Icon: ProcessorIcon
        }
    }

    async function saveObjects(changedProcessors) {
        try {
            await saveUpdatedProcessors(changedProcessors, processors, customerId);
        } catch (err) {
            console.log('save error', err);
        }
    }

    const deleteObject = async (processor) => {
        try {
            await deleteProcessor(customerId, processor.processorId);
            await postEvent(processor.hubId, customerId, 'DELETE_PROCESSOR', { processorId: processor.processorId });
        } catch (err) {
            console.log('Error failed to delete zone');
        }
    }

    const getPlaceholder = () => {
        return <div>
            {hubs.length ? <>
                <p>No processors have been added. Find processors on the network with network scan or add a known processor by pressing the new-button.</p>
                <ProcessorScanButton
                    hubs={hubs}
                    columns={getColumns(processors)}
                    onSubmit={addObject}
                    objects={processors}
                />
            </> : <>
                <p>Connect a hub before adding processors</p>
            </>}
        </div>
    }

    const addObject = hubs.length ? (newProcessor, submitProps) => addNewProcessor(newProcessor, customerId, submitProps) : null;

    return {
        title: 'Processors',
        getColumns,
        parentObjects: processors,
        getListItemProps,
        idField,
        saveObjects,
        deleteObject,
        addObject,
        getActions,
        getPlaceholder
    }
}