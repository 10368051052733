import React from 'react';
import ChoiceField from '../../fields/ChoiceField';
import { hierarchyTypeValues } from '../zoneTypes';

const BackupZoneIdChoiceField = ({ zone, updateZone, zones, cellControl }) => {
    const choices = zones.filter(z => z.hubId === zone.hubId && z.zoneId !== zone.zoneId && z.hierarchyType !== hierarchyTypeValues.MASTER && z.shouldRegulate);
    const selectedZone = choices.find(z => z.zoneId === zone.backupZoneId);
    return <ChoiceField
        selectedItem={selectedZone && { value: selectedZone.zoneId, label: selectedZone.zoneName }}
        items={choices.map(zone => ({ value: zone.zoneId, label: zone.zoneName }))}
        setValue={(value) => updateZone({ backupZoneId: value })}
        cellControl={cellControl}
    />
}

export default BackupZoneIdChoiceField;
