import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { IoPlay } from "react-icons/io5";
import styled from "styled-components";
import UserContext from "../../hooks/UserContext";
import { postEvent } from "../../scripts/event";
import { postUserInteraction } from "../../scripts/userInteraction";
import theme from "../../UI/theme";
import { userInteractionTypes } from "../admin/customerDetails/userInteractionTypes";
import { getAvailableSources } from "../installation/SourceSelector/sourceSelectorUtils";
import Modal from "../UiComponents/Modal";
import { ModalContainer, ModalHeader } from "./ModalStyles";
import PanelButton from "./PanelButton";

const SourceTable = styled.table`
    border-collapse: collapse;

    td {
        padding: 10px 5px;
        line-height: 40px;
        color: ${theme.colors.textGray};
    }
`

const TableSubHeader = styled.tr`
    border-bottom: 1px solid white;

    th {
        color: white;
        font-weight: lighter;
        text-align: start;
        font-size: medium;
    }
`

const SourceSelectorModal = props => {
    const user = useContext(UserContext);
    const [activeSource, setActiveSource] = useState(props.sources?.find(source => source.sourceId === props.sourceSelector?.activeSourceId));

    useEffect(() => {
        setActiveSource(props.sources?.find(source => source.sourceId === props.sourceSelector?.activeSourceId));
    }, [props.sourceSelector?.activeSourceId, props.sources]);

    const changeSource = async (sourceId) => {
        if (user.isImpersonator && user.isImpersonating) {
            return;
        }

        const pickedSourceSelector = (({ sourceSelectorId, name, processorId }) => ({ sourceSelectorId, name, processorId }))(props.sourceSelector);
        const oldSource = props.sources?.find(source => source.sourceId === props.sourceSelector?.activeSourceId);
        setActiveSource(props.sources?.find(source => source.sourceId === sourceId));

        await postEvent(
            props.processor.hubId,
            props.processor.customerId,
            'SET_SOURCE',
            {
                sourceSelectorId: props.sourceSelector.sourceSelectorId,
                sourceId: sourceId
            });

        postUserInteraction(props.processor.customerId, {
            setting: userInteractionTypes.source.key,
            payload: {
                ...pickedSourceSelector,
                from: {
                    activeSourceId: oldSource?.sourceId ?? null,
                    activeSourceDisplayName: oldSource?.name ?? 'Unknown source'
                },
                to: {
                    activeSourceId: sourceId,
                    activeSourceDisplayName: props.sources?.find(source => source.sourceId === sourceId)?.name ?? 'Unknown source'
                }
            }
        });
    }

    return <Modal {...props}>
        <ModalContainer>
            <ModalHeader>Select source - {props.sourceSelector?.name}</ModalHeader>
            <SourceTable>
                <tbody>
                    <TableSubHeader>
                        <th colSpan={3}>Now playing</th>
                    </TableSubHeader>
                    <tr>
                        <td>{activeSource?.name ?? "Unknown source"}</td>
                        <td>{activeSource?.regulate || props.sources?.length === 0 ? 'Regulated' : 'Not regulated'}</td>
                        <td></td>
                    </tr>
                    <TableSubHeader>
                        <th colSpan={3}>Available sources</th>
                    </TableSubHeader>
                    {getAvailableSources(props.sourceSelector, props.sources)
                        ?.filter(source => source.sourceId !== activeSource?.sourceId)
                        ?.sort((a, b) => sortSources(a, b))
                        ?.map((source, index) => {
                            return <tr key={index}>
                                <td>{source?.name ?? "Unknown source"}</td>
                                <td>
                                    {source?.regulate ? 'Regulated' : 'Not regulated'}
                                </td>
                                <td>
                                    <PanelButton disabled={!props.processorConnected} onClick={() => changeSource(source?.sourceId)}>
                                        <IoPlay />
                                        <label>Play</label>
                                    </PanelButton>
                                </td>
                            </tr>
                        })}
                </tbody>
            </SourceTable>
        </ModalContainer>
    </Modal>
}

export default SourceSelectorModal;

const sortSources = (a, b) => {
    if (isFinite(a.address) && isFinite(b.address)) {
        return Number(a.address) > Number(b.address) ? 1 : -1;
    } else {
        return a.address?.localeCompare(b.address);
    }
}

