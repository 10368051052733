import React, { useState, useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { getCalibration, updateCalibration } from '../../../../scripts/calibration';
import { sliceUrlAfter } from '../../../../scripts/common';
import { getSensors } from '../../../../scripts/sensor';
import TopNavigation from '../../../navigation/TopNavigation';
import ViewHeader from '../../../UiComponents/ViewHeader';
import Button from '../../../UiComponents/Button';
import calibrationStates from './calibrationStates';
import AutoCalibrationResult from './AutoCalibrationResult';
import ZoneSelector from './ZoneSelector';
import CalibrationTable from './CalibrationTable';
import Card from '../../../UiComponents/Card';
import styled from 'styled-components';
import { postEvent } from '../../../../scripts/event';
import { getHubs } from '../../../../scripts/hub';
import { getZones } from '../../../../scripts/zone';

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;

    button:not(:first-of-type) {
        margin-left: 10px;
    }
`

const AutoCalibration = props => {
    const { id, calibrationId } = useParams();
    const match = useRouteMatch();
    const [calibration, setCalibration] = useState();
    const [hubs, setHubs] = useState();
    const [zones, setZones] = useState();
    const [selectedZones, setSelectedZones] = useState([]);
    const [stopping, setStopping] = useState(false);

    useEffect(() => {
        getDataHandler();
    }, []);

    const getDataHandler = async () => {

        getDevicesHandler();
        getCalibrationHandler()
    }

    const getCalibrationHandler = async () => {
        try {
            var savedCalibration = await getCalibration(id, calibrationId);
            setCalibration(savedCalibration);
        } catch (error) {
            setCalibration(null);
        }
    }

    const getDevicesHandler = async () => {
        var customerHubs = await getHubs(id);
        if (customerHubs) {
            setHubs(customerHubs);
        }
        var customerSensors = await getSensors(id);
        var customerZones = await getZones(id);
        if (customerZones) {
            customerZones?.forEach(zone => {
                zone.hasData = zoneHasSensors(zone.zoneId, customerSensors) || zoneHasSensors(zone.backupZoneId, customerSensors);
            });
            setZones(customerZones);
        }
    }

    const updateCalibrationAttributes = (updatedAttributes) => {
        setCalibration(current => {
            var updated = { ...current };
            Object.keys(updatedAttributes).forEach(key => {
                updated[key] = updatedAttributes[key];
            })
            return updated;
        })
    }

    const checkResult = async () => {
        setTimeout(async () => {
            try {
                const savedCalibration = await getCalibration(id, calibrationId);
                setCalibration(savedCalibration);
                if (savedCalibration.state === calibrationStates.INITIALIZED || savedCalibration.state === calibrationStates.RUNNING) {
                    checkResult();
                }
            } catch (error) {
            }

        }, 5000)
    }

    const startCalibrationHandler = async () => {
        try {
            
            let zonesInCalibration = [...new Set([...calibration.zones || [], ...selectedZones])];
            const updatedCalibration = await updateCalibration(id, calibrationId, { state: calibrationStates.INITIALIZED, zones: zonesInCalibration });
            setCalibration(updatedCalibration);
            postEvent(updatedCalibration.hubId, id, 'START_CALIBRATION', { calibrationId: calibrationId, zones: [...selectedZones] });
            checkResult();
        } catch (error) {
        }
    }

    const stopCalibrationHandler = async () => {
        try {
            await postEvent(calibration.hubId, id, 'STOP_CALIBRATION');
            setStopping(true);
            setTimeout(() => {
                setStopping(false);
            }, 8000);
        } catch (error) {
            
        }
    }

    const copyBackupResultHandler = async () => {
        var newCalibration = JSON.parse(JSON.stringify(calibration));
        for (let index = 0; index < selectedZones?.length; index++) {
            const selectedZone = selectedZones[index];
            const zone = zones.find(z => z.zoneId === selectedZone);
            if (zone) {
                var backupResult = JSON.parse(JSON.stringify(calibration?.result?.find(result => result.zoneId === zone.backupZoneId)));
                backupResult.zoneId = selectedZone;
                if (backupResult) {
                    var oldResultIndex = calibration?.result?.findIndex(result => result.zoneId === selectedZone);
                    if (oldResultIndex > -1) {
                        newCalibration.result[oldResultIndex] = backupResult;
                    } else {
                        newCalibration.result.push(backupResult);
                    }
                }
            }
        }

        await updateCalibration(id, newCalibration.calibrationId, newCalibration);
        setCalibration(newCalibration);
    }

    return <div>
        <ViewHeader>
            <TopNavigation to={sliceUrlAfter(match.url, `/${calibrationId}`)} />
            <h3>Calibration</h3>
        </ViewHeader>

        <Card>
            {hubs && zones && calibration ? <>
                <ZoneSelector
                    hubs={hubs}
                    zones={zones}
                    selectedZones={selectedZones}
                    setSelectedZones={setSelectedZones}
                    calibration={calibration}
                    updateCalibrationAttributes={updateCalibrationAttributes} />
            </> : <></>}

            {calibration && calibration.hubId ? <>

                <ButtonContainer>
                    {calibration.state === calibrationStates.NONE || calibration.state === calibrationStates.COMPLETED || calibration.state === calibrationStates.FAILED ? <>
                        <Button primary disabled={selectedZones.length < 1} onClick={startCalibrationHandler}>Calibrate</Button>

                        <Button
                            secondary
                            disabled={selectedZones.length < 1 ||
                                !selectedZones?.every(zoneId =>
                                    zones?.some(zone =>
                                        zone.zoneId === zoneId &&
                                        calibration?.result?.some(result => result.zoneId === zone.backupZoneId)))}
                            onClick={copyBackupResultHandler}>Copy backup sweep</Button>
                    </> : <></>}

                    {calibration.state === calibrationStates.RUNNING && !stopping ? <>
                        <Button secondary onClick={stopCalibrationHandler}>Stop calibration</Button>
                    </> : <></>}
                </ButtonContainer>

            </> : <></>}

            {calibration && zones && calibration.state !== calibrationStates.NONE ? <>
                <AutoCalibrationResult calibration={calibration} zones={zones} />
            </> : <></>}

        </Card>

        {calibration && zones && calibration.state !== calibrationStates.NONE ? <>
            <CalibrationTable calibration={calibration} zones={zones} completed={calibration.state === calibrationStates.COMPLETED} />
        </> : <></>}

    </div>
}

export default AutoCalibration;

const zoneHasSensors = (zoneId, sensors) => {
    return sensors.filter(sensor => sensor.zoneId === zoneId).length > 0;
}