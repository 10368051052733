import React, { useState } from 'react';
import Button from '../../../../UiComponents/Button';
import { TextMuted } from '../../../../UiComponents/text/StyledText';
import styled from 'styled-components';
import { updateSensor } from '../../../../../scripts/sensor';
import { postEvent } from '../../../../../scripts/event';

const ValueContainer = styled.span`
    margin: 5px;
`

const SleepLimit = props => {
    const [editState, setEditState] = useState(false);
    const [edited, setEdited] = useState(false);
    const [editedSleepLimit, setEditedSleepLimit] = useState(props.sensor.sleepLimit);

    const editHandler = (newValue) => {
        if (!edited) {
            setEdited(true);
        }
        setEditedSleepLimit(newValue);
    }

    const saveHandler = async () => {
        try {
            var pickedSensor = (({ sensorId, customerId, hubId }) => ({ sensorId, customerId, hubId }))(props.sensor);
            await updateSensor(props.sensor.customerId, { ...pickedSensor, sleepLimit: editedSleepLimit });
            await postEvent(props.sensor.hubId, props.sensor.customerId, 'SET_SLEEP_LIMIT', { sensorId: props.sensor.sensorId, sleepLimit: editedSleepLimit });
            props.updateSensorHandler(props.sensor.sensorId, 'sleepLimit', editedSleepLimit);
            setEdited(false);
            setEditState(false);
        } catch (error) {

        }
    }

    return <div>
        <TextMuted>Sleep Limit:

            <ValueContainer>
                {editState ? <>
                    <input type="number" min={0} max={100} value={editedSleepLimit} onChange={(e) => editHandler(e.target.value)} />
                </> : <>
                        {props.sensor.sleepLimit} dB
                </>}
            </ValueContainer>

        </TextMuted>
        {editState ? <>
            {edited && <Button primary small margin onClick={saveHandler}>Save</Button>}
            <Button secondary small margin onClick={() => setEditState(false)}>Cancel</Button>
        </> : <>
                <Button tertiary small margin onClick={() => setEditState(true)}>Edit</Button>
            </>}
    </div>
}

export default SleepLimit;