import styled, { css } from "styled-components";
import theme from "../../UI/theme";
import { postUserInteraction } from "../../scripts/userInteraction";
import { userInteractionTypes } from "../admin/customerDetails/userInteractionTypes";
import { useContext } from "react";
import UserContext from "../../hooks/UserContext";
import VibeSlider from "./VibeSlider";
import WaUsers from "../UiComponents/Icons/WaUsers";
import WaSpeakers from "../UiComponents/Icons/WaSpeakers";

const VibeContainer = styled.div`
    animation: 0.5s ease-out 0s 1 scaleUpX;

    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    height: 50px;
    align-items: center;
    color: ${theme.colors.darkSpace50};
    justify-content: space-between;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        height: 40px;
    }

    ${props => props.disabled && css`
        opacity: 40%;
    `}
`

const TextRowContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    > svg {
        stroke: ${theme.colors.darkSpace50};
        width: 0.9rem;
        height: 0.9rem;
    }
`

const VibeLabel = styled.label`
    color: ${theme.colors.darkSpace50};
    grid-row: 3;
    font-size: 0.65rem;
    font-weight: 100;
    line-height: 0.65rem;
`

const VibeButton = props => {
    const user = useContext(UserContext);

    const changeVibe = async (newValue) => {
        if (user.isImpersonator && user.isImpersonating) {
            return;
        }

        if (!isNaN(newValue) && newValue >= -2 && newValue <= 2) {
            const oldZoneModeOrderIndex = props.zone.zoneModeOrderIndex;
            await props.updateZoneWithEvent({ zoneId: props.zone.zoneId, hubId: props.zone.hubId, zoneModeOrderIndex: newValue })

            postUserInteraction(props.zone.customerId, {
                zoneId: props.zone.zoneId,
                setting: userInteractionTypes.modeID.key,
                fromValue: oldZoneModeOrderIndex,
                toValue: newValue
            });
        }
    }

    return <VibeContainer {...props}>
        <TextRowContainer>
            <WaUsers />
            <WaSpeakers />
        </TextRowContainer>

        <VibeSlider
            vibe={props.vibe}
            disabled={props.disabled}
            changeVibe={changeVibe} />

        <TextRowContainer>
            <VibeLabel>People</VibeLabel>
            <VibeLabel>Music</VibeLabel>
        </TextRowContainer>
    </VibeContainer >
}

export default VibeButton;