import styled from 'styled-components';
import Card from '../UiComponents/Card';
import ZoneStatItem from './ZoneStatItem';

const Container = styled.div`
    ${Card} {
        margin-bottom: 20px;
    }
`

const ZoneStatistics = props => {
    return <Container>
        {props.zones?.filter(zone => !zone.hide)?.map((zone, index) => {
            return <ZoneStatItem
                {...props}
                zone={zone}
                key={index}
            />
        })}

    </Container>
}

export default ZoneStatistics;