import React from 'react';
import Dropdown from '../../../../UiComponents/Dropdown';

const ZoneDropdown = props => {
    return <>
        <Dropdown
            primary
            items={getItems(props.zones)}
            onClick={(item) => { props.changeZoneHandler(props.sensor.sensorId, item.value)}}
            selectedItem={getSelectedItem(props.sensor, props.zones)}
        />
    </>
}

export default ZoneDropdown;

const getItems = (zones) => {
    let items = zones.map(zone => { return { label: zone.zoneName, value: zone.zoneId } })
    items.push({value: '', label: '--None--'});
    return items;
}

const getSelectedItem = (sensor, zones) => {
    let zone = zones.find(zone => zone.zoneId === sensor.zoneId);
    if (zone) {
        return {label: zone.zoneName, value: sensor.zoneId};
    } else {
        return {label: '--None--', value: ''};
    }
}