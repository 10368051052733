import { useState } from "react";
import { postEvent } from "../../../../scripts/event";
import Button from "../../../UiComponents/Button";
import Modal from "../../../UiComponents/Modal";
import { FieldButton } from "../../fields/styling/FieldStyles";
import { ActionsContainer } from "../../styling/DeviceStyles";


const SystemActionsField = ({ hub }) => {
    const [showModal, setShowModal] = useState(false);

    const performAction = async (command) => {
        try {
            await postEvent(hub.hubId, hub.customerId, command);
            setShowModal(false);
        } catch (err) {
            console.log('Failed to perform action');
        }
    }

    return <>
        <FieldButton small tertiary onClick={() => setShowModal(true)}>System actions</FieldButton>
        <Modal show={showModal} close={() => setShowModal(false)}>
            <h3>System actions</h3>
            <ActionsContainer>
                <Button secondary onClick={() => performAction('RESTART_APP')}>Restart program</Button>
                <Button secondary onClick={() => performAction('REBOOT')}>Reboot</Button>
                <Button secondary onClick={() => performAction('REBOOT_HAT')}>Reboot hat</Button>
            </ActionsContainer>
        </Modal>
    </>
}

export default SystemActionsField;