import Paper, { Key, Layer } from "paper";
import { getUuid } from "../../../scripts/common";
import furnitureTypes from "../container/furnitureTypes";
import heatmapTypes from "../container/heatmapTypes";
import CanvasController from "./CanvasController";
import FurniturePiece from './FurniturePiece';


class FurnitureLayer {
    constructor() {
        this.layer = new Layer();
        this.active = false;
        this.tool = new Paper.Tool();
        this.furniturePieces = [];
        this.copiedFurniturePieces = [];
        this.layer.name = 'Furniture layer';
    }

    async initializeFurniture(furniturePieces) {
        this.layer.activate();
        this.layer.removeChildren();
        this.furniturePieces.forEach(piece => {
            piece.remove();
        })
        this.furniturePieces = [];
        var distinctFurniture = furniturePieces.map(piece => piece.geometry.type).filter((value, index, self) => self.indexOf(value) === index).map(type => furnitureTypes[type]);
        distinctFurniture = await Promise.all(distinctFurniture.map(piece => this.enrichWithGroup(piece).then(newPiece => { return newPiece })));

        furniturePieces.forEach(furniturePiece => {
            this.furniturePieces.push(
                new FurniturePiece(
                    furniturePiece.floorElementId,
                    furniturePiece.geometry.type,
                    distinctFurniture.find(piece => piece.type === furniturePiece.geometry.type).group,
                    () => this.removeFurniture(),
                    () => this.deselectFurniture(),
                    this.layer,
                    furniturePiece.geometry)
            )
        });

        distinctFurniture.forEach(piece => {
            piece.group.remove();
        });
    }

    async enrichWithGroup(furniturePiece) {
        return new Promise((resolve, reject) => {
            Paper.project.importSVG(furniturePiece.source, {
                onLoad: (group) => {
                    group.fillColor = 'transparent';
                    group.position = Paper.view.bounds.bottomRight;
                    resolve({ ...furniturePiece, group: group });
                }
            })
        })
    }

    addEventHandlers() {
        Paper.view.onClick = (event) => {
            this.furniturePieces.forEach(furniturePiece => furniturePiece.deselect());
        }

        this.tool.onKeyDown = (event) => {
            if (this.active) {
                if (event.key === 'c' && Key.modifiers.command) {
                    this.copiedFurniturePieces = this.furniturePieces.filter(fp => fp.selected === true).map(fp => fp.toFloorElement());
                } else if (event.key === 'v' && Key.modifiers.command) {
                    this.deselectFurniture(true);
                    this.copiedFurniturePieces.forEach(async fp => {
                        var geometry = { bounds: fp.bounds, rotation: fp.rotation, type: fp.type };
                        geometry.bounds.x += 0.5;
                        geometry.bounds.y -= 0.5;
                        this.enrichWithGroup({ source: fp.source }).then((res) => {
                            this.furniturePieces.push(new FurniturePiece(
                                getUuid(),
                                fp.type,
                                res.group,
                                () => this.removeFurniture(),
                                () => this.deselectFurniture(),
                                this.layer,
                                geometry,
                                true
                            ));
                            res.group.remove();
                        });
                    })
                }
            }
        }
    }

    async addFurniture(furniture) {
        this.layer.activate();
        var newFurniturePiece = await this.enrichWithGroup(furniture);
        this.furniturePieces.push(new FurniturePiece(getUuid(), furniture.type, newFurniturePiece.group, () => this.removeFurniture(), () => this.deselectFurniture(), this.layer, null, true));
        newFurniturePiece.group.remove();
        CanvasController.canvasCallback(heatmapTypes.actions.ADD_FURNITURE, this.furniturePieces[this.furniturePieces.length - 1].toFloorElement());
    }

    removeFurniture() {
        this.furniturePieces = this.furniturePieces.filter(item => item.group != null);
    }

    deselectFurniture(ignoreCommandKey = false) {
        if (!Key.modifiers.command || ignoreCommandKey) {
            this.furniturePieces.forEach(furniturePiece => furniturePiece.deselect());
        }
    }

    activate() {
        this.layer.activate();
        this.active = true;
        this.tool.activate();
        this.addEventHandlers();
        this.furniturePieces.forEach(furniturePiece => furniturePiece.activate());
    }

    deactivate() {
        this.active = false;
        this.furniturePieces.forEach(furniturePiece => furniturePiece.deactivate());
    }
}

export default FurnitureLayer;