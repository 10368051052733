import { postEvent } from "../../scripts/event";
import { displayModes } from "./utils/installationTypes";

const getRemoteUpdatedPropsList = (remotePropsList, localPropsList, localUpdates, idField) => {
    const remoteUpdatedPropsList = [];
    remotePropsList.forEach(remoteProps => {
        const id = remoteProps[idField];
        const updatedKeys = localUpdates[id];
        const localProps = localPropsList?.find(localProps => localProps[idField] === id);
        let props = getRemoteUpdatedProps(remoteProps, localProps, updatedKeys);
        remoteUpdatedPropsList.push(props);
    });
    return remoteUpdatedPropsList;
}

const getRemoteUpdatedProps = (remoteProps, localProps, updatedKeys) => {
    let props;
    if (localProps && updatedKeys) {
        const updatedProps = {};
        updatedKeys.forEach(key => {
            updatedProps[key] = localProps[key];
        })
        props = { ...remoteProps, ...updatedProps };
    } else {
        props = { ...remoteProps };
    }
    return props;
}

const getChangedPropsList = (propsList, updatedKeys, idField) => {
    const changedPropsList = [];
    Object.keys(updatedKeys).forEach(id => {
        const props = propsList.find(object => object[idField] === id);
        if (props) {
            const changedProps = getChangedProps(props, updatedKeys[id], idField);
            changedPropsList.push(changedProps);
        }
    })
    return changedPropsList;
}

const getChangedProps = (props, keys, idField) => {
    const changedProps = { [idField]: props[idField] };
    keys.forEach(key => {
        changedProps[key] = props[key];
    })
    return changedProps;
}

const addToUpdatedKeys = (updatedKeys, id, updatedProps, removeKeys) => {
    Object.keys(updatedProps).forEach(key => {
        if (!updatedKeys[id]) {
            updatedKeys[id] = [key];
        } else if (!updatedKeys[id].includes(key)) {
            updatedKeys[id].push(key);
        }
    })

    if (removeKeys) {
        updatedKeys[id] = updatedKeys[id]?.filter(key => !removeKeys.includes(key));
        if (updatedKeys[id]?.length === 0) {
            delete updatedKeys[id];
        }
    }
    return updatedKeys;
}

const saveObjects = async (changedObjects, uniqueHubIds, customerId, event, putMethod) => {
    const promises = [];
    changedObjects.forEach(object => {
        const promise = putMethod(customerId, object);
        promises.push(promise);
    })
    await Promise.all(promises);
    for (let i = 0; i < uniqueHubIds.length; i++) {
        await postEvent(uniqueHubIds[i], customerId, event);
    }
}

const postNewObjects = async (objects, postMethod, postEventMethod, uniqueHubIds) => {
    try {
        const promises = [];
        objects.forEach(object => {
            const promise = postMethod(object);
            promises.push(promise);
        })

        await Promise.all(promises);
        for (let i = 0; i < uniqueHubIds.length; i++) {
            await postEventMethod(uniqueHubIds[i]);
        }

    } catch (err) {

    }
}

const getNextOrderIndex = (objects) => {
    try {
        return objects.length ? Math.max(...objects.map(object => object.orderIndex)) + 1 : 1
    } catch (err) {
        return 1;
    }
}

const getDefaultId = (objects, idField) => {
    if (objects.length === 1) {
        return objects[0][idField];
    } else {
        return '';
    }
}

const filterItems = (hubs, processors, sources, sourceSelectors, zones, sensors, filterItem) => {
    if (hubs && processors && sources && sourceSelectors && zones && sensors) {
        let filteredHubs = JSON.parse(JSON.stringify(hubs));
        let filteredProcessors = JSON.parse(JSON.stringify(processors));
        let filteredSources = JSON.parse(JSON.stringify(sources));
        let filteredSourceSelectors = JSON.parse(JSON.stringify(sourceSelectors));
        let filteredZones = JSON.parse(JSON.stringify(zones));
        let filteredSensors = JSON.parse(JSON.stringify(sensors));

        switch (filterItem?.type) {
            case 'HUB':
                filteredHubs = filteredHubs?.filter(hub => hub.hubId === filterItem.id);
                filteredProcessors = filteredProcessors?.filter(processor => processor.hubId === filterItem.id);
                filteredSourceSelectors = filteredSourceSelectors?.filter(sourceSelector => filteredProcessors?.some(processor => processor.processorId === sourceSelector.processorId));
                filteredZones = filteredZones?.filter(zone => zone.hubId === filterItem.id);
                filteredSensors = filteredSensors?.filter(sensor => sensor.hubId === filterItem.id);
                break;
            case 'PROCESSOR':
                filteredProcessors = filteredProcessors?.filter(processor => processor.processorId === filterItem.id);
                filteredHubs = filteredHubs?.filter(hub => filteredProcessors?.some(processor => processor.hubId === hub.hubId));
                filteredSourceSelectors = filteredSourceSelectors?.filter(sourceSelector => filteredProcessors?.some(processor => processor.processorId === sourceSelector.processorId));
                filteredZones = filteredZones?.filter(zone => zone.processorId === filterItem.id);
                filteredSensors = filteredSensors?.filter(sensor => filteredZones?.some(zone => zone.zoneId === sensor?.zoneId));
                break;
            case 'SOURCE_SELECTOR':
                filteredSourceSelectors = filteredSourceSelectors?.filter(sourceSelector => sourceSelector.sourceSelectorId === filterItem.id);
                filteredProcessors = filteredProcessors?.filter(processor => filteredSourceSelectors?.some(sourceSelector => sourceSelector.processorId === processor.processorId));
                filteredHubs = filteredHubs?.filter(hub => filteredProcessors?.some(processor => processor.hubId === hub.hubId));
                filteredZones = filteredZones?.filter(zone => zone.sourceSelectorId === filterItem.id);
                filteredSensors = filteredSensors?.filter(sensor => filteredZones?.some(zone => zone.zoneId === sensor?.zoneId));
                break;
            case 'ZONE':
                filteredZones = filteredZones?.filter(zone => zone.zoneId === filterItem.id);
                filteredSourceSelectors = filteredSourceSelectors?.filter(sourceSelector => filteredZones?.some(zone => zone.sourceSelectorId === sourceSelector.sourceSelectorId));
                filteredProcessors = filteredProcessors?.filter(processor => filteredZones?.some(zone => zone.processorId === processor.processorId));
                filteredHubs = filteredHubs?.filter(hub => filteredZones?.some(zone => zone.hubId === hub.hubId));
                filteredSensors = filteredSensors?.filter(sensor => filteredZones?.some(zone => zone.zoneId === sensor?.zoneId));
                break;
            case 'SENSOR':
                filteredSensors = filteredSensors?.filter(sensor => sensor.sensorId === filterItem.id);
                filteredZones = filteredZones?.filter(zone => filteredSensors?.some(sensor => sensor.zoneId === zone.zoneId));
                filteredSourceSelectors = filteredSourceSelectors?.filter(sourceSelector => filteredZones?.some(zone => zone.sourceSelectorId === sourceSelector.sourceSelectorId));
                filteredProcessors = filteredProcessors?.filter(processor => filteredZones?.some(zone => zone.processorId === processor.processorId));
                filteredHubs = filteredHubs?.filter(hub => filteredSensors?.some(sensor => sensor.hubId === hub.hubId));
                break;
            default:
                break;
        }

        filteredSources = filteredSources?.filter(source => filteredProcessors?.some(processor => processor.processorId === source.processorId));

        return {
            hubs: filteredHubs,
            processors: filteredProcessors,
            sources: filteredSources,
            sourceSelectors: filteredSourceSelectors,
            zones: filteredZones,
            sensors: filteredSensors
        };
    } else {
        return;
    }
}

const getSortedGroups = (objects, sortObjects) => {
    let groups = [];
    if (objects && objects.length) {
        if (sortObjects) {
            groups = sortObjects(objects);
        } else {
            groups = [{ objects }];
        }
    }
    return groups;
}

const hideInList = (displayMode) => {
    return displayMode === displayModes.LIST;
}

export {
    getRemoteUpdatedPropsList,
    getRemoteUpdatedProps,
    getChangedPropsList,
    getChangedProps,
    addToUpdatedKeys,
    saveObjects,
    postNewObjects,
    getNextOrderIndex,
    getDefaultId,
    filterItems,
    getSortedGroups,
    hideInList
}