import { useState } from "react";
import styled from "styled-components";
import Button from "../../../UiComponents/Button";
import Checkbox from "../../../UiComponents/Checkbox";
import ButtonContainer from "../../../UiComponents/containers/ButtonContainer";
import { TextMuted } from "../../../UiComponents/text/StyledText";
import ScheduleDays from "./ScheduleDays"

const Container = styled.div`
    min-width: 100%;
`

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const H4 = styled.h4`
    margin: 0;

    :not(:first-of-type) {
        margin-top: 10px;
    }
`

const ScheduleFilter = props => {
    const [days, setDays] = useState(props.filter?.days ?? []);
    const [zones, setZones] = useState(props.filter?.zones ?? []);

    const selectZoneHandler = (zone) => {
        var newZones = [...zones];
        if (newZones.includes(zone)) {
            newZones = newZones.filter(z => z !== zone);
        } else {
            newZones.push(zone);
        }
        setZones(newZones);
    }

    const applyFilter = () => {
        if (days.length === 0 && zones.length === 0) {
            props.setFilter(null);
        } else {
            props.setFilter({ days: days, zones: zones });
        }
        props.setShowFilterModal(false);
    }

    return <Container>
        <h3>Filter</h3>

        <H4>Zones</H4>
        {props.zones?.map((zone, index) => {
            return <RowContainer key={index}>
                <Checkbox checked={zones.includes(zone.zoneId)} onChange={() => selectZoneHandler(zone.zoneId)} />
                <TextMuted>&nbsp;{zone.zoneName}</TextMuted>
            </RowContainer>
        })}

        <H4>Days</H4>
        <ScheduleDays editable days={days} onSelectDay={(newDays) => setDays(newDays)} />

        <ButtonContainer>
            <Button primary onClick={() => applyFilter()}>Apply filter</Button>
            <Button secondary onClick={() => props.setShowFilterModal(false)}>Cancel</Button>
        </ButtonContainer>
    </Container>
}

export default ScheduleFilter;