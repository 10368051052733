import axios from 'axios';
import { getHostname } from './server/server'

const hostname = getHostname();
const path = '/v2/network';

async function updateWifiCredentials(ssid, password) {
    const res = await axios.put(`${hostname}${path}/wifiCredentials`, { ssid: ssid, password: password });
    return res.data;
}

async function checkInternetConnectionStatus(interfaceName) {
    const res = await axios.get(`${hostname}${path}/connectionStatus${interfaceName ? `?interfaceName=${interfaceName}` : ''}`);
    return res.data;
}

async function getNetworkConfig() {
    const res = await axios.get(`${hostname}${path}/networkConfig`);
    return res.data;
}

export {
    updateWifiCredentials,
    checkInternetConnectionStatus,
    getNetworkConfig
}