import React, { useState, useEffect, useContext } from 'react';
import { getAllHubs } from '../../../scripts/hub';
import TopNavigation from '../../navigation/TopNavigation';
import ViewHeader from '../../UiComponents/ViewHeader';
import ViewContainer from '../../UiComponents/ViewContainer';
import Selector from '../../UiComponents/Selector';
import tabs from './tabs';
import DeviceTableContainer from './DeviceTableContainer';
import UserContext from '../../../hooks/UserContext';
import { getAllSensors } from '../../../scripts/sensor';
import { sortByKey } from '../../../scripts/common';

const AdminDeviceView = props => {
    const [selectedTab, setSelectedTab] = useState(tabs.keys.HUBS);
    const [hubs, setHubs] = useState();
    const [sensors, setSensors] = useState();
    const user = useContext(UserContext);
    const customerId = user.user.customerId;

    useEffect(() => {
        getDataHandler();
    }, []);

    const getDataHandler = async () => {
        try {
            let hubRes = await getAllHubs();
            hubRes = sortByKey(hubRes, 'customerId');
            let sensorRes = await getAllSensors();
            sensorRes = sortByKey(sensorRes, 'customerId');
            setHubs(hubRes);
            setSensors(sensorRes);
        } catch (err) {
        }
    }

    const selectedItem = tabs.items.find(item => item.key === selectedTab);
    return <ViewContainer minWidth={'80%'}>
        <div>
            <ViewHeader fitToSmallScreen={true}>
                <TopNavigation to={'/supportcenter'} />
                <h3>Devices</h3>
            </ViewHeader>

            <Selector large items={tabs.items} selectedItem={selectedItem} onClick={newTab => setSelectedTab(newTab.key)} />

            {selectedTab === tabs.keys.HUBS && hubs ? <>
                <DeviceTableContainer
                    customerId={customerId}
                    devices={hubs}
                    setDevices={setHubs}
                    deviceType={'HUB'}
                    refreshDevices={getDataHandler}
                />
            </> : <></>}

            {selectedTab === tabs.keys.SENSORS && sensors ? <>
                <DeviceTableContainer
                    customerId={customerId}
                    devices={sensors}
                    setDevices={setSensors}
                    deviceType={'SENSOR'}
                    refreshDevices={getDataHandler}
                />
            </> : <></>}
        </div>
    </ViewContainer>

}

export default AdminDeviceView;
