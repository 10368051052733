import React, { useEffect, useState } from 'react';
import ActivityGraphContainer from '../zone/graph/ActivityGraphContainer';
import ActivityMetric from './metric/ActivityMetric';
import ErrorMessage from './ErrorMessage';
import Card from '../../UiComponents/Card';
import { ActivityContainer } from '../zone/StyledComponents/ZoneItemStyles';
import HeaderContainer from '../../UiComponents/containers/HeaderContainer';
import venueTypes from '../venueTypes';
import styled from 'styled-components';

const ContainerCard = styled(Card)`
    margin-bottom: 20px;
`

const VenueActivtiyContainer = props => {
    const [errorMessages, setErrorMessages] = useState([]);

    useEffect(() => {
        if (props.zones?.some(zone => zone.mute)) {
            addError(venueTypes.errorMessages.MUTED);
        } else {
            removeError(venueTypes.errorMessages.MUTED);
        }

        if (props.zones?.some(zone => !zone.isRegulating && !zone.hide && zone.shouldRegulate)) {
            addError(venueTypes.errorMessages.REGULATION_OFF);
        } else {
            removeError(venueTypes.errorMessages.REGULATION_OFF);
        }
    }, [props.zones]);

    const addError = (error) => {
        setErrorMessages(prev => {
            var copyErrors = JSON.parse(JSON.stringify(prev));
            if (!copyErrors.some(err => err.key === error.key)) {
                copyErrors.push(error);
            }
            return copyErrors;
        })
    }

    const removeError = (error) => {
        setErrorMessages(prev => {
            var copyErrors = JSON.parse(JSON.stringify(prev));
            copyErrors = copyErrors.filter(err => err.key !== error.key);
            return copyErrors;
        })
    }

    return <ContainerCard>
        <HeaderContainer>
            <h3>Venue activity</h3>
        </HeaderContainer>
        {errorMessages ? <>
            <ErrorMessage errorMessages={errorMessages} />
        </> : <></>}

        <ActivityContainer>
            <ActivityMetric
                customerId={props.customerId}
                addError={addError}
                removeError={removeError}
            />
            <div>
                <ActivityGraphContainer customerId={props.customerId} type={'VENUE'}/>
            </div>
        </ActivityContainer>
    </ContainerCard>
}

export default VenueActivtiyContainer;