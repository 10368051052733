import React, { useContext } from 'react';
import ViewHeader from '../../../UiComponents/ViewHeader';
import TopNavigation from '../../../navigation/TopNavigation';
import DeviceOverview from './DeviceOverview';
import { DeviceStyleProvider } from './DeviceStyles';
import UserContext from '../../../../hooks/UserContext';
import Card from '../../../UiComponents/Card';
import { useParams, useRouteMatch } from 'react-router-dom';
import { sliceUrlAfter } from '../../../../scripts/common';
import serverConnection from '../../../../scripts/server/ServerConnection';


const DeviceView = props => {
    let { id, hubId } = useParams();
    if (id) {
        serverConnection.setCustomerId(id);
    }
    let match = useRouteMatch();
    const user = useContext(UserContext);
    const readOnly = user.isImpersonator && user.isImpersonating;
    return <div>
        
        <ViewHeader>
            <TopNavigation to={sliceUrlAfter(match.url, '/device')} />
            <h3>Devices</h3>
        </ViewHeader>
        
        <Card>
            <DeviceStyleProvider>
                <DeviceOverview
                    user={user.user}
                    hubId={hubId}
                    customerId={parseInt(id)}
                    readOnly={readOnly}
                />
            </DeviceStyleProvider>
        </Card>
        
    </div>
}

export default DeviceView;