import styled from 'styled-components';
import theme from '../../../../UI/theme';

export const FormField = styled.div`
    margin: 20px 0;

    label {
        color: ${theme.colors.textGray};
    }
`

export const FormInput = styled.div`
    input {
        height: 40px;
    }
`

export const FormContainer = styled.div`
    text-align: left;
    width: 300px;
`