
function getProcessorDropdownItems(processors) {
    let items = processors.map(processor => ({value: processor.processorId, label: getProcessorDisplayName(processor)}));       
    items.push({label: 'None',value: ''});
    return items;
}

function getSelectedProcessorDropdownItem(processors, processorId) {
    const processor = processors.find(processor => processor.processorId === processorId);
    if (processor) {
        return {value: processor.processorId, label: getProcessorDisplayName(processor)}
    } else {
        return {label: '--None--'};
    }
}

function getProcessorDisplayName(processor) {
    return processor?.name || 'Default processor';
}

export {
    getProcessorDropdownItems,
    getSelectedProcessorDropdownItem,
    getProcessorDisplayName
}