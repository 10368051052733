import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/processor';

async function getProcessors(customerId) {
    const res = await axios.get(`${hostname}${path}/processors?customerId=${customerId}`);
    return res.data;
}

async function updateProcessor(customerId, processor) {
    const res = await axios.put(`${hostname}${path}?customerId=${customerId}`, processor);
    return res.data;
}

async function addProcessor(customerId, processor) {
    const res = await axios.post(`${hostname}${path}?customerId=${customerId}`, processor);
    return res.data;
}

async function deleteProcessor(customerId, processorId) {
    const res = await axios.delete(`${hostname}${path}?customerId=${customerId}&processorId=${processorId}`);
    return res.data;
}

async function addressTest(customerId, test) {
    const res = await axios.post(`${hostname}${path}/addressTest?customerId=${customerId}`, test);
    return res.data;
}

export {
    getProcessors,
    updateProcessor,
    addProcessor,
    deleteProcessor,
    addressTest
}