import styled, { css } from "styled-components";
import theme from "../../../UI/theme";
import { SkeletonItem } from "./skeletonStyles";

const SkeletonContainer = styled.div`
    padding: 15px 10px;
    height: fit-content;
    background: ${props => props.backgroundColor ? `linear-gradient(to right, ${props.backgroundColor}, ${theme.colors.darkSpace80})` : theme.colors.darkSpace80};
    border-radius: 5px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 50px 50px;
    align-items: center;
    gap: 10px;
    overflow: hidden;
    opacity: 40%;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        grid-template-rows: 40px 40px;
    }
`

const AlbumContainer = styled.div`
    height: 100%;
    aspect-ratio: 1;
    border-radius: 5px;
    grid-row: 1 / span 2;
`

const InfoContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    overflow: hidden;
`

const ControlContainer = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 50px 50px;
    gap: 20px;
    align-items: end;
    justify-content: end;
    overflow: hidden;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        grid-template-columns: 1fr 40px 40px;
    }
`

const MusicPlayerSkeleton = props => {
    return <SkeletonContainer>
        <AlbumContainer>
            <SkeletonItem />
        </AlbumContainer>
        <InfoContainer>
            <SkeletonItem />
        </InfoContainer>
        <ControlContainer>
            <SkeletonItem />
            <SkeletonItem />
            <SkeletonItem />
        </ControlContainer>
    </SkeletonContainer>
}

export default MusicPlayerSkeleton;