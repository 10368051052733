import styled from "styled-components";
import { TextMuted } from "../../UiComponents/text/StyledText";
import Button from "../../UiComponents/Button";
import HeaderContainer from "../../UiComponents/containers/HeaderContainer";
import { useState } from "react";
import InputField from "../../UiComponents/InputField";
import ButtonContainer from "../../UiComponents/containers/ButtonContainer";
import Modal, { ModalContent } from "../../UiComponents/Modal";
import { changeEmail } from "../../../scripts/authentication";
import { emailRegEx } from "../../../scripts/common";
import theme from "../../../UI/theme";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
`

const EditContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`

const ErrorMessage = styled.label`
    margin-top: 10px;
    font-size: 1rem;
    color: ${theme.colors.raspberryRed};
`

const Label = styled.label`
    font-size: 1rem;
`

const EmailSettings = props => {
    const [editing, setEditing] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState();

    const changeEmailHandler = async () => {
        if (!newEmail.match(emailRegEx)) {
            setErrorMsg("Invalid email format");
            setShowPasswordModal(false);
            return;
        }
        var res = await changeEmail(props.customerId, props.email, password, newEmail);
        if (!res.status) {
            setErrorMsg(res.error);
            setShowPasswordModal(false);
        } else {
            setErrorMsg(null);
            setShowPasswordModal(false);
            setEditing(false);
            await props.refresh();
        }
    }

    return <Container>
        <HeaderContainer>
            <Label>Email</Label>
            {!editing ?
                <Button small tertiary onClick={() => setEditing(true)}>Edit</Button>
                : <></>}
        </HeaderContainer>

        <TextMuted>{props.email}</TextMuted>

        {editing ?
            <EditContainer>
                <Label>New email</Label>
                <InputField type={'email'} value={newEmail} onChange={(e) => { setNewEmail(e.target.value) }} />
                <ButtonContainer>
                    <Button primary onClick={() => setShowPasswordModal(true)}>Change email</Button>
                    <Button secondary onClick={() => setEditing(false)}>Cancel</Button>
                </ButtonContainer>
                {errorMsg ? <ErrorMessage>{errorMsg}</ErrorMessage> : <></>}
            </EditContainer>
            : <></>}

        <Modal show={showPasswordModal} close={() => setShowPasswordModal(false)}>
            <EditContainer>
                <Label>Password confirmation</Label>
                <InputField type={'password'} value={password} onChange={(e) => { setPassword(e.target.value) }} />
                <ButtonContainer>
                    <Button primary onClick={changeEmailHandler}>Change email</Button>
                    <Button secondary onClick={() => setShowPasswordModal(false)}>Cancel</Button>
                </ButtonContainer>
            </EditContainer>
        </Modal>

    </Container>
}

export default EmailSettings;