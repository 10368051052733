import axios from 'axios';
import { postEvent } from './event';
import { getHostname } from './server/server'

const hostname = getHostname();
const path = '/v2/hub';

async function updateHub(customerId, hubId, updatedHub) {
    const res = await axios.put(`${hostname}${path}?customerId=${customerId}&hubId=${hubId}`, updatedHub);
    return res.data;
}

async function getHubs(customerId) {
    const res = await axios.get(`${hostname}${path}/hubs?customerId=${customerId}`);
    return res.data;
}

async function scanForProcessors(customerId, hubId) {
    const updatedHub = await updateHub(customerId, hubId, { availableProcessors: {} });
    await postEvent(hubId, customerId, 'SCAN_FOR_PROCESSORS');
    return updatedHub;
}

async function saveNetworkConfig(customerId, hubId, networkConfig) {
    const updatedHub = await updateHub(customerId, hubId, { networkConfig });
    await postEvent(hubId, customerId, 'UPDATE_NETWORK_CONFIG');
    return updatedHub;
}

async function getAllHubs() {
    const res = await axios.get(`${hostname}${path}/all`);
    return res.data;
}

async function localSetup(setupParameters) {
    const res = await axios.post(`${hostname}${path}/localSetup`, setupParameters);
    return res.data;
}

export {
    updateHub,
    getHubs,
    scanForProcessors,
    saveNetworkConfig,
    getAllHubs,
    localSetup
}