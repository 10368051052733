import React, { useState } from 'react';
import SensorItem from './SensorItem'
import WavedSlider from '../../../UiComponents/legacy/WavedSlider';
import Modal from '../../../UiComponents/Modal';
import Dropdown from '../../../UiComponents/Dropdown';
import { DeviceList } from './DeviceStyles';
import Divider from '../../../UiComponents/Divider';
import InputField from '../../../UiComponents/InputField';
import Button from '../../../UiComponents/Button';
import { TextMuted } from '../../../UiComponents/text/StyledText';
import Checkbox from '../../../UiComponents/Checkbox';
import ZoneHierarchyTypeSelector from './ZoneHierarchyTypeSelector';
import hierarchyTypes from './hierarchyTypes';
import { getProcessorDropdownItems, getSelectedProcessorDropdownItem, getProcessorDisplayName } from './Processor/processorUtils';
import SourceSelectorDropdown from './Zone/SourceSelectorDropdown';

const ZoneComponent = props => {
    const [showDetails, setShowDetails] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    function onSliderCommited(zone) {
        var updatedMapValues = props.zone.parsedMapValues.join(',');
        props.updateZoneHandler(props.zone.zoneId, 'processorMapValues', updatedMapValues);
    }

    function getBackupZoneItems(zoneId) {
        var backupZones = props.zones.filter(z => z.zoneId != zoneId).map(z => {
            return {
                value: z.zoneId,
                label: z.zoneName
            };
        });
        backupZones.push({
            label: 'None',
            value: ''
        })
        return backupZones;
    }

    function getSelectedBackupZone(backupZoneID) {
        var selectedZone = props.zones.find(z => z.zoneId === backupZoneID);
        if (selectedZone) {
            return { value: selectedZone.zoneId, label: selectedZone.zoneName };
        } else {
            return { label: 'None' };
        }
    }

    function setShowDetailsHandler(state) {
        setShowDetails(state);
    }

    return <>
        <Divider />
        <div className="zone-container">
            <div className="flex space-between center-align">
                <h4>
                    {props.isEditing ?
                        <InputField
                            value={props.zone.zoneName}
                            onChange={(e) => props.updateZoneHandler(props.zone.zoneId, 'zoneName', e.target.value)}
                        />
                        : <span>{props.zone.zoneName}</span>}
                    <Button tertiary small margin={12} onClick={() => setShowDetailsHandler(!showDetails)}>{showDetails ? 'Hide details' : 'Details'}</Button>
                </h4>
                <div>
                    {props.sensors.length == 0 && props.isEditing && !props.zone.defaultZone ? <>
                        <Button secondary small onClick={() => setShowDeleteModal(true)}>Delete zone</Button>
                    </> : <>
                        </>}
                </div>
            </div>
            {showDetails ? <>
                <p>Zone ID: {props.zone.zoneId}</p>
                <p className="break-word">Address:&nbsp;
                    <span>
                        {props.isEditing ? <>
                            <InputField
                                small
                                value={props.zone.objectAddress || ''}
                                onChange={(e) => props.updateZoneHandler(props.zone.zoneId, 'objectAddress', e.target.value)}
                            />
                        </> : <>
                                {props.zone.objectAddress}
                            </>}

                    </span>
                </p>
                <div className="waved-slider">
                    <h4>Processor map values</h4>
                    <WavedSlider
                        onChange={(event, newValues) => { if (props.isEditing) { props.updateZoneHandler(props.zone.zoneId, 'parsedMapValues', newValues) } }}
                        onChangeCommitted={(event, newValues) => onSliderCommited()}
                        value={props.zone.parsedMapValues}
                        min={0}
                        max={100}>
                    </WavedSlider>
                </div>

                <div>
                    <p>Processor:&nbsp;
                    {props.isEditing ? <>
                            <Dropdown small
                                items={getProcessorDropdownItems(props.processors)}
                                onClick={(item) => props.updateZoneHandler(props.zone.zoneId, 'processorId', item.value)}
                                selectedItem={getSelectedProcessorDropdownItem(props.processors, props.zone.processorId)}
                            />
                        </> : <>
                                {getProcessorDisplayName(props.processors.find(processor => processor.processorId === props.zone.processorId))}
                            </>}
                    </p>
                </div>

                <div>
                    {props.zones.length > 1 ? <>
                        <h4>Backup zone: {props.isEditing ? <>
                            <Dropdown small
                                items={getBackupZoneItems(props.zone.zoneId)}
                                onClick={(item) => props.updateZoneHandler(props.zone.zoneId, 'backupZoneId', item.value)}
                                selectedItem={getSelectedBackupZone(props.zone.backupZoneId)}
                            />
                        </> : <>
                                {getSelectedBackupZone(props.zone.backupZoneId).label}
                            </>}

                        </h4>

                    </> : <>
                        </>
                    }

                </div>

                {props.user.role === 'admin' ?
                    <>
                        <ZoneHierarchyTypeSelector
                            zoneId={props.zone?.zoneId}
                            hierarchyType={props.zone?.hierarchyType}
                            masterID={props.zone?.masterId}
                            updateZoneHandler={props.updateZoneHandler}
                            isEditing={props.isEditing}
                            masterZones={props.zones?.filter(zone => zone.hierarchyType === hierarchyTypes.MASTER.value)} />

                        <h4>Should regulate zone: <Checkbox
                            checked={props.zone.shouldRegulate}
                            disabled={!props.isEditing}
                            onChange={() => props.updateZoneHandler(props.zone.zoneId, 'shouldRegulate', !props.zone.shouldRegulate)} /></h4>
                    </>

                    : <></>}

                {props.zone.processorId ? <>
                    <div>
                        <p className="break-word">Source selector:&nbsp;
                        <span>
                                {props.isEditing ? <>
                                    <SourceSelectorDropdown
                                        zone={props.zone}
                                        sourceSelectors={props.sourceSelectors.filter(sourceSelector => sourceSelector.processorId === props.zone.processorId)}
                                        updateZoneHandler={props.updateZoneHandler}
                                    />
                                </> : <>
                                    {props.sourceSelectors.find(sourceSelector => sourceSelector.sourceSelectorId === props.zone.sourceSelectorId)?.name}
                                    </>}
                            </span> <br />
                        </p>
                    </div>
                </> : <></>}

            </> : <>
                </>}
            {props.sensors.length ? <>
                <DeviceList>
                    {props.sensors.map(sensor => {
                        return <li key={sensor.sensorId}>
                            <SensorItem
                                customerId={props.customerId}
                                key={sensor.sensorId}
                                hub={props.hub}
                                zones={props.zones}
                                sensor={sensor}
                                updateSensorHandler={props.updateSensorHandler}
                                deleteSensorHandler={props.deleteSensorHandler}
                                isEditing={props.isEditing}
                            />
                        </li>;
                    })}
                </DeviceList>
            </> : <>
                    <TextMuted>No sensors in this zone</TextMuted>
                </>}
        </div>

        <Modal show={showDeleteModal} close={() => setShowDeleteModal(false)}>
            <h3>Delete zone</h3>
            <p>This will action will save the current configuration and delete the zone</p>
            <div>
                <Button primary margin onClick={() => { props.deleteZoneHandler(props.zone.zoneId); setShowDeleteModal(false); }}>Delete Zone</Button>
                <Button secondary margin onClick={() => setShowDeleteModal(false)}>Cancel</Button>
            </div>
        </Modal>

    </>
}

export default ZoneComponent;
