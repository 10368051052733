import { Children, useState } from "react";
import styled, { css } from "styled-components";
import theme from "../../../UI/theme";
import Button from "../Button";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const StepOverviewContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
`

const StepLabel = styled.label`
    flex: 1 1 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid white;
    padding-bottom: 5px;
    cursor: pointer;

    ${props => props.activeStep && css`
        color: ${theme.colors.greenReadable};
        border-color: ${theme.colors.greenEnergy};
    `}
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
`

function StepWizard({ children }) {
    const stepArray = Children.toArray(children);
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        if (activeStep === stepArray.length) {
            return;
        }

        setActiveStep(prev => prev + 1);
    }

    const handleBack = () => {
        if (activeStep === 0) {
            return;
        }

        setActiveStep(prev => prev - 1);
    }

    return <Container>
        <StepOverviewContainer>
            {stepArray.map((child, index) => {
                return <StepLabel
                    key={index}
                    activeStep={index === activeStep}
                    onClick={() => setActiveStep(index)}>
                    {child.props.stepLabel ?? `${index + 1}`}
                </StepLabel>
            })}
        </StepOverviewContainer>

        {stepArray.map((child, index) => {
            if (index === activeStep) {
                return child;
            }
        })}

        <ButtonContainer>
            {activeStep > 0 ? <Button secondary onClick={handleBack}>{stepArray[activeStep].props.backButtonLabel ?? 'Back'}</Button> : <></>}
            {activeStep < stepArray.length - 1 ? <Button primary onClick={handleNext}>{stepArray[activeStep].props.nextButtonLabel ?? 'Next'}</Button> : <></>}
        </ButtonContainer>
    </Container>
}

export default StepWizard;