import axios from 'axios'
import * as AbsintheSocket from "@absinthe/socket";
import { Socket as PhoenixSocket } from 'phoenix'
import { sybGraphQl } from './SoundtrackYourBrandGraphQl';

class SoundtrackYourBrandPlayer {
    constructor(playerInfo) {
        this.id = playerInfo?.remoteId;
        this.token = playerInfo?.token;
        this.refreshToken = playerInfo?.refreshToken;
        this.playbackSocket = undefined;
        this.nowPlayingSocket = undefined;
        this.config = {
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json'
            }
        };
    };

    async checkAvailablePlayers(credentials) {
        const email = credentials.email;
        const password = credentials.password;
        const login = await this.login(email, password);
        if (!login) return false;
        const zones = await this.getZones();
        if (!zones) return false;
        const availablePlayers = {
            token: this.token,
            refreshToken: this.refreshToken,
            zones: zones,
            type: 'SoundtrackYourBrand'
        }
        return availablePlayers;
    }

    async execute(query, config) {
        var sybRes;

        try {
            sybRes = await axios.post('https://api.soundtrackyourbrand.com/v2', query, config);
            //console.log('sybRes: ', sybRes);
            if (sybRes.status === 200 && sybRes.data.data) return sybRes.data.data;
            else if (sybRes.status === 200) return true;
            else return false;
        } catch (e) {
            console.log('SYB: Failed to execute query/mutation:');
            console.log('query: ', query);
            console.log(e);
            return false;
        }
    }

    async login(email, password) {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const query = sybGraphQl.mutation.login(email, password);
        try {
            const result = await this.execute(query, config);
            if (!result) return false;
            this.token = result.loginUser.token;
            this.refreshToken = result.loginUser.refreshToken;
            this.config = {
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    'Content-Type': 'application/json'
                }
            };
            return true;
        } catch (err) {
            console.log(err);
            console.log("Not able to fetch SYB-Token");
            return false;
        }
    }

    async getZones() {
        const query = sybGraphQl.query.getZones();
        const result = await this.execute(query, this.config);
        if (!result) return false;
        var zones = []
        try {
            const accounts = result.me.accounts.edges;
            accounts.forEach(a => {
                var locations = a.node.locations.edges;
                locations.forEach(l => {
                    var lZones = l.node.soundZones.edges;
                    lZones.forEach(z => {
                        var zone = z.node;
                        zone.account = a.node.businessName;
                        zone.location = l.node.name;
                        zone.address = l.node.address;
                        zones.push(zone);
                    })
                })
            });
        } catch {
            console.log('ERROR: Failed to fetch SYB zones');
            return false;
        }
        return zones;
    }

    async play() {
        const mutation = sybGraphQl.mutation.play(this.id);
        const result = await this.execute(mutation, this.config);
        return result;
    }

    async pause() {
        const mutation = sybGraphQl.mutation.pause(this.id);
        const result = await this.execute(mutation, this.config);
        return result;
    }

    async nextSong() {
        const mutation = sybGraphQl.mutation.nextSong(this.id);
        const result = await this.execute(mutation, this.config);
        return result;
    }

    async queuePlaylist(playlistId) {
        const query = sybGraphQl.mutation.queuePlaylist(this.id, playlistId)
        const result = await this.execute(query, this.config);
        return result
    }

    async playPlaylist(playlistID) {
        const queueResult = await this.queuePlaylist(playlistID);
        if (!queueResult) return false;
        const nextResult = await this.nextSong();
        if (!nextResult) return false;
        return true;
    }

    async getState() {
        const query = sybGraphQl.query.state(this.id);
        const result = await this.execute(query, this.config);
        if (result.soundZone.playback.state === 'playing') return 'PLAYING';
        else if (result.soundZone.playback.state === 'paused') return 'PAUSED';
        else return false;
    }

    async getVolume() {
        const query = sybGraphQl.query.getVolume(this.id);
        const result = await this.execute(query, this.config);
        if (result.soundZone) return result.soundZone.playback.volume;
        else return false;
    }

    async setVolume(volume) {
        if (0 > volume || volume > 16) return false;
        const query = sybGraphQl.query.setVolume(this.id, volume);
        const result = await this.execute(query, this.config);
        return result;
    }

    async getPlayingMusic() {
        const query = sybGraphQl.query.getPlayingMusic(this.id);
        var playingInfo = await this.execute(query, this.config);
        playingInfo = playingInfo.nowPlaying;
        const state = await this.getState();
        const volume = await this.getVolume();
        var artists = [];
        playingInfo.track.artists.forEach(artist => { artists.push(artist.name) });
        const music = {
            state: state,
            volume: volume,
            songTitle: playingInfo.track.title,
            artists: artists,
            album: playingInfo.track.album.title,
            playlist: playingInfo.playFrom.name,
            imageUrl: playingInfo.track.album.image.url,
            songColor: playingInfo.track.display.colors.primary.hex,
        }
        return music;
    }

    async getAvailablePlaylists() {
        const query = sybGraphQl.query.getAvailablePlaylists(this.id);
        const result = await this.execute(query, this.config);
        const pl = result.soundZone.account.musicLibrary.playlists.edges;
        var playlists = [];
        pl.forEach(element => {
            var list = {
                name: element.node.name,
                id: element.node.id,
                imageUrl: element.node.display.image.placeholder
            }
            playlists.push(list);
        });
        return playlists;
    }

    async checkAuthorization() {
        const query = sybGraphQl.query.authentication(this.id);
        const result = await this.execute(query, this.config);
        try {
            if (result.soundZone.id) return true;
            else return false; 
        } catch (e) {
            return false;
        }
    }

    async checkConnection() {
        const query = sybGraphQl.query.connection(this.id);
        const result = await this.execute(query, this.config);
        try {
            if (result.soundZone.nowPlaying.track) return true; 
            else return false;
        } catch (e) {
            return false; 
        }
    }

    async wakeupPlayer() {
        this.play();
        return true;
    }

    async updateAuthorization() {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const mutation = sybGraphQl.mutation.updateAuthorization(this.refreshToken);
            const result = await this.execute(mutation, config);
            var newToken = result.refreshLogin.token;
            var newRefreshToken = result.refreshLogin.refreshToken;
            return ({
                token: newToken,
                refreshToken: newRefreshToken
            })
        } catch (err) {
            console.log(err);
            console.log("Not able to refresh SYB-Token");
            return false;
        }
    }

    //// SUBSCRIPTIONS ////
    createAbsinthSocket() {
        const socket = AbsintheSocket.create(
            new PhoenixSocket(
                'wss://api.soundtrackyourbrand.com/v2',
                {
                    params: {
                        'Authorization': `Bearer ${this.token}`
                    }
                }
            )
        );
        return socket;
    }

    async subscribe(musicPlayer) {
        //// PLAYBACK SUBSCRIPTION ////
        this.playbackSocket = this.createAbsinthSocket();
        const playbackSubscription = sybGraphQl.subscription.playback(this.id);
        const playbackNotifier = AbsintheSocket.send(this.playbackSocket, {
            operation: playbackSubscription,
            variables: { input: { soundZone: this.id } }
        });
        AbsintheSocket.observe(this.playbackSocket, playbackNotifier, {
            onResult: this.playbackSubscriptionCallback([{ musicPlayer: musicPlayer }])
        })

        //// NOW PLAYING SUBSCRIPTION ////
        this.nowPlayingSocket = this.createAbsinthSocket();
        const nowPlayingSubscription = sybGraphQl.subscription.nowPlaying(this.id);
        const nowPlayingNotifier = AbsintheSocket.send(this.nowPlayingSocket, {
            operation: nowPlayingSubscription,
            variables: { input: { soundZone: this.id } }
        });
        AbsintheSocket.observe(this.nowPlayingSocket, nowPlayingNotifier, {
            onResult: this.nowPlayingSubscriptionCallback([{ musicPlayer: musicPlayer }])
        })
    }

    async cleanup() {
        try {
            this.playbackSocket?.phoenixSocket?.disconnect(() => {
            })
            this.nowPlayingSocket?.phoenixSocket?.disconnect(()=> {
            })
        } catch (e) {
            console.log('Error disconnecting sockets');
            console.log(e);
        }
    }

    playbackSubscriptionCallback = event => (...args) => {
        const musicPlayer = event[0].musicPlayer;
        try {
            var state = args[0].data.playbackUpdate.playback.state;
            var volume = args[0].data.playbackUpdate.playback.volume;
            var data = {};
            if (state === 'playing') data.state = 'PLAYING';
            else if (state === 'paused') data.state = 'PAUSED';
            data.volume = volume;
            musicPlayer.subscriptionCallback(data);
        } catch (e) {
            console.log('Playback-Subscription Error, fetching...');
            this.getState().then((state) => {
                musicPlayer.subscriptionCallback({ state: state });
            });
            this.getVolume().then((volume) => {
                musicPlayer.subscriptionCallback({ volume: Number(volume) });
            })
        }
    }

    nowPlayingSubscriptionCallback = event => (...args) => {
        const musicPlayer = event[0].musicPlayer;
        try {
            const track = args[0].data.nowPlayingUpdate.nowPlaying.track;
            const playFrom = args[0].data.nowPlayingUpdate.nowPlaying.playFrom;
            var data = {
                songTitle: track.title,
                album: track.album.title,
                playlist: playFrom.name,
                imageUrl: track.album.image.url,
                songColor: track.display.colors.primary.hex
            }
            var artists = [];
            track.artists.forEach(artist => { artists.push(artist.name) });
            data.artists = artists;
            musicPlayer.subscriptionCallback(data);
        } catch (e) {
            console.log('NowPlaying-Subscription Error, fetching...');
            this.getPlayingMusic().then(data => {
                musicPlayer.subscriptionCallback(data);
            })
        }

    }

}
export default SoundtrackYourBrandPlayer;