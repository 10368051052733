import React, { useState, useRef, useContext } from 'react';
import RegulationToggle from '../RegulationToggle';
import styled from 'styled-components';
import { switchGlobalRegulation } from '../../../scripts/zone';
import UserContext from '../../../hooks/UserContext';
import Modal, { ModalContent } from '../../UiComponents/Modal';
import Button from '../../UiComponents/Button';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`

const GlobalRegulationToggle = props => {
    const user = useContext(UserContext);
    const [showModal, setShowModal] = useState(false);
    const newStateRef = useRef();

    var globalRegulationState = props.zones?.filter(zone => zone.isRegulating && !zone.hide).length > 0;

    const toggleHandler = (newState) => {
        if (user.isImpersonator && user.isImpersonating) {
            return;
        }

        if (newState == false) {
            newStateRef.current = newState;
            setShowModal(true);
        } else {
            setGlobalRegulationHandler(newState);
        }

    }

    const setGlobalRegulationHandler = async (newState) => {
        const oldZones = JSON.parse(JSON.stringify(props.zones));
        try {
            props.setZones(zones => {
                var updatedZones = [...zones];
                updatedZones.forEach(zone => {
                    zone.isRegulating = newState;
                });
                return updatedZones;
            });
            await switchGlobalRegulation(props.zones[0].customerId, newState);
        } catch (error) {
            props.setZones(oldZones);
        }
    }

    if (props.zones?.length) {
        return <>
            <Container>
                <span>Global regulation&nbsp;</span>
                <RegulationToggle checked={globalRegulationState} onToggle={toggleHandler} />
            </Container>

            <Modal show={showModal} close={() => setShowModal(false)}>
                <ModalContent>
                    <p>
                        Are you sure you want to pause regulation in all zones?
                    </p>

                    <Button primary onClick={() => { setGlobalRegulationHandler(newStateRef.current); setShowModal(false); }}>Yes</Button>
                    <Button secondary onClick={() => setShowModal(false)}>Cancel</Button>

                </ModalContent>
            </Modal>
        </>;
    } else {
        return <></>;
    }

}

export default GlobalRegulationToggle;

