import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { installationTabs } from "../components/installation/installationTypes";
import SettingsContainer from "../components/installation/SettingsContainer";
import SystemTree from "../components/installation/SystemTree";
import { filterItems } from "../components/installation/tableUtils";
import { useSubscription } from "../hooks/useSubscription";
import { subscriptionsTypes } from "../scripts/subscription/subscriptionTypes";

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
`

const InstallationView = props => {
    const [selectedTab, setSelectedTab] = useState(installationTabs[0]);
    const [selectedItemHierarchy, setSelectedItemHierarchy] = useState();
    const [hubs, setHubs] = useSubscription(subscriptionsTypes.hubs);
    const [processors, setProcessors] = useSubscription(subscriptionsTypes.processors);
    const [sourceSelectors, setSourceSelectors] = useSubscription(subscriptionsTypes.sourceSelectors);
    const [zones, setZones] = useSubscription(subscriptionsTypes.zones);
    const [sensors, setSensors] = useSubscription(subscriptionsTypes.sensors);
    const [sources, setSources] = useSubscription(subscriptionsTypes.sources);
    const [filteredItems, setFilteredItems] = useState();

    useEffect(() => {
        setSelectedItemHierarchy(null);
    }, [props.customerId])

    useEffect(() => {
        let newFilteredItems = filterItems(hubs, processors, sources, sourceSelectors, zones, sensors, selectedItemHierarchy?.[selectedItemHierarchy?.length - 1]);
        setFilteredItems(newFilteredItems);
    }, [hubs, processors, sources, sourceSelectors, zones, sensors, selectedItemHierarchy])

    if (hubs && processors && sourceSelectors && zones && sensors && sources) {
        return <Container>
            <SystemTree
                hubs={hubs}
                processors={processors}
                sourceSelectors={sourceSelectors}
                zones={zones}
                sensors={sensors}
                selectedItemHierarchy={selectedItemHierarchy}
                setSelectedItemHierarchy={setSelectedItemHierarchy}
            />

            <SettingsContainer
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                selectedItemHierarchy={selectedItemHierarchy}
                setSelectedItemHierarchy={setSelectedItemHierarchy}
                customerId={props.customerId}
                hubs={filteredItems?.hubs ?? hubs}
                processors={filteredItems?.processors ?? processors}
                sources={filteredItems?.sources}
                sourceSelectors={filteredItems?.sourceSelectors ?? sourceSelectors}
                zones={filteredItems?.zones ?? zones}
                sensors={filteredItems?.sensors ?? sensors}
            />
        </Container>
    } else {
        return <></>
    }

}

export default InstallationView;