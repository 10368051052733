import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/trend';

const getCurrentWeekTrend = async (customerId, zoneId, dataType) => {
    const result = await axios.get(
        `${hostname}${path}/currentWeekAndYear?customerId=${customerId}&zoneId=${zoneId}&dataType=${dataType}`,
        { withCredentials: true });

    if (result.data.trends) {
        return result.data.trends;
    } else {
        console.log('Error while fetching trend data');
        return false;
    }
}

const getTrendData = async (customerId, period, date, hierarchyLevel, objectID) => {
    const result = await axios.get(
        `${hostname}${path}/trendData?customerId=${customerId}&period=${period}&date=${date}&type=${hierarchyLevel}&objectID=${objectID}`,
        { withCredentials: true });
    if (result.data.trendData) {
        return result.data.trendData;
    } else {
        console.log('Error while fetching trend data');
        return false;
    }
}

const getSensorYearTrend = async (customerId) => {
    const result = await axios.get(
        `${hostname}${path}/sensorYearTrend?customerId=${customerId}`,
        { withCredentials: true }
    );

    if (result.data.trendData) {
        return result.data.trendData;
    } else {
        console.log('Error while fetching trend data');
        return false;
    }
}

export {
    getCurrentWeekTrend,
    getTrendData,
    getSensorYearTrend
}