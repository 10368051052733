import React, { useState, useRef } from 'react';
import Card from '../../UiComponents/Card';
import ZoneKeyMetric from './ZoneKeyMetric';
import ZoneRegulationToggle from './StyledComponents/ZoneRegulationToggle';
import AnimateHeight from 'react-animate-height';
import LiveGraphContainer from './graph/LiveGraphContainer';
import ExpandCollapseButton from '../../UiComponents/buttons/ExpandCollapseButton';
import ActivityGraphContainer from './graph/ActivityGraphContainer';
import { MainContainer, RowContent, GraphContainer, ZoneHeader, SettingsContainer, RowContainer } from './StyledComponents/ZoneItemStyles';
import ZoneModeController from './ZoneModeController';
import HeaderContainer from '../../UiComponents/containers/HeaderContainer';
import ZoneSchedulesContainer from './ZoneSchedulesContainer';
import { FaRegClock, FaSeedling } from 'react-icons/fa';
import UnmuteZoneButton from './UnmuteZoneButton';
import ZoneVolumeSlider from './ZoneVolumeSlider';
import { mappedValuesToArray } from '../../../scripts/volume';
import hierarchyTypes from '../../settings/venue/device/hierarchyTypes';
import { postUserInteraction } from '../../../scripts/userInteraction';
import { userInteractionTypes } from '../../admin/customerDetails/userInteractionTypes';
import MasterZoneInfo from './MasterZoneInfo';
import useServerStatus from '../../../hooks/useServerStatus';
import { getHubConnectedStatus, getHubStatus } from '../../installation/utils/connectionStatus';


const OLD_DATA_LIMIT_SECONDS = 10;

const ZoneItem = props => {
    const zoneLive = props.zoneLive;
    const serverStatus = useServerStatus();
    const [expanded, setExpanded] = useState(false);
    const [hasSchedule, setHasSchedule] = useState(false);
    const lastUpdate = useRef();
    const hubConnected = getHubConnectedStatus(props.hub);
    const processorOK = (props.hub?.secondsSinceLastUpdate <= OLD_DATA_LIMIT_SECONDS && props.processor?.isConnected && serverStatus) || false;
    const isRegulating = props.zone.isRegulating && (zoneLive && zoneLive.isRegulatingAudioSource != 0);

    const getAudioSourceDisplayName = (sourceId) => {
        if (props.hub && props.sources) {
            var source = props.sources.find(source => source.sourceId === sourceId);
            return source ? source.name : sourceId;
        } else {
            return sourceId;
        }
    }

    const unmuteHandler = () => {
        props.updateZoneHandler(props.zone.zoneId, { mute: false });
        postUserInteraction(props.zone.customerId, {
            zoneId: props.zone.zoneId,
            setting: userInteractionTypes.mute.key,
            fromValue: +true,
            toValue: +false,
        });
    }

    return <li>
        <Card>
            <MainContainer onClick={() => setExpanded(s => !s)}>

                <HeaderContainer>
                    <ZoneHeader>
                        {props.name || props.zone.zoneName}{hasSchedule ? <FaRegClock /> : ''}{props.zone.moodSustain ? <FaSeedling /> : ''}
                    </ZoneHeader>
                    <ExpandCollapseButton showState={expanded} height={30} />
                </HeaderContainer>

                {props.zone.shouldRegulate && props.zone.hierarchyType !== hierarchyTypes.MASTER.value ?

                    <RowContent>

                        <ZoneKeyMetric
                            small
                            metrics={[{
                                title: 'Music',
                                value: zoneLive?.sysvol,
                                invalid: zoneLive?.sysvol === null || isNaN(Number(zoneLive?.sysvol)) || !processorOK,
                                unit: '%',
                                disabled: props.zone.mute || !isRegulating,
                                label: props.zone.mute ? 'Music muted' : !isRegulating ? 'Regulation paused' : '',
                            }, {
                                title: 'Measured',
                                value: zoneLive?.averageDecibel,
                                invalid: !hubConnected || isNaN(Number(zoneLive?.averageDecibel)) || zoneLive?.averageDecibel <= 0,
                                unit: ' dB'
                            }]}
                            count={true}
                        />
                        <GraphContainer>
                            <ActivityGraphContainer
                                customerId={props.zone.customerId}
                                zoneId={props.zone.zoneId}
                                type={'ZONE'}
                            />
                        </GraphContainer>

                        <ZoneModeController
                            zone={props.zone}
                            updateZoneHandler={props.updateZoneHandler}
                            disabled={!hubConnected || !processorOK || !isRegulating || props.zone.mute || props.zone.hierarchyType === hierarchyTypes.MASTER.value}
                        />

                    </RowContent>
                    : <></>}
                {serverStatus && hubConnected ? <>
                    {props.zone.shouldRegulate ?
                        <SettingsContainer>

                            {props.zone.hierarchyType !== hierarchyTypes.MASTER.value ? <>
                                <ZoneRegulationToggle
                                    checked={isRegulating}
                                    updateZoneHandler={props.updateZoneHandler}
                                    zone={props.zone}
                                    hub={props.hub}
                                    masterZone={props.masterZone}
                                    preventedByAudioSource={zoneLive && !zoneLive.isRegulatingAudioSource}
                                    audioSourceName={zoneLive && getAudioSourceDisplayName(zoneLive.activeAudioSource)}
                                />
                            </> : <>
                                    {props.zone.isRegulating ? <>
                                        <MasterZoneInfo isRegulating={props.zone.isRegulating} sysvol={zoneLive?.sysvol} />
                                    </> : <></>}
                                </>}



                            {!isRegulating && (zoneLive || processorOK) ?
                                <ZoneVolumeSlider
                                    volume={zoneLive?.sysvol ?? 0}
                                    lastUpdate={lastUpdate.current}
                                    customerId={props.zone.customerId}
                                    zoneId={props.zone.zoneId}
                                    hubId={props.hub?.hubId}
                                    processorMapValues={mappedValuesToArray(props.zone.processorMapValues)}
                                    calibrationSysvols={props.zone.calibrationPoints?.points?.map(point => point.sysvol)}
                                    calibrationMethod={props.hub?.calibrationMethod} />
                                : <></>}

                            {props.zone.mute ?
                                <RowContainer>
                                    {props.zone.mute ? <UnmuteZoneButton onUnmute={unmuteHandler} /> : <></>}
                                </RowContainer>
                                : <></>}

                        </SettingsContainer>
                        : <>
                            {hubConnected && (zoneLive || processorOK) ?
                                <SettingsContainer>
                                    <ZoneVolumeSlider
                                        volume={zoneLive?.sysvol ?? 0}
                                        lastUpdate={lastUpdate.current}
                                        customerId={props.zone.customerId}
                                        zoneId={props.zone.zoneId}
                                        hubId={props.hub?.hubId}
                                        processorMapValues={mappedValuesToArray(props.zone.processorMapValues)}
                                        calibrationSysvols={props.zone.calibrationPoints?.points?.map(point => point.sysvol)}
                                        calibrationMethod={props.hub?.calibrationMethod} />
                                </SettingsContainer>
                                : <></>}
                        </>}

                </> : <></>}

            </MainContainer>

            <AnimateHeight duration={500} height={expanded ? 'auto' : 0}>

                <ZoneSchedulesContainer
                    schedules={props.schedules}
                    zoneId={props.zone.zoneId}
                    setHasSchedule={setHasSchedule} />

                <LiveGraphContainer hidden={!expanded} customerId={props.zone.customerId} objectID={props.zone.zoneId} liveValues={zoneLive} hubConnected={hubConnected}/>

            </AnimateHeight>

        </Card>
    </li>

}

export default ZoneItem;

