import styled from "styled-components";
import { TextMuted } from "../../../UiComponents/text/StyledText";
import TimeInput from "../../../UiComponents/TimeInput";
import ToggleButton from "../../../UiComponents/ToggleButton";
import Button from "../../../UiComponents/Button";
import ButtonContainer from "../../../UiComponents/containers/ButtonContainer";
import { toDisplayTime } from "../../../../scripts/common";
import { useState } from "react";
import theme from "../../../../UI/theme";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const FormContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.2fr 0.2fr 1fr;
    grid-gap: 10px;
    margin-bottom: 10px;
`

const ErrorMessage = styled.label`
    margin: 10px 0;
    color: ${theme.colors.raspberryRed};
    padding: 0;
`

const OpeningHoursForm = props => {
    const [open, setOpen] = useState(props.open);
    const [start, setStart] = useState(props.start);
    const [end, setEnd] = useState(props.end);
    const [error, setError] = useState();

    const toggleHandler = (val) => {
        setOpen(val);
    }

    const changeStartTimeHandler = (newTime) => {
        setStart(toGoogleTime(newTime));
    }

    const changeEndTimeHandler = (newTime) => {
        setEnd(toGoogleTime(newTime));
    }

    const validateOpeningHours = () => {
        if (open === true && (!start || isNaN(parseInt(start)))) {
            setError("Opening time must be a valid time");
            return false;
        } else if (open === true && (!end || isNaN(parseInt(end)))) {
            setError("Closing time must be a valid time");
            return false;
        }
        setError(null);
        return true;
    }

    const saveHandler = () => {
        if (validateOpeningHours()) {
            props.onSave({ open: open, start: start, end: end });
        }
    }

    return <Container>
        <h3>{props.day} opening hours</h3>

        <FormContainer>
            <TextMuted>Open</TextMuted>
            <ToggleButton checked={open} onToggle={(val) => toggleHandler(val)} />

            <TextMuted>Opening time</TextMuted>
            <TextMuted>Closing time</TextMuted>

            <TimeInput value={toDisplayTime(start) ?? ''} onChange={(e) => changeStartTimeHandler(e.target.value)} />
            <TimeInput value={toDisplayTime(end) ?? ''} onChange={(e) => changeEndTimeHandler(e.target.value)} />
        </FormContainer>

        {error ? <ErrorMessage>{error}</ErrorMessage> : <></>}

        <ButtonContainer>
            <Button primary onClick={() => saveHandler()}>Save</Button>
            <Button secondary onClick={() => props.onCancel()}>Cancel</Button>
        </ButtonContainer>

    </Container>
}

export default OpeningHoursForm;

function toGoogleTime(displayTime) {
    if (displayTime.length === 5) {
        return `${displayTime.substr(0, 2)}${displayTime.substr(3, 2)}`
    } else {
        console.log("Invalid time format.")
        return null;
    }
}