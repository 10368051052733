import { postEvent } from "../../../scripts/event";
import { createZone, updateZone } from "../../../scripts/zone";
import { postNewObjects, saveObjects } from "../tableUtils";
import { hierarchyTypes } from "./zoneTypes";

const saveUpdatedZones = async (changedZones, zones, customerId) => {
    prepareForSave(changedZones, zones);
    const uniqueHubIds = getUniqueHubIds(changedZones, zones);
    await saveObjects(changedZones, uniqueHubIds, customerId, 'GET_ALL_ZONE_SETTINGS', (customerId, zone) => updateZone(customerId, zone.zoneId, zone));
}

const addNewZone = async (zoneProps, zones, processors, customerId) => {
    try {
        const processor = processors.find(processor => processor.processorId === zoneProps.processorId);
        const hubId = processor.hubId;
        const newZone = {
            ...zoneProps,
            customerId,
            hubId,
            orderIndex: zones.length ? Math.max(...zones.map(zone => zone.orderIndex)) + 1 : 1
        }
        await createZone(customerId, newZone);
        await postEvent(hubId, customerId, 'GET_ALL_ZONE_SETTINGS');
    } catch (err) {
        console.log('Failed to add new zone', err);
    }
}


export {
    saveUpdatedZones,
    addNewZone
}

const getUniqueHubIds = (changedZones, zones) => {
    const uniqueHubIds = [];
    changedZones.forEach(changedZone => {
        const zone = zones.find(zone => zone.zoneId === changedZone.zoneId);
        if (zone && !uniqueHubIds.includes(zone.hubId)) {
            uniqueHubIds.push(zone.hubId);
        }
    })

    return uniqueHubIds;
}

const prepareForSave = (changedZones, zones) => {
    changedZones.forEach(changedZone => {
        const zone = zones.find(zone => zone.zoneId === changedZone.zoneId);
        if (zone) {
            changedZone.hubId = zone.hubId;
        }
    })
}