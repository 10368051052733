import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MdPause, MdPlayArrow, MdSkipNext } from "react-icons/md";
import styled from "styled-components";
import theme from "../../../UI/theme";
import MusicPlayer from "../../musicPlayer/MusicPlayer";
import PanelButton from "../PanelButton";
import MusicPlayerSkeleton from "../skeletons/MusicPlayerSkeleton";
import { SkeletonItem } from "../skeletons/skeletonStyles";

let musicPlayerObject;

const Container = styled.div`
    padding: 15px 10px;
    height: fit-content;
    background: ${props => props.backgroundColor ? `linear-gradient(to right, ${props.backgroundColor}, ${theme.colors.darkSpace80})` : theme.colors.darkSpace80};
    border-radius: 5px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 50px 50px;
    align-items: center;
    gap: 10px;
    overflow: hidden;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        grid-template-rows: 40px 40px;
    }
`

const AlbumCover = styled.img`
    height: 100%;
    aspect-ratio: 1;
    border-radius: 5px;
    grid-row: 1 / span 2;
`

const InfoContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    overflow: hidden;
`

const InfoText = styled.label`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    font-size: ${theme.fontSize.larger};
    text-overflow: ellipsis;
    text-shadow:
            1px 0 ${theme.colors.darkSpace80}40,
            0 1px ${theme.colors.darkSpace80}40,
            -1px 0 ${theme.colors.darkSpace80}40,
            0 -1px ${theme.colors.darkSpace80}40;
`

const ArtistText = styled(InfoText)`
    font-weight: 100;
    font-size: ${theme.fontSize.small};
`

const ControlContainer = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 50px 50px;
    gap: 20px;
    align-items: end;
    justify-content: end;
    overflow: hidden;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        grid-template-columns: 1fr 40px 40px;
    }
`

const PlaylistDropdown = styled.select`
    outline: none;
    border-radius: 5px;
    font-family: karelia;
    height: 50px;
    color: white;
    background-color: ${theme.colors.darkSpace70};
    box-sizing: border-box;
    border: none;
    max-width: fill-available;
    min-width: 50px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    border: 1px solid transparent;
    box-sizing: border-box;

    &:hover {
        border: 1px solid ${theme.colors.greenEnergy};
    }
    
    > option {
        color: white;
    }

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        height: 40px;
        min-width: 40px;
    }
`

const loadingStates = {
    NONE: 'NONE',
    LOADING: 'LOADING',
    READY: 'READY',
    FAILED: 'FAILED',
}

const playStates = {
    PLAYING: 'PLAYING',
    PAUSED: 'PAUSED'
}

const MusicPlayerItem = props => {
    const [musicPlayerState, setMusicPlayerState] = useState(loadingStates.NONE);
    const [playlistState, setPlaylistState] = useState(loadingStates.NONE);
    const [playState, setPlayState] = useState();
    const [nextSongState, setNextSongState] = useState();
    const [music, setMusic] = useState();
    const [selectedPlaylist, setSelectedPlaylist] = useState();
    let musicPlayer = useRef(musicPlayerObject);

    const onMusicChange = (newMusic) => {
        setMusic(prev => {
            setMusicPlayerState(loadingStates.READY);
            if (newMusic?.availablePlaylists && newMusic?.playlist && (!music?.availablePlaylists || !music?.playlist)) {
                setPlaylistState(loadingStates.READY);
            }

            if (newMusic?.state === playStates.PLAYING) {
                setPlayState(playStates.PLAYING);
            } else if (newMusic?.state === playStates.PAUSED) {
                setPlayState(playStates.PAUSED);
            }

            if ((!nextSongState && newMusic?.songTitle) || newMusic?.songTitle !== music?.songTitle) {
                setNextSongState(loadingStates.READY);
            }

            setSelectedPlaylist(newMusic?.availablePlaylists?.find(playlist => playlist?.name === newMusic?.playlist)?.id);

            return newMusic;
        });
    }

    const changePlayState = () => {
        const oldPlayState = playState;
        let result;
        if (playState === playStates.PLAYING) {
            result = musicPlayer.current?.pause();
            setPlayState(playStates.PAUSED);
        } else if (playState === playStates.PAUSED) {
            result = musicPlayer.current?.play();
            setPlayState(playStates.PLAYING);
        }

        if (result === false) {
            setPlayState(oldPlayState);
        }
    }

    const onNextSong = () => {
        setNextSongState(loadingStates.LOADING);
        musicPlayer.current?.nextSong();
    }

    const changePlayList = (newPlaylistId) => {
        setSelectedPlaylist(newPlaylistId);
        musicPlayer?.current?.queuePlaylist(newPlaylistId);
    }

    useEffect(() => {
        setMusicPlayerState(loadingStates.LOADING);
        musicPlayer.current = new MusicPlayer(props.musicPlayer, onMusicChange);
        musicPlayer.current?.init();

        return () => {
            musicPlayer.current.cleanup();
        }
    }, []);

    return <>
        {musicPlayerState === loadingStates.READY ? <>
            <Container backgroundColor={music?.songColor} >
                <AlbumCover src={music?.imageUrl} />
                <InfoContainer>
                    <InfoText>{music?.songTitle}</InfoText>
                    <ArtistText>{music?.artists?.[0]}</ArtistText>
                </InfoContainer>
                <ControlContainer>
                    {playlistState === loadingStates.READY ?
                        <PlaylistDropdown
                            name={'playlists'}
                            id={'playlists'}
                            value={selectedPlaylist}
                            onChange={(e) => changePlayList(e.target.value)}
                        >
                            {music?.availablePlaylists?.map((playlist, index) => {
                                return <option key={index} value={playlist.id}>{playlist?.name}</option>
                            })}
                        </PlaylistDropdown>
                        : <SkeletonItem />}

                    <PanelButton onClick={() => changePlayState()}>
                        {playState === playStates.PLAYING ? <>
                            <MdPause /><label>Pause</label>
                        </> : <>
                            <MdPlayArrow /><label>Play</label>
                        </>}
                    </PanelButton>

                    <PanelButton disabled={nextSongState === loadingStates.LOADING} onClick={() => onNextSong()}><MdSkipNext /><label>Next</label></PanelButton>
                </ControlContainer>
            </Container>
        </> : <></>}

        {musicPlayerState === loadingStates.LOADING ? <>
            <MusicPlayerSkeleton />
        </> : <></>}

    </>
}

export default MusicPlayerItem;