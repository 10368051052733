import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../UI/theme';
import { getHeatmapData } from '../scripts/heatmap';
import { getUuid } from '../scripts/common';
import heatmapTypes from '../components/heatmap/container/heatmapTypes';
import HeatmapCanvas from '../components/heatmap/container/HeatmapCanvas';
import GetStarted from '../components/heatmap/container/GetStarted';
import SensorInfoBubble from '../components/heatmap/container/SensorInfoBubble';
import MiniDataModeContainer from '../components/heatmap/container/DataMode/MiniDataModeContainer';
import CanvasMessageBox from '../components/heatmap/container/StyledComponents/CanvasMessageBox';
import MiniMenuBar from '../components/heatmap/container/MiniHeatmap/MiniMenuBar';
import MapTitle from '../components/heatmap/container/MiniHeatmap/MapTitle';
import Card from '../components/UiComponents/Card';

const Container = styled.div`
    max-width: 60em;
    /* margin: 12px 12px; */
    @media only screen and (min-width: ${theme.screenSizes.large}px) {
        /* padding: 40px 50px; */
        
    }
`
const CanvasContainer = styled.div`
    position: relative;
`
const Overlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`

const HeatmapView = props => {
    const [state, setState] = useState(heatmapTypes.states.NONE);
    const [floorElements, setFloorElements] = useState();
    const [floors, setFloors] = useState();
    const [activeFloor, setActiveFloor] = useState();
    const [canvasAction, setCanvasAction] = useState();
    const [activeTool, setActiveTool] = useState();
    const [activeMode, setActiveMode] = useState(heatmapTypes.modes.DATA);
    const [showSensorInfo, setShowSensorInfo] = useState(false);
    const [sensorDisplayData, setSensorDisplayData] = useState({});
    const [canvasMessage, setCanvasMessage] = useState();
    const [dataLegend, setDataLegend] = useState();
    const [canvasIcon, setCanvasIcon] = useState();

    const activeFloorRef = useRef();
    const floorElementsRef = useRef();
    const canvasActionRef = useRef();
    activeFloorRef.current = activeFloor;
    floorElementsRef.current = floorElements;
    canvasActionRef.current = canvasAction;


    useEffect(() => {
        getHeatmapDataHandler();
        return canvasCleanup;
    }, [props.customerId]);


    function canvasCleanup() {
        actionHandler(heatmapTypes.actions.CANVAS_CLEANUP, null);
    }

    const getHeatmapDataHandler = async () => {
        setState(heatmapTypes.states.LOADING);
        try {
            var res = await getHeatmapData(props.customerId);
            setFloors(res.heatmapFloors);
            setFloorElements(res.heatmapFloorElements);
            if (res.heatmapFloors.length) {
                setActiveFloor(res.heatmapFloors[0].floorId);
            }

            setState(heatmapTypes.states.DATA);
        } catch (err) {
            setState(heatmapTypes.states.NO_DATA);
        }
    }


    const actionHandler = (type, data) => {

        if (canvasActionRef.current) {
            switch (type) {
                default:
                    canvasActionRef.current(type, data);
                    break;
            }
        }
    }

    const canvasCallback = (type, data) => {
        switch (type) {

            case heatmapTypes.actions.SHOW_SENSOR_INFO:
                setShowSensorInfoHandler(data);
                return;
            case heatmapTypes.actions.GET_OUTER_WALL:
                var element = floorElementsRef.current.find(element => element.type === heatmapTypes.elementTypes.OUTERWALL && element.floorId === activeFloorRef.current);
                data.callback(element);
                return;
            default:
                console.log('Invalid canvas callback')
                return;

        }
    }


    const setActiveFloorHandler = (floorId) => {
        actionHandler(heatmapTypes.actions.FLOOR_SELECT, {
            activeFloor: floorId,
            floorElements,
            activeMode
        });

        setActiveFloor(floorId);
    }


    const setShowSensorInfoHandler = data => {
        if (data) setSensorDisplayData(data);
        else setSensorDisplayData({});
        setShowSensorInfo(!showSensorInfo);
    }

    return <>
        {state === heatmapTypes.states.DATA ? <>
            <Card>
                <Container>
                    {floors.length ? <>
                        <MapTitle />

                        <MiniMenuBar
                            floors={floors}
                            activeFloor={activeFloor}
                            setActiveFloor={setActiveFloorHandler}
                        />
                        <CanvasContainer onTouchMove={(e) => e.preventDefault()}>
                            <Overlay />
                            <HeatmapCanvas
                                floorElements={floorElements}
                                activeFloor={activeFloor}
                                canvasAction={canvasAction}
                                activeMode={activeMode}
                                activeTool={activeTool}
                                canvasCallback={canvasCallback}
                                setCanvasAction={setCanvasAction}
                                heatmapType={heatmapTypes.heatmapTypes.MINI}
                                height={'40vh'}
                                backgroundColor={theme.colors.darkSpace90}
                            />

                            {activeMode === heatmapTypes.modes.DATA ? <>
                                <MiniDataModeContainer
                                    activeMode={activeMode}
                                    actionHandler={actionHandler}
                                    canvasIcon={canvasIcon}
                                    setCanvasIcon={setCanvasIcon}
                                    customerId={props.customerId}
                                    canvasMessage={canvasMessage}
                                    setCanvasMessage={setCanvasMessage}
                                    dataLegend={dataLegend}
                                    setDataLegend={setDataLegend}
                                />
                            </> : <></>}

                            {canvasMessage ? <>
                                <CanvasMessageBox>
                                    {canvasMessage}
                                </CanvasMessageBox>
                            </> : <></>}

                            {dataLegend ? <>
                                {dataLegend}
                            </> : <></>}

                            {canvasIcon ? <>
                                {canvasIcon}
                            </> : <></>}

                        </CanvasContainer>

                    </> : <label>Venue map coming soon!</label>}
                </Container>
            </Card>

        </> : <></>}
    </>

}

export default HeatmapView;

