import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/sourceSelector';

async function getSourceSelectors(customerId) {
    const res = await axios.get(`${hostname}${path}/sourceSelectors?customerId=${customerId}`);
    return res.data;
}

async function getSourceSelector(customerId, sourceSelectorId) {
    const res = await axios.get(`${hostname}${path}?customerId=${customerId}&sourceSelectorId=${sourceSelectorId}`);
    return res.data;
}

async function updateSourceSelector(customerId, sourceSelector) {
    const res = await axios.put(`${hostname}${path}?customerId=${customerId}`, sourceSelector);
    return res.data;
}

async function addSourceSelector(customerId, sourceSelector) {
    const res = await axios.post(`${hostname}${path}?customerId=${customerId}`, sourceSelector);
    return res.data;
}

async function deleteSourceSelector(customerId, sourceSelectorId) {
    const res = await axios.delete(`${hostname}${path}?customerId=${customerId}&sourceSelectorId=${sourceSelectorId}`);
    return res.data;
}


export {
    getSourceSelectors,
    getSourceSelector,
    updateSourceSelector,
    addSourceSelector,
    deleteSourceSelector
}