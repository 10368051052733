import React from 'react';
import ChoiceField from '../../fields/ChoiceField';
import { hierarchyTypeValues } from '../zoneTypes';

const hierarchyTypes = [{
    label: 'None',
    value: hierarchyTypeValues.NONE
}, {
    label: 'Master',
    value: hierarchyTypeValues.MASTER
}, {
    label: 'Slave',
    value: hierarchyTypeValues.SLAVE
}];

const HierarchyTypeChoiceField = props => {
    const selectedItem = hierarchyTypes.find(hierachyType => hierachyType.value === props.hierarchyType) || hierarchyTypes[0];
    return <ChoiceField
        selectedItem={selectedItem}
        items={hierarchyTypes}
        setValue={(value) => props.setValue(value)}
        cellControl={props.cellControl}
        excludeNone={true}
    />
}

export default HierarchyTypeChoiceField;