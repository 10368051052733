import React, { useState } from 'react';
import { postEvent } from '../../../../../scripts/event';
import { deleteProcessor } from '../../../../../scripts/processor';
import Button from '../../../../UiComponents/Button';
import Modal, { ModalContent } from '../../../../UiComponents/Modal';

const DeleteProcessorButton = props => {
    const [showModal, setShowModal] = useState(false);

    const deleteProcessorHandler = async () => {
        try {
            await deleteProcessor(props.processor.customerId, props.processor.processorId);
            await postEvent(props.processor.hubId, props.processor.customerId, 'DELETE_PROCESSOR', { processorId: props.processor.processorId });
            await props.refreshDevices();
            setShowModal(false);
        } catch (err) {
        } 
    }

    return <>
        <Button onClick={() => setShowModal(true)} small secondary>Delete processor</Button>

        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContent>
                <p>
                    Are you sure you want to delete the processor?
                    </p>
                <div>
                    <Button onClick={deleteProcessorHandler} primary>Delete</Button>
                    <Button onClick={() => setShowModal(false)} secondary>Cancel</Button>
                </div>
            </ModalContent>
        </Modal>

    </>
}

export default DeleteProcessorButton;