import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../../../../UI/theme';
import HeaderContainer from '../../../UiComponents/containers/HeaderContainer';

const Container = styled.div`
    display: flex;
    align-items: baseline;
`

const LinkContainer = styled.div`
    margin-left: 18px;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${theme.colors.textGray};
    font-size: 14px;
    &:hover {
        text-decoration: underline;
    }
`;


const MapTitle = props => {

    return <HeaderContainer>
        <h3>Venue map</h3>
        <LinkContainer>
            <StyledLink to='/map'>Go to full version</StyledLink>
        </LinkContainer>
    </HeaderContainer>
}

export default MapTitle;
