import React from 'react';
import ChoiceField from '../../fields/ChoiceField';
import { requiredStates } from '../../utils/installationTypes';

const ZoneChoiceField = ({ sensor, updateSensor, zones, cellControl }) => {
    const choices = zones.filter(zone => zone.hubId === sensor.hubId);
    const currentZone = zones.find(zone => zone.zoneId === sensor.zoneId);
    return <ChoiceField
        selectedItem={currentZone && { value: currentZone.zoneId, label: currentZone.zoneName }}
        items={choices.map(zone => ({ value: zone.zoneId, label: zone.zoneName }))}
        setValue={(value) => updateSensor({ zoneId: value })}
        cellControl={cellControl}
    />
}

export default ZoneChoiceField;


export const getZoneFieldRequiredState = (sensor, zones) => {
    const hubZones = zones.filter(zone => zone.hubId === sensor.hubId);
    return !hubZones.find(zone => zone.zoneId === sensor.zoneId) && hubZones.length > 0 ? requiredStates.RECOMMENDED : requiredStates.OPTIONAL;
} 