import React, { useState } from 'react';
import styled from 'styled-components';
import { saveNetworkConfig } from '../../../../../scripts/hub';
import theme from '../../../../../UI/theme';
import Button from '../../../../UiComponents/Button';
import Checkbox from '../../../../UiComponents/Checkbox';
import InputField from '../../../../UiComponents/InputField';
import Modal, { ModalContent } from '../../../../UiComponents/Modal';
import { TextMuted } from '../../../../UiComponents/text/StyledText';
import { ButtonRowContainer } from '../DeviceStyles';
import { isSameSubnet, validateIPaddress } from './networkUtils';
import ValidationError from './ValidationError';

const StyledTable = styled.table`
    th {
        text-align: left;
    }

    th, td {
        padding: 4px 10px 4px 0px;
    }
`

const AddressCell = styled.td`
    width: 130px;
`

const interfaceKeys = {
    ETH0: 'eth0',
    WLAN0: 'wlan0',
    ETH0_VIRTUAL: 'eth0:0'
}

const NetworkConfig = props => {
    const [editState, setEditState] = useState(false);
    const [networkConfig, setNetworkConfig] = useState({});
    const [validationErrors, setValidationErrors] = useState({})
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState();

    const setEditStateHandler = () => {
        var newEditState = !editState;
        if (newEditState) {
            setNetworkConfig({ ...props.hub.networkConfig });
            validateNetworkConfig();
        }
        setEditState(newEditState);
    }

    const updateNetworkConfig = (interfaceKey, key, value) => {
        var updatedNetworkConfig = { ...networkConfig };
        updatedNetworkConfig[interfaceKey][key] = value;
        validateNetworkConfig(updatedNetworkConfig);
        setNetworkConfig(updatedNetworkConfig);
    }

    const addVirtualInterface = () => {
        var updatedNetworkConfig = { ...networkConfig };
        updatedNetworkConfig[interfaceKeys.ETH0_VIRTUAL] = {
            address: '',
            isStatic: true
        };
        validateNetworkConfig(updatedNetworkConfig);
        setNetworkConfig(updatedNetworkConfig);
    }

    const removeVirtualInterface = () => {
        var updatedNetworkConfig = { ...networkConfig };
        delete updatedNetworkConfig[interfaceKeys.ETH0_VIRTUAL];
        validateNetworkConfig(updatedNetworkConfig);
        setNetworkConfig(updatedNetworkConfig);
    }

    const validateNetworkConfig = (config) => {
        var updatedNetworkConfig = config || networkConfig;
        var updatedValidationErrors = {}
        Object.keys(updatedNetworkConfig).forEach(interfaceKey => {
            var iface = updatedNetworkConfig[interfaceKey];
            if (!validateIPaddress(iface.address)) {
                updatedValidationErrors[interfaceKey] = { message: 'Not a valid ip address', type: 'ERROR' };
            } else {

                // check subnet change on eth0
                if (interfaceKey === interfaceKeys.ETH0) {
                    var current = props.hub.networkConfig[interfaceKeys.ETH0];
                    if (!isSameSubnet(current.address, iface.address)) {
                        updatedValidationErrors[interfaceKey] = { message: 'Changed subnet', type: 'WARNING' };
                    }
                } else if (interfaceKey === interfaceKeys.ETH0_VIRTUAL) {
                    // check virtual is on another subnet
                    var eth0Interface = updatedNetworkConfig[interfaceKeys.ETH0];
                    if (isSameSubnet(eth0Interface.address, iface.address)) {
                        updatedValidationErrors[interfaceKey] = { message: 'Same subnet as eth0', type: 'WARNING' };
                    }
                }

            }
        });

        setValidationErrors(updatedValidationErrors);
    }

    const saveClickHandler = () => {
        var hasErrors = Object.keys(validationErrors).filter(key => validationErrors[key].type === 'ERROR').length;
        var hasWarnings = Object.keys(validationErrors).filter(key => validationErrors[key].type === 'WARNING').length;
        var content
        if (hasErrors) {
            content = <>
                <p>You cannot save network config with errors</p>
                <Button primary onClick={() => setShowModal(false)}>OK</Button>
            </>
        } else if (hasWarnings) {
            content = <>
                <div>
                    <p>Are you sure you want to save network config with warnings? This can potentially make the hub unreachable</p>
                    <Button primary margin onClick={() => saveHandler()}>Yes</Button>
                    <Button secondary margin onClick={() => setShowModal(false)}>Cancel</Button>
                </div>
            </>
        }

        if (content) {
            setModalContent(content);
            setShowModal(true);
        } else {
            saveHandler();
        }
    }

    const saveHandler = async () => {
        await saveNetworkConfig(props.customerId, props.hub.hubId, { ...networkConfig });
        await props.refreshDevices();
        setEditState(false);
        setShowModal(false);
    }

    return <>
        <div>
            <span>Network config</span>
            <Button small tertiary lightBorder disabled={props.readOnly} margin={12} onClick={() => setEditStateHandler()}>
                {editState ? 'Cancel' : 'Edit'}
            </Button>
        </div>

        {props.hub.networkConfig && Object.keys(props.hub.networkConfig).length > 0 ? <>
            <StyledTable>
                <thead>
                    <tr>
                        <th>Interface</th>
                        <th>Address</th>
                        <th>Static</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {editState ? <>
                        {Object.keys(networkConfig).map(interfaceKey => {
                            var iface = networkConfig[interfaceKey];
                            var validationError = validationErrors[interfaceKey];
                            return <tr key={interfaceKey}>
                                <td>{interfaceKey}</td>
                                <AddressCell>
                                    {iface.isStatic ? <>
                                        <InputField
                                            small
                                            width={130}
                                            value={iface.address} onChange={(e) => updateNetworkConfig(interfaceKey, 'address', e.target.value)} />

                                        <br />
                                        {validationError ? <>
                                            <ValidationError validationError={validationError} />
                                        </> : <></>}

                                    </> : <>
                                        {iface.address}
                                    </>}
                                </AddressCell>
                                <td>
                                    <Checkbox
                                        checked={iface.isStatic}
                                        disabled={!canSetStatic(interfaceKey)}
                                        onChange={() => updateNetworkConfig(interfaceKey, 'isStatic', !iface.isStatic)}
                                        backgroundColor={theme.colors.darkSpace}
                                    />
                                </td>
                                <td>
                                    {interfaceKey === interfaceKeys.ETH0_VIRTUAL ? <>
                                        <Button secondary small onClick={removeVirtualInterface}>Remove</Button>
                                    </> : <></>}
                                </td>
                            </tr>
                        })}

                    </> : <>
                        {Object.keys(props.hub.networkConfig).map(interfaceKey => {
                            var iface = props.hub.networkConfig[interfaceKey];

                            return <tr key={interfaceKey}>
                                <td>{interfaceKey}</td>
                                <AddressCell>{getDisplayAddress(interfaceKey, iface)}</AddressCell>
                                <td>{iface.isStatic ? 'Yes' : 'No'}</td>
                            </tr>
                        })}
                    </>}
                </tbody>
            </StyledTable>
            {editState ? <>
                <div>
                    {!networkConfig[interfaceKeys.ETH0_VIRTUAL] ? <>
                        <Button
                            secondary
                            small
                            onClick={addVirtualInterface}
                            disabled={!networkConfig[interfaceKeys.ETH0]}
                        >
                            Add virtual interface
                        </Button>
                        {!networkConfig[interfaceKeys.ETH0] ? <>
                            <p>Virtual interfaces on wlan is not yet supported</p>
                        </> : <></>}
                    </> : <></>}

                    <ButtonRowContainer>
                        <Button small primary onClick={saveClickHandler}>Save</Button>
                        <Button small secondary onClick={() => setEditStateHandler(false)}>Cancel</Button>
                    </ButtonRowContainer>
                </div>
            </> : <></>}

        </> : <></>}

        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContent>
                {modalContent}
            </ModalContent>
        </Modal>

    </>
}

export default NetworkConfig;

const canSetStatic = (key) => {
    return key === interfaceKeys.ETH0;
}

const getDisplayAddress = (key, iface) => {
    var displayAddress = <span>{iface.address}</span>;
    if (key === interfaceKeys.ETH0 && iface.isStatic) {
        if (iface.staticAddress && iface.address != iface.staticAddress) {
            displayAddress = <span>
                Current: {iface.address}<br />
                Set: {iface.staticAddress} <br />
                <TextMuted >Reboot to apply</TextMuted>
            </span>
        }
    }
    return displayAddress;
}


