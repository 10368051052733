import { useState } from "react";
import styled from "styled-components";
import { postEvent } from "../../../../scripts/event";
import Button from "../../../UiComponents/Button";
import Modal, { FixedWidthModalContent } from "../../../UiComponents/Modal";
import Selector from "../../../UiComponents/Selector";
import { DisplayField } from "../../fields/Styles";
import { FieldButton } from "../../fields/styling/FieldStyles";
import { getHubDisplayName, hasZigbeeNetwork } from "../hubUtils";
import ChannelScan from "./ChannelScan";
import FormNetwork from "./FormNetwork";

const ZigbeeConfigButton = ({ hub }) => {
    const [showModal, setShowModal] = useState(false);

    return <>
        {hasZigbeeNetwork(hub) ? <>
            <FieldButton tertiary onClick={() => setShowModal(true)}>Zigbee Setup</FieldButton>
        </> : <>
            <Button small primary onClick={() => setShowModal(true)}>Zigbee Setup</Button>
        </>}

        <Modal show={showModal} close={() => setShowModal(false)} >
            <FixedWidthModalContent>
                <ZigbeeConfig hub={hub} close={() => setShowModal(false)} />
            </FixedWidthModalContent>
        </Modal>
    </>
}

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const ZigbeeSetupField = ({ hub }) => {
    return <>
        <Flex>
            {hasZigbeeNetwork(hub) ? <>
                <DisplayField>
                    Channel {hub.zigbeeChannel}
                </DisplayField>
            </> : <>
            </>}
            <ZigbeeConfigButton hub={hub} />
        </Flex>
    </>
}

export const SensorZigbeeSetupButton = ({ hubs }) => {
    const [selectedHub, setSelectedHub] = useState(hubs.length === 1 ? hubs[0] : null);
    const [showModal, setShowModal] = useState(false);

    return <>
        <Button primary small onClick={() => setShowModal(true)}>Zigbee Setup</Button>
        <Modal show={showModal} close={() => setShowModal(false)}>
            <FixedWidthModalContent>
            {hubs?.length > 1 ? <>
                <h3>Select hub for zigbee setup</h3>
                <Selector
                    items={hubs?.map(hub => ({ display: getHubDisplayName(hub), key: hub.hubId }))}
                    selectedItem={{ key: selectedHub?.hubId }}
                    onClick={(item) => setSelectedHub(hubs?.find(hub => hub.hubId === item.key))}
                />
            </> : <></>}
            {selectedHub ? <>
                <ZigbeeConfig hub={selectedHub} close={() => setShowModal(false)}/>
            </> :<></>}
            </FixedWidthModalContent>
        </Modal>
    </>
}

const configStates = {
    NONE: 0,
    CHANNEL_SCAN: 1,
    FORM_NETWORK: 2
}

const Container = styled.div`
    button {
        margin-right: 6px;
    }
`

export const ZigbeeConfig = ({ hub, close }) => {
    const [configState, setConfigState] = useState(configStates.NONE);
    const [bestChannel, setBestChannel] = useState();
    const [openingNetwork, setOpeningNetwork] = useState(false);

    const openNetwork = async () => {
        try {
            await postEvent(hub.hubId, hub.customerId, 'ADD_SENSORS')
            setOpeningNetwork(true);
            setTimeout(() => {
                setOpeningNetwork(false)
            }, 60000);
        } catch (err) {
            console.log('Failed to open network');
        }
    }

    return <Container>
        {configState === configStates.NONE ? <>
            <h3>Zigbee Setup</h3>
            {hasZigbeeNetwork(hub) ? <>
                <p>Zigbee network set up on channel {hub.zigbeeChannel}</p>
                <Button primary disabled={openingNetwork} onClick={() => openNetwork()}>Open network</Button>
                <Button secondary onClick={() => setConfigState(configStates.CHANNEL_SCAN)}>Channel Scan</Button>
                <Button secondary margin onClick={() => setConfigState(configStates.FORM_NETWORK)}>Create zigbee network</Button>
            </> : <>
                <p>Perform channel scan or create zigbee network on a known channel.</p>
                <Button primary onClick={() => setConfigState(configStates.CHANNEL_SCAN)}>Channel Scan</Button>
                <Button secondary margin onClick={() => setConfigState(configStates.FORM_NETWORK)}>Create zigbee network</Button>
            </>}
        </> : <></>}

        {configState === configStates.CHANNEL_SCAN ? <>
            <ChannelScan hub={hub} formNetwork={(bestChannel) => {
                setBestChannel(bestChannel);
                setConfigState(configStates.FORM_NETWORK)
            }} cancel={close} />
        </> : <></>}

        {configState === configStates.FORM_NETWORK ? <>
            <FormNetwork hub={hub} cancel={close} bestChannel={bestChannel} />
        </> : <></>}
    </Container>
}

export default ZigbeeConfigButton;