import React, { useEffect, useState } from "react";
import KeyMetrics from "./KeyMetrics";
import trendTypes from "./trendTypes";
import Card from '../UiComponents/Card';
import OverviewChartContainer from "./OverviewChartContainer";

const VenueStatistics = props => {
    return (
        <>
            <Card>
                <KeyMetrics
                    trendPeriod={props.trendPeriod}
                    date={props.date}
                    customerId={props.customerId}
                    hierarchyLevel={props.hierarchyLevel}
                    useOpeningHours={props.useOpeningHours} />

                <OverviewChartContainer
                    trendPeriod={props.trendPeriod}
                    hierarchyLevel={props.hierarchyLevel}
                    date={props.date}
                    customerId={props.customerId}
                    objectID={props.customerId.toString()}
                    type={trendTypes.periods.WEEK}
                    useOpeningHours={props.useOpeningHours}
                    hidden={false} />
            </Card>
        </>
    );
}



export default VenueStatistics;