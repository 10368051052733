import React from 'react';
import styled from 'styled-components';
import { getDeviceNumber } from '../../../../scripts/common';
import Checkbox from '../../../UiComponents/Checkbox';
import { TextMuted } from '../../../UiComponents/text/StyledText';
import { CheckBoxContainer } from './AutoCalibrationStyles';
import calibrationStates from './calibrationStates';

const Container = styled.div`
    margin: 12px 0;
`

const ZoneSelector = props => {

    const hubZones = props.zones.filter(zone => zone.hubId === props.calibration.hubId);

    const handleHubSelect = (hubId) => {
        var selectedHubID = hubId !== props.calibration.hubId ? hubId : null;
        props.updateCalibrationAttributes({ hubId: selectedHubID });
    }

    const handleZoneSelect = (zoneId) => {
        var zoneIndex = props.selectedZones?.indexOf(zoneId);
        var updatedZones = [...props.selectedZones];
        if (zoneIndex > -1) {
            updatedZones.splice(zoneIndex, 1);
        } else {
            updatedZones.push(zoneId);
        }
        props.setSelectedZones(updatedZones);
    }

    const zonesWithData = hubZones.filter(zone => zone.hasData);
    const zonesWithoutData = hubZones.filter(zone => !zone.hasData);

    return <div>
        <div>
            Hub
            {props.hubs.map(hub => {
            return <CheckBoxContainer key={hub.hubId}>
                <Checkbox disabled={props.calibration?.state !== calibrationStates.NONE} checked={hub.hubId === props.calibration.hubId} onChange={() => handleHubSelect(hub.hubId)} />
                {'Hub ' + getDeviceNumber(hub)}
            </CheckBoxContainer>
        })}
        </div>

        {props.calibration.hubId ? <>
            
            <Container>
                Zones
                {zonesWithData.map(zone => {
                return <CheckBoxContainer key={zone.zoneId}>
                    <Checkbox
                        disabled={checkDisabled(props.calibration.state)}
                        checked={props.selectedZones?.indexOf(zone.zoneId) > -1}
                        onChange={() => handleZoneSelect(zone.zoneId)}
                    />
                    <label>{zone.zoneName}</label>
                </CheckBoxContainer>
            })}

                {zonesWithoutData.length ? <>
                    <Container>
                    <TextMuted>Zones without sensors or backup zone</TextMuted>
                    {zonesWithoutData.map(zone => {
                        return <CheckBoxContainer key={zone.zoneId}>
                            <Checkbox
                                disabled={true}
                                checked={props.selectedZones?.indexOf(zone.zoneId) > -1}
                                onChange={() => handleZoneSelect(zone.zoneId)}
                            />
                            <TextMuted>{zone.zoneName}</TextMuted>
                        </CheckBoxContainer>
                    })}
                    
                    </Container>
                </> : <></>}
            </Container>
        </> : <></>}
    </div>
}

export default ZoneSelector;

function checkDisabled(calibrationState) {
    if (calibrationState !== calibrationStates.NONE && calibrationState !== calibrationStates.COMPLETED && calibrationState !== calibrationStates.FAILED) {
        return true;
    } else {
        return false;
    }
}