import React, { useState } from 'react';
import ChangePasswordCard from './ChangePasswordCard';
import VerificationCard from './VerificationCard';

const ForgotPasswordView = (props) => {

    var [codeVerified, setCodeVerified] = useState(false);
    var [code, setCode] = useState(false);
    var [verificationEmail, setVerificationEmail] = useState('');
    
    const changeVerification = (verified) =>{
        setCodeVerified(verified);
    }
    const passVerificationCreds = async (email, code) => {
        return new Promise((resolve, reject) => {
            setVerificationEmail(email)
            setCode(code);
            resolve(true);
        });
    }

    const cancelChange = () => {
        window.location.replace('/');
    }

    const whenDone= () => {
        window.location.replace('/');
    }


    return <>
        {codeVerified ?
        <ChangePasswordCard cancelChange={cancelChange} whenDone={whenDone} verificationEmail={verificationEmail} verificationCode={code}/> :
        <VerificationCard codeVerified={codeVerified} changeVerification={changeVerification} passVerificationCreds={passVerificationCreds}/>
        }
    </>
}

export default ForgotPasswordView;