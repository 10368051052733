import Card from "../../../../UiComponents/Card"
import ViewHeader from "../../../../UiComponents/ViewHeader"
import TopNavigation from "../../../../navigation/TopNavigation"
import { useParams } from "react-router"
import { getZones } from "../../../../../scripts/zone"
import { useEffect, useState } from "react"
import SetVolumeLevelStep from "./SetVolumeLevelStep"
import StepWizard from "../../../../UiComponents/StepWizard/StepWizard"
import CalibrationInfoStep from "./CalibrationInfoStep"
import ConfirmCalibrationStep from "./ConfirmCalibrationStep"
import { getLiveZoneLog } from "../../../../../scripts/log"

const volumeSteps = {
    empty: {
        header: "Empty",
        label: "Set the volume level you want when your first guests arrive.",
        nextButtonLabel: "Confirm sound level"
    },
    medium: {
        header: "Medium",
        label: "Set the volume level you want when approximately half of the zone's capacity is filled.",
        nextButtonLabel: "Confirm sound level"
    },
    full: {
        header: "Full",
        label: "Set the volume level you want when the zone is almost full.",
        nextButtonLabel: "Confirm sound level"
    }
}

const CalibrationView = () => {
    const [zone, setZone] = useState();
    let { id, zoneId } = useParams();
    const [currentMapValues, setCurrentMapValues] = useState();
    const [emptyVolumeLevel, setEmptyVolumeLevel] = useState(0);
    const [mediumVolumeLevel, setMediumVolumeLevel] = useState(0);
    const [fullVolumeLevel, setFullVolumeLevel] = useState(0);
    const [liveValues, setLiveValues] = useState();
    const [liveVolumeChanges, setLiveVolumeChanges] = useState(true);

    useEffect(() => {
        onLoadHandler();

        let interval = setInterval(() => {
            getLiveValues();
        }, 5000);

        return () => clearInterval(interval);
    }, [])

    const getLiveValues = async () => {
        try {
            const newLiveValues = await getLiveZoneLog(id, zoneId);
            setLiveValues(newLiveValues);
        } catch (error) {

        }
    }

    const onLoadHandler = async () => {
        var allZones = await getZones(id);
        var selectedZone = allZones.find(z => z.zoneId === zoneId);
        var processorMapValues = selectedZone?.processorMapValues?.split(',');
        setZone(selectedZone);
        if (processorMapValues) {
            setEmptyVolumeLevel(parseInt(processorMapValues?.[0]) ?? 0);
            setMediumVolumeLevel(parseInt(processorMapValues?.[1]) ?? 0);
            setFullVolumeLevel(parseInt(processorMapValues?.[2]) ?? 0);
            setCurrentMapValues(processorMapValues);
        }
    }

    return <>
        <ViewHeader>
            <TopNavigation to={`../../${id}`} />
            <h3>Zone Calibration</h3>
        </ViewHeader>

        <Card>
            {zone ? <StepWizard>
                <CalibrationInfoStep
                    stepLabel={'Info'}
                    nextButtonLabel={'Start calibration'}
                    liveVolumeChanges={liveVolumeChanges}
                    setLiveVolumeChanges={setLiveVolumeChanges} />
                <SetVolumeLevelStep
                    stepLabel={volumeSteps.empty.header}
                    nextButtonLabel={volumeSteps.empty.nextButtonLabel}
                    liveValues={liveValues}
                    zone={zone}
                    volume={emptyVolumeLevel}
                    setVolume={setEmptyVolumeLevel}
                    liveVolumeChanges={liveVolumeChanges}
                    setLiveVolumeChanges={setLiveVolumeChanges}
                    step={volumeSteps.empty} />
                <SetVolumeLevelStep
                    stepLabel={volumeSteps.medium.header}
                    nextButtonLabel={volumeSteps.empty.nextButtonLabel}
                    disabledMin={emptyVolumeLevel}
                    disabledValues={[emptyVolumeLevel]}
                    liveValues={liveValues}
                    zone={zone} volume={mediumVolumeLevel}
                    setVolume={setMediumVolumeLevel}
                    liveVolumeChanges={liveVolumeChanges}
                    setLiveVolumeChanges={setLiveVolumeChanges}
                    step={volumeSteps.medium} />
                <SetVolumeLevelStep
                    stepLabel={volumeSteps.full.header}
                    nextButtonLabel={volumeSteps.empty.nextButtonLabel}
                    disabledMin={mediumVolumeLevel}
                    disabledValues={[emptyVolumeLevel, mediumVolumeLevel]}
                    liveValues={liveValues}
                    zone={zone}
                    volume={fullVolumeLevel}
                    setVolume={setFullVolumeLevel}
                    liveVolumeChanges={liveVolumeChanges}
                    setLiveVolumeChanges={setLiveVolumeChanges}
                    step={volumeSteps.full} />
                <ConfirmCalibrationStep
                    stepLabel={'Summary'}
                    zone={zone}
                    liveVolumeChanges={liveVolumeChanges}
                    emptyVolumeLevel={emptyVolumeLevel}
                    mediumVolumeLevel={mediumVolumeLevel}
                    fullVolumeLevel={fullVolumeLevel}
                    currentMapValues={currentMapValues} />
            </StepWizard> : <></>}
        </Card>
    </>

}

export default CalibrationView;