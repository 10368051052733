import React, { useState, useEffect } from 'react';
import { getAllCustomers } from '../../../scripts/datasource';
import Modal from '../../UiComponents/Modal';
import { CustomerList, ListItem, ImpersonateContainer, ImpersonateButton, ModalContentContainer, ScrollPadding, Flex } from './ImpersonateStyles';
import { FaUserNinja } from 'react-icons/fa';
import Button from '../../UiComponents/Button';
import { isMobile } from '../../../scripts/common';
import { useHistory } from 'react-router';
import CloseButton from '../../UiComponents/buttons/CloseButton';
import InputField from '../../UiComponents/InputField';

const Impersonate = props => {
    const history = useHistory();
    const [customers, setCustomers] = useState([]);
    const [filteredCustomers, setFilteredCustomers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showButton, setShowButton] = useState(true);
    const [searchString, setSearchString] = useState('');

    const mobile = isMobile();
    const isImpersonating = props.customer && props.user.customerId !== props.customer.customerId;

    useEffect(() => {
        getAllCustomers().then(customers => {
            if (customers.length > 0) {
                customers = customers.filter(customer => customer.active);
                customers.sort((a, b) => a > b ? 1 : -1);
                setCustomers(customers);
                setFilteredCustomers(customers);
            }
        })
    }, []);

    const onSearch = (newSearchString) => {
        let upperCaseSearchString = newSearchString?.toUpperCase();
        setSearchString(newSearchString);
        setFilteredCustomers(customers?.filter(customer => {
            return customer?.customerId?.toString()?.toUpperCase()?.includes(upperCaseSearchString) ||
                customer?.companyName?.toUpperCase()?.includes(upperCaseSearchString) ||
                customer?.email?.toUpperCase()?.includes(upperCaseSearchString);
        }))
    }

    function setImpersonation(customerId) {
        props.setCustomer(customerId);
        setShowModal(false);
    };

    const customerList = filteredCustomers.map(customer => {
        return <ListItem
            key={customer.customerId}
            onClick={() => setImpersonation(customer.customerId)}
            current={customer.customerId === props.customer.customerId}
        >
            <span>
                {customer.customerId}.
            </span>

            {customer.companyName ? <>
                <span>
                    {customer.companyName}
                </span>
            </> : <></>}

            <span>
                {customer.email}
            </span>
        </ListItem>
    });

    return <>

        {showButton ? <>
            <ImpersonateContainer>
                {isImpersonating ? <>
                    <div>ID: {props.customer.customerId}</div>
                </> : <></>}
                <ImpersonateButton icon onClick={() => setShowModal(true)}>
                    <span><FaUserNinja /> </span>
                    {!mobile ? <>
                        <label>Impersonate</label>
                    </> : <></>}
                </ImpersonateButton>
            </ImpersonateContainer>
        </> : <></>}

        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContentContainer>
                <Flex>
                    <div>
                        <p>
                            <b>You</b><br />
                            Customer ID: {props.user.customerId}<br />
                            Role: {props.user.role}
                        </p>
                    </div>
                    <div>
                        <CloseButton secondary onClick={() => setShowModal(false)} />
                    </div>
                </Flex>
                {isImpersonating ? <>
                    <Button secondary onClick={() => setImpersonation(props.user.customerId)}>End impersonation</Button>
                </> : <></>}
                <Button secondary onClick={() => { setShowButton(false); setShowModal(false) }}>Hide impersonate-button</Button>

                <Button secondary onClick={() => { setImpersonation(props.user.customerId); history.push("/supportcenter"); setShowModal(false); }}>Support center</Button>

                <div>
                    <InputField value={searchString} onChange={(e) => onSearch(e.target.value)} />
                </div>

                <CustomerList>
                    {customerList}
                    <ScrollPadding />
                </CustomerList>
            </ModalContentContainer>
        </Modal>
    </>
}

export default Impersonate;