import styled from "styled-components";
import { toLocalISOString } from "../../../scripts/common";
import Checkbox from "../../UiComponents/Checkbox";
import DatePickerInput from "../../UiComponents/datepicker/StyledComponents/DatePickerInput";
import Button from "../../UiComponents/Button";
import IconButton from "../../UiComponents/buttons/IconButton";
import theme from "../../../UI/theme";
import { FaBackward, FaFastBackward, FaFastForward, FaForward } from "react-icons/fa";

const DateTimePickerContainer = styled.div`
    width: fill-available;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
    justify-items: center;
    background-color: ${theme.colors.darkSpace90};
    padding: 10px;
    margin-bottom: 20px;
`

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%
`

const MoveIntervalButton = styled(IconButton)`
    padding: 4px 10px;
    font-size: ${theme.fontSize.small};
    background-color: ${theme.colors.darkSpace80};
    border-radius: 4px;
    
`

const DatePicker = props => {
    return <DateTimePickerContainer>
        <label>From</label>
        <label>To</label>

        <DatePickerInput
            type={'datetime-local'}
            content={`'${props.fromDateTime.toLocaleString()}'`}
            max={(new Date()).toString()}
            value={toLocalISOString(props.fromDateTime)}
            onChange={(e) => props.setFromDateTime(new Date(e.target.value))} />

        {!props.detailedValues ?
            <DatePickerInput
                type={'datetime-local'}
                content={`'${props.toDateTime.toLocaleString()}'`}
                max={(new Date()).toISOString()}
                value={toLocalISOString(props.toDateTime)}
                onChange={(e) => props.setToDateTime(new Date(e.target.value))} /> :
            <label>{props.toDateTime.toLocaleString()}</label>}

        <RowContainer >
            <Checkbox checked={props.detailedValues} onChange={() => props.setDetailedValues(prev => !prev)} />
            <label>Detailed logs</label>
        </RowContainer>

        <RowContainer>
            <Button primary disabled={props.isLoading} onClick={() => props.clickHandler()}>Fetch data</Button>
        </RowContainer>

        <ButtonContainer>
            <MoveIntervalButton onClick={() => props.moveInterval(-24)}><FaFastBackward /></MoveIntervalButton>
            <MoveIntervalButton onClick={() => props.moveInterval(-6)}><FaBackward /></MoveIntervalButton>
        </ButtonContainer>
        <ButtonContainer>
            <MoveIntervalButton onClick={() => props.moveInterval(6)}><FaForward /></MoveIntervalButton>
            <MoveIntervalButton onClick={() => props.moveInterval(24)}><FaFastForward /></MoveIntervalButton>
        </ButtonContainer>

    </DateTimePickerContainer>
}

export default DatePicker;