import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Overlay from './Overlay';
import { FaChevronDown } from "react-icons/fa";
import FadeIn from './Animations/FadeIn';
import Button from './Button';
import theme from '../../UI/theme';

const DropdowItem = styled.li`
    padding: 12px 16px;
    cursor: pointer;
`

const DropdownList = styled.ul`
    list-style: none;
    position: ${props => props.ulPosition ? props.ulPosition : 'absolute'};
    background-color: white;
    color: black;
    padding: 16px 0;
    z-index: 100;
    margin: 6px 0;
    border-radius: 6px;
    border: 1px solid gray;
    min-width: 200px;
    animation: ${FadeIn} 0.1s;
    text-align: left;

    >li:hover {
        background-color: #efefef;
    }
`

const DropdownButton = styled(Button)`
    display: flex;
    align-items: center;
`

const ButtonLabel = styled.span`
    margin-right: 12px;
`

const Dropdown = props => {
    const [show, setShow] = useState(false);

    function clickHandler(item) {
        setShow(false);
        props.onClick(item);
    }

    function displayItems(items) {
        return items.map((item, ix) =>
            <DropdowItem key={ix}
                className="dropdown-item"
                onClick={() => clickHandler(item)}>
                {item.label}
            </DropdowItem>);
    }

    return <>
        <div>
            <DropdownButton primary={!props.type || props.type==='primary'} {...props} onClick={() => setShow(s => !s)} >
                <ButtonLabel>{props.selectedItem ? props.selectedItem.label : '-- None --'}</ButtonLabel>
                <FaChevronDown />
            </DropdownButton>
        </div>
        {show ?
            <>
                <DropdownList ulPosition={props.ulPosition} type={props.type}>
                    {displayItems(props.items)}
                </DropdownList>
                <Overlay onClick={() => setShow(false)} />
            </>
            : <></>}
    </>
}


export default Dropdown;