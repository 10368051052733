import styled from "styled-components";
import theme from "../../UI/theme";

const TimeInput = styled.input.attrs(() => ({type: 'time'}))`
    outline: none;
    font-family: 'Karelia';
    font-size: 16px;
    padding: 12px 16px;
    border: none;
    border-radius: 0;
    background-color: white;
    color: ${theme.colors.darkSpace90};
`;

export default TimeInput;