import Button from '../../UiComponents/Button';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../UI/theme';
import Dropdown from '../../UiComponents/Dropdown';
import { addMusicPlayer } from '../../../scripts/musicPlayer';

const StyledSetupPlayerCard = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.darkSpace80};
    border-radius: 5px;
    width: 350px;
    margin: 10px;
`

const StyledAddButton = styled.div`
    
`

const StyledDropdown = styled(Dropdown)`
    position: relative; 
`

const InfoContainer = styled.div`
    margin: 12px;
`

const OptionContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;    
`

const SetupSoundtrackYourBrandPlayer = (props) => {

    const [selectedSource, setSelectedSource] = useState();
    const [added, setAdded] = useState(false);

    useEffect(() => {
        props.sources.forEach(source => {source.label = source.displayName});
        
    },[]);

    const add = async () => {
        setAdded(true);
        const playerInfo = {
            customerId: props.customerId,
            sourceId: selectedSource?.id,
            sourceDisplayName: selectedSource?.displayName,
            remoteId: props.zoneInfo.id,
            name: props.zoneInfo.name,
            displayName: props.zoneInfo.name,
            token: props.sybCustomerInfo.token,
            refreshToken: props.sybCustomerInfo.refreshToken,
            type: props.sybCustomerInfo.type
        }
        try {
            await addMusicPlayer(props.customerId, playerInfo);
            setAdded(true);
        } catch (e) {
            setAdded(false);
        }   
    }

    return (
        <StyledSetupPlayerCard>
            <InfoContainer>
                <h4>Player: {props.zoneInfo.name}</h4>
                <div>Account: {props.zoneInfo.account}</div>
                <div>Address: {props.zoneInfo.address}</div>
            <OptionContainer>
                {added?
                    <div>This player was sucessfully added</div>
                :<>
                    {props.sources.length? <div>
                        <h5>Select Source</h5>
                        <StyledDropdown
                            ulPosition='relative'
                            items={props.sources}
                            selectedItem={selectedSource}
                            onClick={(item) => {
                                setSelectedSource(item);  
                            }}
                        />
                    </div>
                    :<></>}
                    <StyledAddButton>
                        <Button primary onClick={add}>Add Player</Button>
                    </StyledAddButton>
                </>}
            </OptionContainer>
                {!props.sources.length?
                    <h5>You haven't added any sources yet. <br/>
                    Please do this later and link a source to this music player</h5>
                :<></>}
            </InfoContainer>
        </StyledSetupPlayerCard>
    );

}

export default SetupSoundtrackYourBrandPlayer;