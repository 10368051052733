import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../components/UiComponents/Button';
import Card from '../components/UiComponents/Card';
import IconLogo from '../components/UiComponents/IconLogo';
import InputField from '../components/UiComponents/InputField';
import ToastMessage from '../components/UiComponents/ToastMessage';
import { googleAuthenticate, createLocalUser, localAuthenticate } from '../scripts/authentication';
import theme from '../UI/theme';

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Logo = styled(IconLogo)`
    width: 40px;
    height: 40px;
    margin-right: 20px;
`

const InfoParagraph = styled.p`
    margin: 0 0 14px 0;
`

const Input = styled(InputField)`
    margin: 4px 0 10px 0;
`

const SignInSignUpForm = styled.form`
    display: flex;
    flex-direction: column;
`

const StyledLink = styled(Link)`
    color: ${theme.colors.greenReadable};
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`

const SubmitFormContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const SubmitButton = styled(Button)`
    margin: 8px 8px 8px 0;
`

const SwitchLoginButton = styled(Button)`
    margin-top: 8px;
`

const AuthView = (props) => {
    const { signInMethod } = useParams();
    const [hasUser, setHasUser] = useState(signInMethod === "signUp" ? false : true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const checkUser = async () => {
        if ((email.indexOf('@') === -1) || (email.indexOf('.') === -1)) {
            setErrorMsg('Please insert a valid email!');
        } else if (password.length < 4) {
            setErrorMsg('Password must be at least four characters');
        } else if (password !== confirmPassword) {
            setErrorMsg('Passwords do not match!');
        } else {
            let authenticated = await createLocalUser({ email, password });
            if (authenticated) {
                props.setAuthenticated(authenticated);
            } else {
                setErrorMsg('User already exists');
            }
        }
    }

    const googleLogin = () => {
        googleAuthenticate();
    }

    // Might want to use this insted, we'll talk
    const loginLocal = async () => {
        let authenticated = await localAuthenticate({ email, password });
        if (authenticated) {
            //document.location.replace('/');
            props.setAuthenticated(authenticated);
        } else {
            setErrorMsg('Wrong email or password');
        }
    }

    const hasUserHandeler = () => {
        setErrorMsg('');
        setHasUser(!hasUser);
    }


    return (
        <Card>

            <HeaderContainer>
                <Logo />
                <h1>{hasUser ? "Welcome back!" : "Welcome to Waved!"}</h1>
            </HeaderContainer>

            <InfoParagraph>{hasUser ? "Sign in to Waved" : "Create new account"}</InfoParagraph>

            {errorMsg ? <ToastMessage>{errorMsg}</ToastMessage> : <></>}

            <SignInSignUpForm>
                <label>Email</label>
                <Input value={email} onChange={e => setEmail(e.target.value)} type="email" placeholder="user@domain.com" required/>
                <label>Password</label>
                <Input value={password} onChange={e => setPassword(e.target.value)} type="password" required/>

                {hasUser ? <>
                    <SubmitFormContainer>
                        <SubmitButton primary onClick={loginLocal} type={"button"}>Sign in</SubmitButton>
                        <StyledLink to={"/forgotPassword"}>I've forgotten my password</StyledLink>
                    </SubmitFormContainer>
                </> : <>
                    <label>Confirm password</label>
                    <Input value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} type="password" required/>
                    <SubmitFormContainer>
                        <SubmitButton primary onClick={checkUser} type={"button"}>Sign up</SubmitButton>
                    </SubmitFormContainer>
                </>}

            </SignInSignUpForm>


            <SwitchLoginButton secondary onClick={hasUserHandeler}>{hasUser ? "Create new account" : "Sign in to existing account"}</SwitchLoginButton>

        </Card>
    );
}

export default AuthView;