import React from 'react';
import { setAbsoluteVolumeDirect } from "../../../../scripts/volume";
import ZoneCalibrationTable from './ZoneCalibrationTable';
import { getSmoothCalibrationResult, prepareGraphData } from './calibrationUtils';

const CalibrationTable = props => {
    const graphData = prepareGraphData(props.calibration?.result, props.zones);
    const smoothResult = getSmoothCalibrationResult(props.calibration?.result);

    const listenClickHandler = async (zoneId, volume) => {
        await setAbsoluteVolumeDirect(props.calibration.customerId, props.calibration.hubId, zoneId, volume, true);
    }

    return <>
        {props.zones?.filter(zone => zone.shouldRegulate === +true)?.map((zone, index) => {

            return <ZoneCalibrationTable
                key={index}
                savedCalibrationPoints={zone?.calibrationPoints?.points}
                sweepResult={graphData?.datasets?.find(data => data.zoneId === zone.zoneId)?.data}
                smoothSweepResult={smoothResult?.find(data => data.zoneId === zone.zoneId)}
                listenClickHandler={listenClickHandler}
                zone={zone}
                calibrationId={props.calibration.calibrationId}
            />
        })}
    </>
}

export default CalibrationTable;