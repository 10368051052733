import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { fetchUser } from './scripts/authentication';
import { getCustomer } from './scripts/datasource';
import StyleProvider from './UI/StyleProvider'
import './App.css';
import UserContext from './hooks/UserContext';
import AuthView from './views/AuthView';
import SettingsView from './views/SettingsView';
import HeatmapView from './views/HeatmapView';
import MenuBar from './components/navigation/MenuBar';
import ViewContainer from './components/UiComponents/ViewContainer';
import SplashScreenView from './views/SplashScreenView';
import CenteredViewContainer from './components/UiComponents/CenteredViewContainer';
import ForgotPasswordView from './components/authentication/ForgotPasswordView';
import VenueContainer from './components/live/VenueContainer';
import Tracker from './components/analytics/Tracker';
import HeatmapContainer from './components/UiComponents/HeatmapContainer';
import SetupView from './views/SetupView';
import AdminView from './views/AdminView';
import { usePageVisibility } from 'react-page-visibility';
import { getAppVersion, getAppVersionTempMaster } from './scripts/client';
import SupportView from './views/SupportView';
import NorthstarView from './views/NorthstarView';
import { chartJsSetup, checkResizeObserver } from './globalSetup';
import Footer from './components/navigation/Footer';
import serverConnection from './scripts/server/ServerConnection';
import LocalSetupView from './components/setup/LocalSetupView';
import PanelView from './views/PanelView';
import InstallationView from './views/InstallationView';
import version from './version';
import FeatureNotification from './components/notifications/FeatureNotification';

chartJsSetup()
checkResizeObserver()

const env = process.env.REACT_APP_ENVIRONMENT;
const hostType = process.env.REACT_APP_HOST_TYPE;
const LOCAL_APP_VERSION = version;

function App() {
  const [user, setUser] = useState({});
  const [customer, setCustomer] = useState(null);
  const [venues, setVenues] = useState();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const isVisible = usePageVisibility()


  useEffect(() => {
    onLoadHandler();
  }, []);

  useEffect(() => {
    if (isVisible) {
      checkForUpdate();
      serverConnection.onVisible();
    }
  }, [isVisible]);

  useEffect(() => {
    serverConnection.setCustomerId(customer?.customerId);
  }, [customer?.customerId])

  const checkForUpdate = async () => {
    var appVersion = await getAppVersion();
    if (!isNaN(Number(appVersion))) {
      if (appVersion > LOCAL_APP_VERSION) {
        var refreshCount = Number(localStorage.getItem('WAVED_APP_REFRESH_COUNT'));
        if (!refreshCount) {
          localStorage.setItem('WAVED_APP_REFRESH_COUNT', 1)
          window.location.reload();
        }
      } else if (appVersion == LOCAL_APP_VERSION) {
        localStorage.setItem('WAVED_APP_REFRESH_COUNT', 0)
      }
    }
  }

  function onLoadHandler() {
    fetchUser().then(u => {
      setUser(u);
      setCustomerHandler(u.customerId);
    })
  }

  const getVenuesHandler = async (customerId) => {
    var venue = await getCustomer(customerId);
    if (venue) {
      setVenues([venue]);
    }
  }

  const setCustomerHandler = (customerId) => {
    getCustomer(customerId).then((cP) => {
      setCustomer(cP);
      getVenuesHandler(cP.customerId);
    });
  }

  const setAuthenticated = (authenticated) => {
    onLoadHandler();
  }


  if (showSplashScreen) {
    setTimeout(() => {
      setShowSplashScreen(false);
    }, 1500);
    return (
      <SplashScreenView></SplashScreenView>
    );
  } else {
    return (
      <StyleProvider className="App">
        <Router>
          {env === 'PRODUCTION' ? <>
            <Tracker />
          </> : <></>}
          <div>
            <Switch>
              {user && customer && customer.customerId ? <>
                <UserContext.Provider value={{
                  user,
                  customer,
                  setCustomer,
                  isAdmin: user.role === 'admin',
                  isImpersonator: user.role === 'impersonator',
                  isImpersonating: user.customerId !== customer.customerId
                }}>
                  <MenuBar user={user} customer={customer} setCustomer={setCustomerHandler} env={env} />

                  <Route path="/live">
                    <ViewContainer>
                      <VenueContainer
                        customerId={customer.customerId}
                        user={user}
                        venues={venues}
                        live={true}
                      />
                      <Footer />
                    </ViewContainer>
                  </Route>

                  <Route path="/insights">
                    <ViewContainer>
                      <VenueContainer
                        customerId={customer.customerId}
                        user={user}
                        venues={venues}
                        live={false}
                      />
                    </ViewContainer>
                  </Route>

                  <Route path="/settings">
                    <ViewContainer>
                      <SettingsView customerId={customer.customerId} venues={venues} />
                    </ViewContainer>
                  </Route>

                  <Route path="/installation">
                    <InstallationView customerId={customer.customerId} />
                  </Route>

                  <Route exact path="/">
                    
                    <FeatureNotification notificationKey={'PANEL_VIEW'} />
                    <PanelView customerId={customer.customerId} />
                  </Route>

                  <Route path="/forgotPassword">
                    <ViewContainer>
                      <ForgotPasswordView />
                    </ViewContainer>
                  </Route>

                  <Route path="/map">
                    <HeatmapContainer>
                      <HeatmapView customerId={customer.customerId} user={user} />
                    </HeatmapContainer>
                  </Route>

                  <Route path="/setup">
                    <ViewContainer>
                      <SetupView customerId={customer.customerId} />
                    </ViewContainer>
                  </Route>

                  <Route path="/admin">
                    <AdminView customerId={customer.customerId} />
                  </Route>

                  <Route path="/supportcenter">
                    <SupportView setCustomer={setCustomerHandler} />
                  </Route>

                  <Route path="/northstar">
                    <ViewContainer>
                      <NorthstarView customerId={customer.customerId} />
                    </ViewContainer>
                  </Route>

                </UserContext.Provider>
              </> : <>

                {hostType === 'HUB' ? <>
                  <LocalSetupView />
                </> : <>

                  <CenteredViewContainer>
                    <Switch>
                      <Route path="/forgotPassword">
                        <ForgotPasswordView />
                      </Route>

                      <Route path="/:signInMethod?">
                        <AuthView setAuthenticated={setAuthenticated} />
                      </Route>
                    </Switch>

                  </CenteredViewContainer>
                </>}

              </>}
            </Switch>
          </div>
        </Router>
      </StyleProvider>
    );
  }
}

export default App;
