import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SensorIcon, BarIcon, SettingsIcon } from '../UiComponents/Icons';
import theme from '../../UI/theme';
import { List, MenuItem, Container, StyledLink, ItemTitle, InnerContainer, TopPlaceholder, StatusBanner } from './MenuBarStyles';
import Impersonate from '../admin/impersonate/Impersonate';
import EnvironmentTag from './EnvironmentTag';
import ConnectionStatus from './ConnectionStatus';
import { FaList, FaSolarPanel } from 'react-icons/fa';
import NewBadge from './NewBadge';
import UserContext from '../../hooks/UserContext';
import { HubIcon } from '../installation/styling/Icons';

const hostType = process.env.REACT_APP_HOST_TYPE;

const menuItems = [{
    name: 'home',
    displayName: 'Home',
    path: '/',
    icon: FaSolarPanel
}, {
    name: 'insights',
    displayName: 'Insights',
    path: '/insights',
    icon: BarIcon
}, {
    name: 'live',
    displayName: 'Live',
    path: '/live',
    icon: SensorIcon,
}, {
    name: 'settings',
    displayName: 'Settings',
    path: '/settings',
    icon: SettingsIcon
}, {
    name: 'installation',
    displayName: 'Installation',
    hide: (user) => !user.isAdmin,
    path: '/installation',
    icon: HubIcon,
    badge: () => <NewBadge text={'Admin'} />
}];



const MenuBar = props => {
    const [markEnvironment, setMarkEnvironment] = useState(true);
    const location = useLocation();
    const user = useContext(UserContext);
    return <>
        <Container backgroundColor={getBackgroundColor(props.env, markEnvironment)}>
            <List className="LinkBanner">

                {menuItems.map(item => {
                    var Icon, Badge;
                    if (item.icon) {
                        Icon = item.icon;
                    }
                    if (item.badge) {
                        Badge = item.badge;
                    }
                    if (item.hide && item.hide(user)) {
                        return <></>
                    } 
                    return <MenuItem key={item.name}>
                        <StyledLink to={item.path}>
                            <InnerContainer>
                                {item.icon ? <>
                                    <Icon color={isActive(location.pathname, item.path) ? theme.colors.greenEnergy : theme.colors.textGray} />
                                </> : <></>}
                                <ItemTitle>
                                    {item.displayName}
                                </ItemTitle>
                                {item.badge ? <>
                                    <Badge />
                                </> : <></>}
                            </InnerContainer>
                        </StyledLink>
                    </MenuItem>
                })}
            </List>

            {props.env === 'DEVELOPMENT' && markEnvironment ? <>
                <EnvironmentTag display={'DEV'} markEnvironment={markEnvironment} setMarkEnvironment={setMarkEnvironment} />
            </> : <></>}

            {props.user && (props.user.role == 'admin' || props.user.role == 'impersonator') ? <>
                <Impersonate user={props.user} customer={props.customer} setCustomer={props.setCustomer} />
            </> : <></>}


            <StatusBanner>
                <ConnectionStatus />
            </StatusBanner>
        </Container>

        <TopPlaceholder />
    </>
}

export default MenuBar;

const isActive = (currentPath, itemPath) => {
    if (itemPath.length === 1) {
        return currentPath === itemPath;
    } else {
        return currentPath.slice(0, itemPath.length) === itemPath;
    }
}

const getBackgroundColor = (env, markEnvironment) => {
    if (hostType === 'HUB') {
        return theme.colors.blueOcean;
    } else if (env === 'DEVELOPMENT' && markEnvironment) {
        return theme.colors.blueMonday;
    } else {
        return null;
    }
}