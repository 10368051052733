import styled, { css } from "styled-components"
import theme from "../../../../UI/theme"
import { requiredStates } from "../../utils/installationTypes"

export const InfoTable = styled.table`
    border-collapse: collapse;
    width: 100%;
    margin: 12px 0;

    tr > td {
        height: 40px;
        border-bottom: 1px solid ${theme.colors.darkSpace80};
    }

    tr:last-child > td{
        border-bottom: none;
    }
`

export const InfoTableRow = styled.tr`
`

export const InfoTableNameCell = styled.td`
    padding-right: 30px;
`

export const InfoTableValueCell = styled.td`

    ${props => props.requiredState === requiredStates.RECOMMENDED && css`
        background-color: #69861b;
    `}
`
