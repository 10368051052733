import React, { useState } from 'react';
import Modal from '../../../UiComponents/Modal';
import { postEvent } from '../../../../scripts/event';
import Button from '../../../UiComponents/Button';
import styled from 'styled-components'
import RebootHubButton from './RebootHubButton';

const Container = styled.div`
    margin: 20px 0;
    div {
        margin-top: 12px;
    }
`

const DeviceOptions = props => {
    const [state, setState] = useState('NONE');
    const [showAddSensorModal, setShowAddSensorModal] = useState(false);

    function addSensors(hubId) {
        postEvent(hubId, props.customerId, 'ADD_SENSORS');
        setShowAddSensorModal(false);
    }

    function setStateHandler(state){
        setState(state);
    }

    return <>
        <Container>
        {state === 'NONE' ? <>
            <Button disabled={props.readOnly} secondary onClick={() => setStateHandler('SHOW')}>More options</Button>
        </> : <></>}

        {state === 'SHOW' ? <>
            <div><Button secondary onClick={() => setShowAddSensorModal(true)}>Scan for sensors</Button></div>
            <div><RebootHubButton hubId={props.hubId} customerId={props.customerId}/></div>
            <div><Button tertiary onClick={() => setState('NONE')}>Cancel</Button></div>
        </> : <></>}    
        </Container>

        <Modal show={showAddSensorModal} close={() => setShowAddSensorModal(false)}>
            <h3>Start sensor scan</h3>
            <p>This action will open the hub for new connections and add devices on the network</p>
            <div>
                <Button primary margin onClick={() => addSensors(props.hubId)}>Start scan</Button>
                <Button secondary margin onClick={() => setShowAddSensorModal(false)}>Cancel</Button>
            </div>
        </Modal>
    </>
}


export default DeviceOptions;