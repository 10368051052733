import React, { useState } from 'react';
import styled from 'styled-components';
import { postEvent } from '../../../../scripts/event';
import Button from '../../../UiComponents/Button';
import Modal, { FixedWidthModalContent } from '../../../UiComponents/Modal';
import HubChoiceField from '../../fields/HubChoiceField';
import { ActionsContainer } from '../../styling/DeviceStyles';
import { cellStates } from '../../table/tableTypes';

const DropdownContainer = styled.div`
    width: 200px;
    margin-bottom: 20px;
`


const OpenNetworkButton = ({ hubs }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedHub, setSelectedHub] = useState(hubs?.[0]);

    function addSensors() {
        if (selectedHub) {
            postEvent(selectedHub.hubId, selectedHub.customerId, 'ADD_SENSORS');
            setShowModal(false);
        }
    }
    return <>
        <Button small secondary onClick={() => setShowModal(true)}>
            Open network
        </Button>
        <Modal show={showModal} close={() => setShowModal(false)}>
            <FixedWidthModalContent>
                <h3>Open network</h3>
                <p>This action will open the hub for new connections and add devices on the network</p>
                {hubs.length !== 1 ? <>
                <p>Select the hub that you want to open the network on.</p>
                <DropdownContainer>
                    <HubChoiceField
                        hubId={selectedHub?.hubId}
                        hubs={hubs}
                        updateObject={(hubId) => setSelectedHub(hubs.find(hub => hub.hubId === hubId))}
                        cellControl={{ cellState: cellStates.FORM }}
                    />
                </DropdownContainer>
                </> : <></>}
                <ActionsContainer>
                    <Button primary disabled={!selectedHub} onClick={() => addSensors()}>Open network</Button>
                    <Button secondary onClick={() => setShowModal(false)}>Cancel</Button>
                </ActionsContainer>
            </FixedWidthModalContent>
        </Modal>
    </>
}


export default OpenNetworkButton;