import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../../../UI/theme';
import { Chart, registerables } from 'chart.js';
import { replaceZeroValues } from '../../../../scripts/common';
import { chartAreaBackgroundColorPlugin } from '../../../statistics/charts/chartUtils';
Chart.register(...registerables);

const Container = styled.div`
    height: 200px;
    canvas {
        max-width: 600px;
        min-width: 200px;

        @media only screen and (min-width: ${theme.screenSizes.medium}px) {
            min-width: 300px;
        }
    }
`

const chartData = {
    labels: [],
    datasets: [
        {
            type: 'line',
            label: 'Ambience (%)',
            fill: false,
            tension: 0.3,
            backgroundColor: theme.colors.pinky,
            borderColor: theme.colors.pinky,
            pointRadius: 1,
            data: []
        },
        {
            type: 'line',
            label: 'Music (%)',
            fill: false,
            tension: 0.3,
            backgroundColor: theme.colors.yellowFever,
            borderColor: theme.colors.yellowFever,
            pointRadius: 1,
            data: []
        },
        {
            type: 'line',
            label: 'Measured (dB)',
            fill: false,
            tension: 0.3,
            backgroundColor: theme.colors.crystalBlue,
            borderColor: theme.colors.crystalBlue,
            pointRadius: 1,
            hidden: true,
            data: []
        }
    ]
};

const options = {
    maintainAspectRatio: false,
    animation: false,
    scales: {
        x: {
            display: true,
            ticks: {
                maxRotation: 0,
                color: theme.colors.textGray
            },
            grid: {
                display: true,
                drawTicks: true,
                color: `${theme.colors.textGray}20`,
            }
        },
        y: {
            display: true,
            min: 0,
            max: 100,
            ticks: {
                color: theme.colors.textGray
            },
            grid: {
                display: true,
                drawBorder: true,
                borderColor: `${theme.colors.textGray}20`,
                drawTicks: true,
                color: `${theme.colors.textGray}20`,
            }
        }
    },
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                color: theme.colors.textGray,
                textAlign: 'left',
                boxWidth: 7,
                boxHeight: 7,
                usePointStyle: true,
            }
        }
    },
    pointHitRadius: 7,
}

const defaultChartConfig = {
    type: 'line',
    data: chartData,
    options
}

const Graph = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);
    const chartConfig = useRef(JSON.parse(JSON.stringify(defaultChartConfig)));
    const isRegulatingID = `${props.chartID}-1`;
    const isRegulatingAudioSourceID = `${props.chartID}-2`;

    useEffect(() => {
        if (props.data && props.data.datasets && props.data.labels) {
            chartConfig.current.data.datasets[0].data = replaceZeroValues(props.data.datasets[0].data);
            chartConfig.current.data.datasets[1].data = replaceZeroValues(props.data.datasets[1].data);
            chartConfig.current.data.datasets[2].data = replaceZeroValues(props.data.datasets[2].data);
            chartConfig.current.data.labels = props.data.labels;
            if (props.data.labels.length > 0) {
                var ticksLim = getNTicks(props.data.labels.length);
                chartConfig.current.options.scales.x.ticks.maxTicksLimit = ticksLim;
            }

            chartConfig.current.options.plugins[isRegulatingID] = {
                chartID: props.chartID,
                stopColor: `${theme.colors.pinky}08`,
                stops: props.data.datasets[3].data
            }

            chartConfig.current.options.plugins[isRegulatingAudioSourceID] = {
                chartID: props.chartID,
                stopColor: `${theme.colors.crystalBlue}08`,
                stops: props.data.datasets[4].data?.map((value, index) => +(value === 1 || props.data.datasets[3].data[index] === 0))
            }

            if (chartInstance) {
                chartInstance.update();
            }
        }
    }, [props.data])


    var width;
    if (window.innerWidth <= theme.screenSizes.small) {
        width = Math.round(window.innerWidth * 0.6);
    }

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            if (chartInstance) {
                chartInstance.destroy();
            }
            const newChartInstance = new Chart(
                chartContainer.current,
                {
                    ...chartConfig.current,
                    plugins: [
                        { ...chartAreaBackgroundColorPlugin, id: isRegulatingID },
                        { ...chartAreaBackgroundColorPlugin, id: isRegulatingAudioSourceID }
                    ]
                });
            setChartInstance(newChartInstance);
        }
    }, [chartContainer]);

    return (
        <Container>
            <canvas id={props.chartID} ref={chartContainer} width={width} />
        </Container>
    );
}

export default Graph;


function getNTicks(dataLen) {
    var n_tics = 4;
    var ticsOptions = [3, 4, 5];
    ticsOptions.forEach(option => {
        if ((dataLen - 1) % (option - 1) === 0) {
            n_tics = option;
        }
    });

    return n_tics;
}