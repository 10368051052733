import React, { useState } from 'react';
import styled from 'styled-components';
import RegulationToggle from '../../RegulationToggle';
import theme from '../../../../UI/theme';
import { TextMuted } from '../../../UiComponents/text/StyledText';
import Modal, { ModalContent } from '../../../UiComponents/Modal';
import hierarchyTypes from '../../../settings/venue/device/hierarchyTypes';
import ButtonContainer from '../../../UiComponents/containers/ButtonContainer';
import Button from '../../../UiComponents/Button';
import { postUserInteraction } from '../../../../scripts/userInteraction';
import { userInteractionTypes } from '../../../admin/customerDetails/userInteractionTypes';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Label = styled.label`
    color: ${theme.colors.textGray};
    margin-right: 10px;
`

const ZoneRegulationToggle = props => {

    const toggle = async (newState) => {
        props.updateZoneHandler(props.zone.zoneId, { isRegulating: newState });
        postUserInteraction(props.zone.customerId, {
            zoneId: props.zone.zoneId,
            setting: userInteractionTypes.isRegulating.key,
            fromValue: +!newState,
            toValue: +newState
        });
    }

    return <>
        <Container>
            <Label>Zone regulation</Label>
            {props.preventedByAudioSource ? <>
                <TextMuted>Disabled on source: {props.audioSourceName} </TextMuted>
            </> : <>
                <RegulationToggle checked={props.checked} onToggle={toggle} />
            </>}
        </Container>
    </>
}

export default ZoneRegulationToggle;