import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import { getCustomerUseSummaries } from "../../../scripts/client";
import { Table, TableContainer } from "../support/SupportStyles";
import CustomerSuccesTableHeader from "./CustomerSuccessTableHeader";
import CustomerSuccessTableRow from "./CustomerSuccessTableRow";

const CustomerSuccessTable = props => {
    const history = useHistory();
    const [customerData, setCustomerData] = useState();
    const [sortProperty, setSortProperty] = useState('customerId');
    const [sortAsc, setSortAsc] = useState(false);

    useEffect(() => {
        getData().then(data => {
            setCustomerData(data);
        });
    }, []);

    const getData = async () => {
        var data = await getCustomerUseSummaries();
        return data;
    }

    function setImpersonation(customerId) {
        props.setCustomer(customerId);
        history.push(`/settings/venue/${customerId}`);
    };

    function setSort(newProperty) {
        if (newProperty === sortProperty) {
            setSortAsc(prev => { return !prev });
        } else {
            setSortProperty(newProperty);
            setSortAsc(false);
        }
    }

    function goToDetails(customerId) {
        history.push(`/supportcenter/customerdetails/${customerId}`);
        window.scrollTo(0, 0);
    }

    return <TableContainer>
        <Table>
            <thead>
                <CustomerSuccesTableHeader sortProperty={sortProperty} setSort={(newProperty) => setSort(newProperty)} sortAsc={sortAsc}/>
            </thead>

            {customerData ? <tbody>
                {customerData?.customerUseSummaries
                    ?.filter(summary =>
                        !props.searchValue ||
                        summary.customerId === parseInt(props.searchValue)
                        || summary.companyName?.toLowerCase().includes(props.searchValue.toLowerCase()))
                    ?.sort((a, b) => { return sortRecords(a, b, sortProperty, sortAsc) })
                    ?.map((customerUseSummary, index) => {
                        return <CustomerSuccessTableRow {...customerUseSummary} key={index} setImpersonation={setImpersonation} goToDetails={goToDetails} />
                    })}
            </tbody>
                : <></>}

        </Table>
    </TableContainer>
}

export default CustomerSuccessTable;

const sortRecords = (a, b, sortParameter, sortAsc) => {
    var result = 0;

    if (sortParameter === 'customerId') {
        result = a.customerId - b.customerId;
    } else if (sortParameter === 'companyName') {
        result = a.companyName > b.companyName ? 1 : a.companyName < b.companyName ? -1 : 0;
    } else if (sortParameter && !isNaN(a?.customerUseSummary[sortParameter]?.value) && !isNaN(b?.customerUseSummary[sortParameter]?.value)) {
        result = a.customerUseSummary[sortParameter].value - b.customerUseSummary[sortParameter].value;
    }

    if (sortAsc) {
        return result;
    } else {
        return -result;
    }
}
