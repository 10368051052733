import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../../UI/theme';
import InputField from '../../UiComponents/InputField';
import Button from '../../UiComponents/Button';
import Card from '../../UiComponents/Card';
import HeaderContainer from '../../UiComponents/containers/HeaderContainer';
import OpeningHoursMenuItem from './openingHours/OpeningHoursMenuItem';
import GooglePlaceIDForm from './openingHours/GooglePlaceIDForm';
import { updateCustomer } from '../../../scripts/datasource';

const Label = styled.label`
    font-size: 1rem;
`

const Value = styled.span`
    color: ${theme.colors.textGray};
`

const ItemContainer = styled.div`
    margin-bottom: 16px;
`

const VenueInfo = props => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedInfo, setEditedInfo] = useState();
    const [edited, setEdited] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        if (error) {
            setError(null);
        }
    }, [isEditing])

    const changeHandler = (key, newValue) => {
        var updatedVenue = { ...editedInfo };
        updatedVenue[key] = newValue;
        setEditedInfo(updatedVenue);
        if (!edited) {
            setEdited(true);
        }
    }

    const saveHandler = async () => {

        if (editedInfo) {
            try {
                await updateCustomer(props.venue.customerId, editedInfo);
                props.setVenue(editedInfo);
                setIsEditing(false);
                setEditedInfo(false);
                setEditedInfo(null);
            } catch (error) {
                setError('Something went wrong, could not update info');
            }
        }
    }

    const startEditing = () => {
        setEditedInfo({ ...props.venue });
        setIsEditing(true);
    }

    const getInfoItem = (key, label) => {
        return <ItemContainer>
            <Label>{label}</Label>
            <div>
                {isEditing && editedInfo ? <>
                    <InputField value={editedInfo[key]} onChange={(e) => changeHandler(key, e.target.value)} />
                </> : <>
                    <Value>{props.venue[key]}</Value>
                </>}
            </div>
        </ItemContainer>
    }

    return <Card>
        <HeaderContainer>
            <h3>Venue information</h3>
            <div>
                {isEditing ? <>
                    {edited && <Button small primary onClick={saveHandler}>
                        Save
                    </Button>}
                    <Button small secondary onClick={() => setIsEditing(false)}>Cancel</Button>
                </> : <>
                    <Button disabled={props.readOnly || props.venue.googlePlaceId !== null} small tertiary onClick={() => startEditing()}>
                        Edit
                    </Button>
                </>}
            </div>
        </HeaderContainer>

        {error && <p>{error}</p>}

        {getInfoItem('companyName', 'Name')}
        {getInfoItem('address', 'Address')}

        <OpeningHoursMenuItem
            customerId={props.venue.customerId}
            placeID={props.venue.googlePlaceId}
            openingHours={props.openingHours}
            refresh={props.refresh} />

        <GooglePlaceIDForm
            customerId={props.venue.customerId}
            placeID={props.venue.googlePlaceId}
            refresh={props.refresh} />

    </Card>
}

export default VenueInfo;