const zoneColors = ['#00BFFF', '#35C191', '#F06F9E', '#F9F871', '#8177CB', '#DFA01E'];

function getZoneColor(index) {
    var currentZoneIndex = index % zoneColors.length;
    var zoneColor = zoneColors[currentZoneIndex || 0];
    return zoneColor;
}

export {
    getZoneColor
}