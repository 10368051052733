import React, { useState, useEffect } from 'react';
import InitialSetupCard from '../components/setup/InitialSetupCard';
import SetupWaitCard from '../components/setup/SetupWaitCard';
import SetupCompleteCard from '../components/setup/SetupCompleteCard';
import { getSensors } from '../scripts/sensor';
import { getUuid } from '../scripts/common';
import ViewContainer from '../components/UiComponents/ViewContainer';
import Card from '../components/UiComponents/Card';
import ViewHeader from '../components/UiComponents/ViewHeader';
import TopNavigation from '../components/navigation/TopNavigation';
import Button from '../components/UiComponents/Button';


const SetupView = (props) => {

    const [setupProgress, setSetupProgress] = useState('NONE');
    const [systemSensors, setSystemSensors] = useState([]);
    const [finalResponse, setFinalResponse] = useState({});
    const [networkType, setNetworkType] = useState('ZIGBEE');
    const [zigbeeChannel, setZigbeeChannel] = useState(20);
    const [connectionType, setConnectionType] = useState('WIFI');


    const setupHub = async () => {
        console.log('Scanning for bluetooth devices:');
        const service_uuid = 'c5410cd2-488f-4885-9037-fff8c387dffc';
        const read_uuid = '798e2a54-14ed-42cb-96fe-eb5dc98254df';
        const write_uuid = '4a9b856c-63cf-40b6-aefb-a3578a448f12';
        const wifiInfo = await collectWifiInfo();


        console.log(wifiInfo);

        try {
            const hub = await navigator.bluetooth.requestDevice({
                filters: [{
                    services: [service_uuid]
                }]
            });

            // User has hit pair at this point
            setSetupProgress('IN_PROGRESS');

            // add disconnection listener
            hub.addEventListener('gattserverdisconnected', onDisconnected)

            const server = await hub.gatt.connect();

            const service = await server.getPrimaryService(service_uuid);

            // Write Wifi Info
            const writeCharacteristic = await service.getCharacteristic(write_uuid);
            writeCharacteristic.writeValue(Buffer.from(JSON.stringify(wifiInfo)));

            // Read Status
            const readCharacteristic = await service.getCharacteristic(read_uuid);
            var interval = setInterval(async () => {
                try {
                    var responseEncoded = await readCharacteristic.readValue();
                    var hubResponse = decodeStatus(responseEncoded);
                    console.log(hubResponse);
                    if (hubResponse.status === "SUCCESS") {
                        //Disconnect and move to complete card
                        setFinalResponse(hubResponse);
                        hub.gatt.disconnect();
                        setSetupProgress('COMPLETE');
                        updateSensorsHandler();
                        clearInterval(interval);
                    } else if (hubResponse.status === 'FAILED') {
                        // TODO: Handle Error!
                        hub.gatt.disconnect();
                        setSetupProgress('FAILED');
                        clearInterval(interval);
                    }
                } catch (err) {
                    console.log('Bluetooth error');
                }

            }, 1000);

        } catch (err) {
            //setSetupProgress('FAILED');
            setSetupProgress('NONE');
            console.log(err);
        }

    }

    const updateSensorsHandler = () => {
        getSensors(props.customerId).then((sensors) => {
            if (sensors.length) {
                setSystemSensors(sensors);
            };
        });
    }

    useEffect(() => {
        console.log("Getting Sensors");
        updateSensorsHandler();
    }, []);



    const decodeStatus = (result) => {
        var r = '';
        var c = '';
        for (let i = 0; i < result.buffer.byteLength; i++) {
            c = String.fromCharCode(result.getUint8(i));
            r += c;
        }
        try {
            const res = JSON.parse(r);
            return res;
        } catch {
            return r;
        }

    }

    const onDisconnected = (event) => {
        let dev = event.target;
        console.log('Hub disconnected');
    }

    const collectWifiInfo = async () => {

        var netName;
        var netPass;
        try {
            netName = document.getElementById("netName").value;
            netPass = document.getElementById("netPass").value;
        } catch (e) {
            console.log('No wifiinfo');
        }

        let zoneId = getUuid();        

        const wifiInfo = {
            wifiInfo: {
                ssid: netName,
                password: netPass
            },
            customerInfo: {
                id: props.customerId
            },
            zone: {
                id: zoneId
            },
            networkType: networkType,
            zigbeeChannel: zigbeeChannel,
            connectionType: connectionType
        }
        return wifiInfo;
    }

    // Decide what to return
    function getContent() {
        if (setupProgress == 'NONE') {
            return <InitialSetupCard
                setupHub={setupHub}
                networkType={networkType}
                setNetworkType={setNetworkType}
                zigbeeChannel={zigbeeChannel}
                setZigbeeChannel={setZigbeeChannel}
                connectionType={connectionType}
                setConnectionType={setConnectionType}
            />
        } else if (setupProgress == 'IN_PROGRESS') {
            return <SetupWaitCard>
                <h3>We are setting up your sensors!</h3>
                <p>This may take a few minutes...</p>
            </SetupWaitCard>
        } else if (setupProgress == 'COMPLETE') {
            console.log('FINAL RESPONSE:', finalResponse);
            return <SetupCompleteCard customerId={props.customerId} networkType={networkType} numSetupSensors={finalResponse.result.success} systemSensors={systemSensors} setSetupProgress={setSetupProgress} />
        } else if (setupProgress == 'FAILED') {
            return <div>
                <div>
                    <h3>Setup failed</h3>
                    <p>Please verify that your wifi information is correct.</p>
                    <Button primary onClick={() => setSetupProgress('NONE')}>Try again</Button>
                </div>
            </div>

        } else {
            return <div></div> //{/*<div>Huston we have a problem!<br></br>Contact support@waved.no</div>*/};
        }
    }

    return <ViewContainer>
        <div>
            <ViewHeader fitToSmallScreen={true}>
                <TopNavigation to={'/'} />
            </ViewHeader>
            <Card>
                {getContent()}
            </Card>
        </div>
    </ViewContainer>

}

export default SetupView;