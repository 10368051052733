import styled, {css} from 'styled-components';
import theme from '../../UI/theme';


const Button = styled.button`
    padding: 12px 16px;
    border-radius: 4px;
    font-size: ${props => props.fontSize || theme.fontSize.small};
    font-weight: 500;
    background-color: transparent;

    &:hover {
        cursor: ${props => props.disabled ? 'unset' : 'pointer'};
    }

    ${props => props.small && css`
        padding: 6px 8px;
    `}

    ${props => props.margin && css`
        margin: 0 ${parseInt(props.margin) || 4}px;
    `}

    ${props => props.disabled && css`
        opacity: 0.5;
    `}

    ${props => props.primary && css`
        background-color: ${theme.colors.greenEnergy};
        border: none;
        color: black;
        font-weight: bold;

        &:hover {
            background-color: ${theme.colors.greenReadable};
        }
    `}

    ${props => props.secondary && css`
        background-color: ${theme.colors.grayHover};
        color: ${theme.colors.whiteSpace};
        border: none;
        font-weight: bold;

        &:hover {
            background-color: ${theme.colors.grayBorder};
        }
    `}

    ${props => props.tertiary && css`
        background: none;
        color: ${theme.colors.greenEnergy};
        border: 1px solid; 
        border-color: ${props.lightBorder ? theme.colors.darkSpace70 : theme.colors.grayBorder};
        outline: none;


        &:hover {
            background-color: ${theme.colors.grayBorder};
        } 
    `}

    ${props => props.tertiary && props.mute && css`
        color: ${theme.colors.textGray};
    `}

    ${props => props.icon && css`
        font-weight: bold;
        border: none;
        span {
            margin-right: 8px;
        }
        span:last-child {
            margin-right: 0;
        }
    `}
`

export default Button;