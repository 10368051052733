import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/heatmap';

async function getHeatmapData(customerId) {
    try {
        const res = await axios.get(`${hostname}${path}?customerId=${customerId}`, {withCredentials: true});
        var data = res.data;
        if (data.error) {console.log(data.error); return false;}
        return data;
    } catch (e) {
        console.log('could not get heatmap data');
        return false;
    }
}

async function updateHeatmapData(customerId, floors, floorElements, deletedFloorElements) {
    try {
        const res = await axios.post(`${hostname}${path}?customerId=${customerId}`, 
            {
                heatmapFloors: floors,
                heatmapFloorElements: floorElements,
                deletedFloorElements: deletedFloorElements
            }, {
                withCredentials: true
            }
        );
        if (res.data.error) {
            console.log(res.data.error);
            return false;
        } else {
            return res.data.result;
        }
        
    } catch(e) {
        console.log('Could not update heatmap data');
        return false;
    }
}

async function getSensorLiveData(customerId) {
    try {
        const res = await axios.get(`${hostname}${path}/sensordata?customerId=${customerId}`, {withCredentials: true});
        var data = res.data;
        if (data.error) {console.log(data.error); return false;}
        return data;
    } catch (e) {
        console.log('could not get sensor data');
    }
}

async function getZoneLiveData(customerId) {
    try {
        const res = await axios.get(`${hostname}${path}/zonedata?customerId=${customerId}`, {withCredentials: true});
        var data = res.data;
        if (data.error) {console.log(data.error); return false;}
        return data;
    } catch (e) {
        console.log('could not get zone data');
    }
}

async function setHeatmapFloorInactive(customerId, floorID) {
    try {
        const data = {
            floorID: floorID
        } 
        const res = await axios.delete(`${hostname}${path}/floor?customerId=${customerId}`, {withCredentials: true, data: data});
        if (res.data.error){
            console.log('Error: ', res.data.error);
        }
        return res.data.success;
    } catch (e) {
        console.log('error while deleting floor: ', floorID);
        return false;
    }
}

async function getSensorLogInterval(customerId, interval) {
    try {
        const res = await axios.get(`${hostname}${path}/sensordata/history?customerId=${customerId}&interval=${interval}`, {withCredentials: true});
        var data = res.data;
        if (data.error) {console.log(data.error); return false;}
        return data;
    } catch (e) {
        console.log('could not get sensor log interval');
        return false;
    }
}

export {
    getHeatmapData,
    updateHeatmapData,
    getSensorLiveData,
    getZoneLiveData,
    setHeatmapFloorInactive,
    getSensorLogInterval
}