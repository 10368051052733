import styled from "styled-components";
import InputField from "../../UiComponents/InputField";
import Button from "../../UiComponents/Button";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0;

    button {
        width: fit-content;
        margin-top: 10px;
    }
`

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
        margin: 0 10px;
    }
`

const SearchField = props => {
    return <Container>
        <label>Search customer ID or name</label>
        <RowContainer>
            <InputField value={props.value} onChange={(e) => props.onChange(e.target.value)} />
            <Button onClick={() => props.onChange('')} secondary>Clear</Button>
        </RowContainer>
    </Container>
}

export default SearchField;