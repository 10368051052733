import styled, { css } from 'styled-components';
import theme from '../../../UI/theme';
import Button from '../../UiComponents/Button';

export const ModalContentContainer = styled.div`
    @media only screen and (min-width: ${theme.screenSizes.small}px) {
        padding: 30px;
    }

    @media only screen and (max-width: ${theme.screenSizes.small-1}px) {
        width: 100%;
    }

    >button {
        margin-bottom: 10px;
        margin-right: 10px;
        padding: 8px;
    }
`

export const CustomerList = styled.ul`
    list-style: none;
    overflow-y: auto;
    max-height: 55vh;
    padding: 0;
`;

export const ListItem = styled.li`
    padding: 8px 12px;
    cursor: pointer;

    span {
        display: inline-block;
        margin-right: 10px;
    }

    &:hover {
        background-color: ${theme.colors.grayHover};
    }

    ${props => props.current && css`
        color: ${theme.colors.greenEnergy};
    `}
`

export const ImpersonateContainer = styled.div`
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 20;
    display: flex;
    align-items: center;
    background-color: #393c46;/* ${theme.colors.darkSpace70}; */
    opacity: 0.9;
    padding: 6px;
    border-radius: 4px;
    font-size: 12px;

    >div {
        color: ${theme.colors.greenEnergy};
    }
`
export const ImpersonateButton = styled(Button)`
`

export const ScrollPadding = styled.div`
    padding: 20px 0;
`

export const Flex = styled.div`
    display: flex;
    justify-content: space-between;
`



