import { InfoText, StepContainer, StepHeader } from "./CalibrationStyles";
import Button from "./../../../../UiComponents/Button";
import styled, { css } from "styled-components";
import theme from "../../../../../UI/theme";
import { FaVolumeUp } from "react-icons/fa";
import { setAbsoluteVolumeDirect } from "../../../../../scripts/volume";
import { useState } from "react";
import { updateZone } from "../../../../../scripts/zone";
import { useHistory } from "react-router";
import { postEvent } from "../../../../../scripts/event";
import { userInteractionTypes } from "../../../../admin/customerDetails/userInteractionTypes";
import { postUserInteraction } from "../../../../../scripts/userInteraction";


const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    margin: 0 0 20px 0;
`

const CardContainer = styled.div`
    width: fill-available;
    text-align: center;
    background-color: ${theme.colors.darkSpace80};
    border-radius: 4px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const LevelLabel = styled.h2`
    margin: 0 0 5px 0;
`

const ErrorMessage = styled.label`
    margin: 0 0 20px 0;
    color: ${theme.colors.raspberryRed};
`

const ListenButton = styled(Button)`
    ${props => props.selected && css`
        color: ${theme.colors.greenEnergy};
    `}
`

const ConfirmCalibrationStep = props => {
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState();
    const [selectedIndex, setSelectedIndex] = useState();
    const levels = [
        { title: 'Empty', value: props.emptyVolumeLevel },
        { title: 'Medium', value: props.mediumVolumeLevel },
        { title: 'Full', value: props.fullVolumeLevel }
    ];

    const endCalibrationHandler = async () => {
        if (props.emptyVolumeLevel >= props.mediumVolumeLevel || props.emptyVolumeLevel >= props.fullVolumeLevel) {
            setErrorMessage("Empty level must be lower than medium and full level");
            return;
        }
        if (props.fullVolumeLevel <= props.mediumVolumeLevel || props.fullVolumeLevel <= props.emptyVolumeLevel) {
            setErrorMessage("Full level must be higher than medium and empty level");
            return;
        }
        if (props.emptyVolumeLevel > 100 ||
            props.mediumVolumeLevel > 100 ||
            props.fullVolumeLevel > 100 ||
            props.emptyVolumeLevel < 0 ||
            props.mediumVolumeLevel < 0 ||
            props.fullVolumeLevel < 0) {
            setErrorMessage("Calibration values must be between 0 and 100");
            return;
        }

        await updateZone(props.zone.customerId, props.zone.zoneId, { zoneId: props.zone.zoneId, processorMapValues: `${props.emptyVolumeLevel},${props.mediumVolumeLevel},${props.fullVolumeLevel}` });

        postEvent(props.zone.hubId, props.zone.customerId, 'UPDATE_CALIBRATION_VALUES');
        postUserInteraction(props.zone.customerId, {
            setting: userInteractionTypes.calibrate.key,
            zoneId: props.zone.zoneId,
            toValue: parseInt(`${props.emptyVolumeLevel}${props.mediumVolumeLevel}${props.fullVolumeLevel}`) ?? 0,
            fromValue: parseInt(`${props.currentMapValues?.join('')}`) ?? 0,
            payload: {
                from: { processorMapValues: props.currentMapValues.join() },
                to: { processorMapValues: `${props.emptyVolumeLevel},${props.mediumVolumeLevel},${props.fullVolumeLevel}` }
            }
        });

        history.push(`../../${props.zone.customerId}`);
    }

    return <StepContainer>
        <StepHeader>Summary</StepHeader>
        <InfoText>Review chosen values and confirm new calibration.</InfoText>
        <GridContainer>

            {levels.map((level, index) => {
                return <VolumeLevelCard
                    key={index}
                    index={index}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    liveVolumeChanges={props.liveVolumeChanges}
                    title={level.title}
                    value={level.value}
                    customerId={props.zone.customerId}
                    hubId={props.zone.hubId}
                    zoneId={props.zone.zoneId} />
            })}
        </GridContainer>

        {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : <></>}

        <div>
            <Button primary onClick={endCalibrationHandler}>End calibration</Button>
        </div>
    </StepContainer>
}

export default ConfirmCalibrationStep;

const VolumeLevelCard = props => {
    const clickHandler = () => {
        props.setSelectedIndex(props.index);
        setAbsoluteVolumeDirect(props.customerId, props.hubId, props.zoneId, props.value, true);
    }

    return <CardContainer>
        <LevelLabel>{props.title}</LevelLabel>
        <LevelLabel>{props.value}</LevelLabel>
        <ListenButton disabled={!props.liveVolumeChanges} selected={props.index === props.selectedIndex} secondary onClick={() => clickHandler()}>
            <FaVolumeUp />
        </ListenButton>
    </CardContainer>
}