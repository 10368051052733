import { useContext } from "react";
import { FaHistory, FaSeedling } from "react-icons/fa";
import UserContext from "../../hooks/UserContext";
import { postUserInteraction } from "../../scripts/userInteraction";
import { userInteractionTypes } from "../admin/customerDetails/userInteractionTypes";
import InfoButton from "../UiComponents/InfoButton";
import { ModalText, RowContainer } from "./ModalStyles";
import PanelButton from "./PanelButton";

const ZoneAdvancedSettings = props => {
    const user = useContext(UserContext);

    const toggleMoodSustain = async (newState) => {
        if (user.isImpersonator && user.isImpersonating) {
            return;
        }

        await props.updateZoneWithEvent({ zoneId: props.zone.zoneId, hubId: props.zone.hubId, moodSustain: +newState });
        postUserInteraction(props.zone?.customerId, {
            zoneId: props.zone?.zoneId,
            setting: userInteractionTypes.moodSustain.key,
            fromValue: +!newState,
            toValue: +newState
        });
    }

    const toggleAutomaticReset = async (newState) => {
        if (user.isImpersonator && user.isImpersonating) {
            return;
        }

        await props.updateZoneWithEvent({ zoneId: props.zone.zoneId, hubId: props.zone.hubId, autoResetZoneSettings: newState });
    }

    return <>
        {props.zone?.shouldRegulate ? <>
            <label>Advanced settings</label>
            <RowContainer>
                <ModalText>
                    Mood sustain
                    <InfoButton>Sustain the mood a bit longer, even if the crowd decreases.</InfoButton>
                </ModalText>

                <PanelButton
                    onClick={() => toggleMoodSustain(!props.zone?.moodSustain)}
                    deactivated={!props?.zone?.moodSustain}
                    disabled={!props.processorConnected}
                >
                    <FaSeedling />
                    <label>{props.zone?.moodSustain ? 'On' : 'Off'}</label>
                </PanelButton>
            </RowContainer>

            {user.user?.role === 'admin' ?
                <RowContainer>
                    <ModalText>Automatic reset</ModalText>
                    <PanelButton
                        onClick={() => toggleAutomaticReset(!props.zone?.autoResetZoneSettings)}
                        deactivated={!props?.zone?.autoResetZoneSettings}
                        disabled={!props.processorConnected}
                    >
                        <FaHistory />
                        <label>{props.zone?.autoResetZoneSettings ? 'On' : 'Off'}</label>
                    </PanelButton>
                </RowContainer>
                : <></>
            }

        </> : <></>}
    </>
}

export default ZoneAdvancedSettings;