import { useRef } from "react";
import { useContext } from "react";
import { useState } from "react";
import UserContext from "../../hooks/UserContext";
import { postEvent } from "../../scripts/event";
import { postUserInteraction } from "../../scripts/userInteraction";
import { updateZone } from "../../scripts/zone";
import { userInteractionTypes } from "../admin/customerDetails/userInteractionTypes";
import Button from "../UiComponents/Button";
import Modal from "../UiComponents/Modal";
import { ModalContainer, ModalHeader, ModalText, RowContainer } from "./ModalStyles";
import RegulationButton from "./RegulationButton";

const GroupRegulationButton = props => {
    const [showModal, setShowModal] = useState(false);
    const newStateRef = useRef();
    const user = useContext(UserContext);

    const clickHandler = (newState) => {
        if (newState == false) {
            newStateRef.current = +newState;
            setShowModal(true);
        } else {
            toggleGlobalRegulation(+newState)
        }
    }

    const toggleGlobalRegulation = async (newState) => {
        if (user.isImpersonator && user.isImpersonating) {
            return;
        }

        let hubIds = [];
        let promises = [];
        props.zones?.forEach(async (zone) => {
            if (!hubIds.includes(zone.hubId)) {
                hubIds.push(zone.hubId);
            }

            promises.push(
                updateZone(zone.customerId, zone.zoneId,
                    {
                        zoneId: zone.zoneId,
                        hubId: zone.hubId,
                        isRegulating: newState
                    }));
        });

        Promise.all(promises).then(async () => {
            for (let i = 0; i < hubIds.length; i++) {
                const hubId = hubIds[i];
                await postEvent(hubId, props.customerId, 'GET_ALL_ZONE_SETTINGS');
            }
        });

        postUserInteraction(props.customerId, {
            setting: userInteractionTypes.globalRegulation.key,
            fromValue: +!newState,
            toValue: +newState
        });
    }

    return <>
        <RegulationButton
            onClick={() => clickHandler(!props.groupIsRegulating)}
            deactivated={!props.groupIsRegulating}
            disabled={props.disabled}
        >
            <label>
                {!props.groupIsRegulating ? 'Off' : 'On'}
            </label>
        </RegulationButton>

        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContainer>
                <ModalHeader>Group regulation</ModalHeader>
                <ModalText>Are you sure you want to pause for entire group?</ModalText>
                <RowContainer>
                    <Button primary onClick={() => { toggleGlobalRegulation(newStateRef.current); setShowModal(false); }}>Yes</Button>
                    <Button secondary onClick={() => setShowModal(false)}>Cancel</Button>
                </RowContainer>
            </ModalContainer>
        </Modal>
    </>
}

export default GroupRegulationButton;