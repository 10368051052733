import Paper, { Path, Group, Point, Rectangle } from 'paper';
import theme from '../../../UI/theme';

const iconColor = theme.colors.greenReadable;

class BoundHandle {
    constructor(
        bounds,
        layer,
        onMouseDown,
        onMouseDrag,
        onMouseUp,
        onScale,
        onScaleEnd,
        onRotate,
        onRotateEnd,
        onDelete) {
        this.bounds = bounds;
        this.layer = layer;
        this.onMouseDown = onMouseDown;
        this.onMouseDrag = onMouseDrag;
        this.onMouseUp = onMouseUp;
        this.onScale = onScale;
        this.onScaleEnd = onScaleEnd;
        this.onRotate = onRotate;
        this.onRotateEnd = onRotateEnd;
        this.onDelete = onDelete;

        this.backgroundRect = null;
        this.scaleHandle = null;
        this.deleteHandle = null;
        this.rotateHandle = null;
        this.scaleStartPoint = null;
        this.rotateStartPoint = null;
    }

    drawBackgroundRect() {
        if (this.backgroundRect) {
            this.backgroundRect.remove();
        }
        this.backgroundRect = new Path.Rectangle(this.bounds);
        this.backgroundRect.fillColor = 'white';
        this.backgroundRect.fillColor.alpha = 0.01;
        this.backgroundRect.strokeColor = iconColor;
        this.backgroundRect.strokeWidth = 0.05;
        this.backgroundRect.bringToFront();
    }

    addBackgroundRectEvents() {
        if (this.backgroundRect) {
            this.backgroundRect.onMouseDown = (event) => {
                event.stopPropagation();
                if (this.onMouseDown) {
                    this.onMouseDown(event);
                }
            }

            this.backgroundRect.onMouseEnter = (event) => {
                event.stopPropagation();
                Paper.view.element.style.setProperty('cursor', 'move');
            }

            this.backgroundRect.onMouseLeave = (event) => {
                event.stopPropagation();
                Paper.view.element.style.setProperty('cursor', null);
            }

            this.backgroundRect.onMouseDrag = (event) => {
                event.stopPropagation();
                this.backgroundRect.position = this.backgroundRect.position.add(event.delta);
                this.bounds = this.backgroundRect.bounds;
                this.scaleHandle.position = this.scaleHandle.position.add(event.delta);
                this.deleteHandle.position = this.deleteHandle.position.add(event.delta);
                this.rotateHandle.position = this.rotateHandle.position.add(event.delta);
                if (this.onMouseDrag) {
                    this.onMouseDrag(event);
                }
            }

            this.backgroundRect.onMouseUp = (event) => {
                event.stopPropagation();
                if (this.onMouseUp) {
                    this.onMouseUp(event);
                }
            }
        }
    }

    drawHandles() {
        var topRight = this.bounds.topRight;
        var topLeft = this.bounds.topLeft;
        var bottomRight = this.bounds.bottomRight;

        var cornerCircle = new Path.Circle(topRight, 0.2);
        cornerCircle.strokeColor = iconColor;
        cornerCircle.strokeWidth = 0.05;
        cornerCircle.fillColor = 'white';

        if (this.scaleHandle) {
            this.scaleHandle.removeChildren();
            this.scaleHandle.remove();
        }
        this.scaleHandle = new Group();
        var arrowHead = new Path([
            new Point(topRight.x - 0.02, topRight.y - 0.07),
            new Point(topRight.x + 0.07, topRight.y - 0.07),
            new Point(topRight.x + 0.07, topRight.y + 0.02)]);
        arrowHead.strokeWidth = 0.04;
        arrowHead.strokeColor = iconColor;
        arrowHead.strokeCap = 'round';
        var arrowheadCopy = arrowHead.clone().rotate(180, topRight);
        this.scaleHandle.addChildren([cornerCircle, arrowHead, arrowheadCopy]);
        this.scaleHandle.bringToFront();

        if (this.deleteHandle) {
            this.deleteHandle.removeChildren();
            this.deleteHandle.remove();
        }
        var deleteLine = new Path.Line(
            new Point(topLeft.x + 0.1, topLeft.y + 0.1),
            new Point(topLeft.x - 0.1, topLeft.y - 0.1));
        deleteLine.strokeWidth = 0.05;
        deleteLine.strokeColor = iconColor;
        deleteLine.strokeCap = 'round';
        var deleteLineClone = deleteLine.clone().rotate(90, topLeft);

        var deleteCircle = cornerCircle.clone();
        deleteCircle.position = topLeft;

        this.deleteHandle = new Group();
        this.deleteHandle.addChildren([deleteCircle, deleteLine, deleteLineClone]);
        this.deleteHandle.bringToFront();

        if (this.rotateHandle) {
            this.rotateHandle.remove();
        }

        this.rotateHandle = new Group();

        var rotateCircle = cornerCircle.clone();
        rotateCircle.position = bottomRight;
        var rotateArc = new Path.Arc(
            new Point(bottomRight.x, bottomRight.y - 0.08),
            new Point(bottomRight.x + 0.08, bottomRight.y),
            new Point(bottomRight.x - 0.08, bottomRight.y));
        rotateArc.strokeColor = iconColor;
        rotateArc.strokeCap = 'round';
        rotateArc.strokeWidth = 0.04;

        var rotateArrowHead = arrowHead.clone().rotate(-45).scale(0.8);
        rotateArrowHead.position = new Point(bottomRight.x - 0.08, bottomRight.y - 0.02);

        this.rotateHandle.addChildren([rotateCircle, rotateArc, rotateArrowHead]);
        this.rotateHandle.bringToFront();
    }

    addHandleEvents() {
        if (this.scaleHandle) {

            this.scaleHandle.onMouseEnter = (event) => {
                event.stopPropagation();
                Paper.view.element.style.setProperty('cursor', 'ne-resize');
            }

            this.scaleHandle.onMouseLeave = (event) => {
                event.stopPropagation();
                Paper.view.element.style.setProperty('cursor', null);
            }

            this.scaleHandle.onMouseDown = (event) => {
                event.stopPropagation();
                this.scaleStartPoint = this.bounds.bottomLeft;
            }

            this.scaleHandle.onMouseDrag = (event) => {
                event.stopPropagation();
                if (event.point.x > this.scaleStartPoint.x && event.point.y < this.scaleStartPoint.y) {
                    var rectSideLength = Math.min(Math.abs(event.point.x - this.scaleStartPoint.x), Math.abs(event.point.y - this.scaleStartPoint.y));
                    if (rectSideLength > 0.5) {
                        var movedPoint = new Point(this.scaleStartPoint.x + rectSideLength, this.scaleStartPoint.y - rectSideLength);
                        var rect = new Rectangle(this.scaleStartPoint, movedPoint);
                        this.backgroundRect.fitBounds(rect);
                        this.bounds = this.backgroundRect.bounds;
                        this.scaleHandle.position = this.backgroundRect.bounds.topRight;
                        this.deleteHandle.position = this.backgroundRect.bounds.topLeft;
                        this.rotateHandle.position = this.backgroundRect.bounds.bottomRight;
                        if (this.onScale) {
                            this.onScale(event, rect);
                        }
                    }
                }
            }

            this.scaleHandle.onMouseUp = (event) => {
                event.stopPropagation();
                if (this.onScaleEnd) {
                    this.onScaleEnd();
                }
            }
        }

        if (this.deleteHandle) {

            this.deleteHandle.onMouseEnter = (event) => {
                event.stopPropagation();
                Paper.view.element.style.setProperty('cursor', 'pointer');
            }

            this.deleteHandle.onMouseLeave = (event) => {
                event.stopPropagation();
                Paper.view.element.style.setProperty('cursor', null);
            }

            this.deleteHandle.onClick = (event) => {
                event.stopPropagation();
                if (this.onDelete) {
                    this.onDelete();
                }
                this.removeHandles();
            }
        }

        if (this.rotateHandle) {

            this.rotateHandle.onMouseEnter = (event) => {
                event.stopPropagation();
                Paper.view.element.style.setProperty('cursor', 'grab');
            }

            this.rotateHandle.onMouseLeave = (event) => {
                event.stopPropagation();
                Paper.view.element.style.setProperty('cursor', null);
            }

            this.rotateHandle.onMouseDown = (event) => {
                event.stopPropagation();
                this.rotateStartPoint = event.point;
            }

            this.rotateHandle.onMouseDrag = (event) => {
                event.stopPropagation();
                var vector1 = this.rotateStartPoint.subtract(this.bounds.center);
                var angle1 = vector1.angle;
                var vector2 = this.rotateStartPoint.add(event.delta).subtract(this.bounds.center);
                var angle2 = vector2.angle;
                var rotation = (angle2 - angle1);
                this.rotateStartPoint = event.point;
                if (this.onRotate) {
                    this.onRotate(rotation);
                }
            }

            this.rotateHandle.onMouseUp = (event) => {
                event.stopPropagation();
                this.rotateStartPoint = null;
                if (this.onRotateEnd) {
                    this.onRotateEnd();
                }
            }
        }
    }

    hideHandles() {
        if (this.backgroundRect) {
            this.backgroundRect.strokeColor = 'transparent';
        }
        if (this.scaleHandle) {
            this.scaleHandle.visible = false;
        }
        if (this.deleteHandle) {
            this.deleteHandle.visible = false;
        }
        if (this.rotateHandle) {
            this.rotateHandle.visible = false;
        }
    }

    removeHandles() {
        if (this.backgroundRect) {
            this.backgroundRect.remove();
            this.backgroundRect = null;
        }
        if (this.scaleHandle) {
            this.scaleHandle.remove();
            this.scaleHandle = null;
        }
        if (this.deleteHandle) {
            this.deleteHandle.remove();
            this.deleteHandle = null;
        }
        if (this.rotateHandle) {
            this.rotateHandle.remove();
            this.rotateHandle = null;
        }
    }

    updateBounds(bounds) {
        var maxSideLength = Math.max(bounds.width, bounds.height);
        var topLeft = new Point(bounds.center.x - maxSideLength/2, bounds.center.y - maxSideLength/2);
        this.bounds = new Rectangle(topLeft.x, topLeft.y, maxSideLength, maxSideLength);
    }

    select() {
        this.layer.activate();
        this.drawBackgroundRect();
        this.addBackgroundRectEvents();
        this.drawHandles();
        this.addHandleEvents();
    }

    deselect() {
        this.hideHandles();
    }

    activate() {
        this.layer.activate();
        this.drawBackgroundRect();
        this.addBackgroundRectEvents();
        this.hideHandles();
    }

    deactivate() {
        this.removeHandles();
    }
}

export default BoundHandle;