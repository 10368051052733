import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { days, getDayOfWeek, getWeekForDate } from '../../scripts/common';
import { getTrendData } from '../../scripts/trends';
import KeyMetric from '../UiComponents/KeyMetric';
import trendTypes from './trendTypes';
import { filterOpeningHours } from './trendUtils';

const MetricContainer = styled.div`
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    align-items: start;
    margin-bottom: 10px;
    justify-content: space-around;
`

const dangerousNoiseLevel = 80;

const KeyMetrics = props => {
    const [avgDBTrend, setAvgDBTrend] = useState();
    const [regulationsTrend, setRegulationsTrend] = useState();
    const [peak, setPeak] = useState();
    const [regulations, setRegulations] = useState();
    const [timeInDangerousNoise, setTimeInDangerousNoise] = useState();

    useEffect(() => {
        refreshData();
    }, [props.date, props.useOpeningHours, props.trendPeriod])

    var refreshData = async () => {
        if (props.date && props.trendPeriod) {
            var weekDBTrend = await updateTrend(avgDBTrend, setAvgDBTrend, trendTypes.dataTypes.averageDecibel);
            var weekRegulationsTrend = await updateTrend(regulationsTrend, setRegulationsTrend, trendTypes.dataTypes.regulations);
            if (weekDBTrend) {
                getPeakHour(weekDBTrend);
            } else {
                setPeak(null);
                setTimeInDangerousNoise(null);
            }
            if (weekRegulationsTrend) {
                getRegulations(weekRegulationsTrend);
            } else {
                setRegulations(null);
            }
        } else {
            setPeak(null);
            setTimeInDangerousNoise(null);
            setRegulations(null);
            setAvgDBTrend(null);
            setRegulationsTrend(null);
        }
    }

    const updateTrend = async (currentTrend, setCurrentTrend, trendType) => {
        var newTrend = null;
        if (currentTrend) {
            var startOfWeek = new Date(currentTrend.startDate);
            var endOfWeek = new Date(currentTrend.endDate);
            if (props.date < startOfWeek || props.date >= endOfWeek) {
                newTrend = await getTrend(trendType);
                setCurrentTrend(newTrend);
            } else {
                return currentTrend;
            }
        } else {
            newTrend = await getTrend(trendType);
            setCurrentTrend(newTrend);
        }

        return newTrend;
    }

    const getTrend = async (trendType) => {
        var trends = await getTrendData(props.customerId, trendTypes.periods.WEEK, props.date, props.hierarchyLevel, props.objectID, trendType);
        var weekTrend = trends?.filter(trend => trend?.DataTypeValue === trendType)[0];
        return weekTrend;
    }

    const getPeakHour = async (weekTrend) => {
        const dayOfWeek = getDayOfWeek(props.date);
        var peakVal = null;
        var peakTime = null;
        var peakDoW = null;
        var openingTime = 0;
        if (props.useOpeningHours && weekTrend?.OpeningHours) {
            openingTime = weekTrend.OpeningHours[dayOfWeek].start / 100;
            weekTrend = filterOpeningHours(weekTrend, weekTrend.OpeningHours);
        }
        if (weekTrend) {
            switch (props.trendPeriod) {
                case trendTypes.periods.DAY:
                    var hourData = weekTrend?.data?.[dayOfWeek]?.hourData;
                    peakVal = Math.max(...hourData);
                    peakTime = hourData.indexOf(peakVal) + openingTime;
                    setPeak({ value: Math.round(peakVal), time: peakTime });

                    var dangerousNoiseTime = 0;
                    hourData?.forEach(val => {
                        if (val > dangerousNoiseLevel) {
                            dangerousNoiseTime += 1;
                        }
                    })
                    setTimeInDangerousNoise(dangerousNoiseTime);
                    break;
                case trendTypes.periods.WEEK:
                    var dangerousNoiseTime = 0;
                    weekTrend?.data?.forEach((day, index) => {
                        var peakInDay = Math.max(...day.hourData);
                        if (peakInDay && (!peakVal || peakVal < peakInDay)) {
                            peakVal = peakInDay;
                            peakTime = day.hourData.indexOf(peakVal);
                            peakDoW = index;
                        }

                        var dangerousNoiseTimeForDay = 0;
                        day?.hourData?.forEach(val => {
                            if (val > dangerousNoiseLevel) {
                                dangerousNoiseTimeForDay += 1;
                            }
                        })
                        dangerousNoiseTime += dangerousNoiseTimeForDay;
                    });
                    setPeak({ value: Math.round(peakVal), time: peakTime, day: peakDoW });
                    setTimeInDangerousNoise(dangerousNoiseTime);
                    break;
                default:
                    setPeak(null);
                    setTimeInDangerousNoise(null);
                    return;
            }
        } else {
            setPeak(null);
            setTimeInDangerousNoise(null);
        }
    }

    const getRegulations = async (weekTrend) => {
        const dayOfWeek = getDayOfWeek(props.date);
        var hR = null;
        var wR = null;
        var openMinutesInInterval = getOpeningMinutes(props.useOpeningHours, weekTrend.OpeningHours, dayOfWeek, props.date, props.trendPeriod);
        if (props.useOpeningHours && weekTrend?.OpeningHours) {
            weekTrend = filterOpeningHours(weekTrend, weekTrend?.OpeningHours);
        }

        if (weekTrend) {
            switch (props.trendPeriod) {
                case trendTypes.periods.DAY:
                    var newRegulations = weekTrend?.data?.[dayOfWeek].hourData;
                    hR = newRegulations?.map(x => x?.[0])?.reduce((a, b) => a + b, 0);
                    wR = newRegulations?.map(x => x?.[1])?.reduce((a, b) => a + b, 0);
                    setRegulations({ wavedRegulations: wR, humanRegulations: hR, minutes: openMinutesInInterval });
                    break;
                case trendTypes.periods.WEEK:
                    hR = 0;
                    wR = 0;
                    weekTrend?.data?.forEach(day => {
                        var dayRegulations = day.hourData;
                        hR += dayRegulations?.map(x => x?.[0])?.reduce((a, b) => a + b, 0);
                        wR += dayRegulations?.map(x => x?.[1])?.reduce((a, b) => a + b, 0);
                    })
                    setRegulations({ wavedRegulations: wR, humanRegulations: hR, minutes: openMinutesInInterval });
                    break;
                default:
                    setRegulations(null);
                    break;
            }
        } else {
            setRegulations(null);
        }
    }

    return <MetricContainer>
        <KeyMetric metric={notNullOrNaN(peak?.value) && peak?.value > 0 ? formatTime(peak.time) : '-'} title={'Peak hour'} label={notNullOrNaN(peak?.value) && peak?.value > 0 ? formatTimeLabel(peak.value, peak.day) : 'No data available'} count={true} margin={'0 5px'} />

        <KeyMetric metric={notNullOrNaN(regulations?.wavedRegulations) ? regulations?.wavedRegulations : '-'} title={"Regulations"} label={formatRegulationsLabel(regulations)} count={true} margin={'0 5px'} />

        <KeyMetric metric={notNullOrNaN(timeInDangerousNoise) ? timeInDangerousNoise : '-'} title={'Noise'} label={notNullOrNaN(timeInDangerousNoise) ? 'hours in unhealthy noise levels' : 'No data available'} count={true} margin={'0 5px'} />
    </MetricContainer>
}

export default KeyMetrics;

const formatTime = (hourNumber) => {
    var timeString = " am";
    if (hourNumber >= 12) {
        timeString = " pm";
    }
    var hour = (hourNumber % 12) || 12;

    return hour + timeString;
}

const formatTimeLabel = (value, dayOfWeek) => {
    var dayString = days[dayOfWeek];

    if (dayString && dayOfWeek !== null) {
        return `Measured ${value} db on ${dayString}`;
    } else {
        return `Measured ${value} db`
    }
}

const formatRegulationsLabel = (regs) => {
    if (regs) {
        if (!isNaN(regs.wavedRegulations) && regs.wavedRegulations > 0 && regs.minutes != null) {
            return `That's once every ${Math.round(regs.minutes * 100 / regs.wavedRegulations) / 100} minutes`;
        } else {
            return '';
        }
    } else {
        return 'No data available';
    }
}

const getOpeningMinutes = (useOpeningHours, openingHours, dayOfWeek, date, trendPeriod) => {
    if (trendPeriod === trendTypes.periods.DAY) {
        if (useOpeningHours && openingHours) {
            var start = openingHours[dayOfWeek].start;
            var end = openingHours[dayOfWeek].end;
            const today = new Date();
            if (date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
                var now = `${today.getHours()}${today.getMinutes()}`;
                if (parseInt(end) > parseInt(now)) {
                    end = now;
                }
            }

            var hoursInMinutes = (parseInt(end.substring(0, 2)) - parseInt(start.substring(0, 2))) * 60;
            var minutes = hoursInMinutes + (parseInt(end.substring(2)) - parseInt(start.substring(2)));
            return minutes;
        } else {
            return 24 * 60;
        }
    } else if (trendPeriod === trendTypes.periods.WEEK) {
        const today = new Date();
        if (getWeekForDate(date) === getWeekForDate(today)) {
            return getDayOfWeek(today) * 24 * 60 + today.getHours() * 60 + today.getMinutes();
        } else {
            return 7 * 24 * 60;
        }
    }

    return null;
}

const notNullOrNaN = (value) => {
    if (value === undefined || value === null || isNaN(value)) {
        return false;
    }
    return true;
}