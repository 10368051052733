import styled, { css } from "styled-components"
import theme from "../../../UI/theme"

export const TableRow = styled.tr`
    :hover {
        background-color: ${theme.colors.darkSpace60} !important;
    }
`

export const HeaderRow = styled.tr`
    background-color: ${theme.colors.darkSpace90};
    color: ${theme.colors.whiteSpace};
`

export const SortIconContainer = styled.div`
    cursor: pointer;
    background-color: ${theme.colors.darkSpace70};
    color: white;
    width: fit-content;
    height: fit-content;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    margin: 10px 0;
    
    ${props => props.sorted && css`
        background-color: ${theme.colors.darkSpace80};
        color: ${theme.colors.greenEnergy};
    `}

    :hover {
        background-color: ${theme.colors.darkSpace80};
    }
`

export const TableHeader = styled.th`
    text-align: -webkit-center;
`

export const TableContainer = styled.div`
    overflow-x: auto;
    margin-bottom: 100px;
`

export const Table = styled.table`
    min-width: 100%;
    table-layout: auto;

    tbody {
        ${TableRow}:nth-of-type(even) {
            background-color: ${theme.colors.darkSpace80};
        }
    
        ${TableRow}:nth-of-type(odd) {
            background-color: ${theme.colors.darkSpace70};
        }
    }

    td {
        white-space: nowrap;
    }
`

export const ErrorText = styled.label`
    border: 2px solid ${theme.colors.darkSpace90};
    border-radius: 4px;
    padding: 5px 5px;
    margin: 0 10px;
    

    ${props => props.color && css`
        color: ${props.color};
    `}
`

export const DetailsRow = styled.tr`
    background-color: ${theme.colors.darkSpace90};
`

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: ${theme.colors.textGray};
    padding: 10px 0;
`

export const Indicator = styled.div`
    height: 12px;
    width: 12px;
    border-radius: 2px;
    display: inline-block;
    
    ${props => props.color && css`
        background-color: ${props.color};
    `}
`

export const ObjectDetailsContainer = styled.div`
    position: relative;
    display: inline-block;
`

