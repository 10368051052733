import React, { useState } from 'react';
import ViewSelector from './venue/ViewSelector';
import ZoneList from '../../components/live/zone/ZoneList';
import { getZones } from '../../scripts/zone';
import venueTypes from '../../components/live/venueTypes';
import VenueActivtiyContainer from '../../components/live/venue/VenueActivityContainer';
import GlobalRegulationToggle from '../../components/live/venue/GlobalRegulationToggle';
import Card from './../UiComponents/Card';
import FadeInContainer from '../../components/UiComponents/Animations/FadeInContainer';
import StartSetupLink from './StartSetupLink';
import GoToSettingsLink from './GoToSettingsLink';
import SourceSelectors from './source/SourceSelectors';
import MusicDisplayParent from '../musicPlayer/MusicDisplayParent';
import { useSubscription } from '../../hooks/useSubscription';
import { subscriptionsTypes } from '../../scripts/subscription/subscriptionTypes';

const LiveContainer = (props) => {
    const [hubs, setHubs] = useSubscription(subscriptionsTypes.hubs);
    const [processors, setProcessors] = useSubscription(subscriptionsTypes.processors);
    const [sourceSelectors, setSourceSelectors] = useSubscription(subscriptionsTypes.sourceSelectors);
    const [zones, setZones] = useSubscription(subscriptionsTypes.zones);
    const [sources, setSources] = useSubscription(subscriptionsTypes.sources);
    const [schedules, setSchedules] = useSubscription(subscriptionsTypes.schedules);
    const [zoneLives, setZoneLives] = useSubscription(subscriptionsTypes.zoneLives);
    const [musicPlayers, setMusicPlayers] = useSubscription(subscriptionsTypes.musicPlayers);

    let tabs = resolveTabs(zones?.length > 1, sourceSelectors?.length > 0, musicPlayers?.length > 0);
    const [selectedTab, setSelectedTab] = useState(tabs?.find(tab => tab.key === venueTypes.viewTabs.ZONES));

    const refreshZones = async () => {
        try {
            var venueZones = await getZones(props.customerId);
            setZones(venueZones);
        } catch (error) {

        }
    }

    if (hubs?.length === 0) {
        return <>
            <StartSetupLink />
        </>
    } else if (zones?.length === 0) {
        return <>
            <GoToSettingsLink
                customerId={props.customerId}
                hub={hubs?.length === 1 ? hubs[0] : null}
            />
        </>
    } else {
        return <>
            {zones?.length > 1 || sourceSelectors?.length > 0 ? <>
                <ViewSelector
                    tabs={tabs}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab} />
            </> : <></>}
            {selectedTab.key === venueTypes.viewTabs.VENUE ? <>
                <FadeInContainer>
                    {(zones && zones.length === 1) ? <>
                        <ZoneList
                            customerId={props.customerId}
                            zones={zones}
                            hubs={hubs}
                            processors={processors}
                            sources={sources}
                            schedules={schedules}
                            setZones={setZones}
                            refreshZones={refreshZones}
                            zoneLives={zoneLives}
                            name={'My venue'}
                        />
                    </> : <>
                        <VenueActivtiyContainer customerId={props.customerId} zones={zones} />
                    </>}
                </FadeInContainer>
            </> : <></>}

            {selectedTab.key === venueTypes.viewTabs.ZONES ? <>
                <FadeInContainer>
                    <Card>
                        <GlobalRegulationToggle customerId={props.customerId} zones={zones} setZones={setZones} />
                    </Card>
                    <ZoneList
                        customerId={props.customerId}
                        zones={zones}
                        hubs={hubs}
                        processors={processors}
                        sources={sources}
                        schedules={schedules}
                        setZones={setZones}
                        refreshZones={refreshZones}
                        zoneLives={zoneLives}
                    />
                </FadeInContainer>
            </> : <></>
            }

            {selectedTab.key === venueTypes.viewTabs.SOURCES ? <>
                <FadeInContainer>
                    <SourceSelectors
                        customerId={props.customerId}
                        sourceSelectors={sourceSelectors}
                        processors={processors}
                        sources={sources}
                        setSourceSelectors={setSourceSelectors}
                    />
                </FadeInContainer>
            </> : <></>
            }

            {selectedTab.key === venueTypes.viewTabs.MUSIC?   
                <MusicDisplayParent
                    customerId={props.customerId}
                />
            : <></>
            }
        </>
    }
}

export default LiveContainer;

const resolveTabs = (hasMultipleZones, hasSourceSelectors, hasMusicPlayers) => {
    let tabs = [{
        display: 'Venue',
        key: venueTypes.viewTabs.VENUE
    }];

    tabs.push({
        display: 'Zones',
        key: venueTypes.viewTabs.ZONES
    });

    if (hasSourceSelectors) {
        tabs.push({
            display: 'Sources',
            key: venueTypes.viewTabs.SOURCES
        });
    }
    if (hasMusicPlayers) {
        tabs.push({
            display: 'Music',
            key: venueTypes.viewTabs.MUSIC
        })
    }

    return tabs;
}