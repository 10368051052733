import London from "./protocols/London"
import None from "./protocols/None";

const getProtocol = (processorType) => {
    switch (processorType) {
        case 'LONDON':
            return London;
        default:
            return None;
    }
}

export {
    getProtocol
}