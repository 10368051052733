import React from 'react';
import styled from 'styled-components';
import useServerStatus from '../../hooks/useServerStatus';
import theme from '../../UI/theme';
import GroupMuteButton from './GroupMuteButton';
import GroupRegulationButton from './GroupRegulationButton';
import { sortZones } from './panelUtils';
import SourceSelector from './SourceSelector';
import Zone from './Zone';

const GroupContainer = styled.div`
    box-sizing: border-box;
    border: 4px solid ${theme.colors.darkSpace80};
    padding: 8px;
    display: flex;
    flex-direction: column;
    touch-action: manipulation;
    gap: 8px;
    border-radius: 2px;
`

const GroupRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const GroupSettingsContainer = styled.div`
    display: flex;
    gap: 10px;
    padding-right: 14px;
`

const GroupHeader = styled.label`
    font-size: 1.5rem;
`

const SubGrid = styled.div`
    display: grid;
    grid-template-columns: 50px auto;
    background-color: ${theme.colors.darkSpace80};

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        grid-template-columns: 40px auto;
    }

    grid-gap: 2px 2px;
    padding: 4px;
`

const OLD_DATA_LIMIT_SECONDS = 10;

const Group = props => {
    const serverStatus = useServerStatus();
    const unhiddenZones = props.zones?.filter(zone => zone.hide === 0);
    let groupIsRegulating = unhiddenZones?.some(zone => zone.shouldRegulate === 1 && zone.isRegulating === 1);
    let groupMuted = unhiddenZones?.every(zone => zone.mute === 1);
    const sortedZones = sortZones(unhiddenZones);
    const zoneGroups = groupZones(sortedZones);
    const groupHasSourceSelectors = unhiddenZones?.some(zone => props.sourceSelectors?.some(sourceSelector => sourceSelector.sourceSelectorId === zone.sourceSelectorId));
    const groupConnected = props.processors?.some(processor => checkProcessorConnected(processor, props.hubs?.find(hub => hub.hubId === processor.hubId), serverStatus));

    return <GroupContainer onClick={(e) => e.preventDefault()}>
        <GroupRow>
            <GroupHeader>Zones</GroupHeader>
            <GroupSettingsContainer>
                <GroupMuteButton
                    zones={unhiddenZones}
                    groupMuted={groupMuted}
                    customerId={props.customerId}
                    disabled={!groupConnected}
                />
                <GroupRegulationButton
                    zones={unhiddenZones?.filter(zone => zone.shouldRegulate === 1)}
                    groupIsRegulating={groupIsRegulating}
                    customerId={props.customerId}
                    disabled={!groupConnected} />
            </GroupSettingsContainer>
        </GroupRow>

        {[...zoneGroups]?.map((group, index) => {
            const sourceSelector = props.sourceSelectors?.find(sourceSel => sourceSel.sourceSelectorId === group?.[0]);
            let sourceSelectorProcessor;
            let sourceSelectorSources;
            let sourceSelectorHub;
            let sourceSelectorProcessorConnected;

            if (sourceSelector) {
                sourceSelectorProcessor = props.processors?.find(processor => sourceSelector.processorId === processor.processorId);
                sourceSelectorSources = props.sources?.filter(source => source.processorId === sourceSelector.processorId)
                sourceSelectorHub = props.hubs?.find(hub => sourceSelectorProcessor?.hubId === hub.hubId);
                sourceSelectorProcessorConnected = checkProcessorConnected(sourceSelectorProcessor, sourceSelectorHub, serverStatus);
            }

            return <SubGrid>

                {sourceSelector ?
                    <SourceSelector
                        key={sourceSelector.sourceSelectorId}
                        rowSpan={sortedZones?.filter(zone => zone.sourceSelectorId === sourceSelector.sourceSelectorId)?.length}
                        sourceSelector={sourceSelector}
                        processor={sourceSelectorProcessor}
                        sources={sourceSelectorSources}
                        processorConnected={sourceSelectorProcessorConnected}
                        disabled={!sourceSelectorProcessorConnected}
                    />
                    : <></>}

                {group?.[1]?.map(zone => {
                    const zoneProcessor = props.processors?.find(processor => zone.processorId === processor.processorId);
                    const zoneHub = props.hubs?.find(hub => zone.hubId === hub.hubId);
                    const zoneSourceSelector = props.sourceSelectors?.find(sourceSelector => zone.sourceSelectorId === sourceSelector.sourceSelectorId);
                    const zoneProcessorConnected = checkProcessorConnected(zoneProcessor, zoneHub, serverStatus);
                    const isRegulatingAudioSource = !zoneSourceSelector
                        || props.sources?.find(source => source.sourceId === zoneSourceSelector?.activeSourceId)?.regulate == true
                        || props.sources?.length === 0 ? true : false;

                    return <>
                        <Zone
                            key={zone.zoneId}
                            zone={zone}
                            setZone={props.setZone}
                            hub={zoneHub}
                            groupHasSourceSelectors={groupHasSourceSelectors}
                            hasSourceSelector={zoneSourceSelector ? true : false}
                            processorConnected={zoneProcessorConnected}
                            schedules={props.schedules?.filter(schedule => schedule.zones?.includes(zone.zoneId))}
                            isRegulatingAudioSource={isRegulatingAudioSource}
                        />
                    </>
                })}

            </SubGrid>
        })}

    </GroupContainer>
}

export default Group;

const checkProcessorConnected = (processor, hub, serverStatus) => {
    return (hub?.secondsSinceLastUpdate <= OLD_DATA_LIMIT_SECONDS &&
        processor?.isConnected &&
        serverStatus);
}

const groupZones = (zones) => {
    const map = new Map();
    for (let index = 0; index < zones.length; index++) {
        const zone = zones[index];
        const key = zone?.sourceSelectorId ?? zone.zoneId;
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [zone]);
        } else {
            collection.push(zone);
        }
    }

    return map;
}