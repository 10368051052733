import React from 'react';
import { logoutUser } from '../../scripts/authentication';
import Button from '../UiComponents/Button';

const LogoutButton = props => {
    const logout = async () => {
        await logoutUser();
        document.location.replace('/');
    }

    return <Button tertiary fontSize={'1rem'} onClick={logout}>Sign out</Button>;
}

export default LogoutButton;

