import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getNorthstar } from "../scripts/northstar";
import theme from "../UI/theme";
import NsmCounter from "../components/northstar/NsmCounter";
import NorthstarChart from "../components/northstar/NorthstarChart";
import TotalHours from "../components/northstar/TotalHours";
import logoSecondary from  '../components/northstar/images/Waved_secondary-full.svg'
import { DateTime } from "luxon";

const StyledNorthstarView = styled.div`
    text-align: center; 
`
const Logo = styled.img`
    height: 2%;
    width: 300px;
    margin: 10px 10px;
    margin-top: 30px;

    @media only screen and (max-width: 600px) {
        width: 50%;
        height: auto;
    }
`
const NorthstarView = (props) => {

    const [northData, setNorthData] = useState(false);
    const [weekNumbers, setWeekNumers] = useState([]);
    const [total, setTotal] = useState(0);

    function sum(total, num) {
        return total + num;
    }
    function insertThousendSeparator (num) {
        
    }

    useEffect(() => {
        getNorthstar(props.customerId)
        .then(data => {
            if (data.trends.length){
                var numWeeks = data.trends.length;
                var date = new DateTime({zone:0});
                var weekNum = date.weekNumber;
                var weeks = [];
                for (let i = 0; i<numWeeks; i++) {
                    if (weekNum-i < 1) {
                        weeks.push(weekNum-i+52);
                    } else {
                        weeks.push(weekNum-i);
                    }
                }

                setWeekNumers(weeks.reverse());
                setNorthData(data.trends.map(trend => trend.weeklyCount))
            }
        });
    }, []);

    return (
        <StyledNorthstarView>
            <Logo src={logoSecondary} alt="Missing logo"/>
            <h1>Northstar Metric</h1>
            <h2>Waved boosts the mood at our customers venues</h2>
            {northData?
                <NsmCounter weekly={northData[northData.length-2]}></NsmCounter>
            : 
                <NsmCounter></NsmCounter>
            }
            <h2>hours every week!</h2>
            {northData?
                <NorthstarChart
                    data = {northData}
                    labels = {weekNumbers}
                />
            : <></>
            }
            {northData?
                <h3> With an all time total of {northData.reduce(sum).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} hours! </h3>
            :   
                <TotalHours></TotalHours>
            }
            
        </StyledNorthstarView>
    );
}

export default NorthstarView;