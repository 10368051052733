import React from 'react';
import { sortByKey } from '../../../../scripts/common';
import { processorTypes } from '../../../settings/venue/device/AudioSystem/processorTypes';
import ChoiceField from '../../fields/ChoiceField';
import PredefinedObjects from './PredefinedObjects';

const ProcessorTypeField = props => {

    return <>
        <ProcessorTypeChoiceField {...props} />
        {props.cellControl.isForm ? <>
            <PredefinedObjects 
                type={props.type}
                updateSubmitProps={props.cellControl.updateSubmitProps}
            />
        </> : <></>}
    </>
}

export default ProcessorTypeField;

export const ProcessorTypeChoiceField = props => {

    return <ChoiceField 
        items={sortByKey(Object.values(processorTypes), 'label')}
        selectedItem={processorTypes[props.type]}
        setValue={(value) => props.setValue(value)}
        cellControl={props.cellControl}
    />
}
