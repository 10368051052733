import styled from "styled-components";
import theme from "../../../UI/theme";

const IconButton = styled.button`
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    color: ${theme.colors.textGray};
    margin: 0 5px;
    font-size: ${props => props.fontSize ?? '20px'};

    &:hover {
        cursor: pointer;

        @media (hover: hover) {
            transform: scale(1.2);
        }
    }

    &:active {
        transform: scale(1.2);
    }

    &:disabled {
        color: ${theme.colors.textGray}60;
        cursor: auto;
        transform: none;
    }
`

export default IconButton;