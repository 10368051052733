
const sybGraphQl = {
    mutation: {
        login: (email, password) => { return {
            "query": `
                mutation {
                    loginUser(input: {email: "${email}", password: "${password}"}) {
                        token
                        refreshToken
                    }
                }
            `
        }},
        play: (id) => { return {
            "query": `
                mutation {
                    play (input: {soundZone: "${id}"}){
                        status
                    }
                }
            `
        }},
        pause: (id) => { return {
            "query": `
                mutation {
                    pause (input: {soundZone: "${id}"}){
                        status
                    }
                }
            `
        }},
        nextSong: (id) => {return {
            "query": `
                mutation {
                    skipTrack(input: {soundZone: "${id}"}){
                        status
                    }
                }
            `
        }},
        queuePlaylist: (id, playlistId) => { return {
            "query": `
                mutation {
                    setPlayFrom(input: {soundZone: "${id}", source: "${playlistId}"}){
                        playFrom {
                            __typename
                            ... on Playlist {
                                name
                            }
                        }
                    }
                }
            `
        }},
        updateAuthorization: (refreshToken) => { return {
            "query": `
                    mutation {
                        refreshLogin(input:{refreshToken: "${refreshToken}"}){
                            token
                            refreshToken
                        }
                    }
                `
        }}
    },
    query: {
        authentication: (id) => { return {
            "query": `
                query {
                    soundZone(id: "${id}") {
                        id
                    }
                }
            `
        }},
        connection: (id) => { return {
            "query": `
                query {
                    soundZone(id: "${id}") {
                        nowPlaying {
                            track {
                                title
                            }
                        }
                    }
                }
            `
        }},
        state: (id) => { return {
            "query": `
                query {
                    soundZone(id: "${id}") {
                        playback {
                            state
                        }
                    }
                }
            `
        }},
        getVolume: (id) => { return {
            "query": `
                query {
                    soundZone(id: "${id}") {
                        playback {
                            volume
                        }
                    }
                }
            `
        }},
        setVolume: (id, volume) => { return {
            "query": `
                mutation {
                    setVolume(input: {soundZone: "${id}", volume: ${volume}}){
                        status
                    }
                }
            `
        }},
        getPlayingMusic: (id) => { return {
            "query": `
                query {
                    nowPlaying(soundZone: "${id}") {
                        playFrom {
                            __typename
                            ... on Playlist {
                                name
                            }
                        }
                        track {
                            title
                            album {
                                title
                                image {
                                    url
                                }
                            }
                            artists {
                                name
                            }
                            display {
                                colors {
                                    primary {
                                        hex
                                    }
                                    secondary {
                                        hex
                                    }
                                }
                            }
                        }
                    }
                }
            `
        }},
        getAvailablePlaylists: (id) => { return {
            "query": `
                query {
                    soundZone(id: "${id}") {
                        account {
                            musicLibrary {
                                playlists (first: 1000) {
                                    edges{
                                        node {
                                            name
                                            id
                                            display {
                                                image {
                                                    placeholder
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `
        }},
        getZones: () => { return {
            "query": `
                query {
                    me {
                        ...on User {
                            accounts(first: 10) {
                            edges {
                                node {
                                    businessName
                                    locations(first: 10) {
                                        edges {
                                            node {
                                                name
                                                address
                                                soundZones(first: 100) {
                                                    edges {
                                                        node {
                                                            id
                                                            name
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    }
                }
            `
        }}
    },
    subscription: {
        playback: (id) => { return (`
            subscription playback {
                playbackUpdate(input: {soundZone: "${id}"}) {
                    playback {
                        state
                        volume
                    }
                }
            }
        `)},
        nowPlaying: (id) => { return (`
            subscription nowPlaying {
                nowPlayingUpdate(input: {soundZone: "${id}"}) {
                    nowPlaying {
                        playFrom {
                            __typename
                            ... on Playlist {
                                name
                            }
                        }
                        track {
                            title
                            album {
                                title
                                image {
                                    url
                                    width
                                    height
                                }
                            }
                            artists {
                                name
                            }
                            display {
                                colors {
                                    primary {
                                        hex
                                    }
                                    secondary {
                                        hex
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `)}
    }
}

export {
    sybGraphQl
}