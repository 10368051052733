import React from 'react';
import { postEvent } from '../../../../../scripts/event';
import { addProcessor } from '../../../../../scripts/processor';
import Button from '../../../../UiComponents/Button';
import AudioSystem from '../AudioSystem/AudioSystem';
import { processorTypes } from '../AudioSystem/processorTypes';
import { MT10Container } from '../DeviceStyles';

const ProcessorList = props => {
    
    const addProcessorHandler = async () => {
        const processor = {
            hubId: props.hub.hubId,
            customerId: props.customerId,
            type: processorTypes.NONE.value
        };
        try {
            await addProcessor(props.customerId, processor);
            await postEvent(props.hub.hubId, props.customerId, 'UPDATE_PROCESSOR');
            props.refreshDevices();
        } catch (err) {
            
        }
    }

    if (!props.processors) {
        return <></>
    }

    return <>
        <div>
            Processors
            {props.processors.map(processor => {
            return <AudioSystem
                key={processor.processorId}
                {...props}
                processor={processor}
                sourceSelectors={props.sourceSelectors.filter(sourceSelector => sourceSelector.processorId === processor.processorId)}
            />
        })}

            <MT10Container>
                <Button small secondary onClick={addProcessorHandler}>Add processor</Button>
            </MT10Container>
        </div>
    </>


}

export default ProcessorList;