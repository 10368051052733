export default {
    viewTabs: {
        VENUE: 'VENUE',
        ZONES: 'ZONES',
        SOURCES: 'SOURCES',
        MUSIC: 'MUSIC',
        MAP: 'MAP',
    },
    errorMessages: {
        NO_ZONE_DATA: {
            key: 'NO_ZONE_DATA',
            message: 'No zone data',
            details: 'There is no data from any of your zones. Please check that the hub has power and that your network is up.'
        },
        REGULATION_OFF: {
            key: 'REGULATION_OFF',
            message: 'Regulation off in one or more zones',
            details: 'Regulation has been turned off in one or more zones. Regulation can be switched on in the live zones view.'
        },
        MUTED: {
            key: 'MUTED',
            message: 'One or more zones muted',
            details: 'One or more zones have been muted by a schedule. Zones can be unmuted from the live zones view.'
        }
    }
};