import React, { useState } from 'react';
import styled from 'styled-components';
import { postEvent } from '../../../../../../scripts/event';
import { addSourceSelector, deleteSourceSelector, updateSourceSelector } from '../../../../../../scripts/sourceSelector';
import Button from '../../../../../UiComponents/Button';
import ExpandCollapseButton from '../../../../../UiComponents/buttons/ExpandCollapseButton';
import Modal from '../../../../../UiComponents/Modal';
import { TextMuted } from '../../../../../UiComponents/text/StyledText';
import { MT10Container } from '../../DeviceStyles';
import { AudioHeaderContainer, AudioList, AudioListItem } from '../AudioStyles';
import SourceSelector from './SourceSelector';
import SourceSelectorEditor from './SourceSelectorEditor';
import sourceSelectorTypes from './sourceSelectorTypes';

const Header = styled.h4`
    margin: 15px 0 0 0;
`

const Flex = styled.div`
    display: flex;
`

const SourceSelectorList = props => {
    const [showDetailed, setShowDetailed] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [editedSourceSelector, setEditedSourceSelecor] = useState();

    const addSourceSelectorHandler = async () => {
        const sourceSelector = {
            customerId: props.processor.customerId,
            processorId: props.processor.processorId,
            type: sourceSelectorTypes.NORMAL.value,
            address: ''
        };
        editSourceSelector(sourceSelector);
    }

    const editSourceSelector = (sourceSelector) => {
        setEditedSourceSelecor(sourceSelector);
        setShowModal(true);
    }

    const saveHandler = async (sourceSelector, isNew) => {
        try {
            if (isNew) {
                await addSourceSelector(props.processor.customerId, sourceSelector);
            } else {
                await updateSourceSelector(props.processor.customerId, sourceSelector);
            }
            await postEvent(props.processor.hubId, props.processor.customerId, 'UPDATE_SOURCE_SELECTORS');
            await props.refreshDevices();
            setShowModal(false);
            setShowDetailed(true);
        } catch (err) {

        }
    }

    const deleteHandler = async (sourceSelector) => {
        try {
            await deleteSourceSelector(props.processor.customerId, sourceSelector.sourceSelectorId);
            await postEvent(props.processor.hubId, props.processor.customerId, 'DELETE_SOURCE_SELECTOR', { sourceSelectorId: sourceSelector.sourceSelectorId });
            await props.refreshDevices();
            setShowModal(false);
        } catch (err) {
        }
    }

    if (!props.sourceSelectors) {
        return <></>
    }

    return <>

        <MT10Container>
            <Flex>
                <AudioHeaderContainer onClick={() => setShowDetailed(s => !s)}>
                    <span>Source selectors</span>
                    {props.sourceSelectors.length ? <>
                        <ExpandCollapseButton
                            showState={showDetailed}
                            width={40}
                            height={24}
                            display
                        />
                    </> : <></>}
                </AudioHeaderContainer>
            </Flex>
        </MT10Container>

        {showDetailed ? <>
            <AudioList>
                {props.sourceSelectors.map(sourceSelector => {
                    return <AudioListItem key={sourceSelector.sourceSelectorId}>
                        <SourceSelector
                            sourceSelector={sourceSelector}
                            editSourceSelector={editSourceSelector}
                            editState={props.editState}
                        />
                    </AudioListItem>
                })}
            </AudioList>

            <MT10Container>
                <Button small secondary onClick={addSourceSelectorHandler}>Add source selector</Button>
            </MT10Container>
        </> : <></>}





        <Modal show={showModal} close={() => setShowModal(false)}>
            Source selector
            <SourceSelectorEditor
                sourceSelector={editedSourceSelector}
                save={saveHandler}
                delete={deleteHandler}
                cancel={() => setShowModal(false)}
            />
        </Modal>
    </>
}

export default SourceSelectorList;