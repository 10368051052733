import React, { useState } from 'react';
import InputField from '../../UiComponents/InputField';

const CustomEvent = props => {
    const [event, setEvent] = useState({command: '', payload: ''});
    const [error, setError] = useState();
    
    const changeHandler = (newValue, key) => {
        setEvent(current => {
            var updated = {...current};
            updated[key] = newValue;
            return updated;
        });
    }

    const sumbitHandler = () => {
        // parse payload is json
        try {
            var payload = JSON.parse(event.payload);
            if (typeof payload == 'object') {
                props.handleEvent(event.command, payload);
            } else {
                setError('Could not parse as payload as json');
            }
        } catch (e) {
            setError('Could not parse as payload as json');
        }
        
    }

    return <div>

        <div>
            <label>Event Name</label>
            <div>
                <InputField value={event.command} onChange={(e) => changeHandler(e.target.value, 'command')} />
            </div>
        </div>

        <div>
            <label>Payload</label>
            <div>
                <textarea value={event.payload} onChange={(e) => changeHandler(e.target.value, 'payload')} />
            </div>
        </div>

        <p>Number of devices to update: {props.nDevices}</p>
        
        {error}

        <button onClick={sumbitHandler} className="btn btn-primary">Confirm</button>
        <button onClick={() => props.cancel()} className="btn btn-secondary">Cancel</button>
    </div>
}

export default CustomEvent;