import React, { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useSubscription } from '../../hooks/useSubscription';
import { subscriptionsTypes } from '../../scripts/subscription/subscriptionTypes';
import WaInput from '../UiComponents/Icons/WaInput';
import PanelButton from './PanelButton';
import SourceSelectorSkeleton from './skeletons/SourceSelectorSkeleton';
import SourceSelectorModal from './SourceSelectorModal';

const SourceSelectorButton = styled(PanelButton)`
    height: 100%;
    background-color: transparent;
    justify-content: center;
    grid-column: 1;
    grid-row: ${props => `1 / span ${props.rowSpan}`};
`

const SourceSelector = props => {
    const [sourceSelector, setSourceSelector] = useSubscription(subscriptionsTypes.sourceSelector, props.sourceSelector.sourceSelectorId);
    const [showSourceSelectorModal, setShowSourceSelectorModal] = useState(false);

    useEffect(() => {
        return () => setSourceSelector(null);
    }, [])

    return <>
        {sourceSelector ? <>
            <SourceSelectorButton
                rowSpan={props.rowSpan ?? 1}
                row={props.row}
                onClick={() => setShowSourceSelectorModal(true)}
                disabled={props.disabled}>
                <WaInput />
                <label>Source</label>
            </SourceSelectorButton>

            <SourceSelectorModal
                show={showSourceSelectorModal}
                close={() => { setShowSourceSelectorModal(false); }}
                sourceSelector={sourceSelector}
                processor={props.processor}
                sources={props.sources}
                processorConnected={props.processorConnected}
            />
        </>
            : <SourceSelectorSkeleton
                row={props.row}
                rowSpan={props.rowSpan ?? 1} />}
    </>

}

export default SourceSelector;