import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { createCalibration } from "../../../../scripts/calibration";
import { getDeviceNumber, sliceUrlAfter } from "../../../../scripts/common";
import { getHubs } from "../../../../scripts/hub";
import { getZones } from "../../../../scripts/zone";
import TopNavigation from "../../../navigation/TopNavigation";
import Button from "../../../UiComponents/Button";
import Card from "../../../UiComponents/Card";
import Radio from "../../../UiComponents/Radio";
import { TextMuted } from "../../../UiComponents/text/StyledText";
import ViewHeader from "../../../UiComponents/ViewHeader";
import { ButtonRowContainer } from "../device/DeviceStyles";

const HubContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const NewCalibration = props => {
    const { id } = useParams();
    const match = useRouteMatch();
    const history = useHistory();
    const [hubs, setHubs] = useState();
    const [zones, setZones] = useState();
    const [selectedHub, setSelectedHub] = useState();

    useEffect(() => {
        getDataHandler();
    }, [])

    const getDataHandler = async () => {
        try {
            const customerHubs = await getHubs(id);
            const customerZones = await getZones(id);
            setHubs(customerHubs);
            setZones(customerZones);
        } catch (error) {
            setHubs(null);
            setZones(null);
            setSelectedHub(null);
        }
    }

    const startCalibrationHandler = async () => {
        try {
            const newCalibration = await createCalibration(id, selectedHub.hubId);
            history.push(`${sliceUrlAfter(`${match.url}`, `/new`)}/${newCalibration.calibrationId}`);
        } catch (error) {

        }
    }

    return <>
        <ViewHeader>
            <TopNavigation to={sliceUrlAfter(match.url, `/new`)} />
            <h3>New calibration</h3>
        </ViewHeader>

        <Card>
            <h3>Select hub</h3>

            {hubs?.map((hub, hubIndex) => {
                return <div key={hubIndex}>
                    <HubContainer>
                        <Radio name={'hub'} onChange={() => setSelectedHub(hub)} checked={selectedHub?.hubId === hub?.hubId} />
                        <div>Hub {getDeviceNumber(hub)}</div>
                    </HubContainer>
                    <div>
                        {zones?.filter(zone => zone.hubId === hub.hubId)?.map((zone, zoneIndex) => {
                            return <div key={zoneIndex}>
                                <TextMuted>
                                    {zone.zoneName}
                                </TextMuted>
                            </div>
                        })}
                    </div>
                </div>
            })}

            <ButtonRowContainer>
                <Button disabled={!selectedHub?.hubId} primary onClick={() => startCalibrationHandler()}>Create calibration</Button>
            </ButtonRowContainer>
        </Card>
    </>
}

export default NewCalibration;