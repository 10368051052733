import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FaEdit } from 'react-icons/fa';
import Button from '../../../../../UiComponents/Button';
import InputField, { InputFieldContainer } from '../../../../../UiComponents/InputField';
import { getUuid } from '../../../../../../scripts/common';
import Modal from '../../../../../UiComponents/Modal';
import { TextMuted } from '../../../../../UiComponents/text/StyledText';
import { processorTypes } from '../processorTypes';
import { MT10Container } from '../../DeviceStyles';
import RegulationToggle from '../../../../../live/RegulationToggle';
import theme from '../../../../../../UI/theme';
import { updateProcessor } from '../../../../../../scripts/processor';
import { postEvent } from '../../../../../../scripts/event';
import { AudioHeaderContainer, AudioList, AudioListItem } from '../AudioStyles';
import ExpandCollapseButton from '../../../../../UiComponents/buttons/ExpandCollapseButton';
import IconLogo from '../../../../../UiComponents/IconLogo';


const Flex = styled.div`
    display: flex;
`

const StyledLogo = styled(IconLogo)`
    width: ${theme.fontSize.small};
    height: ${theme.fontSize.small};
    margin-bottom: 4px;
    margin-left: 10px;
    
    ${props => props.disabled && css`
        rect {
            fill: ${theme.colors.darkSpace70};
        }
    `}
`


const AudioSources = props => {
    const [editedAudioSource, setEditedActiveAudioSource] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [processorType, setProcessorType] = useState(props.processor.type);
    const [showDetailed, setShowDetailed] = useState(false);

    useEffect(() => {
        if (props.updatedProcessor && props.updatedProcessor.type != processorType) {
            var newVolumeMethodName = props.updatedProcessor.type;
            handleVolumeMethodChange(newVolumeMethodName);
        }
    }, [props.updatedProcessor]);

    const handleVolumeMethodChange = (newVolumeMethodName) => {
        setProcessorType(newVolumeMethodName);
        var newVolumeMethod = processorTypes[newVolumeMethodName];

    }

    const editAudioSource = (audioSource) => {
        setEditedActiveAudioSource(audioSource);
        setShowModal(true);
    }

    const createNewAudioSource = () => {
        var newAudioSource = {
            id: getUuid(),
            displayName: '',
            objectAddress: '',
            regulate: true,
            active: false
        }
        editAudioSource(newAudioSource);
    }

    const updateAudioSource = (audioSource) => {
        updateAudioSources([audioSource]);
    }

    const updateAudioSources = (audioSources, deleteOthers) => {
        var updatedAudioSources = props.audioSources && !deleteOthers ? [...props.audioSources] : [];
        audioSources.forEach(audioSource => {
            var existingAudioSourceIndex = updatedAudioSources.findIndex(source => source.id === audioSource.id);
            if (existingAudioSourceIndex > -1) {
                updatedAudioSources[existingAudioSourceIndex] = audioSource;
            } else {
                updatedAudioSources = [...updatedAudioSources, audioSource];
            }
        });

        props.updateSources(updatedAudioSources, true);
        setShowModal(false);
        setShowDetailed(true);
    }

    const deleteAudioSource = (audioSource) => {
        var updatedAudioSources = props.audioSources ? [...props.audioSources] : [];
        updatedAudioSources = updatedAudioSources.filter(source => source.id !== audioSource.id);
        props.updateSources(updatedAudioSources, true);
        setShowModal(false);
    }

    const saveAudioSources = async (customerId, updatedAudioSources) => {
        const newProcessorProps = { processorId: props.processor.processorId, sources: updatedAudioSources };
        await updateProcessor(props.customerId, newProcessorProps);
        await postEvent(props.hub.hubId, customerId, 'UPDATE_AUDIO_SOURCES');
    }

    const selectAudioSource = async (audioSource) => {
        var updatedAudioSources = [...props.audioSources];
        updatedAudioSources.forEach(as => { as.active = false });
        var existingAudioSource = updatedAudioSources.find(source => source.id === audioSource.id);
        if (existingAudioSource) {
            existingAudioSource.active = true;
            try {
                await saveAudioSources(props.processor.customerId, updatedAudioSources);
                props.updateSources(updatedAudioSources, true);
            } catch (err) {

            }
        }
    }

    return <>

        <MT10Container>
            <Flex>
                <AudioHeaderContainer onClick={() => setShowDetailed(s => !s)}>
                    <span>Sources</span>
                    {props.audioSources?.length ? <>
                        <ExpandCollapseButton
                            showState={showDetailed}
                            width={40}
                            height={24}
                            display
                        />
                    </> : <></>}
                </AudioHeaderContainer>
            </Flex>
        </MT10Container>

        {showDetailed ? <>
            <AudioList>
                {props.audioSources?.map(audioSource => {
                    return <AudioListItem key={audioSource.objectAddress}>
                        <div onClick={() => editAudioSource(audioSource)}>
                            <Flex>
                                <span>{audioSource.displayName}</span>
                                <span><StyledLogo disabled={!audioSource.regulate} /></span>
                            </Flex>
                        </div>
                    </AudioListItem>
                })}
            </AudioList>


            <MT10Container>
                <Button small secondary onClick={createNewAudioSource}>Add audio source</Button>
            </MT10Container>

        </> : <></>}

        <Modal show={showModal} close={() => setShowModal(false)}>
            Audio source
            <EditAudioSource audioSource={editedAudioSource} cancel={() => setShowModal(false)} save={updateAudioSource} delete={deleteAudioSource} />
        </Modal>
    </>
}

export default AudioSources;

const EditAudioSource = props => {
    const [audioSource, setAudioSource] = useState({ ...props.audioSource });

    const updateAttributes = (updatedAttributes) => {
        setAudioSource(current => ({ ...current, ...updatedAttributes }));
    }

    const saveHandler = () => {
        // check if valid
        props.save(audioSource);
    }

    return <div>

        <InputFieldContainer>
            <div><label>Name</label></div>
            <InputField value={audioSource.displayName} onChange={(e) => updateAttributes({ displayName: e.target.value })} />
        </InputFieldContainer>

        <InputFieldContainer>
            <div><label>ObjectAddress</label></div>
            <InputField value={audioSource.objectAddress} onChange={(e) => updateAttributes({ objectAddress: e.target.value })} />
        </InputFieldContainer>

        <InputFieldContainer>
            <label>Regulate</label>
            <MT10Container>
                <RegulationToggle checked={audioSource.regulate} onToggle={() => updateAttributes({ regulate: !audioSource.regulate })} />
            </MT10Container>
        </InputFieldContainer>

        {/* <div>Regulation</div> */}


        <div>
            <Button margin primary onClick={saveHandler}>Update</Button>
            <Button margin secondary onClick={() => props.cancel()}>Cancel</Button>
            <Button margin tertiary lightBorder onClick={() => props.delete(audioSource)}>Delete</Button>
        </div>
    </div>
}


