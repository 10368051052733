import { useState } from "react"
import { useEffect } from "react"
import { usePageVisibility } from "react-page-visibility"
import styled, { css } from "styled-components"
import { getDefinedLogInterval } from "../../../scripts/log"
import theme from "../../../UI/theme"
import zoneTypes from "../../live/zone/zoneTypes"
import ChartModal from "../ChartModal"
import LiveChart from "./LiveChart"

const Container = styled.div`
    height: 100%;
    width: 100%;
    cursor: pointer;
    border-radius: 2px;

    canvas {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
    }

    ${props => props.disabled && css`
        opacity: 40%;
        cursor: inherit;
        pointer-events: none;
    `}
`

const ErrorLabel = styled.label`
    color: ${theme.colors.textGray};
    font-size: 0.65rem;
    font-weight: 100;
    display: flex;
    text-align: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`

const LiveChartContainer = props => {
    const [graphData, setGraphData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const isVisible = usePageVisibility();

    useEffect(() => {
        if (isVisible === true) {
            getData();
        }
    }, [isVisible]);

    useEffect(() => {
        setGraphData(prev => {
            const prevCopy = JSON.parse(JSON.stringify(prev));
            prevCopy.pop();
            prevCopy.unshift({ time: props.zoneLive?.time, averageDecibel: props.zoneLive?.averageDecibel, noise: props.zoneLive?.noise, sysvol: props.zoneLive?.sysvol });
            return prevCopy;
        });
    }, [props.zoneLive?.time])

    const getData = async () => {
        var newData = await getDefinedLogInterval(props.zone?.customerId, props.zone?.zoneId, zoneTypes.intervals.LAST_15_MINUTES, null);
        setGraphData(newData);
    }

    return <>
        <Container className={props.className} disabled={!props.processorConnected} onClick={() => setShowModal(true)}>
            {props.processorConnected ?
                <LiveChart data={graphData} /> :
                <ErrorLabel>
                    Processor not connected
                </ErrorLabel>}
        </Container>

        <ChartModal
            show={showModal}
            close={() => setShowModal(false)}
            zone={props.zone}
            graphData={graphData} />
    </>
}

export default LiveChartContainer;