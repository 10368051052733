import styled from 'styled-components';
import theme from '../../../UI/theme';

export const Table = styled.table`
    min-width: 100%;
    table-layout: auto;
    border-collapse: collapse;
    color: ${theme.colors.textGray};
`

export const TableRow = styled.tr`
    :hover {
        background-color: ${theme.colors.darkSpace60} !important;
        cursor: pointer;
    }
`

export const TableCell = styled.td`
    padding: 10px 0;
`

export const TableHeader = styled.thead`
    color: ${theme.colors.darkSpace30};
    tr {
        border-bottom: 1px solid ${theme.colors.darkSpace50};
    }
`

export const TableHeaderRow = styled.tr`
`
