import ChoiceField from "../../fields/ChoiceField";

const availableZigbeeChannels = [
    { value: 11, label: '11' },
    { value: 14, label: '14' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 25, label: '25' },
];

const ZigbeeChannelChoiceField = props => {
    const selectedItem = availableZigbeeChannels.find(channel => channel.value === props.zigbeeChannel);
    return <ChoiceField 
        selectedItem={selectedItem}
        items={availableZigbeeChannels}
        setValue={props.setValue}
        cellControl={props.cellControl}
        excludeNone={props.excludeNone}
        disabled={props.disabled}
    />
}

export default ZigbeeChannelChoiceField;