import { simpleMovingAverage } from "../../../../scripts/common";
import { getZoneColor } from "../../../../UI/colorUtils";

function prepareGraphData(result, zones) {

    const x = [...Array(101).keys()];
    let labels = x;
    let datasets = [];
    if (result && result.length) {
        zones.forEach(zone => {
            var zoneResultIndex = result?.findIndex(zoneRes => zoneRes.zoneId === zone.zoneId);
            if (zoneResultIndex > -1) {
                var zoneResult = result[zoneResultIndex];
                var lineData = addPointsInbetween(zoneResult, x);
                datasets.push({
                    data: lineData,
                    label: zone?.zoneName,
                    color: getZoneColor(zoneResultIndex),
                    zoneId: zoneResult.zoneId
                })
            }
        })
    }

    return { datasets, labels };
}

function addPointsInbetween(zoneRes, points) {
    const dataColumn = 'averageDecibel';
    var x_index = 0;
    var actualData = zoneRes.data;
    var lineData = [];
    for (var i = 0; i < actualData.length; i++) {
        var datapoint = actualData[i];
        var sysvol = datapoint.sysvol;
        if (i > 0 && sysvol > points[x_index]) {
            var prev = actualData[i - 1];
            while (sysvol > points[x_index]) {
                lineData[x_index] = (datapoint[dataColumn] - prev[dataColumn]) / (sysvol - prev.sysvol) * (points[x_index] - prev.sysvol) + prev[dataColumn];
                x_index++;
            }
        }

        lineData[x_index] = datapoint[dataColumn];
        x_index++;
    }

    return lineData;
}

function getSmoothCalibrationResult(result) {
    if (Array.isArray(result)) {
        var smoothResult = JSON.parse(JSON.stringify(result));
        for (let zoneIndex = 0; zoneIndex < result.length; zoneIndex++) {
            const zone = result[zoneIndex];
            if (Array.isArray(zone.data)) {
                var avgDbMovingAverages = simpleMovingAverage(zone.data.map(point => point.averageDecibel), 5);
                var sysvolMovingAverages = simpleMovingAverage(zone.data.map(point => point.sysvol), 5);
                var avgDiffMovingAverages = simpleMovingAverage(zone.data.map(point => point.averageDiff), 5);

                smoothResult[zoneIndex].data = avgDbMovingAverages.map((value, index) => {
                    return {
                        averageDecibel: avgDbMovingAverages[index],
                        sysvol: sysvolMovingAverages[index],
                        averageDiff: avgDiffMovingAverages[index]
                    };
                })
            }
        }
        return smoothResult;
    } else {
        return [];
    }
}

function getCalibrationParametersFromSweep(sweep) {
    var pointdBRange = 20;
    var lowActivityMinimumOffset = 3;

    var baseLevel = getBaseLevel(sweep);
    var lowActivityDb = baseLevel + lowActivityMinimumOffset;
    var lowActivityPoint = getNearestPointOnCalibrationCurve(sweep, lowActivityDb);

    var sweepAfterLowActivityPoint = sweep.filter(point => point.sysvol >= lowActivityPoint.sysvol);
    var bestLine = getLineByLeastSquaresMethod(sweepAfterLowActivityPoint.map(point => point.sysvol), sweepAfterLowActivityPoint.map(point => point.averageDecibel));
    var slope = bestLine[0];

    if (!isNaN(slope)) {
        var mediumActivityDb = lowActivityPoint.measuredDb + pointdBRange / 2;
        var mediumActivitySysvol = lowActivityPoint.sysvol + (pointdBRange / 2) / bestLine[0];
        var mediumActivityPoint = { measuredDb: mediumActivityDb, sysvol: mediumActivitySysvol };
        var highActivityDb = lowActivityPoint.measuredDb + pointdBRange;
        var highActivitySysvol = lowActivityPoint.sysvol + pointdBRange / bestLine[0];
        var highActivityPoint = { measuredDb: highActivityDb, sysvol: highActivitySysvol };
        var calibrationPoints = [{ ...lowActivityPoint }, { ...mediumActivityPoint }, { ...highActivityPoint }];

        return calibrationPoints;
    } else {
        return null;
    }

}

function getBaseLevel(data) {
    var baseLevel = Math.min(...data.map(point => point.averageDecibel));
    return baseLevel;
}

function getNearestPointOnCalibrationCurve(data, pointDb) {
    var sysvol = 0;
    var measuredDb = pointDb;
    if (data?.length > 2) {
        var closestIndex = 0;
        var closestPoint = data.reduce((a, b) => Math.abs(a.averageDecibel - pointDb) < Math.abs(b.averageDecibel - pointDb) ? a : b);
        closestIndex = data.findIndex(point => point.averageDecibel === closestPoint.averageDecibel && point.sysvol === closestPoint.sysvol);
        sysvol = closestPoint.sysvol;
        measuredDb = pointDb;

        if (pointDb > closestPoint.averageDecibel) {
            if (closestIndex === data.length - 1) {
                var point1 = data[data.length - 2];
                var point2 = data[data.length - 1];
                sysvol = point1.sysvol + ((pointDb - point1.averageDecibel) / (point2.averageDecibel - point1.averageDecibel)) * (point2.sysvol - point1.sysvol);
            } else {
                var nextPoint = data[closestIndex + 1];
                var distance = Math.abs((pointDb - closestPoint.averageDecibel) / (nextPoint.averageDecibel - closestPoint.averageDecibel));
                sysvol = closestPoint.sysvol + distance * (nextPoint.sysvol - closestPoint.sysvol);
            }
        } else if (pointDb < closestPoint.averageDecibel) {
            if (closestIndex === 0) {
                var point1 = data[0];
                var point2 = data[1];
                sysvol = point2.sysvol + ((pointDb - point2.averageDecibel) / (point1.averageDecibel - point2.averageDecibel)) * (point1.sysvol - point2.sysvol);
            } else {
                var previousPoint = data[closestIndex - 1];
                var distance = Math.abs((pointDb - previousPoint.averageDecibel) / (closestPoint.averageDecibel - previousPoint.averageDecibel));
                sysvol = previousPoint.sysvol + distance * (closestPoint.sysvol - previousPoint.sysvol);
            }
        }
    }

    return { measuredDb: measuredDb, sysvol: sysvol };
}

function getLineByLeastSquaresMethod(x, y) {
    if (x.length && y.length && x.length === y.length && x.every(value => !isNaN(value)) && y.every(value => !isNaN(value))) {
        var xAverage = x.reduce((a, b) => a + b, 0) / x.length;
        var yAverage = y.reduce((a, b) => a + b, 0) / y.length;

        var xMinusAverage = x.map(value => value - xAverage);
        var yMinusAverage = y.map(value => value - yAverage);

        // y = ax + b
        var a = xMinusAverage.map((value, index) => value * yMinusAverage[index]).reduce((a, b) => a + b, 0) / xMinusAverage.map(value => value * value).reduce((a, b) => a + b, 0);
        var b = yAverage - a * xAverage;

        var linearPoints = x.map(value => a * value + b);
        var sumOfResiduals = linearPoints.map((value, index) => (y[index] - value) ** 2).reduce((a, b) => a + b, 0);
        var sumOfSquares = y.map(value => (value - yAverage) ** 2).reduce((a, b) => a + b, 0);
        var rSquared = 1 - sumOfResiduals / sumOfSquares;

        return [a, b, rSquared];
    } else {
        return false;
    }
}

export {
    prepareGraphData,
    addPointsInbetween,
    getSmoothCalibrationResult,
    getCalibrationParametersFromSweep
}