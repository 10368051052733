import { useState } from "react";
import styled from "styled-components";
import NetworkConfig from "../../../settings/venue/device/NetworkConfig/NetworkConfig";
import Modal, { FixedWidthModalContent } from "../../../UiComponents/Modal";
import { editableColor, readOnlyColor } from "../../fields/Styles";
import { FieldButton } from "../../fields/styling/FieldStyles";

const FieldContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const NetworkConfigField = ({ hub, updateHub, customerId }) => {
    const [showModal, setShowModal] = useState(false);

    const isConfigured = checkConfigured(hub.networkConfig);
    return <>
        <FieldContainer>

            <div style={{ color: isConfigured ? editableColor : readOnlyColor }}>{isConfigured ? 'Configured' : 'Default'}</div>
            <div>
                <FieldButton tertiary small onClick={() => setShowModal(true)}>Show</FieldButton>
            </div>
        </FieldContainer>
        <Modal show={showModal} close={() => setShowModal(false)}>
            <FixedWidthModalContent>
                <NetworkConfig
                    hub={hub}
                    customerId={customerId}
                    readOnly={false}
                    refreshDevices={() => { }}
                />
            </FixedWidthModalContent>
        </Modal>
    </>
}

export default NetworkConfigField;

const checkConfigured = (networkConfig) => {
    if (networkConfig) {
        const hasVirutalInterface = networkConfig?.['eth0:0'] !== undefined;
        const hasStaticAddress = Object.keys(networkConfig).filter(key => networkConfig[key].isStatic) > 0;
        return hasVirutalInterface || hasStaticAddress;
    } else {
        return false;
    }
}