import React from 'react';
import styled from 'styled-components';
import theme from '../../UI/theme';
import { Chevron } from '../UiComponents/Icons';
import {Link} from 'react-router-dom';

const Container = styled.div`
    display: flex;
    padding: 0 16px;
`

const BackButton = styled.button`
    border: none;
    color: ${theme.colors.textGray};
    background-color: transparent;

    &:hover {
        cursor: pointer;
    }
`

const TopNavigation = props => {

    return <Container>
        <div>

        {props.goBack ? <>
            <BackButton onClick={props.goBack}>
                <div>
                <Chevron height={20} width={20}/>
                </div>
            </BackButton>
        
        </> : <>
            <Link to={props.to || '/'}>
                <BackButton>
                    <div>
                    <Chevron height={20} width={20}/>
                    </div>
                </BackButton>
            </Link>

        </>}            
            


            
        </div>
        <div></div>
    </Container>


}

export default TopNavigation;