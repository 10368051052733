import React, { useState } from 'react';
import Checkbox from '../../UiComponents/Checkbox';

const SensorTable = props => {
    const [allSelected, setAllSelected] = useState(false);
    
    function selectAll() {
        var selectedState = !allSelected;
        props.selectAll(selectedState);
        setAllSelected(selectedState);
    }

    const sensorList = props.sensors.map(sensor => {
        return <tr key={sensor.sensorId}>
                <td><Checkbox checked={props.isSelected(sensor.sensorId)} onChange={() => props.selectHandler(sensor.sensorId)}/></td>
                <td>{sensor.customerId}</td>
                <td>{sensor.NetworkType ? <>({sensor.networkType})</> : <></>}</td>
                <td>{sensor.sensorId}</td>
                <td>{sensor.updated}</td>
                <td>{sensor.version}</td>
                <td>{sensor.isConnected}</td>
        </tr>
    })

    return <>
    <table className="waved-table">
        <thead>
            <tr>
                <th><Checkbox checked={allSelected} onChange={selectAll}/></th>
                <th>CustomerId</th>
                <th>Type</th>
                <th>Sensor ID</th>
                <th>Updated</th>
                <th>Version</th>
                <th>Connected</th>
            </tr>
        </thead>
        <tbody>
            {sensorList}
        </tbody>
    </table>
    </>
}

export default SensorTable;