import ToggleButton from "../../../../UiComponents/ToggleButton";
import { InfoText, StepContainer, StepHeader, ToggleContainer } from "./CalibrationStyles";

const CalibrationInfoStep = props => {
    return <StepContainer>
        <StepHeader>Zone calibration</StepHeader>
        <InfoText>
            Calibration should be performed when your venue is free from guests and other naturally changing sound sources.
            Before calibrating, make sure that the music you usually play is turned on, and that your audio system is set to the correct settings.
            Turn off regulation in the zone before calibrating.
        </InfoText>

        <ToggleContainer>
            <label>Set zone volume while calibrating</label>
            <ToggleButton checked={props.liveVolumeChanges} onToggle={(newVal) => props.setLiveVolumeChanges(newVal)} />
        </ToggleContainer>

    </StepContainer>
}

export default CalibrationInfoStep;