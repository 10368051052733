
import colormap from 'colormap';

var customColorMap;
try {
    const getColorMap = require('../../../UI/colormap');
    customColorMap = getColorMap();
} catch (e) {
    /* Gradient tool: https://colordesigner.io/gradient-generator */
    customColorMap = ['#aadb23','#a6d623','#a2d123','#9ecb22','#9ac622','#96c122','#92bc22','#8eb722','#8ab122','#81a721','#7da221','#799d21','#759721','#6d8d20','#698820','#658320','#617d20','#5d7820','#556e1f','#51681f','#4d631f','#495e1f','#45591f','#3d4e1e','#39491e','#35441e','#303f1e','#28341d','#242f1d','#202a1d','#1c251d','#18201d','#141a1c','#10151c','#0c101c'];
}


const dataParams = {
    maxVol: 80,
    minVol: 35
}

const colorMapStart = 0;

/* const dataColors = colormap({
    colormap: 'YIOrRd',
    nshades: Math.round((dataParams.maxVol - dataParams.minVol)+colorMapStart),
    format: 'hex',
    alpha: 1
}).reverse().slice(colorMapStart, dataParams.maxVol - dataParams.minVol+colorMapStart); */

const dataColors = customColorMap.reverse();


const zoneColors = ['#00BFFF', '#35C191', '#F06F9E', '#F9F871', '#8177CB', '#DFA01E'];

// Exported functions
function getDataColor(data) {
    var colorIndex = Math.round(data) - dataParams.minVol;
    if (colorIndex < 0) {
        colorIndex = 0;
    } else if (colorIndex > dataColors.length - 1) {
        colorIndex = dataColors.length - 1;
    }
    return dataColors[colorIndex];
}

function getZoneColor(currentZone, zones) {
    var currentZoneIndex = zones.findIndex(zone => zone.floorElementId === currentZone.floorElementId) % zoneColors.length;
    var zoneColor = zoneColors[currentZoneIndex || 0];
    return zoneColor;
}

function getDataColors() {
    return dataColors;
}

function getDataParams() {
    return dataParams;
}


export {
    getDataColor,
    getZoneColor,
    getDataColors,
    getDataParams
}