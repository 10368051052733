import styled from 'styled-components';
import theme from '../../../../UI/theme';
import InnerCard from '../../../UiComponents/containers/InnerCard';

export const DeviceContainer = styled(InnerCard)`
  padding: 10px 10px;
`

export const DeviceList = styled.ul`
    list-style: none;
    padding: 0;
    li {
        margin-bottom: 12px;
    }
`

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${theme.fontSize.small};
    padding: 0 10px 10px 10px;
`

export const SectionHeader = styled.h4`
    margin: 0 0 10px 0;
`

export const DetailsRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const DetailsParagraph = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0;
`

export const WarningParagraph = styled.div`
    color: ${theme.colors.mellowYellow};
    margin: 10px 0 0 0;
`

export const MT10Container = styled.div`
    margin-top: 10px;
`

export const DeviceIconContainer = styled.div`
    margin-right: 8px;
`

export const ButtonRowContainer = styled(MT10Container)`
    margin: 0;
    button {
        margin: 10px 10px 0 0;
    }    
`

export const DeviceStyleProvider = styled.div`
.sensor-list-wrapper {
    max-width: 500px;
    margin: auto;
  }
  
  .detail-container {
    padding: 0px 16px;
    transition: 0.3s linear;
  }
  
  .sensor-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .sensor-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  
  .sensor-left {
    display: flex;
    align-items: center;
  }
  
  .sensor-icon {
    margin: 8px;
    margin-right: 20px;
  }
  
  .sensor-text {
    margin-right: 20px;
  }
  
  .text-muted {
    margin-top: 5px;
    font-size: $font-size-small;
    color: $gray-400;
    font-weight: 500;
  }
  
  .text-caution {
    margin-top: 5px;
    font-size: $font-size-small;
    color: $gray-600;
    font-weight: 500;
  }
  .zone-container {
    padding: 5px 0;
    h4 {
      margin: 0;
    }
  }

  .divider {
    margin: 24px 10px;
    border-bottom: 2px solid $gray-500;
  }

  .sensor-summary-container {
    display: flex;
    min-height: 50px;
    //align-items: center;
    font-weight: 600;
    padding: 12px 10px;
    background-color: $gray-700;
    border-radius: 12px;
    margin: 10px 0;
  }
  
  .sensor-summary-container.single-sensor-container {
    background-color: $gray-700;
  }
  
  .sensor-summary-container > div:first-child {
    margin-right: 12px;
    color: $waved-accent;
  }
  
  .show-more-btn {
    margin-top: 5px;
    color: $gray-400;
    font-size: $font-size-small;
  }
  
  .show-more-btn:hover {
    color: $gray-500;
    cursor: pointer;
  }

  .sensor-zone-list {
  
    span {
      margin: 4px 0;
      display: inline-block;
      /* border-left: 4px solid transparent; */
    }
  
    .active span {
      /* border-left: 4px solid ${theme.colors.greenEnergy}; */
      color: ${theme.colors.greenEnergy};
      background-color: $gray-600;
    }
    
    span:hover {
      //background-color: $gray-500;
      background-color: ${theme.colors.grayHover};
    }
    
  }
  
  .btn.add-zone-btn {
    color: white;
    span:hover{
      text-decoration: underline;
    }
  }
  
  .more-btn {
    height: 30px;
    border: none;
    border-radius: 20px;
    background-color: $gray-700;
    color: white;
    margin-right: 10px;
    :hover {
      cursor: pointer;
    }
    i {
      font-size: 1.5em;
    }
  }

  @media only screen and (max-width: 400px) {
    .xs-hidden {
      display: none;
    }
  }
  
  .card-section-title {
    color: $gray-200;
    font-size: $font-size-medium;
    margin-bottom: 10px;
  }

  .btn {
    padding: 12px 16px;
    border-radius: 4px;
    font-size: $font-size-small;
    font-weight: 500;
    margin: 5px 10px;
  }
  
  .btn:hover {
    cursor: pointer;
  }
  
  .btn.btn-small {
    padding: 6px 8px;
  }
  
  .btn-primary {
    background-color: $waved-accent;
    border: none;
    color: $waved-primary;
  }
  
  .btn.btn-primary:disabled {
    opacity: 0.7;
  }
  
  .btn-primary:hover {
    background-color: $waved-accent-dark;
  }
  
  .btn-secondary {
    background-color: #ddd;
    color: $gray-800;
    border: none;
  }
  
  .btn-secondary:hover {
    background-color: $gray-400;
  }
  
  .btn-center-container {
    text-align: center;
  }
  
  .btn-tertiary {
    background: none;
    color: $waved-accent;
    border: none;
    outline: none;
  }
  
  .btn-tertiary:hover {
    text-decoration: underline;
  }

  .flex {display: flex;}

  .space-between {justify-content: space-between;}
  .justify-center {justify-content: center;}

  .center-align {align-items: center;}
  .baseline-align {align-items: baseline;}

  .flex-wrap {flex-wrap: wrap;}

  .py6 {padding: 6px 0px;}
  .py12 {padding: 12px 0px;}
  .py20 {padding: 20px 0px;}

  .margin-zero {margin: 0;}

  .text-right {text-align: right;}
  .break-word {word-break: break-word;}
  .bold {font-weight: bold;}
  .btn.btn-tertiary.white {color: white;}
  .btn.btn-tertiary.no-padding {padding: 0;}
  .btn.margin-left-zero {margin-left: 0}

  .indicator-dot div{
    width: 22px;
    height: 22px;
    border-radius: 50%;
    //background-color: #74ce5f;
    margin: 16px;
  }
  
  .indicator-dot.small div {
    width: 10px;
    height: 10px;
    margin: 6px;
  }
  
  .indicator-dot.small.left-align div{
    margin: 0;
    margin-right: 10px; 
  }

  .card {
    padding: 25px;
    border-radius: 15px;
    color: white;
    background-image: linear-gradient(25deg, $gradient-dark, $gradient-light);
    //box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }
  
  .card.center-card {
    padding: 50px;
    text-align: center;
  }

  .profile-field {
    display: block;
    padding-top: 6px;
    font-weight: 700;
  }

  .waved-input-field {
    font-size: $font-size-small;
    border: none;
    padding: 10px 10px;
    margin: 5px 0;
    border-radius: 6px;
    background-color: $gray-200;
    color: $gray-900;
    width: 200px;
  }
  
  .waved-input-field.wide {
    width: 90%;
  }
  
  .input-field-label.dark {
    color: $gray-700;
  }
  
  input:focus{
    box-shadow: 0 3px 6px rgba(0,0,0,.16);
    outline: none;
  }
  
  .input-field-label {
    //font-size: $font-size-small;
    color: $gray-300;
  }
  
  .input-field-container {
    padding-bottom: 30px;
    margin-right: 20px;
  }
`