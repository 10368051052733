import styled from "styled-components"
import theme from "../../../../UI/theme"
import Card from "../../../UiComponents/Card"
import { readOnlyColor } from "../../fields/Styles"

export const StyledTable = styled.table`
    min-width: 100%;
    table-layout: auto;
    border-collapse: collapse;
    font-size: 14px;

    th, td {
        border: 2px solid ${props => props.borderColor || theme.colors.darkSpace90};
        border-collapse: collapse;
    } 
`

export const HeaderRow = styled.tr`
    background-color: ${props => props.backgroundColor || theme.colors.darkSpace90};
    color: ${readOnlyColor};
    text-align: left;

    >th {
        padding: 4px;
        font-weight: normal;
    }
`

export const Placeholder = styled.div`
    padding: 12px;
    border: 2px solid ${theme.colors.darkSpace90};
    border-radius: 4px;
    color: ${theme.colors.textGray};
`