import styled from "styled-components";
import PanelButton from "./PanelButton";
import theme from "../../UI/theme";
import WavedIcon from "../UiComponents/Icons/WavedIcon";
import WaWaved from "../UiComponents/Icons/WaWaved";

const StyledPanelButton = styled(PanelButton)`
    visibility: ${props => props.visibility ? props.visibility : ''};
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 0.65rem;
    font-weight: 100;
    line-height: 0.65rem;

    > label {
        cursor: inherit;
    }

    > svg {
        width: 1.25rem;
        height: 1.25rem;
    }

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        width: 40px;
        height: 40px;
    }
`

const RegulationButton = props => {
    return <StyledPanelButton {...props}>
        <WaWaved
            width={"100%"}
            stroke={props.deactivated ? theme.colors.darkSpace50 : theme.colors.greenEnergy}
        />
        {props.children}
    </StyledPanelButton>
}

export default RegulationButton;