import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import theme from '../../UI/theme';

const popupAnimation = keyframes`
    0% {transform: translateY(20px);}
    100% {transform: translateY(0);}
`

const StyledModalOverlay = styled.div`
    background-color:#4e4e4ea1;
    height: 100vh;
    max-height: 70vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 110;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 15vh 0;
`

const StyledModalContainer = styled.div`
    background-color: ${theme.colors.darkSpace};
    font-size: 1rem;
    color: white;
    border-radius: 4px;
    padding: 20px;
    z-index: 20;
    min-width: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    animation: ${popupAnimation} 0.2s;
    margin: auto;
    height: fit-content;
`

const Modal = props => {

    function handleClick(ev) {
        if (ev.target.id === 'modal-overlay') {
            ev.stopPropagation();
            props.close();
        }
    }

    if (props.show) {
        return (
            <StyledModalOverlay id="modal-overlay" onClick={handleClick}>
                <StyledModalContainer onClick={e => e.stopPropagation()}>
                    {props.children}
                </StyledModalContainer>
            </StyledModalOverlay>
        );
    } else {
        return <></>
    }
}

export default Modal;

export const ModalContent = styled.div`
    text-align: center;
    >button {
        margin: 6px;
    }
    max-width: 30em;
`

export const FixedWidthModalContent = styled.div`

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        width: 100%;
    }
    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        width: ${props => props.width || '40em'};
    }
`