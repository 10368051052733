import theme from '../UI/theme';

const emailRegEx = new RegExp("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}");
const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const daysShort = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const getUuid = () => {
    return 'xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

const isMobile = () => {
    return window.innerWidth <= theme.screenSizes.small;
}

const modulo = (n, m) => {
    return ((n % m) + m) % m;
}

const getDayOfWeek = (date) => {
    var dow = modulo(date.getDay() - 1, 7)
    return dow;
}

const getStartOfWeek = (week, year = (new Date().getFullYear())) => {
    var week1Start = new Date(year, 0, 0);
    var weekStart = new Date(week1Start);
    while (weekStart.getDay() != 1) {
        weekStart.setDate(weekStart.getDate() - 1);
    }
    weekStart.setDate(weekStart.getDate() + week * 7);
    return weekStart;
}

const getWeekForDate = (date) => {
    var dateCopy = new Date(date);
    dateCopy.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    dateCopy.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(dateCopy.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((dateCopy.getTime() - week1.getTime()) / 86400000
        - 3 + (week1.getDay() + 6) % 7) / 7);
}

function getDeviceNumber(device) {
    var number;
    if (device?.ipAddress && device?.ipAddress?.includes('.')) {
        var splitIP = device.ipAddress.split('.');
        var number = splitIP[splitIP.length - 1];
    } else if (device?.sensorId) {
        number = device.sensorId.slice(device.sensorId.length - 3, device.sensorId.length);
    } else if (device?.hubId) {
        number = device.hubId.slice(device.hubId.length - 3, device.hubId.length);
    }
    return number;
}

const toLocalISOString = (date) => {
    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = (new Date(date - tzoffset)).toISOString().slice(0, -1);
    return localISOTime;
}

const replaceZeroValues = (array) => {
    if (array && array.length && array.every(num => !isNaN(num))) {
        return array.map(val => {
            if (val > 0) {
                return val;
            } else {
                return 'N/A';
            }
        });
    } else {
        return array;
    }
}

const toDisplayTime = (timeString) => {
    if (timeString?.length === 4) {
        return `${timeString.substring(0, 2)}:${timeString.substring(2)}`;
    } else {
        return timeString;
    }
}

const isSameDay = (d1, d2) => {
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
}

function formatDateTimeLabel(dt) {
    var mins = (dt.getMinutes() < 10) ? '0' + dt.getMinutes() : dt.getMinutes();
    var hours = (dt.getHours() < 10) ? '0' + dt.getHours() : dt.getHours();
    return `${hours}:${mins}`;
}

function sliceUrlAfter(url, key) {
    var index = url.indexOf(key);
    return url.slice(0, index);
}

function sortByKey(array, key) {
    let type = array.length ? typeof array[0][key] : null;
    array.sort((a, b) => {
        var nameA = a[key];
        var nameB = b[key];
        if (type === 'string') {
            nameA = nameA.toLowerCase();
            nameB = nameB.toLowerCase();
        }
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    })
    return array;
}

function simpleMovingAverage(values, windowLength = 5) {
    if (!Array.isArray(values) || values.length < windowLength) {
        return values;
    }

    let index = windowLength - 1;
    const length = values.length + 1;
    const simpleMovingAverages = [];

    while (++index < length) {
        const windowSlice = values.slice(index - windowLength, index);
        const sum = windowSlice.reduce((prev, curr) => prev + curr, 0);
        simpleMovingAverages.push(sum / windowLength);
    }

    return simpleMovingAverages;
}

async function wait(timeMs) {
    return new Promise((resolve) => setTimeout(resolve, timeMs));
}

function timeSince(date) {

    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
}


export {
    emailRegEx,
    days,
    daysShort,
    getUuid,
    modulo,
    getDayOfWeek,
    getStartOfWeek,
    getWeekForDate,
    isMobile,
    getDeviceNumber,
    toLocalISOString,
    replaceZeroValues,
    toDisplayTime,
    isSameDay,
    formatDateTimeLabel,
    sliceUrlAfter,
    sortByKey,
    simpleMovingAverage,
    wait,
    timeSince
}