import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../../UI/theme';
import Button from '../../UiComponents/Button';
import InputField from '../../UiComponents/InputField';
import IconLogo from '../../UiComponents/IconLogo';
import { AiFillEye } from 'react-icons/ai';
import { AiFillEyeInvisible } from 'react-icons/ai';
import SetupSoundtrackYourBrandPlayer from './SetupSoundtrackYourBrandPlayer';
import soundtrackYourBrandLogo from '../logos/soundtrackYourBrand-logo-white.png';
import { checkAvailablePlayers } from '../providers/providerUtils';

const StyledButton = styled(Button)`
    max-width: 150px;
    margin: 20px 0;
`
const StyledInputField = styled(InputField)`
    text-align: center;
`
const PassWrapper = styled.div`
    position: relative;
    display: flex;
    margin-bottom: 14px;
    color: ${theme.colors.darkSpace50};
`
const I = styled.i`
    position: absolute;
    top: 30%;
    right: 4%;
`
const StyledIconLogo = styled(IconLogo)`
    margin: 30px 0;
`
const CenteredText = styled.div`
    text-align: center;
    margin: 10px;
`
const ContentLogo = styled.img`
    width: 200px;
    margin: 10px 10px;
    opacity: ${props => props.comming ? '0.2' : '1'};
`

const SoundtrackYourBrandSetup = (props) => {

    const [setupState, setSetupState] = useState('GET_CREDENTIALS');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [sybCustomerInfo, setSybCustomerInfo] = useState([]);

    const getPlayers = async () => {
        setSetupState('GETTING_DATA');
        setShowPassword(false);
        var success = false;
        setTimeout(() => {
            if (!success) {
                setSetupState('FAILED');
            }
        }, 10000);
        
        const credentials = {
            email: username,
            password: password
        }
        const availablePlayers = await checkAvailablePlayers("SoundtrackYourBrand", credentials);
        if (availablePlayers) {
            success = true;
            setSybCustomerInfo(availablePlayers);
            setSetupState('GOT_DATA');
        }
    }

    const tryAgain = () => {
        setUsername('');
        setPassword('');
        setSetupState('GET_CREDENTIALS');
    }

    return (<>
        {setupState === 'GET_CREDENTIALS' ? <>
            <ContentLogo
                src={soundtrackYourBrandLogo}
                alt="Soundtrack your brand"
            />
            <h4>Please provide your login details</h4>
            <p>Username</p>
            <StyledInputField
                placeholder='your user name'
                value={username}
                onChange={e => setUsername(e.target.value)}
            />
            <p>Password</p>
            <PassWrapper>
                <StyledInputField
                    type={showPassword ? "text" : "password"}
                    placeholder="your password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                {showPassword ?
                    <I><AiFillEyeInvisible fontSize="18" onClick={() => setShowPassword(!showPassword)} /></I>
                    :
                    <I><AiFillEye fontSize="18" onClick={() => setShowPassword(!showPassword)} /></I>
                }
            </PassWrapper>
            <br></br>
            <Button primary onClick={getPlayers}>Start Setup</Button>
        </> : <></>}
        {setupState === 'GETTING_DATA' ? <>
            <h3>... Connecting ...</h3>
            <CenteredText>this may take up to 10 seconds</CenteredText>
            <StyledIconLogo preloader ></StyledIconLogo>
        </> : <></>}
        {setupState === 'GOT_DATA' ? <>
            <h4>Add relevant music players</h4>
            {sybCustomerInfo.zones.map((zoneInfo) =>
                <SetupSoundtrackYourBrandPlayer
                    customerId={props.customerId}
                    zoneInfo={zoneInfo}
                    sources={props.sources}
                    sybCustomerInfo={sybCustomerInfo}
                />
            )}
            <Button primary onClick={props.endSetup}>Done</Button>
        </> : <></>}
        {setupState === 'COMPLETE' ? <>
            <h4>All good!</h4>
            <Button primary onClick={() => { setSetupState('GET_CREDENTIALS'); props.setShowModal(false) }}>Close</Button>
        </> : <></>}
        {setupState === 'FAILED' ? <>
            <h4>Failed to set up account</h4>
            <CenteredText>There seems to be a problem connecting to your content provider.</CenteredText>
            <CenteredText>Please check your username and password and try again</CenteredText>
            <br></br>
            <CenteredText>If the problem persists please contact us at support@waved.no</CenteredText>
            <StyledButton primary onClick={tryAgain}>Try Again</StyledButton>
        </> : <></>}
    </>)

}

export default SoundtrackYourBrandSetup;