import React from 'react';
import styled from 'styled-components';
import { postEvent } from '../../../scripts/event';
import { postUserInteraction } from '../../../scripts/userInteraction';
import { userInteractionTypes } from '../../admin/customerDetails/userInteractionTypes';
import Button from '../../UiComponents/Button';
import Card from '../../UiComponents/Card';
import HeaderContainer from '../../UiComponents/containers/HeaderContainer';
import { ZoneHeader } from '../zone/StyledComponents/ZoneItemStyles';

const SourceButton = styled(Button)`
    margin: 0 8px 8px 0;
`

const SourceSelectorItem = props => {

    const setActiveSource = async (sourceId) => {
        try {
            const pickedSourceSelector = (({ sourceSelectorId, name, processorId }) => ({ sourceSelectorId, name, processorId }))(props.sourceSelector);
            const oldSource = props.sources?.find(source => source.sourceId === props.sourceSelector?.activeSourceId);
            await props.setSourceSelectorAttributes(props.sourceSelector.sourceSelectorId, { activeSourceId: sourceId }, true);
            await postEvent(props.processor.hubId, props.customerId, 'SET_SOURCE', { sourceSelectorId: props.sourceSelector.sourceSelectorId, sourceId: sourceId });
            await postUserInteraction(props.customerId, {
                setting: userInteractionTypes.source.key,
                payload: {
                    from: {
                        ...pickedSourceSelector,
                        activeSourceId: oldSource?.sourceId ?? null,
                        activeSourceDisplayName: oldSource?.name ?? 'Unknown'
                    },
                    to: {
                        ...pickedSourceSelector,
                        activeSourceId: sourceId,
                        activeSourceDisplayName: props.sources?.find(source => source.sourceId === sourceId)?.name ?? 'Unknown'
                    }
                }
            })
        } catch (err) {

        }
    }

    return <Card>
        <HeaderContainer>
            <ZoneHeader>
                {props.sourceSelector.name}
            </ZoneHeader>
        </HeaderContainer>

        {props.sources?.map(source => {
            return <SourceButton
                key={source.sourceId}
                primary={props.sourceSelector.activeSourceId === source.sourceId}
                secondary={props.sourceSelector.activeSourceId !== source.sourceId}
                onClick={() => setActiveSource(source.sourceId)}
            >
                {source.name}
            </SourceButton>
        })}

        {!props.sources?.map(source => source.sourceId).includes(props.sourceSelector.activeSourceId) ? <>
            <SourceButton
                primary
            >
                Unknown {props.sourceSelector.activeSourceId}
            </SourceButton>
        </> : <></>}

    </Card>
}

export default SourceSelectorItem;
