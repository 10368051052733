import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../../../../UI/theme';
import Button from '../../../../UiComponents/Button';
import { TextMuted } from '../../../../UiComponents/text/StyledText';
import { MT10Container } from '../DeviceStyles';
import NetworkConfig from './NetworkConfig';

const StyledButton = styled(Button)`
    font-size: 0.9em;
    color: ${theme.colors.darkSpace20};
`

const NetworkInfo = props => {
    const [showAdvanced, setShowAdvanced] = useState(false);

    return <>
        <div>
            <span>Internet connection</span>
        </div>

        {props.hub.connectionType && <TextMuted>Connection type: {props.hub.connectionType}</TextMuted>}
        <br />
        <TextMuted> IP: {props.hub.ipAddress}</TextMuted>

        <MT10Container>
            <StyledButton tertiary small lightBorder onClick={() => setShowAdvanced(!showAdvanced)}>{showAdvanced ? 'Hide advanced' : 'Show advanced'}</StyledButton>
        </MT10Container>


        {showAdvanced ? <>
            <MT10Container>
                <NetworkConfig {...props} />
            </MT10Container>
        </> : <></>}

    </>
}

export default NetworkInfo;