import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { getZones } from '../../../scripts/zone';
import { getSensors } from '../../../scripts/sensor';
import ViewHeader from '../../UiComponents/ViewHeader';
import TopNavigation from '../../navigation/TopNavigation';
import VenueInfo from './VenueInfo';
import ZoneSettings from './zone/ZoneSettings';
import DeviceSettingsOverview from './device/DeviceSettingsOverview';
import styled from 'styled-components';
import UserContext from '../../../hooks/UserContext';
import ScheduleSettingsOverview from './schedule/ScheduleSettingsOverview';
import { getCustomer, getOpeningHours } from '../../../scripts/datasource';
import AutoCalibrationSettingsOverview from './calibration/AutoCalibrationSettingsOverview';
import ContentSettingsOverview from '../../musicPlayer/ContentSettingsOverview';
import { getHubs } from '../../../scripts/hub';
import InstallationSettingsOverview from './installation/InstallationSettingsOverview';

const Section = styled.div`
    margin-bottom: 20px;
`

const VenueSettings = props => {
    let { id } = useParams();
    const [hubs, setHubs] = useState();
    const [sensors, setSensors] = useState();
    const [zones, setZones] = useState();
    const [venue, setVenue] = useState();
    const [openingHours, setOpeningHours] = useState();
    const user = useContext(UserContext);

    const readOnly = user.isImpersonator && user.isImpersonating;

    useEffect(() => {
        getDataHandler();
    }, []);

    const getDataHandler = () => {
        getVenueHandler();
        getHubsHandler();
        getZonesHandler();
        getSensorsHandler();
        getOpeningHoursHandler();
    }

    const getVenueHandler = async () => {
        var venueRes = await getCustomer(id);
        if (venueRes) {
            setVenue(venueRes);
        }
    }

    const getHubsHandler = async () => {
        var venueHubs = await getHubs(id);
        if (venueHubs) {
            setHubs(venueHubs);
        }
    }

    const getZonesHandler = async () => {
        var venueZones = await getZones(id);
        if (venueZones) {
            setZones(venueZones);
        }
    }

    const getSensorsHandler = async () => {
        var venueSensors = await getSensors(id);
        if (venueSensors) {
            setSensors(venueSensors);
        }
    }

    const getOpeningHoursHandler = async () => {
        var venueOpeningHours = await getOpeningHours(id);
        if (venueOpeningHours.length) {
            setOpeningHours(venueOpeningHours);
        }
    }

    const refreshVenueInfo = async () => {
        getVenueHandler();
        getOpeningHoursHandler();
    }


    return <div>

        <ViewHeader>
            <TopNavigation to={'/settings'} />
            <h3>Venue Settings</h3>
        </ViewHeader>

        {venue && venue.customerId ? <>

            <Section>
                <VenueInfo
                    venue={venue}
                    setVenue={setVenue}
                    readOnly={readOnly}
                    openingHours={openingHours}
                    refresh={refreshVenueInfo}
                />
            </Section>

            <Section>
                <ScheduleSettingsOverview />
            </Section>

            {(user.user.role === "admin") ?
                <Section>
                    <ContentSettingsOverview />
                </Section>
                : <></>}

            <Section>
                {zones ? <>
                    <ZoneSettings
                        customerId={venue.customerId}
                        zones={zones}
                        setZones={setZones}
                        readOnly={readOnly}
                    />
                </> : <></>}
            </Section>

            <Section>
                {hubs ? <>
                    <DeviceSettingsOverview hubs={hubs} zones={zones} sensors={sensors} />
                </> : <></>}
            </Section>

            {user.user.role === 'admin' || process.env.REACT_APP_HOST_TYPE === 'HUB' ? <>
                <Section>
                    <AutoCalibrationSettingsOverview />
                </Section>
            </> : <></>}

            {user.isAdmin || user.isImpersonator || process.env.REACT_APP_HOST_TYPE === 'HUB' ? <>
                <Section>
                    <InstallationSettingsOverview />
                </Section>
            </> : <></>}

        </> : <></>}
    </div>
}

export default VenueSettings;