import React from 'react';
import { useState } from 'react';
import Card from '../UiComponents/Card';
import CustomerSetup from './CustomerSetup';
import { networkInterfaces } from './networkInterfaces';
import NetworkSetup from './NetworkSetup';
import setupSteps from './setupSteps';

const LocalSetupView = props => {
    const [setupStep, setSetupStep] = useState(setupSteps.NETWORK_SETUP);
    const [networkInterface, setNetworkInterface] = useState(networkInterfaces.ETHERNET);
    const [networkConfig, setNetworkConfig] = useState();

    return <Card>

        {setupStep === setupSteps.NETWORK_SETUP ?
            <NetworkSetup
                setSetupStep={setSetupStep}
                networkInterface={networkInterface}
                setNetworkInterface={setNetworkInterface}
                networkConfig={networkConfig}
                setNetworkConfig={setNetworkConfig}
            />
            : <></>}
        {setupStep === setupSteps.CUSTOMER_SETUP ?
            <CustomerSetup
                setSetupStep={setSetupStep}
                networkInterface={networkInterface}
                networkConfig={networkConfig}
            />
            : <></>}

    </Card>
}

export default LocalSetupView;