import { getUuid } from "../../scripts/common";
import { getHostname } from "../../scripts/server/server";

const hostname = getHostname();
const protocol = hostname.includes('https') ? 'wss' : 'ws';
const webSocketUrl = `${protocol}://${hostname.split('://')[1]}`;

class Socket {
    constructor(serverConnection) {
        this.serverConnection = serverConnection;
        this.messageQueue = [];
    }

    connect() {
        return new Promise((resolve, reject) => {
            try {
                console.log('Connecting to websocket');
                const socket = new WebSocket(webSocketUrl);

                let timeout = setTimeout(() => {
                    resolve(0);
                }, 1000);

                socket.onopen = (event) => {
                    this.serverConnection.socketCallback('open');
                    clearTimeout(timeout);
                    resolve(1);
                };

                socket.onmessage = (message) => {
                    const data = JSON.parse(message.data);
                    if (data.messageId) {
                        const handled = this.handleSyncMessage(data);
                        if (handled) {
                            return;
                        }
                    }

                    if (data.messageType === 'UPDATE') {
                        this.serverConnection.socketCallback('update', data.payload);
                    } else if (data.messageType === 'EVENT_RESPONSE') {
                        this.serverConnection.socketCallback('event_response', data.payload);
                    }
                };

                socket.onclose = (event) => {
                    this.serverConnection.socketCallback('close');
                }

                this.socket = socket;
            } catch (err) {
                console.log('Error - websocket connection failed', err);
                reject();
            }


        })
    }

    isReady() {
        return this.socket?.readyState === 1;
    }

    sendMessage(payload) {
        this.socket.send(JSON.stringify({ payload }));
    }

    sendMessageSync(payload) {
        return new Promise((resolve, reject) => {
            const messageId = getUuid();
            const message = {
                payload,
                messageId
            };
            if (this.isReady()) {
                this.socket.send(JSON.stringify(message));
                this.messageQueue.push({ messageId, resolve });
    
                setTimeout(() => {
                    this.removeFromMessageQueue(messageId);
                    reject();
                }, 1000);
            } else {
                reject();
            }
        })
    }

    handleSyncMessage(message) {
        let handled = false;
        const queueItem = this.messageQueue.find(item => item.messageId === message.messageId);
        if (queueItem) {
            this.removeFromMessageQueue(message.messageId);;
            queueItem.resolve(message);
            handled = true;
        }
        return handled;
    }

    removeFromMessageQueue(messageId) {
        this.messageQueue = this.messageQueue.filter(item => item.messageId !== messageId);
    }

    async isReachable() {
        if (this.isReady()) {
            try {
                const res = await this.sendMessageSync({type: 'PING'});
                return true;
            } catch (err) {
                return false;
            }
        } else {
            return false;
        }
    }
}


export default Socket;