import { postEvent } from "../../../scripts/event";
import { addProcessor, updateProcessor } from "../../../scripts/processor";
import { processorTypes } from "../../settings/venue/device/AudioSystem/processorTypes";
import { addNewSource } from "../Source/sourceUtils";
import { addNewSourceSelector } from "../SourceSelector/sourceSelectorUtils";
import { saveObjects } from "../tableUtils";
import { addNewZone } from "../Zone/zoneUtils";

const saveUpdatedProcessors = async (changedProcessors, processors, customerId) => {
    prepareForSave(changedProcessors, processors);
    const uniqueHubIds = getUniqueHubIds(changedProcessors, processors);
    await saveObjects(changedProcessors, uniqueHubIds, customerId, 'UPDATE_PROCESSOR',
        (customerId, processor) => updateProcessor(customerId, processor)
    )
}

const addNewProcessor = async (processor, customerId, submitProps) => {
    try {
        const newProcessor = {...processor, customerId};
        const addedProcessor = await addProcessor(customerId, newProcessor);
        await postEvent(processor.hubId, customerId, 'UPDATE_PROCESSOR');
        await handleOnSubmitProps(addedProcessor, customerId, submitProps);
    } catch (err) {
        console.log('Failed to add new processor', err);
    }
}

const handleOnSubmitProps = async (processor, customerId, submitProps) => {
    const promises = [];
    if (submitProps.addPredefinedConfig) {
        const processorType = processorTypes[processor.type];
        const predefinedConfig = processorType?.predefinedConfig;
        if (predefinedConfig) {
            predefinedConfig.sources?.forEach(predefinedSource => {
                const source = {...predefinedSource, processorId: processor.processorId};
                const promise = addNewSource(source, [processor], customerId);
                promises.push(promise);
            })

            predefinedConfig.sourceSelectors?.forEach(predefinedSourceSelector => {
                const sourceSelector = {...predefinedSourceSelector, processorId: processor.processorId};
                const promise = addNewSourceSelector(sourceSelector, [], [processor], customerId);
                promises.push(promise);
            })

            predefinedConfig.zones?.forEach(predefinedZone => {
                const zone = {...predefinedZone, processorId: processor.processorId, hubId: processor.hubId};
                const promise = addNewZone(zone, [], [processor], customerId);
                promises.push(promise);
            })
        }
    }

    await Promise.all(promises);
}

export {
    saveUpdatedProcessors,
    addNewProcessor
}

const getUniqueHubIds = (changedProcessors, processors) => {
    const uniqueHubIds = [];
    changedProcessors.forEach(changedProcessor => {
        const processor = processors.find(processor => processor.processorId === changedProcessor.processorId);
        if (processor && !uniqueHubIds.includes(processor.hubId)) {
            uniqueHubIds.push(processor.hubId);
        }
    })
    return uniqueHubIds;
};


const prepareForSave = (changedProcessors, processors) => {
    changedProcessors.forEach(changedProcessor => {
        const processor = processors.find(processor => processor.processorId === changedProcessor.processorId);
        if (processor) {
            changedProcessor.hubId = processor.hubId;
        }
    })
}