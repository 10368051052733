import React, { useState, useEffect } from 'react'
import NewZoneCreator from './NewZoneCreator';
import { deleteSensor, updateSensors } from '../../../../scripts/sensor';
import Modal from '../../../UiComponents/Modal';
import ZoneComponent from './ZoneComponent';
import Button from '../../../UiComponents/Button';
import { deleteZone, updateZones } from '../../../../scripts/zone';
import { DeviceList } from './DeviceStyles';
import SensorItem from './SensorItem'
import Divider from '../../../UiComponents/Divider';
import { postEvent } from '../../../../scripts/event';

const ZoneOverview = (props) => {
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [editedSensors, setEditedSensors] = useState([]);
    const [editedZones, setEditedZones] = useState([]);
    const [editState, setEditState] = useState(false);
    const [updateState, setUpdateState] = useState('NONE');

    useEffect(() => {

        if (!editState) {
            // Zones and sensors are updated by parent if they're not in editing state
            setEditedSensors([...props.sensors]);
            setEditedZones([...props.zones]);
        }
    }, [props.zones, props.sensors]);

    const setEditStateHandler = (editState) => {
        if (editState) {
            // copy last saved sensors and zones and store in separate state 
            // probably not necessary to check out parent states here
            var currentSensors = [...props.sensors];
            var currentZones = [...props.zones];
            setEditedSensors(currentSensors);
            setEditedZones(currentZones);
        }
        setEditState(editState);
    }

    function updateZoneHandler(zoneId, field, value) {
        var updatedZones = [...editedZones];
        var updatedZone = updatedZones.find(zone => zone.zoneId === zoneId);
        if (updatedZone) {
            updatedZone[field] = value;
            if (!updatedZone.updatedFields) { updatedZone.updatedFields = [] };
            if (updatedZone.updatedFields.indexOf(field) == -1) { updatedZone.updatedFields.push(field) }
            setEditedZones(updatedZones);
            if (updateState != 'UPDATED') {
                setUpdateState('UPDATED');
            }
        }
    }

    function updateSensorHandler(sensorId, field, value) {
        var updatedSensors = [...editedSensors];
        var updatedSensor = updatedSensors.find(sensor => sensor.sensorId == sensorId);
        if (updatedSensor) {
            updatedSensor[field] = value;
            if (!updatedSensor.updatedFields) { updatedSensor.updatedFields = [] };
            if (updatedSensor.updatedFields.indexOf(field) == -1) { updatedSensor.updatedFields.push(field) }
            updatedSensor.updatedFields.push(field);
            setEditedSensors(updatedSensors);
            if (updateState != 'UPDATED') {
                setUpdateState('UPDATED');
            }
        }
    }

    async function deleteSensorHandler(sensorId) {
        let oldEditedSensors = JSON.parse(JSON.stringify(editedSensors));
        let newEditedSensors = [...editedSensors].filter(sensor => sensor.sensorId !== sensorId);
        try {
            setEditedSensors(newEditedSensors);
            await deleteSensor(props.customerId, props.hub.hubId, sensorId);
            await postEvent(props.hub.hubId, props.customerId, 'DELETE_SENSOR', { sensorId: sensorId });
        } catch (error) {
            setEditedSensors(oldEditedSensors);
        }
    }

    async function saveHandler() {
        setShowSaveModal(false);
        try {
            await updateZones(props.customerId, editedZones, true);
            await updateSensors(props.customerId, editedSensors);
            await postEvent(props.hub.hubId, props.customerId, 'CONFIGURE_ZONES');
            setEditStateHandler(false);
            setUpdateState('SUCCESS');
            setTimeout(() => {
                setUpdateState('NONE');
            }, [1000]);
        } catch (error) {
            setUpdateState('FAILED');
        } finally {
            await props.refreshDevices();
        }
    }

    function cancelHandler() {

        setEditedZones(props.zones);
        setEditedSensors(props.sensors);

        setEditStateHandler(false);
        setShowCancelModal(false);
        setUpdateState('NONE');
    }

    function showCancelModalHandler() {
        if (updateState == 'UPDATED') {
            setShowCancelModal(true);
        } else {
            cancelHandler();
        }
    }

    async function deleteZoneHandler(zoneId) {
        try {
            await deleteZone(props.customerId, zoneId);
            await postEvent(props.hub.hubId, props.customerId, 'DELETE_ZONE', { zoneId })
            await props.refreshDevices();
        } catch (err) {
            setUpdateState('FAILED');
        } finally {
            setEditState(0);
        }
    }

    const sensors = editState ? editedSensors : props.sensors;
    const zones = editState ? editedZones : props.zones;

    const sensorsWithoutZone = sensors?.filter(sensor => !sensor.zoneId || !zones.find(zone => zone.zoneId === sensor.zoneId));

    return (<>
        <div className="py20"></div>
        <div className="flex space-between">
            <h3 className="margin-zero">Zones</h3>
            {editState ?
                <>
                    <div>
                        {updateState == 'UPDATED' ? <>
                            <Button primary small margin onClick={() => setShowSaveModal(true)}>Save</Button>
                        </> : <></>}
                        <Button secondary small margin onClick={showCancelModalHandler}>Cancel</Button>
                    </div>
                </> : <>
                    <Button tertiary small margin disabled={props.readOnly} onClick={() => setEditStateHandler(true)}>Edit zones</Button>
                </>}
        </div>

        {(props.zones?.length) ? <>

            {zones.map(zone => {
                var zoneSensors = sensors?.filter(sensor => sensor.zoneId === zone.zoneId);
                return <ZoneComponent
                    key={zone.zoneId}
                    hub={props.hub}
                    customerId={props.customerId}
                    user={props.user}
                    zone={zone}
                    zones={zones}
                    processors={props.processors}
                    sourceSelectors={props.sourceSelectors}
                    sensors={zoneSensors}
                    isEditing={editState}
                    updateZoneHandler={updateZoneHandler}
                    updateSensorHandler={updateSensorHandler}
                    deleteZoneHandler={deleteZoneHandler}
                    deleteSensorHandler={deleteSensorHandler}
                />
            })}
        </> : <></>}

        {sensorsWithoutZone?.length ? <>
            <Divider />
            <div className="zone-container">
                <h4>Sensors without zone</h4>
                <DeviceList>
                    {sensorsWithoutZone.map(sensor => {
                        return <li key={sensor.sensorId}>
                            <SensorItem
                                customerId={props.customerId}
                                key={sensor.sensorId}
                                hub={props.hub}
                                zones={zones}
                                sensor={sensor}
                                updateSensorHandler={updateSensorHandler}
                                deleteSensorHandler={deleteSensorHandler}
                                isEditing={editState}
                            />
                        </li>;
                    })}
                </DeviceList>
            </div>
        </> : <></>}

        <NewZoneCreator
            hubId={props.hub.hubId}
            addZone={props.addZone}
            readOnly={props.readOnly}
            processors={props.processors}
        />

        <Modal show={showSaveModal} close={() => setShowSaveModal(false)}>
            <h3>Are you sure you want to save?</h3>
            <p>The changes visible on the screen will be set permanently</p>
            <div>
                <Button primary margin onClick={saveHandler}>Confirm Save</Button>
                <Button secondary margin onClick={() => setShowSaveModal(false)}>Continue Editing</Button>
            </div>
        </Modal>

        <Modal show={showCancelModal} close={() => setShowCancelModal(false)}>
            <h3>Are you sure you want to cancel?</h3>
            <p>You will loose all the changes you have made</p>
            <div>
                <Button primary margin onClick={cancelHandler}>Discard changes</Button>
                <Button secondary margin onClick={() => setShowCancelModal(false)}>Continue Editing</Button>
            </div>
        </Modal>

    </>
    );
}

export default ZoneOverview;



