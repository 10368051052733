import styled from 'styled-components';
import theme from '../../../../UI/theme';

export const MainContainer = styled.div`
    position: relative;
    touch-action: manipulation;

    :hover {
        cursor: pointer;
    }
`

export const RowContent = styled.div`
    display: grid;
    grid-template-columns: 25% 63% 12%;
    align-items: center;

    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        grid-template-columns: 20% 70% 10%;
    }
`

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
`

export const ContentLabelContainer = styled.div`
    text-align: center;
    font-size: 0.9rem;
    color: ${theme.colors.textGray};
`

export const ActivityContainer = styled.div` 
    display: grid;
    grid-template-columns: fit-content(150px) 1fr;
`

export const ModeAndRegulationContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const IconContainer = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const SettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.darkSpace80};
    padding: 12px 16px;
    border-radius: 4px;
    margin: 10px 0 0 0;
`

export const GraphContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction:column;
    justify-content: center;
    >div {
        padding: 0 12px;
    }
`

export const SchedulesContainer = styled.div`
    margin: 15px 0 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    font-size: ${theme.fontSize.small};
`

export const ScheduleSummary = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 5px;
    margin-left: 10px;
    border-left: 1px dashed ${theme.colors.greenEnergy};
`

export const ZoneHeader = styled.h3`
    display: flex;
    align-items: center;
    white-space: nowrap;

    svg {
        margin-left: 6px;
        font-size: ${theme.fontSize.medium};
        color: ${theme.colors.greenEnergy};
    }
`