import { postEvent } from '../../../scripts/event';
import { deleteSourceSelector } from '../../../scripts/sourceSelector';
import NameField from '../fields/NameField';
import NumberField from '../fields/NumberField';
import ProcessorChoiceField from '../fields/ProcessorChoiceField';
import { DisplayField } from '../fields/Styles';
import { SourceSelectorIcon } from '../styling/Icons';
import { getDefaultId } from '../tableUtils';
import SourceConfigField from './fields/SourceConfigField';
import SourceSelectorAddressField from './fields/SourceSelectorAddressField';
import { addNewSourceSelector, saveUpdatedSourceSelectors } from './sourceSelectorUtils';

const idField = 'sourceSelectorId';

export const getSourceSelectorTableProps = ({ sourceSelectors, processors, sources, customerId }) => {

    const getColumns = (sourceSelectors) => [{
        key: 'name',
        displayName: 'Name',
        form: true,
        width: '150px',
        render: (sourceSelector, updateSourceSelector, cellControl) => (
            <NameField
                object={sourceSelector}
                updateObject={updateSourceSelector}
                objects={sourceSelectors}
                idField={'sourceSelectorId'}
                nameField={'name'}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'sourceSelectorId',
        displayName: 'Id',
        width: '80px',
        readOnly: true,
        render: (sourceSelector) => (
            <DisplayField>{sourceSelector.sourceSelectorId}</DisplayField>
        )
    }, {
        key: 'processorId',
        displayName: 'Processor',
        width: '150px',
        form: true,
        getSuggested: () => getDefaultId(processors, 'processorId'),
        render: (sourceSelector, updateSourceSelector, cellControl) => (
            <ProcessorChoiceField
                object={sourceSelector}
                processors={processors}
                updateObject={updateSourceSelector}
                cellControl={cellControl}
            />
        )
    }, {
        key: 'address',
        displayName: 'Address',
        width: '300px',
        form: true,
        render: (sourceSelector, updateSourceSelector, cellControl) => (
            <SourceSelectorAddressField
                sourceSelector={sourceSelector}
                updateSourceSelector={updateSourceSelector}
                sourceSelectors={sourceSelectors}
                processors={processors}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'mapping',
        displayName: 'Source Config',
        width: '150px',
        render: (sourceSelector, updateSourceSelector, cellControl) => (
            <SourceConfigField
                sourceConfig={sourceSelector.sourceConfig}
                updateSourceConfig={(sourceConfig) => updateSourceSelector({ sourceConfig })}
                sources={sources.filter(source => source.processorId === sourceSelector.processorId)}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'orderIndex',
        displayName: 'Order',
        width: '50px',
        render: (object, updateObject, cellControl) => (
            <NumberField
                value={object.orderIndex}
                setValue={(value) => updateObject({ orderIndex: value })}
                cellState={cellControl.cellState}
            />
        )
    }]

    const saveObjects = async (changedSourceSelectors) => {
        try {
            await saveUpdatedSourceSelectors(changedSourceSelectors, sourceSelectors, processors, customerId);
        } catch (err) {
            console.log('Failed to update sourceselectors');
        }
    }

    const deleteObject = async (sourceSelector) => {
        try {
            await deleteSourceSelector(customerId, sourceSelector.sourceSelectorId);
            const processor = processors.find(processor => processor.processorId === sourceSelector.processorId);
            await postEvent(processor.hubId, customerId, 'DELETE_SOURCE_SELECTOR', { sourceSelectorId: sourceSelector.sourceSelectorId });
        } catch (err) {
            console.log('Error failed to delete sourceSelector');
        }
    }

    const addObject = processors.length ? (newSourceSelector) => addNewSourceSelector(newSourceSelector, sourceSelectors, processors, customerId) : null;

    const getListItemProps = (sourceSelector) => {
        return {
            displayName: sourceSelector.name,
            Icon: SourceSelectorIcon
        }
    }

    const getActions = (sourceSelector) => [];

    const getPlaceholder = () => {
        return <div>
            {processors.length ? <>
                <p>No source selectors have been added.</p>
            </> : <>
                <p>Add a processor before adding source selectors</p>
            </>}
        </div>
    }

    return {
        title: 'Source selectors',
        getColumns,
        parentObjects: sourceSelectors,
        getListItemProps,
        idField,
        saveObjects,
        deleteObject,
        addObject,
        getActions,
        getPlaceholder
    }
}
