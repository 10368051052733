import React from 'react';
import styled from 'styled-components';
import theme from '../../../../UI/theme';

const Container = styled.div`
    /* margin-top: 4px; */
    text-align: center;
    font-size: 0.8rem;
    font-weight: 100;
    color: ${theme.colors.textGray};
`

const ActivityContext = props => {


    return <Container>
        {getActivityLabel(props.trendDataForHour, props.currentHourValue)}
    </Container>
}

export default ActivityContext;


function getActivityLabel(trendDataForHour, currentHourValue) {
    var newLabel = <></>;
    if (trendDataForHour > 0 && currentHourValue > 0) {
        var changePercentage = Math.round(100 * (Math.abs(trendDataForHour - currentHourValue)) / (currentHourValue));
        if (changePercentage > 0) {
            if (trendDataForHour > currentHourValue) {
                newLabel = `${changePercentage}% lower than average`;
            } else {
                newLabel = `${changePercentage}% higher than average`;
            }
        } else if (changePercentage == 0) {
            newLabel = "As usual";
        }
    }

    return newLabel;
}