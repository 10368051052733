import { useContext } from "react";
import { useState } from "react";
import SearchField from "../components/admin/support/SearchField";
import SupportTable from "../components/admin/support/SupportTable";
import TopNavigation from "../components/navigation/TopNavigation";
import ViewHeader from "../components/UiComponents/ViewHeader";
import UserContext from "../hooks/UserContext";
import styled from "styled-components";

const Container = styled.div`
    margin: 0 10px;
`

const CustomerErrorsView = props => {
    const user = useContext(UserContext);
    const [searchValue, setSearchValue] = useState('');

    const searchHandler = (value) => {
        setSearchValue(value)
    }

    if (user.user.role === 'admin' || user.user.role === 'impersonator') {
        return <>
            <ViewHeader fitToSmallScreen={true}>
                <TopNavigation to={'/supportcenter'} />
                <h3>Customer Errors</h3>
            </ViewHeader>

            <Container>
                <SearchField value={searchValue} onChange={searchHandler} />

                <SupportTable searchValue={searchValue} setCustomer={props.setCustomer} />
            </Container>

        </>
    } else {
        return <></>
    }
}

export default CustomerErrorsView;