import React, { useState } from 'react';
import styled from 'styled-components';
import { Range, Direction } from 'react-range';
import theme from '../../UI/theme';

const trackHeight = 40;

const Thumb = styled.div`
    height: 100%;
    width: ${trackHeight}px;
    background-color: ${props => props.thumbColor || theme.colors.greenEnergy};
`

const Track = styled.div`
    /* border: 3px solid ${theme.colors.textGray}30; */
    /* border-radius: 4px; */
`

const Mark = styled.div`
    position: relative;
    bottom: -30px;
    /* right: 10px; */
    font-size: 12px;
`

const Label = styled.div`
    position: relative;
    bottom: ${trackHeight-23}px;
    font-size: 12px;
    text-align: center;
`

const SimpleSlider = props => {
    var thumbColor = props.thumbColor;

    const changeHandler = (newValue) => {
        if (props.onChange) {
            props.onChange(newValue);
        }
    }

    const changeCommittedHandler = (newValues) => {
        if (props.onChangeCommitted) {
            props.onChangeCommitted(newValues);
        }
    }
    var step = props.step || 1;
    var min = props.min || 0;
    var max = props.max || 10;
    var values = [props.value];
    const rtl = false;
    var marks = props.marks;
    const backgroundColor = props.backgroundColor || theme.colors.darkSpace;

    return (
        <Range
            step={step}
            min={min}
            max={max}
            values={values}
            onChange={(values) => {
                changeHandler(values);
            }}
            onFinalChange={(values) => changeCommittedHandler(values[0])}
            direction={Direction.Right}
            
            renderTrack={({ props, children }) => (
                <Track
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    {...props}
                    style={{
                        ...props.style,
                        height: trackHeight + 'px',
                        width: '100%',
                        backgroundColor: backgroundColor
                    }}
                >
                    {children}
                </Track>
            )}
            renderThumb={({ props, value }) => (
                <Thumb
                    {...props}
                    thumbColor={thumbColor}
                    style={{
                        ...props.style,
                    }}
                >
                    <Label>{value}</Label>
                </Thumb>
                
            )}

            renderMark={({ props, index }) => (
                <div
                    {...props}
                >
                 {/*    {index === 0 || index === (MAX / STEP) ? <>
                        <Mark>
                            {index * STEP}%
                        </Mark>
                    </> : <></>} */}
                    {renderMark(marks, index * step + min)}
                </div>
            )}
        />
    );
}

export default SimpleSlider;

const renderMark = (marks, value) => {
    var Component = <></>;
    if (marks && marks.length) {
        var mark = marks.find(mark => mark.value == value);
        if (mark) {
            var Component = <Mark>{mark.label}</Mark>;
        }
    }
    
    return Component;
}

function getThumbColor(props) {
    var thumbColor;
    if (props.thumbColor) {
        thumbColor = props.thumbColor;
    } else if (props.disabled) {
        thumbColor = theme.colors.darkSpace70;
    }
    return thumbColor;
}