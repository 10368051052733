import React, { useState, useEffect, useRef } from 'react';
import KeyMetric from '../../../UiComponents/KeyMetric';
import { getLiveVenueActivity } from '../../../../scripts/venue';
import venueTypes from '../../venueTypes';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: center;
`

const ActivityMetric = props => {
    const [metricValue, setMetricValue] = useState();
    const liveDataInterval = useRef();

    useEffect(() => {
        getLiveData();
        startLiveDataInterval();

        return () => clearInterval(liveDataInterval.current);
    }, [props.customerId]);

    const startLiveDataInterval = () => {
        clearInterval(liveDataInterval.current);
        liveDataInterval.current = setInterval(getLiveData, 5000);
    }

    const getLiveData = async () => {
        var value = await getLiveVenueActivity(props.customerId);
        if (value) {
            setMetricValue(value);
            props.removeError(venueTypes.errorMessages.NO_ZONE_DATA);
        } else {
            setMetricValue(null);
            props.addError(venueTypes.errorMessages.NO_ZONE_DATA);
        }
    }

    return <Container>
        <KeyMetric
        title={'Now'}
        metric={metricValue}
        unit={'dB'}
        invalid={!metricValue}
        label={'Measured volume'}
        count={true}
        margin={'0 20px 0 0'}
    />
    </Container>
}

export default ActivityMetric;