import Modal from "../UiComponents/Modal";
import { ModalContainer, ModalHeader } from "./ModalStyles";
import SchedulesInfo from "./SchedulesInfo";
import ZoneAdvancedSettings from "./ZoneAdvancedSettings";
import ActivityChartContainer from "./charts/ActivityChartContainer";

const ZoneInfoModal = props => {

    return <Modal {...props}>
        <ModalContainer>
            <ModalHeader>{props.zone?.zoneName}</ModalHeader>

            <ActivityChartContainer zone={props.zone} />

            <SchedulesInfo schedules={props.schedules} zone={props.zone} />

            <ZoneAdvancedSettings
                zone={props.zone}
                updateZoneWithEvent={props.updateZoneWithEvent}
                processorConnected={props.processorConnected}
            />
        </ModalContainer>
    </Modal>
}

export default ZoneInfoModal;