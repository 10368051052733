import React from "react";
import styled from "styled-components";
import { getDayOfWeek } from "../../../scripts/common";
import Selector from "../Selector";

const Container = styled.div`
    margin-top: 10px;
`

const days = [
    { display: "Mon" },
    { display: "Tue" },
    { display: "Wed" },
    { display: "Thu" },
    { display: "Fri" },
    { display: "Sat" },
    { display: "Sun" }];

const DayPicker = props => {
    var dayIndex = getDayOfWeek(props.date);
    for (let index = 0; index < 7; index++) {
        var newDate = new Date(props.date);
        newDate.setDate(newDate.getDate() + (index - dayIndex));
        days[index].date = newDate;
    }

    return (
        <Container>
            <Selector
                items={days.map((day, index) => { return { key: index, disabled: day.date > new Date(), ...day } })}
                selectedItem={{ key: getDayOfWeek(props.date), ...days[getDayOfWeek(props.date)] }}
                onClick={item => props.setDate(item.date)}
                width={'0'}
                flex={'1 1 0px'} />
        </Container>
    );
}

export default DayPicker;
