import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../UI/theme';
import { getHeatmapData, updateHeatmapData, setHeatmapFloorInactive } from '../scripts/heatmap';
import { getUuid } from '../scripts/common';
import heatmapTypes from '../components/heatmap/container/heatmapTypes';
import HeatmapCanvas from '../components/heatmap/container/HeatmapCanvas';
import SensorBank from '../components/heatmap/container/SensorBank';
import WallBank from '../components/heatmap/container/WallBank';
import FloorInfo from '../components/heatmap/container/FloorInfo';
import GetStarted from '../components/heatmap/container/GetStarted';
import MenuBar from '../components/heatmap/container/MenuBar';
import Toolbar from '../components/heatmap/container/Toolbar';
import ZoneBank from '../components/heatmap/container/ZoneBank';
import FurnitureBank from '../components/heatmap/container/FurnitureBank';
import SensorInfoBubble from '../components/heatmap/container/SensorInfoBubble';
import DataModeContainer from '../components/heatmap/container/DataMode/DataModeContainer';
import CanvasMessageBox from '../components/heatmap/container/StyledComponents/CanvasMessageBox';
import Card from '../components/UiComponents/Card';
import TopNavigation from '../components/navigation/TopNavigation';
import ViewHeader from '../components/UiComponents/ViewHeader';

const Container = styled(Card)`
    max-width: 60em;
    margin-bottom: 200px;
    @media only screen and (min-width: ${theme.screenSizes.large}px) {
        padding: 40px 50px;
    }
    
`
const CanvasContainer = styled.div`
    position: relative;
`

const HeatmapView = props => {
    const [state, setState] = useState(heatmapTypes.states.NONE);
    const [floorElements, setFloorElements] = useState();
    const [deletedFloorElements, setDeletedFloorElements] = useState([]);
    const [floors, setFloors] = useState();
    const [activeFloor, setActiveFloor] = useState();
    const [canvasAction, setCanvasAction] = useState();
    const [activeTool, setActiveTool] = useState();
    const [activeMode, setActiveMode] = useState(heatmapTypes.modes.DATA);
    const [edited, setEdited] = useState(false);
    const [markedSensor, setMarkedSensor] = useState();
    const [selectedWall, setSelectedWall] = useState();
    const [selectedZone, setSelectedZone] = useState();
    const [showSensorInfo, setShowSensorInfo] = useState(false);
    const [sensorDisplayData, setSensorDisplayData] = useState({});
    const [canvasMessage, setCanvasMessage] = useState();
    const [dataLegend, setDataLegend] = useState();
    const [canvasIcon, setCanvasIcon] = useState();

    const activeFloorRef = useRef();
    const floorElementsRef = useRef();
    const canvasActionRef = useRef();
    activeFloorRef.current = activeFloor;
    floorElementsRef.current = floorElements;
    canvasActionRef.current = canvasAction;


    useEffect(() => {
        getHeatmapDataHandler();
        setSensorDisplayData({});
        setShowSensorInfo(false);
        return canvasCleanup;
    }, [props.customerId]);

    useEffect(() => {
        if (state === heatmapTypes.states.DATA && !edited) {
            setEdited(true);
        }
    }, [floorElements, floors]);

    useEffect(() => {
        if (state === heatmapTypes.states.DATA) {
            setMarkedSensor(null);
            setSelectedWall(null);
            setSelectedZone(null);
        }

    }, [activeFloor, activeTool, activeMode]);


    function canvasCleanup() {
        actionHandler(heatmapTypes.actions.CANVAS_CLEANUP, null);
    }

    const getHeatmapDataHandler = async () => {
        setState(heatmapTypes.states.LOADING);
        try {
            var res = await getHeatmapData(props.customerId);
            setFloors(res.heatmapFloors);
            setFloorElements(res.heatmapFloorElements);
            if (res.heatmapFloors.length) {
                setActiveFloor(res.heatmapFloors[0].floorId);
            }
    
            setState(heatmapTypes.states.DATA);
        } catch (err) {
            setState(heatmapTypes.states.NO_DATA);
        }
        
    }

    const saveHandler = async () => {
        var res = await updateHeatmapData(props.customerId, floors, floorElements, deletedFloorElements);
        if (res) {
            setEdited(false);
            setDeletedFloorElements([]);
        }
    }

    const actionHandler = (type, data) => {

        if (canvasActionRef.current) {
            switch (type) {
                case heatmapTypes.actions.SAVE:
                    saveHandler();
                    break;
                default:
                    canvasActionRef.current(type, data);
                    break;
            }
        }
    }

    const canvasCallback = (type, data) => {
        switch (type) {
            case heatmapTypes.actions.SENSOR_PLACED:
                sensorPlacedHandler(data);
                return;
            case heatmapTypes.actions.SENSOR_REMOVED:
                sensorPlacedHandler(data, true);
                return;
            case heatmapTypes.actions.OUTER_WALL_CLOSED:
                wallClosedHandler(data);
                return;
            case heatmapTypes.actions.ADD_OUTER_WALL:
                addOuterWallHandler(data);
                return;
            case heatmapTypes.actions.ADD_INNER_WALL:
                addInnerWallHandler(data);
                return;
            case heatmapTypes.actions.DELETE_WALL:
                deleteFloorElementHandler(data);
                return;
            case heatmapTypes.actions.SENSOR_MARKED:
                setMarkedSensor(data);
                return;
            case heatmapTypes.actions.WALL_SELECT:
                setSelectedWall(data);
                return;
            case heatmapTypes.actions.ZONE_PLACED:
                zonePlacedHandler(data, true);
                return;
            case heatmapTypes.actions.ZONE_REMOVED:
                zonePlacedHandler(data, false);
                return;
            case heatmapTypes.actions.ZONE_SELECT:
                setSelectedZone(data);
                return;
            case heatmapTypes.actions.SHOW_SENSOR_INFO:
                setShowSensorInfoHandler(data);
                return;
            case heatmapTypes.actions.ADD_FURNITURE:
                addFurnitureHandler(data);
                return;
            case heatmapTypes.actions.REMOVE_FURNITURE:
                deleteFloorElementHandler(data);
                return;
            case heatmapTypes.actions.GET_OUTER_WALL:
                var element = floorElementsRef.current.find(element => element.type === heatmapTypes.elementTypes.OUTERWALL && element.floorId === activeFloorRef.current);
                data.callback(element);
                return;
            default:
                return;

        }
    }

    const sensorPlacedHandler = (sensor, removeFlag) => {
        var sensorId = sensor.id;

        setFloorElements(elements => {
            var updatedSensor = elements.find(e => e.floorElementId === sensorId);
            if (updatedSensor) {
                if (removeFlag) {
                    updatedSensor.placed = false;
                    updatedSensor.geometry = null
                    updatedSensor.floorId = null;
                } else {
                    updatedSensor.placed = true;
                    updatedSensor.geometry = { ...sensor.coordinates };
                    updatedSensor.floorId = activeFloorRef.current;
                }
            }
            return [...elements];
        });
    }

    const addOuterWallHandler = (wallData) => {
        setFloorElements(currentElements => {
            var elements = [...currentElements];
            var element = elements.find(element => element.type === heatmapTypes.elementTypes.OUTERWALL && element.floorId === activeFloorRef.current);
            if (!element) {
                element = {};
                element.floorId = activeFloorRef.current;
                element.floorElementId = wallData.id || getUuid();
                element.type = heatmapTypes.elementTypes.OUTERWALL;
                return [...elements, element];
            }
            return [...elements];
        })
    }

    const wallClosedHandler = (wallData) => {
        setFloorElements(currentElements => {
            var elements = [...currentElements];
            var element = elements.find(element => element.type === heatmapTypes.elementTypes.OUTERWALL && element.floorId === activeFloorRef.current);
            if (!element) {
                element = {};
                element.floorId = activeFloorRef.current;
                element.floorElementId = getUuid();
                element.type = heatmapTypes.elementTypes.OUTERWALL;
                element.geometry = wallData.segments.map(seg => ({ x: seg.point.x, y: seg.point.y }));
                return [...elements, element];
            }
            element.geometry = wallData.segments.map(seg => ({ x: seg.point.x, y: seg.point.y }));
            return [...elements];
        });
    }

    const addInnerWallHandler = (wallData) => {
        setFloorElements(elements => {
            var element = elements.find(element => element.type === heatmapTypes.elementTypes.INNERWALL && element.floorElementId === wallData.id);
            if (!element) {
                element = {};
                element.floorId = activeFloorRef.current;
                element.floorElementId = wallData.id;
                element.type = heatmapTypes.elementTypes.INNERWALL;
                return [...elements, element];
            }
            element.geometry = wallData.wall.segments.map(seg => ({ x: seg.point.x, y: seg.point.y }));
            return [...elements];
        });
    }

    const deleteFloorElementHandler = (floorElementID) => {
        var deletedElement = floorElementsRef.current.find(e => e.floorElementId === floorElementID);
        if (deletedElement) {
            setDeletedFloorElements(deletedElements => [...deletedElements, deletedElement]);
        }
        setFloorElements(elements => {
            var updatedElements = elements.filter(e => e.floorElementId != floorElementID);
            return updatedElements;
        });
    }

    const zonePlacedHandler = (zone, isPlaced) => {
        var zoneId = zone.id;
        setFloorElements(elements => {
            var updatedZone = elements.find(e => e.floorElementId === zoneId);
            if (updatedZone) {
                if (!isPlaced) {
                    updatedZone.placed = false;
                    updatedZone.geometry = null;
                    updatedZone.floorId = null;
                } else {
                    updatedZone.placed = true;
                    updatedZone.floorId = activeFloorRef.current;
                    updatedZone.geometry = zone.geometry;
                }
            }
            return [...elements];
        });
    }

    const addFurnitureHandler = (furniture) => {
        var furnitureID = furniture.id;
        setFloorElements(elements => {
            var element = elements.find(e => e.floorElementId === furnitureID);
            if (!element) {
                element = {};
                element.floorElementId = furnitureID;
                element.floorId = activeFloorRef.current;
                element.type = heatmapTypes.elementTypes.FURNITURE;
                element.geometry = { bounds: furniture.bounds, rotation: furniture.rotation, type: furniture.type };
                return [...elements, element];
            }
            element.geometry = { bounds: furniture.bounds, rotation: furniture.rotation, type: furniture.type };
            return [...elements];
        })
    }

    const setActiveToolHandler = (toolName) => {
        if (toolName === activeTool) {
            toolName = heatmapTypes.tools.NONE;
        }
        actionHandler(heatmapTypes.actions.TOOL_SELECT, {
            toolName,
            floorElements,
            activeFloor: activeFloorRef.current
        });
        setActiveTool(toolName);
    }

    const setActiveFloorHandler = (floorId) => {
        actionHandler(heatmapTypes.actions.FLOOR_SELECT, {
            activeFloor: floorId,
            floorElements,
            activeMode
        });

        setActiveFloor(floorId);
        setSelectedZone(null);
    }

    const setActiveModeHandler = (modeName) => {
        actionHandler(heatmapTypes.actions.MODE_SELECT, modeName);
        setActiveTool(null);
        setActiveMode(modeName);
    }

    const updateFloorName = async (floorId, floorName) => {
        var updatedFloors = [...floors];
        var updatedFloor = updatedFloors.find(floor => floor.floorId === floorId);
        if (updatedFloor) {
            updatedFloor.name = floorName;
            setFloors(updatedFloors);
        }
    }

    const addFloorHandler = () => {
        var newFloor = getNewFloor(floors.length, props.customerId);
        if (floors.length === 0) {
            setActiveModeHandler(heatmapTypes.modes.SETUP);
            setActiveTool(heatmapTypes.tools.FLOOR_GENERAL);
            setActiveFloor(newFloor.floorId);
        } else {
            setActiveToolHandler(heatmapTypes.tools.FLOOR_GENERAL);
            setActiveFloorHandler(newFloor.floorId);
        }

        setFloors(currentFloors => [...currentFloors, newFloor]);
    }

    const deleteFloorHandler = async (customerId, floorId) => {
        const floorDeleted = await setHeatmapFloorInactive(customerId, floorId);
        if (floorDeleted) {
            var nextActive = floors.find(f => f.floorId != floorId);
            if (nextActive) {
                setActiveFloorHandler(nextActive.floorId);
            }
            getHeatmapDataHandler();
        }
        return floorDeleted;
    }

    const setShowSensorInfoHandler = data => {
        if (data) {
            setSensorDisplayData(data);
            if (props.onSensorClickHandler) {
                props.onSensorClickHandler(data);
            }
        }
        else setSensorDisplayData({});
        setShowSensorInfo(!showSensorInfo);
    }

    return <div>
        {!props.hideBackButton ? <>
            <ViewHeader fitToSmallScreen={true}>
                <TopNavigation to={'/'} />
            </ViewHeader>
        </> : <></>}

        <Container>

            {state === heatmapTypes.states.DATA ? <>

                {floors.length ? <>

                    <MenuBar
                        activeMode={activeMode}
                        setModeHandler={setActiveModeHandler}
                        floors={floors}
                        activeFloor={activeFloor}
                        setActiveFloor={setActiveFloorHandler}
                        addFloorHandler={addFloorHandler}
                    />

                    {activeMode === heatmapTypes.modes.SETUP ? <>
                        <Toolbar
                            actionHandler={actionHandler}
                            activeTool={activeTool}
                            setActiveTool={setActiveToolHandler}
                            edited={edited}
                        />
                    </> : <></>}

                    <CanvasContainer>
                        {activeMode === heatmapTypes.modes.SETUP ? <>
                            {activeTool === heatmapTypes.tools.SENSOR ? <>
                                <SensorBank
                                    customerId={props.customerId}
                                    sensors={floorElements.filter(e => e.type === heatmapTypes.elementTypes.SENSOR)}
                                    zones={floorElements.filter(e => e.type === heatmapTypes.elementTypes.ZONE)}
                                    actionHandler={actionHandler}
                                    floors={floors}
                                    markedSensor={markedSensor}
                                    setMarkedSensor={setMarkedSensor}
                                />
                            </> : <></>}

                            {activeTool === heatmapTypes.tools.FLOOR_GENERAL ? <>
                                <FloorInfo
                                    customerId={props.customerId}
                                    floor={floors.find(floor => floor.floorId === activeFloor)}
                                    updateFloorName={updateFloorName}
                                    deleteFloorHandler={deleteFloorHandler}
                                />
                            </> : <></>}

                            {activeTool === heatmapTypes.tools.FLOOR ? <>
                                <WallBank
                                    outerWallElement={floorElements.find(e => e.type === heatmapTypes.elementTypes.OUTERWALL && e.floorId == activeFloor)}
                                    innerWallElements={floorElements.filter(e => e.type === heatmapTypes.elementTypes.INNERWALL && e.floorId == activeFloor)}
                                    actionHandler={actionHandler}
                                    selectedWall={selectedWall}
                                    setSelectedWall={setSelectedWall} />
                            </> : <></>}

                            {activeTool === heatmapTypes.tools.ZONE ? <>
                                <ZoneBank
                                    zones={floorElements.filter(e => e.type === heatmapTypes.elementTypes.ZONE)}
                                    actionHandler={actionHandler}
                                    floors={floors}
                                    selectedZone={selectedZone}
                                    setSelectedZone={setSelectedZone}
                                />
                            </> : <></>}

                            {activeTool === heatmapTypes.tools.FURNITURE ? <>
                                <FurnitureBank
                                    actionHandler={actionHandler}
                                />
                            </> : <></>}
                        </> : <></>}

                        <HeatmapCanvas
                            floorElements={floorElements}
                            activeFloor={activeFloor}
                            canvasAction={canvasAction}
                            activeMode={activeMode}
                            activeTool={activeTool}
                            canvasCallback={canvasCallback}
                            setCanvasAction={setCanvasAction}
                            backgroundColor={theme.colors.darkSpace90}
                            height={props.height}
                        />

                        {canvasMessage ? <>
                            <CanvasMessageBox>
                                {canvasMessage}
                            </CanvasMessageBox>
                        </> : <></>}

                        {dataLegend ? <>
                            {dataLegend}
                        </> : <></>}

                        {canvasIcon ? <>
                            {canvasIcon}
                        </> : <></>}

                    </CanvasContainer>

                    {activeMode === heatmapTypes.modes.DATA ? <>
                        <DataModeContainer
                            activeMode={activeMode}
                            actionHandler={actionHandler}
                            canvasIcon={canvasIcon}
                            setCanvasIcon={setCanvasIcon}
                            customerId={props.customerId}
                            canvasMessage={canvasMessage}
                            setCanvasMessage={setCanvasMessage}
                            dataLegend={dataLegend}
                            setDataLegend={setDataLegend}
                        />
                    </> : <></>}

                </> : <>
                        <GetStarted onGetStarted={addFloorHandler} />
                    </>}

            </> : <></>}

            {state === heatmapTypes.states.LOADING ? <>
                Loading heatmap data
        </> : <></>}

            {state === heatmapTypes.states.NO_DATA ? <>
                Failed to load heatmap data
        </> : <></>}
            <SensorInfoBubble
                show={showSensorInfo}
                close={() => { setShowSensorInfoHandler(false) }}
                sensorData={sensorDisplayData}
            />
        </Container>
    </div>
}

export default HeatmapView;


function getNewFloor(existingFloors, customerId) {
    var floorNr = (existingFloors + 1);
    var newFloor = {
        floorId: getUuid(),
        name: 'Floor ' + floorNr,
        customerId: customerId
    };
    return newFloor;
}