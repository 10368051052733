import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import styled from "styled-components";
import { getWeekForDate, toLocalISOString } from "../../../scripts/common";
import trendTypes from "../../statistics/trendTypes";
import IconButton from "../buttons/IconButton";
import DatePickerInput from './StyledComponents/DatePickerInput';

const DateInputContainer = styled.div`
    display: grid;
    grid-template-columns: 15% 70% 15%;
    align-items: center;
    justify-items: center;
    height: 25px;
    overflow: visible;
`

const steps = { increment: 1, decrement: -1 };

const PeriodStepper = (props) => {
    const stepDate = (step) => {
        var delta = 0;
        if (props.period === trendTypes.periods.DAY) {
            delta = 1;
        } else if (props.period === trendTypes.periods.WEEK) {
            delta = 7;
        }

        if (props.date && props.setDate && step) {
            var dateAfterStep = new Date(props.date);
            dateAfterStep.setDate(dateAfterStep.getDate() + delta*step);
            props.setDate(dateAfterStep < new Date() ? dateAfterStep : new Date());
        }
    }

    return <DateInputContainer>
        <IconButton onClick={() => stepDate(steps.decrement)} ><FaChevronLeft /></IconButton>
        <DatePickerInput
            type={'date'}
            content={formatDateString(props.date, props.period)}
            max={(new Date()).toISOString().split('T')[0]}
            value={toLocalISOString(props.date).split('T')[0]}
            onChange={(e) => props.setDate(new Date(e.target.value))} />
        <IconButton onClick={() => stepDate(steps.increment)} disabled={isCurrentPeriod(props.date, props.period)}><FaChevronRight /></IconButton>
    </DateInputContainer>
}

export default PeriodStepper;

const isCurrentPeriod = (date, period) => {
    var today = new Date();

    if (period === trendTypes.periods.DAY) {
        return date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate();
    } else if (period === trendTypes.periods.WEEK) {
        return getWeekForDate(date) === getWeekForDate(today);
    } else {
        return true;
    }
}

const formatDateString = (date, period) => {
    if (period === trendTypes.periods.DAY) {
        return `'${date.toDateString()}'`;
    } else if (period === trendTypes.periods.WEEK) {
        var startOfWeek = new Date(date);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1);
        var endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 6);
        return `'${startOfWeek.toDateString()} - ${endOfWeek.toDateString()}'`;
    } else {
        return `'${date.toDateString()}'`;
    }
}