import { useState } from "react";
import styled from "styled-components";
import serverConnection from "../../../../scripts/server/ServerConnection";
import theme from "../../../../UI/theme";
import NeutralSpinner from "../../../UiComponents/Animations/NeutralSpinner";
import Button from "../../../UiComponents/Button";
import { FormField } from "../../table/styles/FormStyles";
import { cellStates } from "../../table/tableTypes";
import ZigbeeChannelChoiceField from "../fields/ZigbeeChannelChoiceField";
import { hasZigbeeNetwork } from "../hubUtils";

const DropdownContainer = styled.div`
    width: 200px;
`

const formStates = {
    NONE: 0,
    FORMING: 1,
    SUCCESS: 2,
    FAILED: 3
}

const FormNetwork = ({ hub, cancel, bestChannel }) => {
    const [state, setState] = useState(formStates.NONE);
    const [selectedChannel, setSelectedChannel] = useState(parseInt(bestChannel) || hub.zigbeeChannel);

    const formNetwork = async () => {
        try {
            setState(formStates.FORMING);
            const result = await serverConnection.hubEventHandler.sendEvent(
                hub.hubId,
                hub.customerId,
                'ZIGBEE_SETUP_START',
                {channel: selectedChannel}
            );
            if (result.status === 'OK') {
                setState(formStates.SUCCESS);
            } else {
                setState(formStates.FAILED);
            }
        } catch (err) {
            console.log('Form network error', err)
            setState(formStates.FAILED);
        }
    }

    return <div>
        <h3>Form network</h3>
        {state === formStates.NONE ? <>
            <p>
                Use a channel scan sensor to report link quality index on available zigbee channels.
                Starting a new channel scan will close the current network and disconnect all connected sensors.
            </p>
            <DropdownContainer>
                <FormField>
                    <label>Zigbee channel</label>
                    <ZigbeeChannelChoiceField
                        zigbeeChannel={selectedChannel}
                        setValue={(value) => setSelectedChannel(value)}
                        cellControl={{ cellState: cellStates.FORM }}
                    />
                </FormField>
            </DropdownContainer>
            <Button primary margin onClick={formNetwork}>Form network</Button>
            <Button secondary onClick={cancel}>Cancel</Button>
        </> : <></>}

        {state === formStates.FORMING ? <>
            <p>Forming network on channel {selectedChannel}...</p>
            <NeutralSpinner
                color={theme.colors.whiteSpace}
                size={60}
                speed={1.5}
            />
        </> : <></>}

        {state === formStates.SUCCESS ? <>
            <p>Network was formed successfully on channel {selectedChannel}</p>
            <Button primary margin onClick={cancel}>Continue</Button>
        </> : <></>}

        {state === formStates.FAILED ? <>
            <p>Network forming failed on channel {selectedChannel}</p>
            <Button primary margin onClick={() => setState(formStates.NONE)}>Retry</Button>
            <Button secondary margin onClick={cancel}>Cancel</Button>
        </> : <></>}
    </div>
}

export default FormNetwork;

export const hideFormNetworkField = (hubs) => {
    return hubs?.find(hub => !hasZigbeeNetwork(hub)) === undefined;
}