import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { deleteCalibration, getAllCalibrations } from '../../../../scripts/calibration';
import { sliceUrlAfter } from '../../../../scripts/common';
import TopNavigation from '../../../navigation/TopNavigation';
import { Table, TableCell, TableHeader, TableHeaderRow, TableRow } from '../../../UiComponents/table/TableStyles';
import ViewHeader from '../../../UiComponents/ViewHeader';
import Button from '../../../UiComponents/Button';
import styled from 'styled-components';

const StartButtonContainer = styled.div`
    text-align: center;
`

const AutoCalibrationList = props => {
    const { id } = useParams();
    const match = useRouteMatch();
    const history = useHistory();
    const [calibrations, setCalibrations] = useState();

    useEffect(() => {
        getDataHandler();
    }, []);

    const getDataHandler = async () => {
        var calibrationsRes = await getAllCalibrations(id);
        if (calibrationsRes) {
            setCalibrations(calibrationsRes);
        }
    }

    const handleClick = (calibrationId) => {
        history.push(`${match.url}/${calibrationId}`);
    }

    const handleNewCalibration = () => {
        history.push(`${match.url}/new`);
    }

    const handleDeleteCalibration = async (calibrationId) => {
        try {
            await deleteCalibration(id, calibrationId);
            getDataHandler();
        } catch (error) {

        }
    }

    return <div>
        <ViewHeader>
            <TopNavigation to={sliceUrlAfter(match.url, '/autocalibration')} />
            <h3>Calibrations</h3>
        </ViewHeader>

        <StartButtonContainer>
            <Button onClick={() => handleNewCalibration()} primary>
                New calibration
            </Button>
        </StartButtonContainer>

        {calibrations && calibrations.length ? <>
            <h4>Previous calibrations</h4>
            <Table>
                <TableHeader>
                    <TableHeaderRow>
                        <td>Time</td>
                        <td>Hub</td>
                        <td>Nr of zones</td>
                        <td>Status</td>
                    </TableHeaderRow>
                </TableHeader>
                <tbody>
                    {calibrations.map(calibration => {
                        return <TableRow key={calibration.calibrationId} onClick={() => handleClick(calibration.calibrationId)}>
                            <TableCell>{calibration.time}</TableCell>
                            <TableCell>{calibration.hubId}</TableCell>
                            <TableCell>{calibration.zones?.length}</TableCell>
                            <TableCell>{calibration.state}</TableCell>
                            <TableCell>
                                <Button onClick={(e) => { e.stopPropagation(); handleDeleteCalibration(calibration.calibrationId); }} small secondary>Delete</Button>
                            </TableCell>
                        </TableRow>
                    })}
                </tbody>
            </Table>
        </> : <></>}
    </div>
}

export default AutoCalibrationList;

