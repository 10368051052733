import React from 'react';
import absolute_volume_slider from './images/absolute_volume_slider.PNG'
import styled from 'styled-components';

const StyledImage = styled.img`
    padding: 20px 0;
    width: 100%;
`

const AbsoluteVolumeNotificationContent = props => {
    return <div>
        <h3>Feature update</h3>
        <div>
            The volume slider has been updated.
            When regulation is turned off, you can now set the volume above Waved's recommended max value.
            <StyledImage src={absolute_volume_slider} />
            Be careful, volumes above this level can be harmful over time.
        </div>

    </div>
}

const absoluteVolumeNotification = {
    startDate: new Date(2021, 10, 3),
    endDate: new Date(2021, 11, 4),
    content: AbsoluteVolumeNotificationContent
}

export default absoluteVolumeNotification;