import Paper, { Point, Path, Size, Rectangle } from 'paper';
import FloorElement from './FloorElement';
import CanvasController from './CanvasController';
import heatmapTypes from '../container/heatmapTypes';
import { getDataColor } from './colorUtils';
import theme from '../../../UI/theme';
import logo from '../images/STREK.svg';

const OLD_LIVE_DATA_LIMIT = 10;

const sensorParams = {
    fillColor: '#2C2C2C',
    size: 1.2,
    radius: 0.2,
    activeStrokeColor: 'orange',
    activeStrokeWidth: 0.2,
    markedStrokeColor: '#18a0fb',
    markedStrokeWidth: 0.4
}

class Sensor extends FloorElement {

    constructor(sensorInfo) {
        super();
        this.id = sensorInfo.floorElementId;
        this.placed = sensorInfo.placed;
        this.coordinates = sensorInfo.geometry;
        this.status = null;

        if (this.placed && this.coordinates) {
            this.placeSensor(this.coordinates)
        }

        Sensor.list.push(this);
    }

    drawSensor(point) {
        this.isOnMap = true;
        if (CanvasController.heatmapType === heatmapTypes.heatmapTypes.MINI) {
            return;
        }
        Sensor.layer.activate();
        var rectangle = new Rectangle(point, new Size(sensorParams.size, sensorParams.size));
        var radius = new Size(sensorParams.radius, sensorParams.radius);
        var sensorPath = new Path.Rectangle(rectangle, radius);
        sensorPath.position = point;
        sensorPath.fillColor = sensorParams.fillColor;
        this.sensorPath = sensorPath;
    }

    drawActivated() {
        if (this.sensorPath) {
            this.sensorPath.fillColor = sensorParams.fillColor;
            this.sensorPath.strokeColor = sensorParams.activeStrokeColor;
            this.sensorPath.strokeWidth = sensorParams.activeStrokeWidth;
        }
    }

    drawDeactivated() {
        if (this.sensorPath) {
            this.sensorPath.strokeColor = 'transparent';
            this.sensorPath.fillColor = sensorParams.fillColor;
        }
    }

    placeSensor(coordinates) {
        var point = new Point(coordinates.x, coordinates.y);
        this.drawSensor(point);
    }

    remove() {
        this.sensorPath.remove();
        CanvasController.canvasCallback(heatmapTypes.actions.SENSOR_REMOVED, this);
    }

    mark() {
        this.marked = true;
        this.sensorPath.fillColor = sensorParams.markedStrokeColor;
        //this.sensorPath.strokeWidth = sensorParams.markedStrokeWidth;
    }

    unmark() {
        this.marked = false;
        this.drawActivated();
    }

    drawDataPath(data) {
        Sensor.layer.activate();
        if (this.dataPath) {
            this.dataPath.remove();
        }
        var point = this.sensorPath.position;
        var scaleCoeff = 0.02;
        var size = new Size(sensorParams.size * (1 + data * scaleCoeff), sensorParams.size * (1 + data * scaleCoeff))
        var rectangle = new Rectangle(point, size);
        var radius = new Size(sensorParams.radius, sensorParams.radius);
        var dataPath = new Path.Rectangle(rectangle, radius);
        dataPath.position = point;

        dataPath.fillColor = getDataColor(data);
        dataPath.sendToBack();
        this.dataPath = dataPath;
    }


    showData(data) {
        this.drawDataPath(data)
        this.startFluctuating(data);
    }

    startFluctuating(data) {
        clearInterval(this.fluctuateInterval);
        var prevFluctNumber = data;
        var fluctNumber = (Math.random() * 20 - 10) + data;
        var count = 0;
        this.fluctuateInterval = setInterval(() => {
            var size = (prevFluctNumber + (fluctNumber - prevFluctNumber) * count / 10);
            this.drawDataPath(size);
            if (++count == 10) {
                count = 0;
                prevFluctNumber = fluctNumber;
                fluctNumber = Math.random() * 10 + data;
            }
        }, 50);
    }

    removeData() {
        clearInterval(this.fluctuateInterval);
        if (this.dataPath) {
            this.dataPath.remove();
        }
    }

    addDataModeEventHandlers() {
        const onClickHandler = (event) => {
            if (Sensor.sensorData) {
                const sd = Sensor.sensorData.find(s => s.sensorId === this.id);
                if (sd) {
                    const data = {
                        ...sd,
                        pageX: event.event.pageX,
                        pageY: event.event.pageY,
                    }
                    CanvasController.canvasCallback(heatmapTypes.actions.SHOW_SENSOR_INFO, data);
                }
            }
        }
        if (this.sensorPath) {
            this.sensorPath.onClick = onClickHandler;
        }
        if (this.logoGroup) {
            this.logoGroup.onClick = onClickHandler;
        }
    }

    addEventHandlers() {
        if (this.sensorPath) {
            this.sensorPath.onMouseDrag = (event) => {
                this.sensorPath.position = this.sensorPath.position.add(event.delta);
                event.stopPropagation();
            }
            this.sensorPath.onMouseUp = (event) => {
                this.setPlaced(this.sensorPath.position);
            }

            this.sensorPath.onClick = (event) => {
                if (!this.marked) {
                    Sensor.unmarkAllSensors();
                    this.mark();
                    CanvasController.canvasCallback(heatmapTypes.actions.SENSOR_MARKED, this.id);
                } else {
                    CanvasController.canvasCallback(heatmapTypes.actions.SENSOR_MARKED, null);
                    this.unmark();
                }
            }

            this.sensorPath.onMouseEnter = (event) => {
                Paper.view.element.style.setProperty('cursor', 'move');
            }

            this.sensorPath.onMouseLeave = (event) => {
                Paper.view.element.style.setProperty('cursor', null);
            }
        }

        this.drawActivated();
    }

    removeEventHandlers() {
        if (this.sensorPath) {
            this.sensorPath.onMouseDrag = null;
            this.sensorPath.onClick = null;
            this.sensorPath.onMouseEnter = null;
            this.sensorPath.onMouseLeave = null;
            Paper.view.element.style.setProperty('cursor', null);
        }
        this.drawDeactivated();
    }

    setPlaced(position) {
        this.state = 'PLACED';
        this.coordinates = { x: position.x, y: position.y };
        CanvasController.canvasCallback(heatmapTypes.actions.SENSOR_PLACED, this);
    }

    startPlacing() {
        Paper.view.onMouseMove = (event) => {
            if (!(this.state == 'PLACING')) {
                this.drawSensor(event.point);


                this.state = 'PLACING';
                this.sensorPath.onMouseDown = (event) => {
                    if (this.state === 'PLACING') {
                        //this.setPlaced(event.point);
                        Paper.view.onMouseMove = null;
                        this.addEventHandlers();
                    }
                }

            }
            if (this.sensorPath && this.state === 'PLACING') {
                this.sensorPath.position = event.point
            }
        }
    }


    setStatus(status) {
        this.status = status;
        if (!this.logoGroup) {
            return;
        }
        if (status === 'CONNECTED') {
            this.logoGroup.fillColor = theme.colors.greenEnergy;
        } else if (status === 'DISCONNECTED') {
            this.logoGroup.fillColor = theme.colors.raspberryRed;
        } else {
            this.logoGroup.fillColor = theme.colors.mellowYellow;
        }
    }

    drawStatusPath() {
        return new Promise((resolve, reject) => {
            Sensor.layer.activate();
            if (this.sensorPath && !this.logoGroup) {
                Paper.project.importSVG(logo, {
                    onLoad: (group) => {
                        Sensor.layer.activate();
                        group.position = Paper.view.bounds.topLeft;
                        this.logoGroup = group.clone();
                        this.logoGroup.fitBounds(this.sensorPath.bounds);
                        this.logoGroup.scale(0.8);
                        this.logoGroup.insertAbove(this.sensorPath);
                        this.setStatus(this.status);
                        this.addDataModeEventHandlers();
                        group.remove();
                        resolve();
                    }
                });
            }
        })
    }

    removeStatusPath() {
        if (this.logoGroup) {
            this.logoGroup.remove();
            this.logoGroup = null;
        }
    }

}

Sensor.list = [];
Sensor.displayMethod = null;


Sensor.initList = function (sensors) {
    Sensor.removeData();
    Sensor.list = [];
    sensors.forEach(function (sensor) {
        new Sensor(sensor);
    });
}

Sensor.drawAll = function () {
    Sensor.list.forEach(function (sensor) {
        sensor.draw();
    });
}

Sensor.placeSensor = function (sensor) {
    var sensorItem = Sensor.list.find(s => s.id == sensor.floorElementId);
    if (sensorItem) {
        sensorItem.startPlacing();
    } else {
        var newSensor = new Sensor(sensor);
        newSensor.startPlacing();
    }
}

Sensor.removeSensor = function (sensor) {
    var sensorItem = Sensor.list.find(s => s.id == sensor.floorElementId);
    if (sensorItem) {
        sensorItem.remove();
    } else {
        CanvasController.canvasCallback(heatmapTypes.actions.SENSOR_REMOVED, { id: sensor.floorElementId });
    }
}

Sensor.markSensor = function (sensor) {
    var sensorItem = Sensor.list.find(s => s.id == sensor.floorElementId);
    if (sensorItem) {
        sensorItem.mark();
    }
}

Sensor.unmarkAllSensors = function () {
    Sensor.list.forEach(sensor => {
        sensor.unmark();
    });
}

Sensor.showSensorData = function (sensorData) {
    if (!sensorData) {
        return;
    }
    Sensor.layer.activate();
    this.sensorData = [...sensorData];

    // check connection
    //if (this.dataLayer && this.dataLayer.dataMode === heatmapTypes.dataModes.LIVE) {
    Sensor.checkConnectedStatus();
    //}

    if (Sensor.activeMode === heatmapTypes.modes.DATA) {
        if (Sensor.displayMethod === heatmapTypes.displayMethods.CONTINUOUS) {
            if (Sensor.dataLayer) {
                Sensor.dataLayer.setSensorData(this.sensorData);
            }
        } else {
            Sensor.list.forEach(sensor => {
                var data = sensorData.find(s => s.sensorId === sensor.id);
                if (data && data.averageDecibel && sensor.sensorPath) {
                    sensor.showData(data.averageDecibel);
                }
            })
        }
    }
}

Sensor.initDataMode = async function () {
    Sensor.list.forEach(async (sensor) => {
        sensor.addDataModeEventHandlers();
        await sensor.drawStatusPath();
    });
}

Sensor.initDataLayer = function () {
    Sensor.removeData();
    if (Sensor.displayMethod === heatmapTypes.displayMethods.CONTINUOUS) {

        // try to get outerwallelement from react-state if it does not exist
        if (!Sensor.outerWallElement) {
            CanvasController.canvasCallback(heatmapTypes.actions.GET_OUTER_WALL, {
                callback: (element) => {
                    if (element) {
                        Sensor.outerWallElement = element;
                    }
                }
            });
        }
        this.dataLayer.initializeGrid(Sensor.outerWallElement, Sensor.list);
        Sensor.showSensorData(this.sensorData);
    }
}

Sensor.setDataType = function (dataType) {
    this.dataLayer.setDataType(dataType);
    Sensor.showSensorData(this.sensorData);
}

Sensor.setDataMode = function (dataMode) {
    if (Sensor.dataLayer) {
        Sensor.dataLayer.setDataMode(dataMode);
    }
}

Sensor.changeFloorHandler = function (outerWallElement) {
    Sensor.outerWallElement = outerWallElement;
}

Sensor.setDisplayMethod = function (displayMethod) {
    Sensor.displayMethod = displayMethod;
}



Sensor.removeData = function () {
    Sensor.list.forEach(sensor => {
        sensor.removeData();
        sensor.removeStatusPath();
    });

    if (Sensor.dataLayer) {
        Sensor.dataLayer.clearData();
    }
}

Sensor.checkConnectedStatus = function () {
    // check for old data and ignore if older than limit 
    var ignoreCount = 0;
    this.sensorData.forEach(sensor => {
        var sensorObject = this.list.find(s => s.id === sensor.sensorId);
        var status = null;
        if (sensor.secondsSinceLastUpdate > OLD_LIVE_DATA_LIMIT || sensor.isConnected == 0 || sensor.averageDecibel == 0) {
            sensor.ignore = true;
            ignoreCount++;
            status = 'DISCONNECTED';
        } else {
            status = 'CONNECTED';
        }

        if (sensorObject) {
            sensorObject.setStatus(status);
        }

    });

    if (ignoreCount == this.sensorData.length) {
        // Display error message
    }
}

export default Sensor;


