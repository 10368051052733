import styled, { css } from "styled-components";
import theme from "../../UI/theme";

const Container = styled.div`
    --tabFontSize: 0.9rem;
    --tabBackgroundColor: ${theme.colors.darkSpace90};
    
    display: flex;
    flex-direction: row;
    height: 30px;
    border-bottom: 2px solid var(--tabBackgroundColor);
    overflow: hidden;
`

const Tab = styled.div`
    display: flex;
    gap: 5px;
    position: relative;
    width: 0;
    flex: 1 1 0px;
    max-width: 150px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    cursor: pointer;
    color: ${theme.colors.textGray};
    stroke: ${theme.colors.textGray};
    font-weight: 100;
    font-size: var(--tabFontSize);
    overflow: hidden;
    border-right: 2px solid var(--tabBackgroundColor);

    > svg {
        font-size: var(--tabFontSize);
        max-height: var(--tabFontSize);
        max-width: var(--tabFontSize);
        min-width: var(--tabFontSize);
        min-height: var(--tabFontSize);
    }

    ${props => props.selected && css`
        background-color: var(--tabBackgroundColor);
        border-radius: 5px 5px 0 0;
        border-color: transparent;
        color: ${theme.colors.greenReadable};
        stroke: ${theme.colors.greenReadable};
        flex-basis: min-content;
    `}

    ${props => props.beforeSelected && css`
        border-color: transparent;
    `}
`

const TabHeader = styled.label`
    display: inline-block;
    align-items: center;
    font-size: var(--tabFontSize);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: inherit;
`

const TabSelector = ({tabs, selectedTab, onClick}) => {

    const clickHandler = tab => {
        if (onClick) {
            onClick(tab);
        }
    }

    return <>
        <Container>
            {tabs?.map((tab, index) => {
                return <Tab
                    key={index}
                    selected={selectedTab?.key === tab.key}
                    beforeSelected={tabs?.[index + 1]?.key === selectedTab?.key}
                    onClick={() => clickHandler(tab)}
                >
                    {tab.icon ? <tab.icon /> : <></>}
                    <TabHeader>{tab.display}</TabHeader>
                </Tab>
            })}
        </Container>
    </>
}

export default TabSelector;