import React, { useState } from 'react';
import useEditableProps from '../hooks/useEditableProps';
import styled from 'styled-components';
import { cellStates } from './tableTypes';
import Button from '../../UiComponents/Button';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { InfoTable, InfoTableNameCell, InfoTableRow, InfoTableValueCell } from './styles/InfoTable';
import DeleteButton, { DeleteListButton } from './DeleteButton';

const ButtonContainer = styled.div`
    >button {
        margin-right: 8px;
    }
`

const EditableProps = ({ columns, parentObject, idField, saveObject, deleteObject }) => {
    const [object, updateObject, isEdited, saveHandler] = useEditableProps({ parentObject, idField, saveObject });
    const [activeIndex, setActiveIndex] = useState();
    const outsideClickRef = useOutsideClick(outsideClickHandler)

    function outsideClickHandler(event) {
        setActiveIndex(null);
    }

    return <div>
        <InfoTable>
            <tbody ref={outsideClickRef}>
                {columns.map((column, ix) => {
                    const cellState = (ix === activeIndex) ? cellStates.ACTIVE : cellStates.NONE;
                    const cellControl = { cellState, addCallback: () => { }, removeCallback: () => { } };
                    const hideInItem = column.hideInItem && column.hideInItem(object);
                    const requiredState = column.getRequiredState && column.getRequiredState(object);
                    if (!column.hide && !hideInItem) {
                        return <InfoTableRow key={ix}>
                            <InfoTableNameCell onClick={outsideClickHandler}>{column.displayName}</InfoTableNameCell>
                            <InfoTableValueCell 
                                requiredState={requiredState}
                                onClick={() => setActiveIndex(ix)}
                                >
                                {column.render(object, updateObject, cellControl)}
                            </InfoTableValueCell>
                        </InfoTableRow>
                    } else {
                        return <></>
                    }
                })}
            </tbody>
        </InfoTable>

        <ButtonContainer>

            
            <Button small disabled={!isEdited()} primary onClick={saveHandler}>Save</Button>

            <DeleteListButton onClick={() => deleteObject(object)}/>

        </ButtonContainer>
    </div>


}

export default EditableProps;