import React, { useState } from 'react';
import styled from 'styled-components';
import { getVerificationCode, verifyTypedCode } from '../../scripts/authentication';
import { emailRegEx } from '../../scripts/common';
import Button from '../UiComponents/Button';
import Card from '../UiComponents/Card';
import InputField from '../UiComponents/InputField';

const ForgotPasswordForm = styled.form`
    display: flex;
    flex-direction: column;

    button {
        width: fit-content;
    }
`

const Input = styled(InputField)`
    margin: 4px 0 10px 0;
`

const VerificationCard = (props) => {

    const [email, setEmail] = useState(null);
    const [emailValidMsg, setEmailValidMsg] = useState();
    const [verificationCode, setVerificationCode] = useState();
    const [sendCodeMsg, setSendCodeMsg] = useState();
    const [verifyMsg, setVerifyMsg] = useState();

    const sendCode = async () => {
        if (!email) {
            setEmailValidMsg("Email cannot be empty");
            return;
        } else if (!email.match(emailRegEx)) {
            setEmailValidMsg("Invalid email format (user@domain.com)");
            return;
        } else {
            setEmailValidMsg(null);
        }

        var codeSent = await getVerificationCode(email);
        if (codeSent) {
            setSendCodeMsg(`Verification code sent to ${email}`);
        } else {
            setSendCodeMsg('An error occured while sending the verification code. Try again or contact support@waved.no');
        }

    }

    const verifyCode = async () => {
        const codeInfo = await verifyTypedCode(email, verificationCode);
        if (codeInfo.verified) {
            await props.passVerificationCreds(email, verificationCode);
            props.changeVerification(true);
        }
        else if (codeInfo.outdated) {
            // The code is outdated;
            setVerifyMsg("This verification code is outdated, please request a new code.");
        } else {
            setVerifyMsg("Incorrect verification code");
        }
    }

    return <Card>
        <ForgotPasswordForm>
            <h1 className="deep-blue">Forgotten password</h1>

            <h3>Email</h3>
            <Input type="email" id="email" placeholder="user@domain.com" value={email} onChange={(e) => setEmail(e.target.value)}/>
            {emailValidMsg ? <p>{emailValidMsg}</p> : <></>}
            
            <Button primary type={'button'} onClick={sendCode}>Get verification code</Button>
            {sendCodeMsg ? <p>{sendCodeMsg}</p> : <></>}

            <h3>Verification code</h3>
            <Input
                type="email"
                id="verificationCode"
                placeholder="X X X X X X"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}/>
            <Button primary type={'button'} onClick={verifyCode}>Verify</Button>

            {verifyMsg ? <p>{verifyMsg.toString()}</p> : <></>}
        </ForgotPasswordForm>
    </Card>
}

export default VerificationCard;