import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/calibration';

async function getCalibration(customerId, calibrationId) {
    var res = await axios.get(`${hostname}${path}?customerId=${customerId}&calibrationId=${calibrationId}`);
    return res.data;
}

async function updateCalibration(customerId, calibrationId, calibration) {
    var res = await axios.put(`${hostname}${path}?customerId=${customerId}&calibrationId=${calibrationId}`, calibration);
    return res.data;
}

async function createCalibration(customerId, hubId) {
    var res = await axios.post(`${hostname}${path}?customerId=${customerId}&hubId=${hubId}`, {});
    return res.data;
}

async function deleteCalibration(customerId, calibrationId) {
    var res = await axios.delete(`${hostname}${path}?customerId=${customerId}&calibrationId=${calibrationId}`);
    return res.data;
}

async function getAllCalibrations(customerId) {
    try {
        var res = await axios.get(`${hostname}${path}/getAllCalibrations?customerId=${customerId}`);
        var data = res.data;
        if (data.error) {
            console.log(data.error);
            return false;
        } else {
            return data.result;
        }
    } catch (err) {
        return false;
    }
}


export {
    getCalibration,
    updateCalibration,
    createCalibration,
    deleteCalibration,
    getAllCalibrations,
}