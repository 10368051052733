import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import theme from '../../../UI/theme';
import { cellStates } from '../table/tableTypes';
import FieldError from './FieldError';
import { Disabled, editableColor, StyledInputField } from './Styles';

const Container = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    ${props => props.active && css`
        background-color: ${theme.colors.dropdownGray};
    `}
`

const InputFieldContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 4px;
    color: ${editableColor};
`

const ErrorContainer = styled.div`
    padding-right: 6px;
    display: flex;
    align-items: center;
`

const StringField = ({ value, setValue, cellState, error, type, disabled, maxWidth }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (cellState === cellStates.ACTIVE && !disabled) {
            ref.current.focus();
        }
    }, [cellState]);

    const onChange = (newValue) => {
        setValue(newValue);
    }

    const active = cellState === cellStates.ACTIVE || cellState === cellStates.FORM;
    return <>
        <Container active={active && !disabled}>
            <InputFieldContainer>
                {!disabled ? <>
                    {active ? <>
                        <StyledInputField
                            ref={ref}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            type={type || 'text'}
                            maxWidth={maxWidth}
                            active={active}
                        />
                    </> : <>
                        {value}
                    </>}
                </> : <>
                    <Disabled>{value}</Disabled>
                </>}

            </InputFieldContainer>

            {error && error.message ? <>
                <ErrorContainer>
                    <FieldError error={error} />
                </ErrorContainer>
            </> : <></>}
        </Container>
    </>
}

export default StringField;