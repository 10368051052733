import { postEvent } from "../../../scripts/event";
import { updateHub } from "../../../scripts/hub";
import { isSameSubnet, validateIPaddress } from "../../settings/venue/device/NetworkConfig/networkUtils";

const hasZigbeeNetwork = (hub) => {
    return hub && hub.zigbeeChannel !== undefined && hub.zigbeeChannel !== null;
}

const getDefaultSubnetIp = (subnet) => {
    return subnet + '.244';
}

const getVirtualInterfaceIpFromProcessorIp = (processorIp) => {
    const processorSubnet = processorIp.split('.').slice(0, 3).join('.');
    return getDefaultSubnetIp(processorSubnet);
}

const hasIpAddressesOnSameSubnet = (ipAddress, networkConfig) => {
    if (networkConfig) {
        return Object.keys(networkConfig).filter(key => isSameSubnet(networkConfig[key].address, ipAddress)).length > 0;
    } else {
        return false;
    }
}

const addVirtualInterface = async (hub, ip) => {
    try {
        if (validateIPaddress(ip)) {
            const updatedNetworkConfig = { ...hub.networkConfig };
            updatedNetworkConfig['eth0:0'] = {
                address: ip,
                isStatic: true
            };
            await updateHub(hub.customerId, hub.hubId, { networkConfig: updatedNetworkConfig })
            await postEvent(hub.hubId, hub.customerId, 'UPDATE_HUB');
        }
    } catch (err) {
        console.log('failed to add virtual interface');
    }
}

const getHubDisplayName = (hub) => {
    const hostAddress = hub.ipAddress?.split('.')?.[3];
    return hub.name || `Hub ${hostAddress || ''}`;
}

export {
    hasZigbeeNetwork,
    addVirtualInterface,
    getVirtualInterfaceIpFromProcessorIp,
    getDefaultSubnetIp,
    hasIpAddressesOnSameSubnet,
    getHubDisplayName
}