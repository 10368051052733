import React from 'react';
import AddressField from '../../fields/AddressField';
import { getProtocol } from '../../protocol/protocolUtils';

const SourceSelectorAddressField = ({ sourceSelector, updateSourceSelector, sourceSelectors, processors, cellState }) => {
    const processor = processors.find(processor => processor.processorId === sourceSelector.processorId); 
    const Protocol = getProtocol(processor?.type);

    const validate = (address) => {
        const processorObjects = sourceSelectors.filter(s => s.processorId === sourceSelector.processorId); 
        const addressesOnSameProcessor = processorObjects.filter(s => s.sourceSelectorId !== sourceSelector.sourceSelectorId).map(s => s.address?.toLowerCase());
        if (address && addressesOnSameProcessor.includes(address?.toString().toLowerCase())) {
            return ({type: 'WARNING', message: 'Multiple source selectors with same address on same processor'});
        } else {
            return Protocol.validateSourceSelectorAddress(address);
        }
    }

    return <AddressField
        processorId={sourceSelector.processorId}
        hubId={processor?.hubId}
        type={'SOURCE'}
        address={sourceSelector.address}
        customerId={processor?.customerId}
        setAddress={(address) => updateSourceSelector({ address })}
        error={validate(sourceSelector.address)}
        cellState={cellState}
    />
}

export default SourceSelectorAddressField;


