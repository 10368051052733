import React, { useEffect, useState, useContext } from 'react'
import { postEvent } from '../../../../scripts/event';
import { getDeviceNumber } from '../../../../scripts/common';
import AnimateHeight from 'react-animate-height';
import { DeviceContainer, DeviceIconContainer, DetailsContainer, DetailsParagraph, MT10Container } from './DeviceStyles';
import Divider from '../../../UiComponents/Divider';
import { SensorIcon } from '../../../UiComponents/Icons';
import { TextMuted } from '../../../UiComponents/text/StyledText';
import Button from '../../../UiComponents/Button';
import MoreButton from '../../../UiComponents/buttons/MoreButton';
import deviceTypes from './deviceTypes';
import SleepLimit from './Battery/SleepLimit';
import UserContext from '../../../../hooks/UserContext';
import ZoneDropdown from './Sensor/ZoneDropdown';
import Modal, { ModalContent } from '../../../UiComponents/Modal';
import useServerStatus from '../../../../hooks/useServerStatus';
import { getSensorDisplayStatus, getSensorStatus, getSensorStatusColor } from '../../../installation/utils/connectionStatus';

const SingleSensorCard = (props) => {

    const serverStatus = useServerStatus();
    const [detailed, setDetailed] = useState(false);
    const [sensorStatus, setSensorStatus] = useState();
    const [isBlinking, setIsBlinking] = useState(false);
    const [showDeleteSensorModal, setShowDeleteSensorModal] = useState(false);
    const user = useContext(UserContext);

    useEffect(() => {
        checkDevice();
    }, [props.sensor, props.hub, serverStatus]);

    const sendCommand = (command) => {
        if (props.sensor.networkType == 'WIFI') {
            postEvent(props.sensor.sensorId, props.customerId, command);
        } else {
            var payload = { sensorId: props.sensor.sensorId };
            postEvent(props.hub.hubId, props.customerId, command, payload);
        }
    }

    function changeZoneHandler(sensorId, zoneId) {
        props.updateSensorHandler(sensorId, 'zoneId', zoneId);
    }

    async function deleteSensorHandler() {
        props.deleteSensorHandler(props.sensor.sensorId);
        setShowDeleteSensorModal(false);
    }

    const changeDetailed = () => {
        setDetailed(!detailed);
    }

    const handleBlink = (e) => {
        e.stopPropagation();
        if (isBlinking) {
            setIsBlinking(false);
            sendCommand("STOP_BLINK");
        } else {
            sendCommand("BLINK");
            setIsBlinking(true);
            setTimeout(() => {
                setIsBlinking(false);
            }, 90000)
        }
    }

    const checkDevice = () => {
        const status = getSensorStatus(props.sensor, props.hub, serverStatus);
        setSensorStatus(status);
    }

    return (
        <>
            <DeviceContainer>
                <div onClick={changeDetailed} className="sensor-flex">
                    <div className="sensor-left">
                        <DeviceIconContainer>
                            <SensorIcon height={20} />
                        </DeviceIconContainer>
                        <div>
                            <div className="sensor-text" >
                                <h4>
                                    {`Sensor ${getDeviceNumber(props.sensor)}`}
                                </h4>

                                <TextMuted>
                                    {props.sensor.networkType === 'ZIGBEE' ? `${getNodeTypeDisplay(props.sensor.nodeType)} - ` : ''}
                                    {getSensorDisplayStatus(sensorStatus)}
                                </TextMuted>
                            </div>
                        </div>
                    </div>

                    {props.isEditing ?
                        <>
                            <div className="flex center-align">
                                <Button secondary margin onClick={handleBlink}>{isBlinking ? 'Stop Blink' : 'Blink Sensor'}</Button>
                                <MoreButton height={30} />
                            </div>
                        </> : <>
                            <div className="indicator-dot" >
                                <div style={{ backgroundColor: getSensorStatusColor(sensorStatus) }} ></div>
                            </div>
                        </>
                    }

                </div>

                <AnimateHeight duration={500} height={detailed ? 'auto' : 0}>

                    <DetailsContainer>
                        <Divider />

                        {props.isEditing ? <>
                            <h4>Choose zone</h4>
                            <MT10Container>
                                <ZoneDropdown
                                    zones={props.zones}
                                    sensor={props.sensor}
                                    changeZoneHandler={changeZoneHandler}
                                />
                            </MT10Container>
                            <MT10Container>
                                <Button secondary onClick={() => setShowDeleteSensorModal(true)}>Delete</Button>
                            </MT10Container>
                        </> : <>

                                <DetailsParagraph>
                                    <h4>Device Details:</h4>
                                    <TextMuted>ID: {props.sensor.sensorId}</TextMuted>
                                    {props.sensor.networkType == 'ZIGBEE' ? <>
                                        <TextMuted>Network type: Zigbee</TextMuted>
                                    </> : <></>}
                                    <TextMuted>{`Node ID: ${props.sensor.nodeId}`}</TextMuted>
                                    {props.sensor.batteryConnected ? <>
                                        <TextMuted>Battery level: {props.sensor.batteryLevel}%</TextMuted>
                                    </> : <></>}

                                    {user.user.role === 'admin' ? <>
                                        <SleepLimit sensor={props.sensor} updateSensorHandler={props.updateSensorHandler} />
                                        <TextMuted>App mode: {props.sensor.appMode}</TextMuted>
                                    </> : <></>}

                                </DetailsParagraph>

                                {props.sensor.networkType === 'ZIGBEE' ? <>
                                    <DetailsParagraph>
                                        <h4>Network:</h4>
                                        <TextMuted>LQI: {props.sensor.lqi ?? 'Network analysis required'}</TextMuted>
                                        <TextMuted>Parent: {props.sensor.parentNodeId ?? 'Network analysis required'}</TextMuted>
                                        <TextMuted>Node type: {getNodeTypeDisplay(props.sensor.nodeType) ?? 'Network analysis required'}
                                        </TextMuted>
                                    </DetailsParagraph>

                                </> : <></>}

                                <MT10Container>
                                    <Button secondary onClick={handleBlink}>{isBlinking ? 'Stop Blink' : 'Blink Sensor'}</Button>
                                    {props.sensor.networkType === 'ZIGBEE' ? <>
                                        <Button secondary margin={8} onClick={() => sendCommand('REQUEST_SENSOR_INFO')}>Request info</Button>
                                    </> : <></>}
                                </MT10Container>
                            </>}
                    </DetailsContainer>

                </AnimateHeight>
            </DeviceContainer>

            <Modal show={showDeleteSensorModal} close={() => setShowDeleteSensorModal(false)}>
                <ModalContent>
                    <h3>Are you sure you want to delete this sensor?</h3>
                    <Button primary onClick={() => deleteSensorHandler()}>Delete</Button>
                    <Button secondary onClick={() => setShowDeleteSensorModal(false)}>Cancel</Button>
                </ModalContent>
            </Modal>
        </>
    )
}

export default SingleSensorCard;

const getNodeTypeDisplay = (typeValue) => {
    var typeDisplay = deviceTypes.nodeTypes.find(nodeType => nodeType.key === parseInt(typeValue))?.display;
    if (typeDisplay) {
        return typeDisplay;
    } else {
        return '';
    }
}