import styled from "styled-components";
import theme from "../../../../../UI/theme";

export const NetworkAnalysisContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const NetworkHeader = styled.h4`
    margin: 10px 0;
`

export const NodeList = styled.ul`
    margin: 0;
    padding: 0;

    li > ul {
        margin-left: 20px;
    }
`

export const ListItem = styled.li`
    list-style-type: none;
    margin: 5px 0;
`

export const SensorInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const LQILabel = styled.span`
    color: ${theme.colors.greenEnergy};
    border-radius: 4px;
    margin: 0 10px;
    padding: 2px 4px;
`