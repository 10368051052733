import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { scanForProcessors } from '../../../../../scripts/hub';
import Button from '../../../../UiComponents/Button';
import { TextMuted } from '../../../../UiComponents/text/StyledText';
import { MT10Container, SectionHeader } from '../DeviceStyles';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0 0 0;
`

const ScanButton = styled(Button)`
    width: fit-content;
`

const ResultList = styled.ul`
    margin: 10px 0 0 0;
    padding: 0;
`

const ScanResult = styled.li`
    display: flex;
    flex-direction: column;
    list-style-type: none;
    :not(:last-of-type) {
        margin-bottom: 10px;
    }
`

const ProcessorScanner = props => {
    const [scanPerformed, setScanPerformed] = useState(false);
    const [isScanning, setIsScanning] = useState(false);

    useEffect(() => {
        if (props.availableProcessors && props.availableProcessors.length) {
            setIsScanning(false);
        }
    }, [props.availableProcessors])

    const onClickHandler = async () => {
        try {
            scanForProcessors(props.customerId, props.hubId);
            setIsScanning(true);
            setScanPerformed(true);
            setTimeout(() => {
                setIsScanning(false);
            }, 30000);
        } catch (err) {
            setIsScanning(false);
        }
    }

    return <Container>

        <ScanButton small secondary onClick={onClickHandler}>
            Scan for processors
        </ScanButton>

        {scanPerformed ? <>
            <MT10Container>
                {!isScanning ? <>
                    <div><span>Scan results: </span></div>

                    {props.availableProcessors && props.availableProcessors.length ?
                        <ResultList>
                            {props.availableProcessors.map((res, index) => {
                                var keys = Object.keys(res);
                                return (<ScanResult key={index}>
                                    <label>Result</label>
                                    {keys?.map(x => {
                                        return <div>
                                            <TextMuted>{x}: {res[x]}</TextMuted>
                                        </div>
                                    })}
                                </ScanResult>
                                )
                            })}
                        </ResultList>
                        : <>
                            <TextMuted>No processors found</TextMuted>
                        </>}
                </>
                    : <>
                        <TextMuted>Scanning...</TextMuted>
                    </>}
            </MT10Container>
        </>
            : <></>}
    </Container>
}

export default ProcessorScanner;