import styled from "styled-components";
import Modal from "../UiComponents/Modal";
import LiveChart from "./charts/LiveChart";
import { ModalContainer, ModalHeader } from "./ModalStyles";

const ChartContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: 200px;

    canvas {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
    }
`

const ChartModal = props => {
    return <Modal show={props.show} close={props.close}>
        <ModalContainer>
            <ModalHeader>{props.zone?.zoneName} - Live data</ModalHeader>

            <ChartContainer>
                <LiveChart data={props.graphData} detailed/>
            </ChartContainer>
        </ModalContainer>
    </Modal>
}

export default ChartModal;