import styled from 'styled-components'
import theme from '../../../../../UI/theme'

export const AudioList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 10px;

    li {
        margin-top: 10px;
    }
`

export const AudioListItem = styled.li`
    display: flex;
    align-items: center;
    >div {
        margin-right: 8px;
    }
    :hover {
        color: ${theme.colors.darkSpace50};
    }
    color: ${theme.colors.darkSpace20};
`

export const AudioHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid gray;
    padding: 4px;
`