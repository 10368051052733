import React, { useState } from 'react';
import { sortByKey } from '../../../../../../scripts/common';
import Button from '../../../../../UiComponents/Button';
import Dropdown from '../../../../../UiComponents/Dropdown';
import InputField, { InputFieldContainer } from '../../../../../UiComponents/InputField';
import sourceSelectorTypes from './sourceSelectorTypes';

const SourceSelectorEditor = props => {
    const [sourceSelector, setSourceSelector] = useState({...props.sourceSelector});
    const updateAttributes = (updatedAttributes) => {
        setSourceSelector(current => ({...current, ...updatedAttributes}));
    }

    const saveHandler = () => {
        var isNew = sourceSelector.sourceSelectorId === undefined;
        props.save(sourceSelector, isNew);
    }

    return <div>
        <InputFieldContainer>
            <div><label>Name</label></div>
            <InputField value={sourceSelector.name || ''} onChange={(e) => updateAttributes({ name: e.target.value })} />
        </InputFieldContainer>

        <InputFieldContainer>
            <div><label>Address</label></div>
            <InputField value={sourceSelector.address || ''} onChange={(e) => updateAttributes({ address: e.target.value })} />
        </InputFieldContainer>

        <InputFieldContainer>
            <div><label>Type</label></div>
            <Dropdown 
                small
                items={sortByKey(Object.values(sourceSelectorTypes), 'label')}
                onClick={(item) => updateAttributes({type: item.value})}
                selectedItem={sourceSelectorTypes[sourceSelector.type]}
            />
        </InputFieldContainer>
        <div>
            <Button margin primary onClick={saveHandler}>Save</Button>
            <Button margin secondary onClick={() => props.cancel()}>Cancel</Button>
            {sourceSelector.sourceSelectorId ? <>
                <Button margin tertiary lightBorder onClick={() => props.delete(sourceSelector)}>Delete</Button>
            </> : <></>}
        </div>
    </div>
}

export default SourceSelectorEditor;