import { useRef, useState } from "react";
import { installationTabs } from "../installation/installationTypes";
import Button from "../UiComponents/Button";
import Modal, { ModalContent } from "../UiComponents/Modal";
import TabSelector from "../UiComponents/TabSelector";

const InstallationTabSelector = props => {
    const [showModal, setShowModal] = useState(false);
    const nextTab = useRef();

    const onClick = tab => {
        if (props.editableTableRef.current?.isEdited()) {
            nextTab.current = tab;
            setShowModal(true);
        } else {
            props.onClick(tab);
        }
    }

    const save = async () => {
        try {
            if (props.editableTableRef.current?.saveHandler) {
                await props.editableTableRef.current?.saveHandler();
            } 
            goToNextTab();
        } catch (err) {
            console.log('Failed to save', err);
        } finally {
            nextTab.current = null;
        }
    }

    const goToNextTab = () => {
        setShowModal(false);
        if (nextTab.current) {
            props.onClick(nextTab.current);
        }
    }

    return <>
        <TabSelector tabs={installationTabs} selectedTab={props.selectedTab} onClick={onClick} />
        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContent>
                <h3>Continue without saving?</h3>
                <p>You have unsaved changes on the current tab. Do you want to save before continuing?</p>
                <Button primary onClick={save}>Save</Button>
                <Button secondary onClick={goToNextTab}>Cancel</Button>
            </ModalContent>
        </Modal>
    </>
}

export default InstallationTabSelector;