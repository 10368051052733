import React, { useState } from 'react';
import styled from 'styled-components';
import { buildLocalApp } from '../../../scripts/app';
import { timeSince } from '../../../scripts/common';
import theme from '../../../UI/theme';
import Button from '../../UiComponents/Button';
import Modal, { FixedWidthModalContent, ModalContent } from '../../UiComponents/Modal';
import { TextMuted } from '../../UiComponents/text/StyledText';

const Container = styled.div`
    border: 1px solid ${theme.colors.darkSpace70};
    border-radius: 4px;
    padding: 10px;
    display: inline-block;
    margin: 10px 0;
    font-size: ${theme.fontSize.small}
`

const MetaData = styled(TextMuted)`
    font-size: ${theme.fontSize.tiny};
`

const LocalAppVersion = ({ localAppVersion, customerId }) => {
    return <Container>
        <span>Available local app version: {localAppVersion.version} </span> <br />
        {localAppVersion.behindMaster > 0 ? <>
            <MetaData>{`${localAppVersion.behindMaster} deploy${localAppVersion.behindMaster > 1 ? 's' : ''} behind master`} (Updated {timeSince(new Date(localAppVersion.lastUpdated))} ago) </MetaData>
            <div style={{ marginTop: '12px' }}>
                <TriggerLocalAppBuildButton customerId={customerId} />
            </div>
        </> : <></>}

    </Container>
}

export default LocalAppVersion;

const TriggerLocalAppBuildButton = ({ customerId }) => {
    const [showModal, setShowModal] = useState(false);

    const triggerBuild = async () => {
        try {
            await buildLocalApp(customerId);
        } catch (err) {
            console.log('failed to trigger');
        } finally {
            setShowModal(false);
        }
    }

    return <>
        <Button secondary small onClick={() => setShowModal(true)}>Trigger build</Button>
        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContent>
                <p>Are you sure you want to trigger a local app build? This will take about 2 minutes.</p>
                <Button primary onClick={triggerBuild}>Trigger build</Button>
                <Button secondary onClick={() => setShowModal(false)}>Cancel</Button>
            </ModalContent>
        </Modal>
    </>
}