import React from 'react';
import { useSubscription } from '../../hooks/useSubscription';
import { subscriptionsTypes } from '../../scripts/subscription/subscriptionTypes';
import ViewContainer from '../UiComponents/ViewContainer';

const WebSocketView = props => {
    const [zones, setZones] = useSubscription(subscriptionsTypes.zone.key);

    return <ViewContainer>
        <div>
            WebSocket
            {zones ? <>
                {zones.map(zone => {
                    return <div key={zone.zoneId}>
                        {zone.zoneName}: {zone.isRegulating}
                    </div>
                })}
            </> : <></>}
        </div>
    </ViewContainer>
}

export default WebSocketView;
