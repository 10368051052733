import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import theme from '../../UI/theme';
import ContentSettings from './ContentSettings';
import Card from '../UiComponents/Card';
import { MdSettings } from 'react-icons/md';
import MusicInfoDisplay from './MusicInfoDisplay';
import Dropdown from '../UiComponents/Dropdown';
import UserContext from '../../hooks/UserContext';
import MusicPlayer from './MusicPlayer';

const StyledCard = styled(Card)`
    max-width: 600px;
    width: 90%;
`

const MusicInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 4px;
`

const PlayingContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    background: linear-gradient(90deg, ${props => props.color? props.color: theme.colors.darkSpace80} 0%, ${theme.colors.darkSpace70} 70%, ${theme.colors.darkSpace90} 100%);
    padding: 16px;
    border-radius: 4px;
    width: 100%;
    height: 132px;
`// Sangfarge --> background-color: ${props => props.color? props.color: theme.colors.darkSpace80};

const SettingsButton = styled.div`
    position: absolute; 
    top: 10px;
    right: 10px;
    color: ${theme.colors.darkSpace70};
`
const Heading = styled.h4`
    margin-bottom: 0px;
`
const SubHeading = styled.h5`
    margin: 4px 0px;
`

const ArtistText = styled.h5`
    margin-top: 0px;
    margin-bottom: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`

const AlbumImage = styled.img`
    border-radius: 8px;
    height: 100%;
`

const StyledDropdown = styled(Dropdown)`
    position: absolute; 
    bottom: 0;
    margin-bottom: 5px;
    background-color: ${theme.colors.darkSpace60};
    opacity: 0.8;
    color: white;
    width: 220px;
`

const StyledDropdownText = styled.span`
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 165px;
`

const SongInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 20px;
    padding: 0px 16px;
    border-radius: 4px;
    width: fill-available;
    position: relative;
`


const SongTitle = styled.h4`
    margin-top: 8px;
    margin-bottom: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`

// Must be declared outside of functionall component. 
var musicPlayer;

const MusicDisplay = (props) => {

    const [music, setMusic] = useState({});

    useEffect(() => {
        musicPlayer = new MusicPlayer(props.musicPlayer, musicPlayerCallback);
        musicPlayer.init()
        .then(() => setDataState('GOT_DATA')); 
    },[]);

    const musicPlayerCallback = (updatedMusic) => {
        setMusic(updatedMusic);
        setUserAction('NONE')
    }
    //////////////////// END RELEVANT FOR MUSIC PLAYER USAGE /////////////////
    //////////////////////////////////////////////////////////////////////////
    ///////////////// BELOW FOLLOWS ONE WAY OF USING MUSICPLAYER ///////////// 

    const user = useContext(UserContext);

    // Visual states, nothing to do with MusicPlayer
    const [dataState, setDataState] = useState('LOADING'); // need to know when we have data
    const [userAction, setUserAction] = useState('NONE'); // so that we can use spinners to indicate actions
    const [showSettings, setShowSettings] = useState(false); 

    async function play() {
        setUserAction('PLAY');
        musicPlayer.play();
        
    }

    async function pause() {
        setUserAction('PAUSE');
        musicPlayer.pause();
    }
    async function nextSong() {
        setUserAction('NEXT');
        musicPlayer.nextSong();
        musicPlayer.play();
    }

    async function wakeupPlayer() {
        musicPlayer.wakeupPlayer();
    }
    

    return (
        <StyledCard>
            <Heading>Player: {props.musicPlayer.displayName}</Heading>
            <SubHeading>On source: {props.musicPlayer.sourceDisplayName}</SubHeading>
            <MusicInfoContainer>
                <PlayingContainer color={music.songColor}>
                    {(music.state)?<>
                        <AlbumImage src = {music.imageUrl}></AlbumImage>
                        <SongInfoContainer>
                            <SongTitle>{music.songTitle}</SongTitle>
                            <ArtistText>{music.artists[0]}</ArtistText>
                            {/*<SongTitle>Playlist</SongTitle>*/}
                            {music.availablePlaylists?<>
                                <StyledDropdown
                                    items={music.availablePlaylists}
                                    selectedItem={{label: <StyledDropdownText>{music.playlist}</StyledDropdownText>}}
                                    onClick={(item) => {
                                        musicPlayer.queuePlaylist(item.id, item.name);
                                        //setPlaylist(`(next) ${item.name}`);
                                    }}
                                />
                            </>:<></>}
                        </SongInfoContainer>
                    </>:<></>}
                    <MusicInfoDisplay
                        state = {dataState}
                        playState = {music.state}
                        userAction = {userAction}
                        play = {play}
                        pause = {pause}
                        nextSong = {nextSong}
                        wakeupPlayer = {wakeupPlayer}
                        color={music.color}
                    />
                    {user.user.role === "admin"?
                        <SettingsButton>
                            <MdSettings fontSize="25px" onClick={()=>setShowSettings(!showSettings)}></MdSettings>
                        </SettingsButton>
                    :<></>}

                </PlayingContainer>
            </MusicInfoContainer>

            {musicPlayer && music?<>
            <ContentSettings
                show={showSettings}
                close={() => {setShowSettings(false)}}
                musicPlayer={musicPlayer}
                currentMusic = {music}
            />
            </>:<></>}
        </StyledCard>

    )
}
export default MusicDisplay;