import React, { useEffect, useState } from 'react';
import { getAverageTrend, getTrendData } from '../../../../scripts/trends';
import trendTypes from '../../../statistics/trendTypes';
import ZoneActivityGraph, { ZoneContainer } from './ActivityGraph';
import { getDayOfWeek } from '../../../../scripts/common';
import ActivityContext from './ActivityContext';


const ZoneActivityGraphContainer = props => {
    const [graphData, setGraphData] = useState();

    useEffect(() => {
        getDataHandler();
    }, [props.customerId]);

    const getDataHandler = async () => {
        var trendData = await getTrendData(props.customerId, 'WEEK', new Date(), props.type, props.zoneId, trendTypes.dataTypes.averageDecibel);

        var avgTrendData = await getAverageTrend(props.customerId, props.zoneId, trendTypes.dataTypes.averageDecibel, props.type);
        var dayOfWeek = getDayOfWeek(new Date());

        var labels = ([...Array(24).keys()]).map(key => ("0" + key).slice(-2));

        if (trendData && trendData.length && avgTrendData) {
            var updatedGraphData = {
                trend: avgTrendData.data[dayOfWeek].hourData,
                labels: labels,
                data: trendData[0]?.data[dayOfWeek].hourData
            }
            fillInMissingDataEarlyInCurrentHour(updatedGraphData.data);
            setGraphData(updatedGraphData);

        } else {
            setGraphData(null);
        }
    }

    return <>
        <ZoneContainer>
            <ZoneActivityGraph data={graphData} highlightIndex={new Date().getHours()} />
            </ZoneContainer>
            <ActivityContext
                trendDataForHour={getDataForCurrentHour(graphData?.trend)}
                currentHourValue={getDataForCurrentHour(graphData?.data)}
            />
        </>
}

export default ZoneActivityGraphContainer;


function fillInMissingDataEarlyInCurrentHour(hourData) {
    try {
        var hourIndex = new Date().getHours();
        if (!hourData[hourIndex]) {
            var minutes = new Date().getMinutes();
            if (minutes < 10 && hourData[hourIndex-1]) {
                hourData[hourIndex] = hourData[hourIndex-1];
            }
        }
        
    } catch (err) {
        
    }
}

function getDataForCurrentHour(hourData) {
    if (hourData) {
        var hourIndex = new Date().getHours();
        return hourData[hourIndex];
    } else {
        return;
    }

}