import React, { useState, useEffect, useRef } from 'react';
import HubItem from './HubItem';
import ZoneOverview from './ZoneOverview';
import DeviceOptions from './DeviceOptions';
import { createZone } from '../../../../scripts/zone';
import { useSubscription } from '../../../../hooks/useSubscription';
import { subscriptionsTypes } from '../../../../scripts/subscription/subscriptionTypes';
import useServerStatus from '../../../../hooks/useServerStatus';
import LocalAppLink from './LocalAppLink';
import { postEvent } from '../../../../scripts/event';

const DeviceOverview = props => {
    const [allHubs, setAllHubs] = useSubscription(subscriptionsTypes.hubs);
    const [allProcessors, setAllProcessors] = useSubscription(subscriptionsTypes.processors);
    const [sourceSelectors, setSourceSelectors] = useSubscription(subscriptionsTypes.sourceSelectors);
    const [allZones, setAllZones] = useSubscription(subscriptionsTypes.zones);
    const [allSensors, setAllSensors] = useSubscription(subscriptionsTypes.sensors);
    const [isEditing, setIsEditing] = useState(false);
    
    async function addZone(newZone) {
        await createZone(props.customerId, newZone);
        await postEvent(newZone.hubId, props.customerId, 'GET_ALL_ZONE_SETTINGS');
    }

    const getDataHandler = async () => {

    }
    const hub = allHubs?.find(hub => hub.hubId === props.hubId);
    const processors = allProcessors?.filter(processor => processor.hubId === props.hubId);
    const zones = allZones?.filter(zones => zones.hubId === props.hubId);
    const sensors = allSensors?.filter(sensor => sensor.hubId === props.hubId);

    return <>
        {hub && processors && zones && sourceSelectors && sensors ? <>
            {process.env.REACT_APP_HOST_TYPE !== 'HUB' ? <>
                <LocalAppLink hub={hub} />
            </> : <></>}

            <div>
                <h3>Hub</h3>
            </div>
            <HubItem
                hub={hub}
                sensors={sensors}
                processors={processors}
                sourceSelectors={sourceSelectors}
                user={props.user}
                customerId={props.customerId}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                refreshDevices={getDataHandler}
                missingObjectAddresses={zones?.filter(zone => !zone.objectAddress).length}
                readOnly={props.readOnly}
            />
            <ZoneOverview
                hub={hub}
                user={props.user}
                customerId={props.customerId}
                zones={zones}
                sensors={sensors}
                processors={processors}
                sourceSelectors={sourceSelectors}
                refreshDevices={getDataHandler}
                addZone={addZone}
                readOnly={props.readOnly}
            />

            <DeviceOptions
                hubId={props.hubId}
                user={props.user}
                customerId={props.customerId}
                refreshDevices={getDataHandler}
                readOnly={props.readOnly}
            />

        </> : <>
                <div>
                    <div className="controls-container">
                        {/* <SetupLink /> */}
                    No devices found
                </div>
                </div>
            </>}
    </>
}

export default DeviceOverview;


function parseMapValues(zone) {

    var parsedMapValues;
    if (zone.processorMapValues) {
        parsedMapValues = zone.processorMapValues.split(',').map(mv => parseInt(mv));
    }
    if (!parsedMapValues || parsedMapValues.filter(mv => isNaN(mv)).length > 0) {
        parsedMapValues = [40, 60, 80];
    }
    zone.parsedMapValues = parsedMapValues;
}