import styled, {css} from 'styled-components';
import theme from '../../UI/theme';

const ViewHeader = styled.div`
    display: grid;
    grid-template-columns: 15% 70% 15%;
    width: 100%;
    text-align: center;
    align-items: center;
    margin: 12px 0;
    color: ${theme.colors.textGray};
    h3 {
        font-weight: normal;
    }

    ${props => props.fitToSmallScreen && css`
        @media only screen and (max-width: ${theme.screenSizes.small}px) {
            margin: 6px 0;
        }
    `}
`

export default ViewHeader;