import styled, { css } from 'styled-components';
import { toDisplayTime } from '../../../scripts/common';
import theme from '../../../UI/theme';
import { TextMuted } from '../../UiComponents/text/StyledText';
import { SchedulesContainer, ScheduleSummary } from './StyledComponents/ZoneItemStyles';
import scheduleTypes from '../../settings/venue/schedule/scheduleTypes';
import IconLogo from '../../UiComponents/IconLogo';
import { FaSeedling, FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import VibeChangeIcon from '../../UiComponents/VibeChangeIcon';

const TimeLine = styled.div`
    width: 100%;
    border-top: 1px solid ${theme.colors.textGray};
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
`

const Dot = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${theme.colors.greenEnergy};
    position: absolute;
    top: -5px;
    left: 5px;
`

const ScheduleDetails = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
`

const ScheduleIconsContainer = styled.div`
    display: grid;
    height: fit-content;
    grid-template-rows: auto auto;
    row-gap: 4px;
    grid-template-columns: auto auto;
    column-gap: 4px;
    align-items: center;
    justify-items: center;
    margin-top: 2px;
    direction: rtl;
`

const ExtraMuted = styled.span`
    color: ${theme.colors.darkSpace60};
`

const StyledLogo = styled(IconLogo)`
    width: ${theme.fontSize.small};
    height: ${theme.fontSize.small};
    margin-bottom: 4px;
    
    ${props => props.disabled && css`
        rect {
            fill: ${theme.colors.darkSpace70};
        }
    `}
`

const MuteIcon = styled(FaVolumeMute)`
    color: ${theme.colors.darkSpace70};
    font-size: ${theme.fontSize.small};
`

const UnmuteIcon = styled(FaVolumeUp)`
    color: ${theme.colors.greenEnergy};
    font-size: ${theme.fontSize.small};
`

const MoodSustainIcon = styled(FaSeedling)`
    color: ${theme.colors.greenEnergy};

    ${props => props.disabled && css`
        color: ${theme.colors.darkSpace70};
    `}
`

const VibeChangeIconContainer = styled.div`
    color: ${theme.colors.greenEnergy};
`

const ZoneSchedules = props => {

    return <SchedulesContainer>
        <TimeLine><Dot /></TimeLine>
        <TimeLine><Dot /></TimeLine>
        <ScheduleElement header={"Previous schedule"} schedule={props.previousSchedule} nullMessage={'No previous schedules'} />
        <ScheduleElement header={"Next schedule"} schedule={props.nextSchedule} nullMessage={'No schedules coming up'} />
    </SchedulesContainer>
}

export default ZoneSchedules;

const ScheduleElement = props => {
    return <ScheduleSummary>
        <ScheduleDetails>
            <TextMuted>{props.header}</TextMuted>
            <ExtraMuted>{props.schedule?.displayName ?? props.nullMessage}</ExtraMuted>
            <ExtraMuted>{props.schedule ? `${toDisplayTime(props.schedule.time)}` : ''}</ExtraMuted>
        </ScheduleDetails>

        <ScheduleIconsContainer>
            {props.schedule?.scheduleTypes?.includes(scheduleTypes.types.REGULATION) ?
                <StyledLogo
                    width={window.screen.width > theme.screenSizes.small ? '1.5rem' : '1rem'}
                    height={window.screen.width > theme.screenSizes.small ? '1.5rem' : '1rem'}
                    disabled={!props.schedule?.isRegulating} />
                : <></>}

            {props.schedule?.scheduleTypes?.includes(scheduleTypes.types.VIBE) ?
                <VibeChangeIconContainer>
                    <VibeChangeIcon vibeChange={props.schedule.vibeChange} />
                </VibeChangeIconContainer>
                : <></>}

            {props.schedule?.scheduleTypes?.includes(scheduleTypes.types.MUTE) ?
                <>{props.schedule?.mute ? <MuteIcon /> : <UnmuteIcon />}</>
                : <></>}

            {props.schedule?.scheduleTypes?.includes(scheduleTypes.types.MOOD_SUSTAIN) ?
                <MoodSustainIcon disabled={!props.schedule?.moodSustain} />
                : <></>}
        </ScheduleIconsContainer>
    </ScheduleSummary>
};