import { useHistory } from "react-router";
import { getCustomerErrors } from "../../../scripts/client";
import { useEffect, useState } from "react";
import SupportTableHeader from "./SupportTableHeader";
import { Table, TableContainer } from "./SupportStyles";
import SupportTableRow from "./SupportTableRow";
import { applyErrorTypeFilter, applyPriorityFilter } from "./errorUtils";
import errorColumns from "./errorColumns";


const SupportTable = props => {
    const history = useHistory();
    const [customerData, setCustomerData] = useState();
    const [filter, setFilter] = useState();
    const [priorityFilter, setPriorityFilter] = useState([]);

    useEffect(() => {
        getData().then((res) => {
            setCustomerData(res);
        })
    }, [])

    function setImpersonation(customerId) {
        props.setCustomer(customerId);
        history.push(`/settings/venue/${customerId}`);
    };

    function goToSensorDetail(customerId, sensorId) {
        props.setCustomer(customerId);
        setTimeout(() => { history.push(`/admin/sensor-detail/${sensorId}`); }, 500)
    }

    const getData = async () => {
        var data = await getCustomerErrors();
        return data;
    }

    return <TableContainer>
        <Table>
            <thead>
                <SupportTableHeader errorColumns={errorColumns} filter={filter} setFilter={setFilter} priorityFilter={priorityFilter} setPriorityFilter={setPriorityFilter} />
            </thead>
            {customerData ? <>
                <tbody>
                    {applyFilter(customerData?.customerSummaries, filter, priorityFilter)
                        ?.filter(summary =>
                            !props.searchValue ||
                            summary.customerId === parseInt(props.searchValue) ||
                            summary.companyName?.toLowerCase().includes(props.searchValue.toLowerCase()))
                        ?.map((customer, index) => {
                            return <SupportTableRow
                                errorColumns={errorColumns}
                                key={index}
                                {...customer}
                                setImpersonation={setImpersonation}
                                goToSensorDetail={goToSensorDetail} />
                        })}
                </tbody>
            </> : <></>}
        </Table>
    </TableContainer>

}

export default SupportTable;

const applyFilter = (array, columnFilter, priorityFilter) => {
    var filteredArray = applyErrorTypeFilter(array, columnFilter);
    filteredArray = applyPriorityFilter(filteredArray, priorityFilter);
    return filteredArray;
}
