import React from 'react';
import styled from 'styled-components';
import FloorSelector from '../FloorSelector';

const MenuContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const MenuBar = props => {

    return <MenuContainer>

        {props.floors && props.floors.length > 1 ? <>
            <FloorSelector
                floors={props.floors}
                activeFloor={props.activeFloor}
                setActiveFloor={props.setActiveFloor}
            />
        </> : <>
                <div></div>
            </>}

    </MenuContainer>
}

export default MenuBar;