import { Chart } from "chart.js";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import theme from "../../../../UI/theme";

const Container = styled.div`
    width: fill-available;
    height: 30vh;
    margin-bottom: 10px;
`

const chartData = {
    datasets: [
        {
            label: 'Sweep',
            data: [],
            pointRadius: 0,
            backgroundColor: theme.colors.purplePleasure,
            borderColor: theme.colors.purplePleasure,
            borderWidth: 1,
            showLine: true,
            pointStyle: 'line',
            order: 6
        },
        {
            label: 'Saved',
            data: [],
            pointRadius: 3,
            backgroundColor: theme.colors.pinky,
            borderColor: theme.colors.pinky,
            borderWidth: 1,
            showLine: true,
            pointStyle: 'circle',
            order: 3
        },
        {
            label: 'New',
            data: [],
            pointRadius: 3,
            pointHitRadius: 5,
            backgroundColor: theme.colors.greenEnergy,
            borderColor: theme.colors.greenEnergy,
            showLine: true,
            borderWidth: 1,
            pointStyle: 'circle',
            order: 2
        },
        {
            label: 'Live',
            data: [],
            pointRadius: 3,
            pointHitRadius: 5,
            backgroundColor: theme.colors.raspberryRed,
            borderColor: theme.colors.raspberryRed,
            pointStyle: 'circle',
            order: 1
        },
        {
            label: 'Suggested',
            data: [],
            pointRadius: 3,
            pointHitRadius: 5,
            backgroundColor: theme.colors.darkSpace90,
            borderColor: theme.colors.crystalBlue,
            showLine: true,
            borderWidth: 1,
            borderDash: [5, 2],
            pointStyle: 'circle',
            order: 4
        }
    ]
}

const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            min: 0,
            max: 100,
            ticks: {
                color: theme.colors.textGray,
            },
            grid: {
                color: theme.colors.darkSpace80
            },
            title: {
                text: 'System volume [%]',
                display: true,
                color: theme.colors.textGray
            }
        },
        y: {
            min: 0,
            suggestedMax: 80,
            ticks: {
                color: theme.colors.textGray,
            },
            grid: {
                color: theme.colors.darkSpace80
            },
            title: {
                text: 'Decibel',
                display: true,
                color: theme.colors.textGray
            }
        }
    },
    plugins: {
        legend: {
            display: true,
            labels: {
                usePointStyle: true,
                boxWidth: 5,
                color: theme.colors.textGray
            }
        },
    }
}

const defaultChartConfig = {
    type: 'scatter',
    data: chartData,
    options
}

const ZoneResultGraph = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);
    const chartConfig = useRef(JSON.parse(JSON.stringify(defaultChartConfig)));

    chartConfig.current.data.datasets[0].data = props.data?.sweepResult?.map((point, index) => { return { x: index, y: point } });

    chartConfig.current.data.datasets[1].data = props.data?.savedCalibrationPoints?.map((point) => { return { x: point.sysvol, y: point.measuredDb } });

    chartConfig.current.data.datasets[2].data = props.data?.newCalibrationPoints?.map((point) => { return { x: point.sysvol, y: point.measuredDb } });

    if (props.data?.liveZoneValues?.sysvol || props.data?.liveZoneValues?.averageDecibel) {
        chartConfig.current.data.datasets[3].data = [{ x: props.data?.liveZoneValues?.sysvol, y: props.data?.liveZoneValues?.averageDecibel }];
    }

    chartConfig.current.data.datasets[4].data = props.data?.suggestedPoints?.map((point) => { return { x: point.sysvol, y: point.measuredDb } });

    if (chartInstance) {
        chartInstance.update();
    }

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            if (chartInstance) {
                chartInstance.destroy();
            }
            const newChartInstance = new Chart(chartContainer.current, { ...chartConfig.current });
            setChartInstance(newChartInstance);
        }
    }, [chartContainer])

    return <Container>
        <canvas ref={chartContainer} style={{ height: '100%', width: '100%' }} />
    </Container>
}

export default ZoneResultGraph;