import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import SettingsMain from '../components/settings/SettingsMain';
import VenueSettings from '../components/settings/venue/VenueSettings';
import UserSettings from '../components/settings/user/UserSettings';
import ScheduleView from '../components/settings/venue/schedule/ScheduleView';
import CalibrationView from '../components/settings/venue/zone/calibration/CalibrationView';
import DeviceView from '../components/settings/venue/device/DeviceView';
import AutoCalibrationRouter from '../components/settings/venue/calibration/AutoCalibrationRouter';
import MusicContentView from '../components/musicPlayer/MusicContentView';

const SettingsView = props => {
    let match = useRouteMatch();

    return <div>
        <Switch>
            <Route path={`${match.path}/user`}>
                <UserSettings customerId={props.customerId} />
            </Route>
            <Route path={`${match.path}/venue/:id/schedules`}>
                <ScheduleView />
            </Route>
            <Route path={`${match.path}/venue/:id/calibratezone/:zoneId`}>
                <CalibrationView />
            </Route>
            <Route path={`${match.path}/venue/:id/device/:hubId`}>
                <DeviceView />
            </Route>
            <Route path={`${match.path}/venue/:id/autocalibration`}>
                <AutoCalibrationRouter />
            </Route>
            <Route path={`${match.path}/venue/:id/music`}>
                <MusicContentView/>
            </Route>
            <Route path={`${match.path}/venue/:id`}>
                <VenueSettings />
            </Route>

            <Route path={`${match.path}`}>
                <SettingsMain venues={props.venues} />
            </Route>
        </Switch>
    </div>
}

export default SettingsView;