import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const zonePath = '/v2/zone';

const updateZone = async (customerId, zoneId, updatedZone) => {
    const result = await axios.put(`${hostname}${zonePath}/zone?customerId=${customerId}&zoneId=${zoneId}`, updatedZone);
    return result.data;
}

const updateZones = async (customerId, zones, preventEvent) => {
    const result = await axios.put(`${hostname}${zonePath}/zones?customerId=${customerId}`, zones);
    return result.data;
}

const getZones = async (customerId) => {
    const result = await axios.get(`${hostname}${zonePath}/zones?customerId=${customerId}`);
    return result.data;
}

const getZone = async (customerId, zoneId) => {
    const result = await axios.get(`${hostname}${zonePath}/zone?customerId=${customerId}&zoneId=${zoneId}`);
    return result.data;
}

const createZone = async (customerId, zone) => {
    const res = await axios.post(`${hostname}${zonePath}/zone?customerId=${customerId}`, zone);
    return res.data;
}

const deleteZone = async (customerId, zoneId) => {
    const res = await axios.delete(`${hostname}${zonePath}/zone?customerId=${customerId}&zoneId=${zoneId}`);
    return res.data;
}

const switchGlobalRegulation = async (customerId, state) => {
    const res = await axios.put(`${hostname}${zonePath}/globalRegulation?customerId=${customerId}`, { state });
    return res.data;
}

const updateZoneOrder = async (customerId, zones) => {
    const res = await axios.post(`${hostname}${zonePath}/updateZoneOrder?customerId=${customerId}`, { zones });
    if (res.data.error) { console.log(res.data.error); return false; };
    return true;
}


export {
    updateZone,
    updateZones,
    getZones,
    getZone,
    createZone,
    deleteZone,
    switchGlobalRegulation,
    updateZoneOrder
};