import React from 'react';
import styled from 'styled-components';
import theme from '../../../UI/theme';
import FloorSelector from './FloorSelector';
import heatmapTypes from './heatmapTypes';
import {FaEdit, FaTimes, FaPlus, FaSlidersH} from 'react-icons/fa';
import Button from '../../UiComponents/Button';

const MenuContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
`

const FloorSelectorContainer = styled.div`
    display: flex;
`

const AddFloorButtonContainer = styled.div`
    margin-left: 12px;
`

const MenuBar = props => {

    const showEditButton = (window.innerWidth > theme.screenSizes.medium);

    return <MenuContainer>

            <FloorSelectorContainer>
                <FloorSelector 
                    floors={props.floors} 
                    activeFloor={props.activeFloor} 
                    setActiveFloor={props.setActiveFloor} 
                    
                />

                {props.activeMode === heatmapTypes.modes.SETUP ? <>
                <AddFloorButtonContainer>
                    <Button secondary icon small onClick={props.addFloorHandler}>
                        <span>Add floor</span>
                        <FaPlus />
                    </Button>
                </AddFloorButtonContainer>
                </> : <></>}
            </FloorSelectorContainer>

            
                <div>
                    {props.activeMode === heatmapTypes.modes.DATA ? <>
                        {showEditButton ? <>
                            <Button secondary icon small onClick={() => props.setModeHandler(heatmapTypes.modes.SETUP)}>
                                <span>Edit map</span><FaEdit />
                            </Button>
                        </> : <></>}
                    </> : <></>}
                
                    {props.activeMode === heatmapTypes.modes.SETUP ? <>
                        <Button secondary icon small onClick={() => props.setModeHandler(heatmapTypes.modes.DATA)}>
                            <span>End edit</span><FaTimes />
                        </Button>
                    </> : <></>} 
                </div>
            
    </MenuContainer>
}

export default MenuBar;