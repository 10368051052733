import styled from "styled-components";
import theme from "../../../UI/theme";

export const SkeletonItem = styled.div`
    @keyframes skeletonShimmer{
        0%{
            background-position: -100% 0
        }
        100%{
            background-position: 100% 0
        }
    }

    width: 100%;
    height: 100%;
    background: linear-gradient(to right, ${theme.colors.darkSpace90} 10%, ${theme.colors.darkSpace70} 18%, ${theme.colors.darkSpace90} 33%);
    background-size: 200%;
    animation: skeletonShimmer 2s infinite reverse;
`