import theme from "../../../UI/theme";

export const userInteractionTypes = {
    isRegulating: {
        key: 'isRegulating',
        display: 'Toggle regulation',
        color: theme.colors.mellowYellow
    },
    globalRegulation: {
        key: 'GlobalRegulation',
        display: 'Toggle global regulation',
        color: theme.colors.mellowYellow
    },
    mute: {
        key: 'mute',
        display: 'Toggle mute',
        color: theme.colors.raspberryRed,
    },
    globalMute: {
        key: 'globalMute',
        display: 'Toggle global mute',
        color: theme.colors.raspberryRed,
    },
    modeID: {
        key: 'modeId',
        display: 'Vibe change',
        color: theme.colors.crystalBlue
    },
    directVolume: {
        key: 'SetVolumeDirect',
        display: 'Set volume direct',
        color: theme.colors.pinky
    },
    moodSustain: {
        key: 'moodSustain',
        display: 'Toggle mood sustain',
        color: theme.colors.mediumRare
    },
    calibrate: {
        key: 'Calibrate',
        display: 'Zone calibration',
        color: theme.colors.greenMint
    },
    schedule: {
        key: 'Schedule',
        display: 'Schedule change',
        color: theme.colors.blueMonday
    },
    source: {
        key: 'source',
        display: 'Source change',
        color: theme.colors.purplePleasure
    },
    volumeExternal: {
        key: 'volumeExternal',
        display: 'Volume external',
        color: theme.colors.greenReadable
    }
}