import React, { useContext } from 'react';
import UserContext from '../../../hooks/UserContext';
import styled from 'styled-components';
import ZoneItem from './ZoneItem';
import { updateZone } from '../../../scripts/zone';
import hierarchyTypes from '../../settings/venue/device/hierarchyTypes';
import { postEvent } from '../../../scripts/event';

const List = styled.ul`
    list-style: none;
    padding: 0;
    margin-bottom: 0;

    li {
        margin-top: 20px;
    }

    li:first-child {
        margin-top: 0;
    }
`

const ZoneList = props => {
    const user = useContext(UserContext);

    const updateZoneWithEvent = async (zone) => {
        await updateZone(zone.customerId, zone.zoneId, zone);
        await postEvent(zone.hubId, zone.customerId, 'GET_ALL_ZONE_SETTINGS');
    }

    const updateZoneHandler = (zoneId, updatedAttributes) => {
        if (user.isImpersonator && user.isImpersonating) {
            return;
        }

        let oldZonesState = JSON.parse(JSON.stringify(props.zones));
        var newZonesState = JSON.parse(JSON.stringify(props.zones));
        var zoneToUpdate = newZonesState.find(zone => zone.zoneId === zoneId);
        if (zoneToUpdate) {
            Object.keys(updatedAttributes).forEach(key => {
                zoneToUpdate[key] = updatedAttributes[key];
            })
            props.setZones(newZonesState);

            try {
                let updatedZoneProps = (({ hubId, customerId }) => ({ ...updatedAttributes, zoneId, hubId, customerId }))(zoneToUpdate);
                updateZoneWithEvent(updatedZoneProps);
            } catch (error) {
                props.setZones(oldZonesState);
            }
        }
    }


    return <List>

        {props.zones?.filter(zone => !zone.hide).map?.((zone, ix) => {
            return <ZoneItem
                key={ix}
                zone={zone}
                masterZone={zone?.hierarchyType === hierarchyTypes.SLAVE.value ? props.zones?.find(z => z.zoneId === zone.masterId) : null}
                user={user}
                schedules={props.schedules}
                updateZoneHandler={updateZoneHandler}
                zoneLive={props.zoneLives?.find(zL => zL.zoneId === zone.zoneId)}
                name={props.name}
                hub={props.hubs?.find(hub => zone.hubId === hub.hubId)}
                processor={props.processors?.find(processor => processor.processorId === zone.processorId)}
                sources={props.sources?.filter(source => source.processorId === zone.processorId)}
                serverStatus={props.serverStatus}
            />
        })}
    </List>


}

export default ZoneList;