import { useEffect, useState } from "react";
import styled from "styled-components";
import { getLiveZoneLog } from "../../../../scripts/log";
import { postEvent } from "../../../../scripts/event";
import { getMappedVolume } from "../../../../scripts/volume";
import { updateZone } from "../../../../scripts/zone";
import { userInteractionTypes } from "../../../admin/customerDetails/userInteractionTypes";
import Button from "../../../UiComponents/Button";
import ZoneResultGraph from "./ZoneResultGraph";
import Card from "../../../UiComponents/Card";
import { postUserInteraction } from "../../../../scripts/userInteraction";
import CalibrationPointMover from "./CalibrationPointMover";
import CalibrationPointRotater from "./CalibrationPointRotater";
import CalibrationPoint from "./CalibrationPoint";
import { getCalibrationParametersFromSweep } from "./calibrationUtils";

const CardContainer = styled(Card)`
    margin-top: 20px;
`

const ZoneHeader = styled.div`
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    button {
        margin-right: 10px;
    }
`

const ControlContainer = styled.div`
    height: fit-content;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    margin: 0 0 10px 0;
    gap: 10px;
`

const defaultCalibrationPoints = [
    { sysvol: 40, measuredDb: 50 },
    { sysvol: 60, measuredDb: 60 },
    { sysvol: 80, measuredDb: 70 }
]

const LIVE_UPDATE_INTERVAL = 5000;

const ZoneCalibrationTable = props => {
    const [valuesChanged, setValuesChanged] = useState(false);
    const [calibrationPoints, setCalibrationPoints] = useState(props.savedCalibrationPoints?.map(point => { return { sysvol: point.sysvol, measuredDb: point.measuredDb } }) ?? [...defaultCalibrationPoints]);
    const [savedCalibrationPoints, setSavedCalibrationPoints] = useState(props.savedCalibrationPoints?.map(point => { return { sysvol: point.sysvol, measuredDb: point.measuredDb } }) ?? []);
    const [suggestedPoints, setSuggestedPoints] = useState();
    const [processorMapValues, setProcessorMapValues] = useState(props.zone?.processorMapValues ?? null);
    const [processorMapValuesChanged, setProcessorMapValuesChanged] = useState(false);
    const [showAdvancedPoints, setShowAdvancedPoints] = useState(false);
    const [liveZoneValues, setLiveZoneValues] = useState();

    const vibeIncrement = (6 * (savedCalibrationPoints[2]?.sysvol - savedCalibrationPoints[0]?.sysvol) / 40) || null;

    useEffect(() => {
        if (props.smoothSweepResult) {
            var suggested = getCalibrationParametersFromSweep(props.smoothSweepResult.data);
            if (suggested?.length === 3) {
                setSuggestedPoints(suggested);
            } else {
                setSuggestedPoints(null);
            }
        } else {
            setSuggestedPoints(null);
        }
    }, [props.smoothSweepResult]);

    const movePointsHandler = (propName, value, indices, absolute = false) => {
        setValuesChanged(true);
        setProcessorMapValuesChanged(true);
        setCalibrationPoints(prev => {
            var newPoints = JSON.parse(JSON.stringify(prev));
            indices.map(index => {
                if (newPoints[index]) {
                    if (absolute) {
                        newPoints[index][propName] = value;
                    } else {
                        newPoints[index][propName] += value;
                    }
                }
            })
            return newPoints;
        })
    }

    const rotatePointsHandler = (angle, centerX, centerY, indices) => {
        const radians = angle * Math.PI / 180;
        const sine = Math.sin(radians);
        const cosine = Math.cos(radians);
        setValuesChanged(true);
        setProcessorMapValuesChanged(true);
        setCalibrationPoints(prev => {
            var newPoints = JSON.parse(JSON.stringify(prev));

            for (let index = 0; index < indices.length; index++) {
                const element = indices[index];
                var movedX = prev[element].sysvol - centerX;
                var movedY = prev[element].measuredDb - centerY;
                var newX = movedX * cosine - movedY * sine;
                newX += centerX;
                newPoints[element].sysvol = newX;
            }

            return newPoints;
        })
    }

    const saveChangesHandler = async () => {
        const oldCalibrationPoints = JSON.parse(JSON.stringify(props.zone.calibrationPoints));
        var result = await updateZone(props.zone?.customerId, props.zone?.zoneId, {
            zoneId: props.zone?.zoneId, 
            calibrationPoints: {points: calibrationPoints, calibrationId: props.calibrationId}
        });
        if (result) {
            setSavedCalibrationPoints(calibrationPoints);
            setValuesChanged(false);
            await postEvent(props.zone.hubId, props.zone.customerId, 'GET_ALL_ZONE_SETTINGS');
            await postUserInteraction(props.zone.customerId, {
                zoneId: props.zone.zoneId,
                setting: userInteractionTypes.calibrate.key,
                payload: {
                    from: {
                        calibrationPoints: oldCalibrationPoints
                    },
                    to: {
                        calibrationPoints: {
                            points: calibrationPoints,
                            calibrationId: props.calibrationId
                        }
                    }
                }
            })
        }
    }

    const updateProcessorMapValuesHandler = async () => {
        var newProcessorMapValues = calibrationPoints?.map(point => point.sysvol)?.join();

        try {
            await updateZone(props.zone.customerId, props.zone.zoneId, { processorMapValues: newProcessorMapValues });

            postEvent(props.zone.hubId, props.zone.customerId, 'UPDATE_CALIBRATION_VALUES');
            postUserInteraction(props.zone.customerId, {
                setting: userInteractionTypes.calibrate.key,
                zoneId: props.zone.zoneId,
                toValue: parseInt(`${calibrationPoints?.map(point => point.sysvol)?.join('')}`) ?? 0,
                fromValue: parseInt(`${processorMapValues.split(',').join('')}`) ?? 0,
                payload: { from: { processorMapValues: processorMapValues }, to: { processorMapValues: calibrationPoints?.map(point => point.sysvol)?.join() } }
            });

            setProcessorMapValues(newProcessorMapValues);
            setProcessorMapValuesChanged(false);
        } catch (error) {

        }
    }

    useEffect(() => {
        getLiveData();
        var liveInterval = setInterval(() => {
            getLiveData();
        }, LIVE_UPDATE_INTERVAL);

        return () => {
            clearInterval(liveInterval);
            setLiveZoneValues(null);
        }
    }, []);

    const getLiveData = async () => {
        try {
            var newLiveValues = await getLiveZoneLog(props.zone.customerId, props.zone.zoneId);
            setLiveZoneValues(newLiveValues);
        } catch (error) {

        }
    }

    const resetToSaved = () => {
        for (let index = 0; index < calibrationPoints?.length; index++) {
            movePointsHandler('measuredDb', savedCalibrationPoints[index].measuredDb, [index], true);
            movePointsHandler('sysvol', savedCalibrationPoints[index].sysvol, [index], true);
            setValuesChanged(false);
            setProcessorMapValuesChanged(false);
        }
    }

    const setSuggestedCalibrationPoints = () => {
        for (let index = 0; index < calibrationPoints?.length; index++) {
            movePointsHandler('measuredDb', suggestedPoints[index].measuredDb, [index], true);
            movePointsHandler('sysvol', suggestedPoints[index].sysvol, [index], true);
            setValuesChanged(true);
            setProcessorMapValuesChanged(true);
        }
    }

    return <CardContainer>
        <ZoneHeader>
            <RowContainer>
                <label>{props.zone?.zoneName}</label>
            </RowContainer>

        </ZoneHeader>

        <ZoneResultGraph
            data={{
                sweepResult: props.sweepResult,
                newCalibrationPoints: calibrationPoints,
                savedCalibrationPoints: savedCalibrationPoints,
                suggestedPoints: suggestedPoints,
                liveZoneValues: {
                    sysvol: getMappedVolume(
                        savedCalibrationPoints?.map(point => point.sysvol),
                        liveZoneValues?.sysvol),
                    averageDecibel: liveZoneValues?.averageDecibel - liveZoneValues?.averageDiff
                }
            }} />

        <ControlContainer>
            {calibrationPoints?.map((point, index) => {
                const vibeChange = ((point.sysvol - savedCalibrationPoints[index]?.sysvol) / vibeIncrement) || null;
                return <CalibrationPoint
                    key={index}
                    point={point}
                    pointIndex={index}
                    vibeChange={vibeChange}
                    zoneId={props.zone?.zoneId}
                    showAdvanced={showAdvancedPoints}
                    listenClickHandler={props.listenClickHandler}
                    movePointsHandler={movePointsHandler} />
            })}
        </ControlContainer>

        <ControlContainer>
            <CalibrationPointMover movePointsHandler={movePointsHandler} indices={[0, 1, 2]} />
            <CalibrationPointRotater
                angle={1}
                indices={[1, 2]}
                centerX={calibrationPoints[0].sysvol}
                centerY={calibrationPoints[0].measuredDb}
                rotatePointsHandler={rotatePointsHandler}
                title={'\u03B1\u2081'} />
            {showAdvancedPoints ?
                <CalibrationPointRotater
                    angle={1}
                    indices={[2]}
                    centerX={calibrationPoints[1].sysvol}
                    centerY={calibrationPoints[1].measuredDb}
                    rotatePointsHandler={rotatePointsHandler}
                    title={'\u03B1\u2082'} />
                : <></>}
        </ControlContainer>

        <RowContainer>
            <Button small tertiary onClick={() => setShowAdvancedPoints(prev => !prev)}>{showAdvancedPoints ? 'Hide advanced' : 'Show advanced'}</Button>
            {props.savedCalibrationPoints ?
                <Button small tertiary onClick={() => resetToSaved()}>Reset</Button>
                : <></>}

            {suggestedPoints ?
                <Button small tertiary onClick={() => setSuggestedCalibrationPoints()}>Set suggested</Button>
                : <></>}

            {valuesChanged ? <>
                <Button small primary onClick={saveChangesHandler}>Save new</Button>
            </>
                : <></>}

            {processorMapValuesChanged ? <>
                <Button small primary onClick={updateProcessorMapValuesHandler}>Save old</Button>
            </>
                : <></>}
        </RowContainer>

    </CardContainer >
}

export default ZoneCalibrationTable;