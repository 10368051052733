import ToggleButton from "../../../UiComponents/ToggleButton";
import { ToggleSettingContainer } from "./ZoneSettingStyles";

const ZoneSettingToggle = props => {
    const onToggle = async (newState) => {
        if (props.readOnly) {
            return;
        }
        props.onToggle(newState);
    }

    return <ToggleSettingContainer>
        <div>
            <span>{props.title}</span>
        </div>
        <div>
            <ToggleButton checked={props.checked} onToggle={onToggle} />
        </div>
    </ToggleSettingContainer>
}

export default ZoneSettingToggle;