import React from 'react';
import styled, { css } from 'styled-components';
import theme from '../../UI/theme';
import CountingNumber from './CountingNumber';

const Container = styled.div`
    display: grid;
    grid-template-rows: 30% 40% 30%;
    min-width: 90px;
    height: 90px;

    @media only screen and (min-width: ${theme.screenSizes.small}px) {
        width: 130px;
        height: 130px;
    }

    background-color: ${theme.colors.darkSpace80};
    text-align: center;
    border-radius: 5px;
    padding: 10px;
    
    ${props => props.margin && css`
        margin: ${props.margin};
    `}

    ${props => props.small && css`
        min-width: 70px;
        width: 70px;
        height: 70px;
        padding: 4px;
    `}
`

const Label = styled.label`
    margin: 0;
    color: ${theme.colors.textGray};
    font-size: 10px;

    @media only screen and (min-width: ${theme.screenSizes.small}px) {
        font-size: 13px;
    }

    ${props => props.small && css`
        font-size: 0.7rem;
    `}

`

const Title = styled.h4`
    margin: 0;
    color: ${theme.colors.textGray};
    @media only screen and (min-width: ${theme.screenSizes.small}px) {
        font-size: 20px;
    }

    ${props => props.small && css`
        font-size: 0.8rem;
    `}
`

const Value = styled.h1`
    margin: 0;
    color: ${theme.colors.greenReadable};

    ${props => props.small && css`
        font-size: 1.1rem;
    `}
`
const InvalidValue = styled.div`
    color: ${theme.colors.darkSpace60};
`

const KeyMetric = props => {
    return <Container {...props}>
        <Title {...props}>{props.title}</Title>
        {!props.invalid ? <>
            <Value {...props}>
                {props.count && !isNaN(Number(props.metric)) ? <>
                    <CountingNumber value={Number(props.metric)} />
                </> : <>
                        {props.metric}
                    </>} {props.unit}
            </Value>
        </> : <>
            <InvalidValue>No data</InvalidValue>
        </>}
        <Label {...props}>{props.label}</Label>
    </Container>
}

export default KeyMetric;