import React, { useState, useContext } from 'react';
import Modal, { ModalContent } from '../UiComponents/Modal';
import UserContext from '../../hooks/UserContext';
import ToggleButton from '../UiComponents/ToggleButton';
import Button from '../UiComponents/Button';
import { TextMuted } from '../UiComponents/text/StyledText'

const RegulationToggle = props => {
    const [showModal, setShowModal] = useState(false);
    const user = useContext(UserContext);

    const onChangeHandler = (newState) => {
        if (user.isImpersonator && user.isImpersonating) {
            return;
        }
        if (newState === false) {
            if (props.zone) {
                setShowModal(true);
            }
        }
        props.onToggle(newState);
    }
    return <>
        <ToggleButton checked={props.checked} onToggle={onChangeHandler} />

        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContent>
                {props.zone?.autoResetZoneSettings ? <>
                    <p>Regulation will be paused until tomorrow</p>
                </> : <>
                    <p>Regulation will be paused until you turn it on again. </p>
                    <p><TextMuted>Go to settings to enable regulation to be automatically turned on every day</TextMuted></p>
                </>}

                <Button primary onClick={() => setShowModal(false)}>Ok</Button>
            </ModalContent>
        </Modal>
    </>
}

export default RegulationToggle;