import styled from "styled-components";
import Button from "../../../UiComponents/Button";
import { FaVolumeUp } from "react-icons/fa";
import theme from "../../../../UI/theme";
import AnimateHeight from "react-animate-height";
import CalibrationPointMover from "./CalibrationPointMover";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: fill-available;
    justify-content: center;
    text-align: center;
`

const StyledButton = styled(Button)`
    font-size: ${theme.fontSize.small};
    color: ${props => props.active ? theme.colors.greenReadable : theme.colors.textGray};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: ${props => props.borderRadius ?? 'inherit'};
`

const Header = styled.label`
    color: ${theme.colors.greenEnergy};
    font-size: ${theme.fontSize.small};
    cursor: pointer;
`

const SubHeader = styled.label`
    font-size: ${theme.fontSize.tiny};
    cursor: pointer;
`

const VibeLabel = styled.label`
    font-size: ${theme.fontSize.tiny};
    margin: 0px;
    color: ${theme.colors.textGray};
`

const ControlContainer = styled.div`
    background-color: ${theme.colors.grayBorder};
    display: flex;
    justify-content: center;
    padding: 5px;
    border-radius: 0 0 4px 4px;
`

const CalibrationPoint = props => {
    return <Container>
        <VibeLabel>{props.vibeChange ?
            <>
                {props.vibeChange > 0 ? '+' : ''}{props.vibeChange.toFixed(2)} vibes
            </>
            : <>&nbsp;</>}
        </VibeLabel>
        <StyledButton small tertiary borderRadius={props.showAdvanced ? '4px 4px 0 0' : ''} onClick={() => props.listenClickHandler(props.zoneId, props.point?.sysvol)}>
            <Header>
                P{props.pointIndex + 1}&nbsp;<FaVolumeUp />
            </Header>
            <SubHeader>
                {props.point?.sysvol.toFixed(2)}%, {props.point?.measuredDb.toFixed(2)}dB
            </SubHeader>
        </StyledButton>
        <AnimateHeight duration={500} height={props.showAdvanced ? 'auto' : 0}>
            <ControlContainer>
                <CalibrationPointMover movePointsHandler={props.movePointsHandler} indices={[props.pointIndex]} />
            </ControlContainer>
        </AnimateHeight>
    </Container>
}

export default CalibrationPoint;