
class WavedBox {
    static getPredefinedConfig() {
        return {
            sources: [{
                name: 'RCA 1',
                address: 0,
                regulate: 1
            }, {
                name: 'RCA 2',
                address: 1,
                regulate: 1
            },{
                name: 'Balanced',
                address: 2,
                regulate: 1
            }],
            sourceSelectors: [{
                name: 'Wavedbox',
                address: 'wavedbox'
            }],
            zones: [...Array(6).keys()].map(ix => ({zoneName: `Zone ${ix+1}`, objectAddress: ix.toString(), regulate: 1}))
        }
    }
}

export default WavedBox;