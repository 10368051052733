import { useContext, useState } from "react";
import styled, { css } from "styled-components";
import theme from "../../../../UI/theme";
import Card from "../../../UiComponents/Card";
import Modal from "../../../UiComponents/Modal";
import { TextMuted } from "../../../UiComponents/text/StyledText";
import ScheduleDays from "./ScheduleDays";
import Button from "../../../UiComponents/Button";
import { deleteSchedule } from "../../../../scripts/schedules";
import ScheduleForm from "./ScheduleForm";
import scheduleTypes from "./scheduleTypes";
import IconLogo from "../../../UiComponents/IconLogo";
import UserContext from "../../../../hooks/UserContext";
import { toDisplayTime } from "../../../../scripts/common";
import { FaSeedling, FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import ButtonContainer from "../../../UiComponents/containers/ButtonContainer";
import VibeChangeIcon from "../../../UiComponents/VibeChangeIcon";

const RowContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
`

const TypeContainer = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    height: fit-content;
`

const ColContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Header = styled.h3`
    margin: 0 10px 0 0;
`

const SubHeader = styled.h4`
    margin: 10px 0 8px 0;
    line-height: 1;
    :first-of-type {
        margin: 0;
    }
`

const TimeHeader = styled.h3`
    margin: 0 10px 0 0;
    padding: 3px;
    background-color: ${theme.colors.darkSpace80};
    border-radius: 4px;
`

const ScheduleCard = styled(Card)`
    margin: 10px 0px;
`

const SectionContainer = styled.div`
    margin: 10px 0 0 0;
`

const ScheduleTypesContainer = styled.div`
    width: 100%;
    :not(:last-of-type) {
        margin-bottom: 5px;
    }
    display: grid;
    grid-template-columns: 6fr 1fr;
    align-items: center;
    text-align: end;
    color: ${theme.colors.textGray};
    span {
        margin-right: 10px;
    }
`

const IconContainer = styled.div`
    display: flex;
    align-items: center;

    svg {
        font-size: 15px;

        @media only screen and (min-width: ${theme.screenSizes.small}px) {
            font-size: 22px;
        }
        color: ${theme.colors.greenEnergy};
        ${props => props.disabled && css`
            color: ${theme.colors.darkSpace70};
        `}
    }
`

const StyledIcon = styled(IconLogo)`
    width: 15px;
    height: 15px;

    @media only screen and (min-width: ${theme.screenSizes.small}px) {
        width: 22px;
        height: 22px;
    }

    ${props => props.disabled && css`
        rect {
            fill: ${theme.colors.darkSpace70};
        }
    `}
`

const ScheduleItem = props => {
    const user = useContext(UserContext);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const schedule = props.schedule;

    const deleteScheduleHandler = async () => {
        await deleteSchedule(schedule.customerId, schedule.scheduleId);
        setShowDeleteModal(false);
        props.refreshSchedules();
    }

    return <ScheduleCard>
        <RowContainer>
            <FlexRow>
                <TimeHeader>{toDisplayTime(schedule.time)}</TimeHeader>
                <Header>{schedule.displayName}</Header>
            </FlexRow>

            <TypeContainer>
                <ColContainer>
                    {schedule.scheduleTypes?.includes(scheduleTypes.types.VIBE) ?
                        <ScheduleTypesContainer>
                            <span>Vibe change</span>
                            <IconContainer><VibeChangeIcon vibeChange={schedule.vibeChange} /></IconContainer>
                        </ScheduleTypesContainer>
                        : <></>}

                    {schedule.scheduleTypes?.includes(scheduleTypes.types.REGULATION) ?
                        <ScheduleTypesContainer>
                            <span>{schedule.isRegulating ? 'Regulation on' : 'Regulation off'}</span>
                            <StyledIcon disabled={!schedule.isRegulating} />
                        </ScheduleTypesContainer>
                        : <></>}

                    {schedule.scheduleTypes?.includes(scheduleTypes.types.MUTE) ?
                        <ScheduleTypesContainer>
                            <span>{schedule.mute ? 'Mute' : 'Unmute'}</span>
                            <IconContainer disabled={schedule.mute}>
                                {schedule.mute ? <FaVolumeMute /> : <FaVolumeUp />}
                            </IconContainer>
                        </ScheduleTypesContainer>
                        : <></>}

                    {schedule.scheduleTypes?.includes(scheduleTypes.types.MOOD_SUSTAIN) ?
                        <ScheduleTypesContainer>
                            <span>{schedule.moodSustain ? 'Mood sustain on' : 'Mood sustain off'}</span>
                            <IconContainer disabled={!schedule.moodSustain}>
                                <FaSeedling />
                            </IconContainer>
                        </ScheduleTypesContainer>
                        : <></>}
                </ColContainer>
            </TypeContainer>
        </RowContainer>

        <SectionContainer>
            <SubHeader>Zones</SubHeader>
            {schedule.zones?.map((zone, index) => {
                return <div key={index}>
                    <TextMuted>{props.zones?.find(z => z.zoneId === zone)?.zoneName}</TextMuted>
                </div>
            }) ?? <TextMuted>No zones selected</TextMuted>}
        </SectionContainer>

        <SectionContainer>
            <SubHeader>Days</SubHeader>
            <ScheduleDays days={schedule.days} />
        </SectionContainer>

        <ButtonContainer>
            <Button primary disabled={user.isImpersonator && user.isImpersonating} onClick={() => setShowEditModal(true)}>Edit</Button>
            <Button secondary disabled={user.isImpersonator && user.isImpersonating} onClick={() => setShowDeleteModal(true)}>Delete</Button>
        </ButtonContainer>

        <Modal show={showEditModal} close={() => setShowEditModal(false)}>
            <ScheduleForm schedule={schedule} setShowScheduleModal={setShowEditModal} customerId={props.customerId} zones={props.zones} refreshSchedules={props.refreshSchedules} />
        </Modal>

        <Modal show={showDeleteModal} close={() => setShowDeleteModal(false)}>
            <h3>Are you sure you want to delete this schedule?</h3>

            <ButtonContainer>
                <Button primary onClick={() => deleteScheduleHandler()}>Delete</Button>
                <Button secondary onClick={() => setShowDeleteModal(false)}>Cancel</Button>
            </ButtonContainer>
        </Modal>
    </ScheduleCard>
}

export default ScheduleItem;