import React from 'react';
import TopNavigation from '../components/navigation/TopNavigation';
import ViewHeader from '../components/UiComponents/ViewHeader';
import StatisticsContainer from '../components/statistics/StatisticsContainer';
import LiveContainer from '../components/live/LiveContainer';

const VenueView = props => {

    return <>
        <div>
            <ViewHeader>
                {props.multipleVenues ? <TopNavigation goBack={props.goBack} /> : <div></div>}
                <h3>{props.venue.companyName}</h3>
            </ViewHeader>

            {props.live ? <>
                <LiveContainer customerId={props.venue.customerId} />
            </> : <>
                <StatisticsContainer customerId={props.venue.customerId} />
            </>}
        </div>
    </>
}

export default VenueView;