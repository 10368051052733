import styled from "styled-components";
import theme from "../../../../../UI/theme";

export const StepContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
export const StepHeader = styled.h2`
    margin: 20px 0 0 0;
`

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    margin-bottom: 10px;
`

export const ToggleContainer = styled(RowContainer)`
    justify-content: space-between;
    margin-bottom: 0;
`

export const InfoText = styled.p`
    color: ${theme.colors.textGray};
`