import React from 'react';
import LocationItem from '../LocationItem';
import { Link, useLocation } from 'react-router-dom';

const VenueItem = props => {
    const location = useLocation();

    return <LocationItem>
        {props.item.companyName}
        <button onClick={props.selectVenue}>{'->'}</button>
    </LocationItem>

}

export default VenueItem;