
import React, { useContext } from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import UserContext from '../../../../hooks/UserContext';
import AutoCalibration from './AutoCalibration';
import AutoCalibrationList from './AutoCalibrationList';
import NewCalibration from './NewCalibration';


const AutoCalibrationRouter = props => {
    let match = useRouteMatch();
    const user = useContext(UserContext);

    if (user.user.role === 'admin' || process.env.REACT_APP_HOST_TYPE === 'HUB') {
        return <div>
            <Switch>

                <Route path={`${match.path}/new`}>
                    <NewCalibration />
                </Route>

                <Route path={`${match.path}/:calibrationId`}>
                    <AutoCalibration />
                </Route>

                <Route path={`${match.path}`}>
                    <AutoCalibrationList />
                </Route>
            </Switch>
        </div>
    } else {
        return <div>
            This page is for admin only
        </div>
    }

}

export default AutoCalibrationRouter;