
const colormap = [
    '#aadb23',
    '#a6d623',
    '#a2d123',
    '#9ecb22',
    '#9ac622',
    '#96c122',
    '#92bc22',
    '#8eb722',
    '#8ab122',
    /* '#86ac21', */
    '#81a721',
    '#7da221',
    '#799d21',
    '#759721',
    /* '#719220', */
    '#6d8d20',
    '#698820',
    '#658320',
    '#617d20',
    '#5d7820',
    /* '#59731f', */
    '#556e1f',
    '#51681f',
    '#4d631f',
    '#495e1f',
    '#45591f',
    /* '#41541e', */
    '#3d4e1e',
    '#39491e',
    '#35441e',
    '#303f1e',
    /* '#2c3a1d', */
    '#28341d',
    '#242f1d',
    '#202a1d',
    '#1c251d',
    '#18201d',
    '#141a1c',
    '#10151c',
    '#0c101c',
]

function getColorMap(nColors) {
    var step = Math.floor((colormap.length - 1) / (nColors - 1));
    var nLongerSteps = (colormap.length - 1) % (nColors - 1);
    if (nColors && nColors < colormap.length) {
        var result = [colormap[0]];
        var colormapIndex = 0;
        for (var i = 0; i < nColors - 1; i++) {
            colormapIndex += nLongerSteps-- > 0 ? step + 1 : step;
            result.push(colormap[colormapIndex]);
        }
        return result;
    } else {
        return colormap;
    }
}


export default getColorMap;