import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getCustomer } from '../../../scripts/datasource';
import ViewHeader from '../../UiComponents/ViewHeader';
import TopNavigation from '../../navigation/TopNavigation';
import Card from '../../UiComponents/Card';
import styled from 'styled-components';
import Button from '../../UiComponents/Button';
import EmailSettings from './EmailSettings';

const StyledLink = styled(Link)`
    text-decoration: none;
    color: white;
    font-size: 20px;
`;

const UserSettings = props => {
    const [user, setUser] = useState();

    useEffect(() => {
        getUserHandler();
    }, [props.customerId]);

    const getUserHandler = async () => {
        var userRes = await getCustomer(props.customerId);
        if (userRes) {
            setUser(userRes);
        }
    }

    if (user) {

        return <div>
            <ViewHeader>
                <TopNavigation to={'/settings'} />
                <h3>User Settings</h3>
            </ViewHeader>
            <Card>
                <h3>User information</h3>

                <EmailSettings customerId={props.customerId} email={user.email} refresh={getUserHandler}/>

                <div>
                    <StyledLink to={'/forgotPassword'}>
                        <Button tertiary>
                            Change password
                        </Button>
                    </StyledLink>
                </div>
            </Card>
        </div>
    } else {
        return <></>
    }
}

export default UserSettings;