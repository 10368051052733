import { useState } from "react";
import styled, { css } from "styled-components";
import theme from "../../UI/theme";
import ZoneVolumeModal from "./ZoneVolumeModal";

const Container = styled.div`
    position: relative;
    height: 50px;
    aspect-ratio: 1;

    ${props => props.disabled && css`
        pointer-events: none;
        opacity: 40%;
    `}
    
    @media only screen and (max-width: ${theme.screenSizes.medium}px) {
        height: 40px;
    }
`

const VolumeLabel = styled.label`
    position: absolute;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: all 0.5s linear;
    height: 100%;
    width: 100%;
`

const WavedVolume = styled(VolumeLabel)`
    bottom: 0;
    right: 0;
    color: ${theme.colors.greenEnergy};

    ${props => !props.isRegulating && css`
        bottom: 5px;
        height: 33%;
        font-size: 0.65rem;
        opacity: 90%;
    `}

    ${props => props.shouldRegulate === 0 && css`
        visibility: hidden;
    `}
`

const SystemVolume = styled(VolumeLabel)`
    top: 0;
    left: 0;
    height: 67%;
    opacity: 100%;
    color: white;
    
    ${props => props.isRegulating == true && css`
        opacity: 0;
        font-size: 0;
    `}

    ${props => props.shouldRegulate === 0 && css`
        height: 100%;
    `}
`

const ZoneVolume = props => {
    const [showVolumeModal, setShowVolumeModal] = useState(false);

    return <Container onClick={() => setShowVolumeModal(true)} disabled={props.disabled} className={props.className}>
        {props.systemVolume !== null && props.wavedVolume !== null ? <>
            <SystemVolume isRegulating={props.isRegulating} shouldRegulate={props.shouldRegulate}>
                {!isNaN(props.systemVolume) ? `${props.systemVolume?.toFixed()}%` : ''}
            </SystemVolume>
            <WavedVolume isRegulating={props.isRegulating} shouldRegulate={props.shouldRegulate}>
                {!isNaN(props.wavedVolume) ? `${props.wavedVolume?.toFixed()}%` : ''}
            </WavedVolume>
        </> : <></>}


        <ZoneVolumeModal
            show={showVolumeModal}
            close={() => setShowVolumeModal(false)}
            zone={props.zone}
            systemVolume={props.systemVolume}
            wavedVolume={props.wavedVolume} />
    </Container>
}

export default ZoneVolume;