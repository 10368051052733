import styled, { css } from "styled-components"
import theme from "../../../../UI/theme"

export const ListActionButton = styled.button`
    background-color: ${theme.colors.dropdownGray};;
    height: 45px;
    width: 45px;
    aspect-ratio: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 5px;
    aspect-ratio: 1;
    /* border: 2px solid ${theme.colors.darkSpace80}; */
    border: 1px solid transparent;
    box-sizing: border-box;
    font-size: 0.65rem;
    font-weight: 100;
    line-height: 0.65rem;

    > label {
        cursor: inherit;
    }

    > svg {
        height: 1.2rem;
        width: 1.2rem;
        size: 1.2rem;
        color: ${theme.colors.greenEnergy};
    }

    @media (hover:hover) {
        &:hover {
            cursor: pointer;
            border: 1px solid ${theme.colors.greenEnergy};
        }
    }

    ${props => props.deactivated && css`
        svg {
            color: ${theme.colors.darkSpace90};
        }
    `}

    ${props => props.disabled && css`
        opacity: 40%;
        

        @media (hover:hover) {
            &:hover {
                border: 1px solid transparent;
                cursor: default;
            }
        }
    `}
`