import styled, { css } from "styled-components";
import theme from "../../UI/theme";

export const TreeOverlay = styled.div`
    display: flex;
    background-color: ${theme.colors.darkSpace90};
    transition: all 0.5s;

    @media only screen and (max-width: ${theme.screenSizes.large - 1}px) {
        height: ${props => props.expanded ? '75%' : '40px'};
        width: fill-available;
        position: fixed;
        z-index: 1;
        bottom: 0;
        left: 0;
        overflow: hidden;
        flex-direction: column;
        border-radius: 50px 50px 0 0;
    }

    @media only screen and (min-width: ${theme.screenSizes.large}px) {
        height: calc(100vh - 105px);
        width: ${props => props.expanded ? '35%' : '40px'};
        flex-direction: row-reverse;
    }
    
    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        margin-bottom: 86px
    }
`

export const TreeContainer = styled.div`
    height: fill-available;
    min-height: fit-content;
    width: fill-available;
    overflow: auto;
    padding: 10px;
    box-sizing: border-box;

    li::before, li::after {
        content: "";
        position: absolute;
        left: -20px;
    }

    li::before {
        border-top: 2px solid ${theme.colors.textGray};
        top: 12px;
        width: 10px;
        height: 0;
    }

    li::after {
        border-left: 2px solid ${theme.colors.textGray};
        height: 100%;
        width: 0px;
        top: 4px;
    }

    ul > li:last-child::after {
        height: 8px;
    }

    svg {
        font-size: ${theme.fontSize.large};
        max-width: 1.1rem;
        max-height: 1.1rem;
    }
`

export const ExpandButtonContainer = styled.div`
    
`

export const ExpandTreeButton = styled.button`
    height: 40px;
    width: fill-available;
    background-color: ${theme.colors.dropdownGray};
    color: ${theme.colors.textGray};
    border: none;
    border-radius: 50px 50px 0 0;
    cursor: pointer;

    @media only screen and (min-width: ${theme.screenSizes.large}px) {
        height: fill-available;
        width: 40px;
        flex-direction: row;
        border-radius: 0 5px 5px 0;

        > svg {
            transform: rotate(90deg);
        }
    }
`

export const TreeUL = styled.ul`
    list-style-type: none;
    margin: 0 0 0 30px;
    padding: 1px 0;
    position: relative;
    display: ${props => props.expanded ? '' : 'none'};
`

export const TopLevelTreeUL = styled(TreeUL)`
    display: unset;
    margin: 0px;
`

export const TreeLI = styled.li`
    position: relative;
    padding: 1px 0;
`

export const TopLevelTreeLI = styled(TreeLI)`
    &:before, &:after {
        all: unset !important;
    }

    &:before {
        all: unset !important;
    }

    &:after {
        all: unset !important;
    }
`

export const LIHeader = styled.label`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    font-size: ${theme.fontSize.small};
    font-weight: lighter;
    color: ${theme.colors.textGray};
    stroke: ${theme.colors.textGray};
    white-space: nowrap;
`

export const BranchLabel = styled.label`
    cursor: pointer;

    ${props => props.selected && css`
        color: ${theme.colors.greenReadable};
    `}
`