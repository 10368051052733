import styled from "styled-components"
import Checkbox from "../../UiComponents/Checkbox"
import Button from "../../UiComponents/Button";
import { HeaderRow } from "./SupportStyles";
import PriorityFilter from "./PriorityFilter";

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

const FilterHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const SupportTableHeader = props => {
    return <>
        <HeaderRow>
            <th colSpan={2}>
                <FilterHeaderContainer>
                    <label>Filter</label>
                    <FilterHeaderContainer>
                        <label>Priority: &nbsp;</label>
                        <PriorityFilter priorityFilter={props.priorityFilter} setPriorityFilter={props.setPriorityFilter} />
                    </FilterHeaderContainer>
                </FilterHeaderContainer>

            </th>

            {props.errorColumns?.map((column, index) => {
                return <th key={index}>
                    <Container>
                        <Checkbox checked={props.filter?.[column.key] || false} onChange={() => props.setFilter(prev => { return { ...prev, [column.key]: !props.filter?.[column.key] } })} />
                    </Container>
                </th>
            })}

            <th><Button style={{ width: '100%' }} secondary onClick={() => props.setFilter(null)}>Clear filter</Button></th>
        </HeaderRow>

        <HeaderRow>
            <th>ID</th>
            <th>Name</th>
            {props.errorColumns?.map((column, index) => {
                return <th key={index}>{column.display}</th>
            })}
            <th>Impersonate</th>
        </HeaderRow>
    </>
}

export default SupportTableHeader;