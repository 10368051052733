import React from 'react';
import HeaderContainer from '../../../UiComponents/containers/HeaderContainer';
import SimpleSlider from '../../../UiComponents/SimpleSlider';
import styled from 'styled-components';
import theme from '../../../../UI/theme';


const SliderContainer = styled.div`
    width: 80%;
    margin-left: 12px;
    margin-bottom: 30px;
    margin-top: 10px;
`

const MAX_MAX_STEP = 6;
const AVERAGE_TIME_COEFFICIENT = 5;

const SensitivitySlider = props => {

    const onChange = (newValue, committed) => {
        var newMaxStep = newValue;
        var newAverageTime = (MAX_MAX_STEP + 1 - newValue) * AVERAGE_TIME_COEFFICIENT;
        props.updateZoneHandler(props.zone.zoneId, { averageTime: newAverageTime, maxStep: newMaxStep }, !committed);
    }

    return <div>
        <HeaderContainer>
            <div>
                <h4>Sensitivity</h4>
            </div>
        </HeaderContainer>

        <SliderContainer>
            <SimpleSlider
                min={1}
                max={MAX_MAX_STEP}
                step={1}
                value={[props.zone.maxStep]}
                onChange={(newValues) => onChange(newValues[0])}
                onChangeCommitted={(newValues) => onChange(newValues[0], true)}
                marks={[{ value: 1, label: 'Low' }, { value: MAX_MAX_STEP, label: 'High' }]}
                backgroundColor={theme.colors.darkSpace90}
            />
        </SliderContainer>
    </div>;
}

export default SensitivitySlider;


