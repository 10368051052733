import React from 'react';
import ChoiceField from '../../fields/ChoiceField';
import { requiredStates } from '../../utils/installationTypes';

const SourceSelectorChoiceField = ({ zone, updateZone, sourceSelectors, cellControl }) => {
    const choices = sourceSelectors.filter(sourceSelector => sourceSelector.processorId === zone.processorId);
    const selectedSourceSelector = choices.find(sourceSelector => sourceSelector.sourceSelectorId === zone.sourceSelectorId);
    return <ChoiceField
        selectedItem={selectedSourceSelector && { value: selectedSourceSelector.sourceSelectorId, label: selectedSourceSelector.name }}
        items={choices.map(sourceSelector => ({ value: sourceSelector.sourceSelectorId, label: sourceSelector.name }))}
        setValue={(value) => updateZone({ sourceSelectorId: value })}
        cellControl={cellControl}
    />
}

export default SourceSelectorChoiceField;

export const getSourceSelectorRequiredState = (zone, sourceSelectors) => {
    const processorSourceSelectors = sourceSelectors.filter(sourceSelector => sourceSelector.processorId === zone.processorId);
    return !processorSourceSelectors.find(sourceSelector => sourceSelector.sourceSelectorId === zone.sourceSelectorId) && processorSourceSelectors.length > 0 ? requiredStates.RECOMMENDED : requiredStates.OPTIONAL;
}