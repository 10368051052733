import React from 'react';
import { Link, useRouteMatch } from "react-router-dom";
import LogoutButton from '../authentication/LogoutButton';
import { FaChevronRight } from 'react-icons/fa';
import {BiHome} from 'react-icons/bi'
import styled from 'styled-components';
import theme from '../../UI/theme';
import WaUser from '../UiComponents/Icons/WaUser';

const Container = styled.div`
    h2,h3 {
        margin-left: 12px;
    }
`

const List = styled.ul`
    padding: 0;
    background-color: ${theme.colors.darkSpace90};
`

const ListContainer = styled.div`
    margin-top: 50px;
`

const ListItem = styled.li`
    list-style: none;
    padding: 12px 16px;
    &:hover {
        background-color: ${theme.colors.grayHover};
    }
`

const StyledLink = styled(Link)`
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
`;

const InnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    svg {
        margin-right: 10px;
        font-size: 16px;
        stroke: white;
        height: 18px;
        width: 18px;
    }
`
const Arrow = styled.div`
    color: ${theme.colors.textGray};
`

const LogoutButtonContainer = styled.div`
    margin-top: 40px;
    margin-left: 8px;
`

const SettingsMain = props => {
    let match = useRouteMatch();


    const getLinkItem = (route, title, icon) => {
        return <ListItem key={route}>
            <StyledLink to={`${match.url}/${route}`}>
                <InnerContainer>
                    <div>
                        {icon}
                        <span>{title}</span>
                    </div>
                    <Arrow>
                        <FaChevronRight />
                    </Arrow>
                </InnerContainer>
            </StyledLink>
        </ListItem>
    }

    return <Container>
        <h2>Settings</h2>
        <ListContainer>
            <List>
                {getLinkItem('user', 'User', <WaUser />)}
                {/* {getLinkItem('modes', 'Modes and Schedules', <FaRegClock />)} */}
            </List>
        </ListContainer>

        <ListContainer>
            <h3>Venue Settings</h3>
            <List>
                {props.venues.map(venue =>
                    getLinkItem(`venue/${venue.customerId}`, venue.companyName || 'My venue', <BiHome />)
                )}
            </List>
        </ListContainer>

        <LogoutButtonContainer>
            <LogoutButton />
        </LogoutButtonContainer>
    </Container>
}

export default SettingsMain;