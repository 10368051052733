import { useState, useContext, useEffect } from "react";
import UserContext from '../../../hooks/UserContext';
import styled from "styled-components";
import { postEvent } from "../../../scripts/event";
import theme from "../../../UI/theme";
import Slider from "../../UiComponents/Slider";
import { getReverseMappedValue, getReverseMappedVolume } from "../../../scripts/volume";
import { FaMinus, FaPlus } from "react-icons/fa";
import { userInteractionTypes } from "../../admin/customerDetails/userInteractionTypes";
import { postUserInteraction } from "../../../scripts/userInteraction";

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 10px 0;
`

const Label = styled.label`
    color: ${theme.colors.textGray};
    white-space: nowrap;

    :not(:first-of-type) {
        justify-self: end;
    }
`

const VolumeButtonContainer = styled.div`
    justify-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: ${theme.colors.textGray};
    width: 100%;

    button {
        height: 20px;
        padding: 0 10px;
        border: none;
        background-color: transparent;
        color: ${theme.colors.textGray};
        font-size: ${theme.fontSize.large};

        :not(:last-of-type) {
            margin-right: 10px;
        }

        :hover {
            cursor: pointer;
            svg {
                transform: scale(1.2);
            }
        }
    }
`

const SliderTicContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`

const TicBox = styled.div`
    border: 2px solid ${theme.colors.textGray};
    border-width: 2px 2px 0 0;
    text-align: end;
    padding-right: 5px;
    font-size: ${theme.fontSize.small};
    color: ${theme.colors.textGray};
    overflow: hidden;
`

const WavedArea = styled(TicBox)`
    box-sizing: border-box;
    width: ${props => props.width};
    border-color: ${theme.colors.greenEnergy};
    color: ${theme.colors.greenEnergy};
`

const ProcessorArea = styled(TicBox)`
    flex: 1 1 0;
    overflow: hidden;
`

const VOLUME_UPDATE_WAIT = 10000;

const ZoneVolumeSlider = props => {
    const [volume, setVolume] = useState(props.volume);
    const [volumeChange, setVolumeChange] = useState();
    const user = useContext(UserContext);
    const [lastCommit, setLastCommit] = useState(0);
    const maxVolume = Math.floor(props.calibrationMethod === 1 ? getReverseMappedVolume(props.calibrationSysvols, 100) : getReverseMappedValue(100, props.processorMapValues));

    useEffect(() => {
        if (!volumeChange?.now &&
            (new Date().getTime() - volumeChange?.time > VOLUME_UPDATE_WAIT
                || !volumeChange?.time)) {
            setVolume(props.volume);
        }
    }, [props.volume, props.lastUpdate]);

    const onChangeHandler = (newValue) => {
        if (user.isImpersonator && user.isImpersonating) {
            return;
        }
        setVolumeChange({ now: true, time: new Date().getTime() });
        throttleCommitVolume(newValue, 1000);
        setVolume(newValue);
    }

    const throttleCommitVolume = (newValue, timeFrame) => {
        var now = new Date().getTime();
        if (now - lastCommit >= timeFrame) {
            commitVolume(newValue, true);
            setLastCommit(now);
        }
    }

    const commitVolume = async (newValue, preventEventLog = false) => {
        try {
            var res = await postEvent(props.hubId, props.customerId, 'SET_VOLUME_DIRECT', { zoneId: props.zoneId, value: newValue, preventEventLog: preventEventLog });
            return res;
        } catch (error) {
            console.log('Could not change zone volume');
            return false;
        }
    }

    const onChangeCommittedHandler = async (newValue) => {
        if (user.isImpersonator && user.isImpersonating) {
            return;
        }
        setVolumeChange({ now: false, time: new Date().getTime() });
        setVolume(newValue);
        try {
            await commitVolume(newValue);
            postUserInteraction(props.customerId, {
                setting: userInteractionTypes.directVolume.key,
                zoneId: props.zoneId,
                toValue: newValue
            });
        } catch (error) {

        }
    }

    const incrementVolume = async (increment) => {
        if (user.isImpersonator && user.isImpersonating) {
            return;
        }
        if (volume + increment > Math.max(maxVolume, 100) || volume + increment < 0) {
            return;
        }
        setVolumeChange({ now: false, time: new Date().getTime() });
        var newValue = volume + increment;
        setVolume(newValue);
        try {
            await commitVolume(newValue);
            postUserInteraction(props.customerId, {
                setting: userInteractionTypes.directVolume.key,
                zoneId: props.zoneId,
                toValue: newValue
            });
        } catch (error) {

        }
    }

    return <>
        <Container>
            <Label>Volume</Label>
            <VolumeButtonContainer>
                <button onClick={(e) => { e.stopPropagation(); incrementVolume(-1); }}><FaMinus /></button>
                <button onClick={(e) => { e.stopPropagation(); incrementVolume(1); }}><FaPlus /></button>
            </VolumeButtonContainer>
            <Label>{volume ? `${volume}%` : ''}</Label>
        </Container>

        <Slider
            min={0}
            backgroundColor={theme.colors.darkSpace90}
            value={volume}
            max={maxVolume > 100 ? maxVolume : 100}
            onChange={(newVal) => onChangeHandler(newVal)}
            onChangeCommitted={(newVal) => onChangeCommittedHandler(newVal)} />

        <SliderTicContainer>
            <WavedArea width={`calc(${100 * (1 - (maxVolume - 100) / maxVolume)}% - ${30 * (1 - (maxVolume - 100) / maxVolume) - 30}px)`}>Waved 100%</WavedArea>
            {maxVolume > 100 ?
                <ProcessorArea>Max</ProcessorArea>
                : <></>}
        </SliderTicContainer>
    </>
}

export default ZoneVolumeSlider;