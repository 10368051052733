import { FaSave } from "react-icons/fa";
import styled from "styled-components";
import { isMobile } from "../../../scripts/common";
import theme from "../../../UI/theme";
import MoreActionsButton from "../../UiComponents/buttons/MoreActionsButton";
import AddObjectButton from "./AddObjectButton";
import DisplayModeSelector from "./DisplayModeSelector"
import SaveButton from "./SaveButton";
import { ListActionButton } from "./styles/ActionStyles";

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
    align-items: center;   
`

const Section = styled.div`
    display: flex;
    align-items: center;

    gap: 10px;

    >h3 {
        margin: 0 12px;
        color: ${theme.colors.textGray};
    }
`


const EditableTableHeader = ({
    title,
    displayMode,
    setDisplayMode,
    columns,
    addObject,
    isEdited,
    saveHandler,
    actions
}) => {

    const renderAddButton = () => <>
        <AddObjectButton
            disabled={!addObject}
            columns={columns}
            onSubmit={addObject}
            title={title}
        />
    </>

    const getActionsList = () => {
        let actionsList = [];
        if (isMobile()) {
            actionsList.push({
                key: 'displayModeSelector',
                render: () => (
                    <DisplayModeSelector
                        displayMode={displayMode}
                        setDisplayMode={setDisplayMode}
                        fitContainer={true}
                    />
                )
            })
        }
        return [...actionsList, ...actions.filter(action => !action.hide)];
    }

    const actionsList = getActionsList();

    return <Container>

        <Section>
            <h3>{title}</h3>
        </Section>

        <Section>
            {isMobile() ? <>
                {renderAddButton()}
                <MoreActionsButton actions={actionsList} />
            </> : <>
                <DisplayModeSelector
                    displayMode={displayMode}
                    setDisplayMode={setDisplayMode}
                />
                {renderAddButton()}
                <SaveButton isEdited={isEdited} saveHandler={saveHandler} />
                <MoreActionsButton disabled={!actionsList.length} actions={actionsList} />
            </>}

        </Section>
    </Container>
}

export default EditableTableHeader;

