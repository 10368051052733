import { useEffect, useState } from "react";
import { getDayOfWeek } from "../../../scripts/common";
import { compareScheduleTimesAsc, compareScheduleTimesDesc } from "../../../scripts/schedules";
import ZoneSchedules from "./ZoneSchedules"

const ZoneSchedulesContainer = props => {
    const [nextSchedule, setNextSchedule] = useState();
    const [previousSchedule, setPreviousSchedule] = useState();

    useEffect(() => {
        if (props.schedules) {
            getSurroundingSchedules();
        }
    }, [props.schedules]);

    const getSurroundingSchedules = () => {
        const today = new Date();
        const dayOfWeek = getDayOfWeek(today);
        const nowTime = `${('0' + today.getHours()).slice(-2)}${('0' + today.getMinutes()).slice(-2)}`;
        const resetTime = '0500';

        var otherDay = new Date();
        var afterMidnight = false;
        if (nowTime >= resetTime) {
            otherDay.setDate(otherDay.getDate() + 1);
        } else {
            otherDay.setDate(otherDay.getDate() - 1);
            afterMidnight = true;
        }

        const otherDayOfWeek = getDayOfWeek(otherDay);

        const todaysSchedules = props.schedules?.filter(schedule => {
            if (!schedule.zones?.includes(props.zoneId)) {
                return false;
            }
            if (afterMidnight) {
                if ((schedule.days.includes(dayOfWeek) && schedule.time < resetTime) ||
                    (schedule.days.includes(otherDayOfWeek) && schedule.time >= resetTime)) {
                    return true;
                }
                return false;
            } else {
                if ((schedule.days.includes(dayOfWeek) && schedule.time >= resetTime) ||
                    (schedule.days.includes(otherDayOfWeek) && schedule.time < resetTime)) {
                    return true;
                }
                return false;
            }
        })
        
        var newNextSchedule = null;
        var newPreviousSchedule = null;
        if (afterMidnight) {
            newNextSchedule = todaysSchedules
                ?.filter(schedule => schedule.days.includes(dayOfWeek))
                ?.sort(compareScheduleTimesAsc)
                ?.find(schedule => schedule.time > nowTime && schedule.time < resetTime);

            newPreviousSchedule = todaysSchedules
                ?.filter(schedule => schedule.days.includes(dayOfWeek))
                ?.sort(compareScheduleTimesDesc)
                ?.find(schedule => schedule.time <= nowTime);
            if (!newPreviousSchedule) {
                newPreviousSchedule = todaysSchedules
                    ?.filter(schedule => schedule.days.includes(otherDayOfWeek))
                    ?.sort(compareScheduleTimesDesc)
                    ?.find(schedule => schedule.time > resetTime);
            }
        } else {
            newNextSchedule = todaysSchedules
                ?.filter(schedule => schedule.days.includes(dayOfWeek))
                ?.sort(compareScheduleTimesAsc)
                ?.find(schedule => schedule.time > nowTime);
            if (!newNextSchedule) {
                newNextSchedule = todaysSchedules
                    ?.filter(schedule => schedule.days.includes(otherDayOfWeek))
                    ?.sort(compareScheduleTimesAsc)
                    ?.find(schedule => schedule.time < resetTime);
            }

            newPreviousSchedule = todaysSchedules
                ?.filter(schedule => schedule.days.includes(dayOfWeek))
                ?.sort(compareScheduleTimesDesc)
                ?.find(schedule => schedule.time <= nowTime && schedule.time > resetTime);
        }
        
        setNextSchedule(newNextSchedule);
        setPreviousSchedule(newPreviousSchedule);

        if (props.setHasSchedule) {
            if (newNextSchedule || newPreviousSchedule) {
                props.setHasSchedule(true);
            } else {
                props.setHasSchedule(false);
            }
        }
    }

    return <>
        {props.schedules && (nextSchedule || previousSchedule) ?
            <ZoneSchedules nextSchedule={nextSchedule} previousSchedule={previousSchedule} />
            : <></>}
    </>
}

export default ZoneSchedulesContainer;