import React from 'react';
import { connectionTypes, processorTypes } from '../../../settings/venue/device/AudioSystem/processorTypes';
import { isSameSubnet, validateIPaddress } from '../../../settings/venue/device/NetworkConfig/networkUtils';
import StringField from '../../fields/StringField';
import { DisplayField } from '../../fields/Styles';
import { hasIpAddressesOnSameSubnet } from '../../Hub/hubUtils';
import TestProcessorConnection from './TestProcessorConnection';
import VirtualInterfaceField from './VirtualInterfaceField';

const IpAddressField = ({ processor, updateObject, hub, cellControl }) => {

    const validate = (ipAddress) => {
        var validationError;
        if (!validateIPaddress(ipAddress)) {
            validationError = { message: 'Not a valid ip address', type: 'ERROR' };
        } else if (hub.networkConfig) {
            var ipAddressesOnSameSubnet = Object.keys(hub.networkConfig).filter(key => isSameSubnet(hub.networkConfig[key].address, ipAddress)).length;
            if (ipAddressesOnSameSubnet === 0) {
                validationError = { message: 'No ip addresses on this subnet', type: 'WARNING' };
            }
        }

        return validationError;
    }

    const ipAddress = processor.ipAddress;
    const disabled = disableIpAddressField(processor);
    const validationError = validate(ipAddress);
    if (disabled) {
        return <DisplayField>N/A</DisplayField>
    } else {
        return <>
            <StringField
                value={ipAddress}
                setValue={(value) => updateObject({ ipAddress: value })}
                error={validationError}
                cellState={cellControl.cellState}
                disabled={disabled}
            />

            {cellControl.isForm && !hasIpAddressesOnSameSubnet(ipAddress, hub.networkConfig) ? <>
                <VirtualInterfaceField
                    processor={processor}
                    hub={hub}
                />
            </> : <></>}

            {cellControl.isForm && !(validationError?.type === 'ERROR') ? <>
                <TestProcessorConnection processor={processor} hub={hub} />
            </> : <></>}
        </>
    }

}
export default IpAddressField;

export const hideIpAddressField = (processors) => {
    let hide = true;
    processors.forEach(processor => {
        if (!disableIpAddressField(processor)) {
            hide = false;
        }
    })
    return hide;
}

export const disableIpAddressField = (processor) => {
    const processorType = processorTypes[processor.type];
    return processorType?.connection !== connectionTypes.TCP;
}