import { useEffect, useState } from "react";
import { StepHeader, StepContainer, RowContainer, InfoText, ToggleContainer } from "./CalibrationStyles";
import Slider from "../../../../UiComponents/Slider";
import styled from "styled-components";
import theme from "../../../../../UI/theme";
import { useContext } from "react";
import UserContext from "../../../../../hooks/UserContext";
import ToggleButton from "../../../../UiComponents/ToggleButton";
import { setAbsoluteVolumeDirect } from "../../../../../scripts/volume";
import { FaMinus, FaPlus } from "react-icons/fa";
import InnerCard from "../../../../UiComponents/containers/InnerCard";
import { TextMuted } from "../../../../UiComponents/text/StyledText";

const VolumeButtonContainer = styled.div`
    justify-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: ${theme.colors.textGray};
    width: 100%;

    button {
        height: 20px;
        padding: 0 10px;
        border: none;
        background-color: transparent;
        color: ${theme.colors.textGray};
        font-size: ${theme.fontSize.large};

        :not(:last-of-type) {
            margin-right: 10px;
        }

        :hover {
            cursor: pointer;
            svg {
                transform: scale(1.2);
            }
        }
    }
`

const SliderLabel = styled.label`
    color: ${theme.colors.textGray};
    white-space: nowrap;

    :not(:first-of-type) {
        justify-self: end;
    }
`

const NoiseLabel = styled(TextMuted)`
    margin-bottom: 10px;
`

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 10px 0;
`

const SetVolumeLevelStep = (props) => {
    const [processorVolume, setProcessorVolume] = useState(props.disabledMin >= 0 ?
        (props.volume > props.disabledMin ? props.volume : props.disabledMin + 1)
        : props.volume);
    const user = useContext(UserContext);

    useEffect(() => {
        var newProcessorVolume = props.disabledMin > 0 ?
            (props.volume > props.disabledMin ? props.volume : props.disabledMin + 1)
            : props.volume;
        onChangeCommittedHandler(newProcessorVolume);
    }, [props.disabledMin])

    const onChangeCommittedHandler = async (newVal) => {
        if (props.liveVolumeChanges) {
            await setAbsoluteVolumeDirect(props.zone.customerId, props.zone.hubId, props.zone.zoneId, newVal, true);
        }
        setProcessorVolume(newVal);
        props.setVolume(newVal);
    }

    const incrementVolume = async (value) => {
        var newVolume = processorVolume + value;
        var minVol = props.disabledMin >= 0 ? props.disabledMin + 1 : 0;
        if (newVolume >= minVol && newVolume <= 100) {
            await onChangeCommittedHandler(newVolume);
        }
    }

    const thumbWidth = 30;

    return <StepContainer>
        <StepHeader>{props.zone.zoneName} - {props.step.header}</StepHeader>
        <InfoText>{props.step.label}</InfoText>

        <Container>
            <SliderLabel>Volume</SliderLabel>
            <VolumeButtonContainer>
                <button onClick={() => incrementVolume(-1)}><FaMinus /></button>
                <button onClick={() => incrementVolume(1)}><FaPlus /></button>
            </VolumeButtonContainer>
            <SliderLabel>{processorVolume}</SliderLabel>
        </Container>

        <RowContainer>
            {props.disabledValues?.map((disabledValue, index) => {
                const minVal = index > 0 ? props.disabledValues[index - 1] : 0;
                return <Slider
                    key={index}
                    min={minVal + 1}
                    max={disabledValue}
                    width={`calc(${disabledValue - minVal}% + ${thumbWidth}px)`}
                    value={disabledValue}
                    thumbColor={theme.colors.darkSpace80}
                    thumbWidth={`${thumbWidth}px`}
                />
            })}

            <Slider
                min={props.disabledMin >= 0 ? props.disabledMin + 1 : 0}
                value={processorVolume}
                max={100}
                width={props.disabledMin ? `${100 - props.disabledMin - 1}%` : '100%'}
                onChange={(newVal) => setProcessorVolume(newVal)}
                onChangeCommitted={(newVal) => onChangeCommittedHandler(newVal)} />

        </RowContainer>

        {user.user.role === 'admin' ? <>
            <NoiseLabel>Noise: {props.liveValues?.noise}</NoiseLabel>
        </>
            : <></>}

        <InnerCard>
            <ToggleContainer>
                <label>Set zone volume while calibrating</label>
                <ToggleButton checked={props.liveVolumeChanges} onToggle={(newVal) => props.setLiveVolumeChanges(newVal)} />
            </ToggleContainer>
        </InnerCard>

    </StepContainer>
}

export default SetVolumeLevelStep;