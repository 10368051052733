import React, { useState, useEffect, useRef } from 'react';
import heatmapTypes from '../heatmapTypes';
import { getSensorLiveData, getZoneLiveData } from '../../../../scripts/heatmap';
import NoConnectedSensorsMessage from './NoConnectedSensorsMessage';
import styled from 'styled-components';
import MiniDataLegend from './MiniDataLegend';
import StyledContainer from './StyledContainer';
import LiveIcon from './LiveIcon';

const SelectorContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`

const OLD_LIVE_DATA_LIMIT = 10; // make shared import with sensor

const DataModeContainer = props => {
    const [activeDataMode, setActiveDataMode] = useState(heatmapTypes.dataModes.LIVE);
    const [activeDataType, setActiveDataType] = useState(heatmapTypes.dataTypes.AVG_DB);
    const liveDataInterval = useRef();

    useEffect(() => {
        if (props.activeMode === heatmapTypes.modes.DATA && activeDataMode === heatmapTypes.dataModes.LIVE) {
            setTimeout(() => {
                getSensorLiveDataHandler();
                getZoneLiveDataHandler();
            }, 1000);
            startLiveInterval();
            props.actionHandler(heatmapTypes.actions.SET_DATA_MODE, heatmapTypes.dataModes.LIVE);
        } else {
            clearInterval(liveDataInterval.current);
            if (!props.canvasMessage) { props.setCanvasMessage(null); }
        }
        props.setCanvasIcon(<LiveIcon activeDataMode={activeDataMode} />);
        return cleanup;
    }, [props.activeMode, activeDataMode, props.customerId]);

    useEffect(() => {
        setDataLegendHandler();
        return (() => props.setDataLegend(null));
    }, [activeDataType]);

    const cleanup = () => {
        props.setCanvasMessage(null);
        props.setCanvasIcon(null);
        clearInterval(liveDataInterval.current);
    }

    const getSensorLiveDataHandler = async () => {
        var liveData = await getSensorLiveData(props.customerId);
        if (liveData && liveData.sensordata.length && props.activeMode === heatmapTypes.modes.DATA) {
            props.actionHandler(heatmapTypes.actions.SENSOR_DATA, liveData.sensordata);
            // check connections
            //checkConnections(liveData.sensordata);
        }
    }

    const getZoneLiveDataHandler = async () => {
        var liveData = await getZoneLiveData(props.customerId);
        if (liveData && liveData.zonedata.length && props.activeMode === heatmapTypes.modes.DATA) {
            props.actionHandler(heatmapTypes.actions.ZONE_DATA, liveData.zonedata);
        }
    }

    const startLiveInterval = () => {
        clearInterval(liveDataInterval.current);
        liveDataInterval.current = setInterval(() => {
            getSensorLiveDataHandler();
            getZoneLiveDataHandler();
        }, 5000);
    }

    const checkConnections = (sensordata) => {
        var disconnectedCount = 0;
        sensordata.forEach(sensorDataPoint => {
            if (sensorDataPoint.secondsSinceLastUpdate > OLD_LIVE_DATA_LIMIT) {
                disconnectedCount++;
            }
        });
        if (disconnectedCount === sensordata.length) {
            // No sensors are connected -> display error message
            props.setCanvasMessage(<NoConnectedSensorsMessage />);
        } else {
            if (!props.canvasMessage) {
                props.setCanvasMessage(null);
            }
        }
    }

    const setDataLegendHandler = () => {
        props.setDataLegend(<MiniDataLegend />);
    }

    const selectModeHandler = (mode) => {
        setActiveDataMode(mode);
        props.actionHandler(heatmapTypes.actions.SET_DATA_MODE, mode);
    }

    const selectDataTypeHandler = (dataType) => {
        setActiveDataType(dataType);
        props.actionHandler(heatmapTypes.actions.SET_DATA_TYPE, dataType);
    }


    return <div>


        {/* <SelectorContainer>
            <DataModeSelector selectedMode={activeDataMode} setSelectedMode={selectModeHandler} />
        </SelectorContainer>

        {activeDataMode === heatmapTypes.dataModes.HISTORY ? <>
            <HistoryModeContainer
                customerId={props.customerId}
                actionHandler={props.actionHandler}
            />
        </> : <></>}

        {activeDataMode === heatmapTypes.dataModes.TREND ? <>
            <TrendModeContainer
                customerId={props.customerId}
                actionHandler={props.actionHandler}
            />
        </> : <></>} */}

    </div>
}

export default DataModeContainer;