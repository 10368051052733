import React, { useState, useEffect } from 'react';
import LocationList from './LocationList';
import VenueItem from './venue/VenueItem';
import VenueView from '../../views/VenueView';
import styled from 'styled-components';

const NoVenuesLabel = styled.label`
    margin: 20px;
`

const VenueContainer = props => {
    const [activeVenueID, setActiveVenueID] = useState();

    useEffect(() => {
        if (props.venues && props.venues.length) {
            var defaultVenue = getDefaultVenue(props.venues);
        }
        if (defaultVenue) {
            setActiveVenueID(defaultVenue);
        }
    }, [props.venues]);

    const getViewContent = () => {
        var activeVenue;
        if (activeVenueID) {
            activeVenue = props.venues.find(venue => venue.customerId === activeVenueID);
        }
        if (activeVenueID && activeVenue) {
            return <>
                <VenueView
                    venue={activeVenue}
                    goBack={() => setActiveVenueID(null)}
                    live={props.live}
                    multipleVenues={props.venues?.length > 1}
                    {...props}
                />
            </>
        } else {
            return <>
                <LocationList>
                    {props.venues.map((item, ix) => {
                        return <VenueItem
                            key={ix}
                            item={item}
                            selectVenue={() => setActiveVenueID(item.customerId)}
                            live={props.live}
                        >
                        </VenueItem>
                    })}
                </LocationList>
            </>
        }
    }

    return <>
        {props.venues ?
            <div>
                {getViewContent()}
            </div>
            : <NoVenuesLabel>
                No venues could be found for your account. Please contact support@waved.no if you have any questions.
            </NoVenuesLabel>}
    </>
}

export default VenueContainer;

function getDefaultVenue(venues) {
    return venues.length === 1 ? venues[0].customerId : null;
}

