import { updateSensor } from "../../../scripts/sensor";
import { saveObjects } from "../tableUtils";

const saveUpdateSensors = async (changedSensors, sensors, customerId) => {
    prepareForSave(changedSensors, sensors);
    const uniqueHubIds = getUniqueHubIds(changedSensors);
    await saveObjects(changedSensors, uniqueHubIds, customerId, 'UPDATE_SENSORS', (customerId, sensor) => updateSensor(customerId, sensor));
}

export {
    saveUpdateSensors
}


const getUniqueHubIds = (sensors) => {
    const uniqueHubIds = [];
    sensors.forEach(sensor => {
        if (!uniqueHubIds.includes(sensor.hubId)) {
            uniqueHubIds.push(sensor.hubId);
        }
    })
    return uniqueHubIds;
}

const prepareForSave = (changedSensors, sensors) => {
    changedSensors.forEach(changedSensor => {
        const sensor = sensors.find(sensor => sensor.sensorId === changedSensor.sensorId);
        if (sensor) {
            changedSensor.hubId = sensor.hubId;
            changedSensor.customerId = sensor.customerId;
        }
    });
}

