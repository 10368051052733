
import React, { useEffect, useState } from "react";
import { getMusicPlayers } from "../../scripts/musicPlayer";
import MusicDisplay from "./MusicDisplay";

const MusicDisplayParent = (props) => {

    const [savedPlayers, setSavedPlayers] = useState();

    useEffect(() => {
        getData();
    },[])

    const getData = async () => {
        var savedMusicPlayers = await getMusicPlayers(props.customerId);
        setSavedPlayers(savedMusicPlayers);
    }

    return (<>
        {savedPlayers?<>
            {savedPlayers.map(musicPlayer => {
                return (
                    <MusicDisplay
                        musicPlayer={musicPlayer}
                        key={musicPlayer.remoteId}
                    />
                )
            })}
        </>:<></>}
    </>)
} 
export default MusicDisplayParent;