import React, { useState, useRef } from 'react';
import ButtonContainer from '../../UiComponents/containers/ButtonContainer';
import { FormContainer, FormField, FormInput } from './styles/FormStyles';
import Button from '../../UiComponents/Button';
import { cellStates } from './tableTypes';
import { loadingStates } from '../installationTypes';
import NeutralSpinner from '../../UiComponents/Animations/NeutralSpinner';
import styled from 'styled-components';


const NewObjectForm = ({ columns, onSubmit, onCancel, initProps, title }) => {
    const [object, setObject] = useState(initProps || getSuggestedValues(columns));
    const [activeFieldIndex, setActiveFieldIndex] = useState(0);
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);
    const submitProps = useRef([]);

    const updateSubmitProps = (updatedProps) => {
        submitProps.current = { ...submitProps.current, ...updatedProps };
    }

    const updateObject = (updatedProps) => {
        setObject({ ...object, ...updatedProps });
    }

    const submitHandler = async () => {
        try {
            const timeout = setTimeout(() => {
                setLoadingState(loadingStates.LOADING);
            }, 500);
            
            await onSubmit({ ...object }, submitProps.current);
            clearTimeout(timeout);
            setLoadingState(loadingStates.SUCCESS);
        } catch (err) {
            console.log('Failed to submit form');
            setLoadingState(loadingStates.FAILED);
        }
    }

    const onFocus = (ix) => {
        setActiveFieldIndex(ix);
    }

    return <>
        <FormContainer>
            {title ? <>
                <h3>Add {formatTitle(title)}</h3>
            </> : <></>}
            {columns.filter(column => column.form).map((column, ix) => {
                const cellState = (ix === activeFieldIndex) ? cellStates.ACTIVE : cellStates.FORM;
                const cellControl = { cellState, updateSubmitProps, isForm: true };
                const hideInForm = column.hideInItem && column.hideInItem(object);
                if (!hideInForm) {
                    return <FormField key={ix} onFocus={() => onFocus(ix)}>
                        <div>
                            <label>{column.displayName}</label>
                        </div>
                        <FormInput>
                            {column.render(object, updateObject, cellControl)}
                        </FormInput>
                    </FormField>
                } else {
                    <></>
                }
            })}
            <div>
                {loadingState !== loadingStates.LOADING ? <>
                    <ButtonContainer>
                        <Button primary onClick={submitHandler}>Save</Button>
                        <Button secondary onClick={onCancel}>Cancel</Button>
                    </ButtonContainer>
                </> : <>
                    <NeutralSpinner />
                </>}
            </div>
        </FormContainer>
    </>
}

export default NewObjectForm;

const getSuggestedValues = (columns) => {
    const suggestedValues = {};
    columns.filter(column => column.form && column.getSuggested).forEach(column => {
        suggestedValues[column.key] = column.getSuggested();
    });
    return suggestedValues;
}

const formatTitle = (title) => {
    if (title[title.length - 1] === 's') {
        title = title.slice(0, title.length-1);
    }
    return title.toLowerCase();
}