import RegulationButton from "./RegulationButton";

const ZoneRegulationButton = props => {
    return <RegulationButton {...props}>
        <label>
            {!props.isRegulatingAudioSource ? 'Source off' :
                !props.isRegulating ? 'Off' :
                    'On'}
        </label>
    </RegulationButton>
}

export default ZoneRegulationButton;