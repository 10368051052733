import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/northstar';

async function getNorthstar(customerId) {
    try {
        var res = await axios.get(`${hostname}${path}?customerId=${customerId}`, {withCredentials: true});
        var data = res.data;
        if (data.error) {
            console.log(data.error);
            return false;
        } else {
            return data;
        }
    } catch (err) {
        return false;
    }
}

export {
    getNorthstar
}