import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import heatmapTypes from '../heatmapTypes';
import { getSensorYearTrend } from '../../../../scripts/trend';
import WeekDaySelector from './WeekDaySelector';
import LoadingSpinner from '../../../UiComponents/LoadingSpinner';
import TrendHourChart from './TrendHourChart';

const Container = styled.div`
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`

const TrendModeContainer = props => {
    const [state, setState] = useState(heatmapTypes.states.NONE);
    const [data, setData] = useState();
    const [chartData, setChartData] = useState();
    const [activeHour, setActiveHour] = useState(0);
    const [activeDay, setActiveDay] = useState(0);

    useEffect(() => {
        getTrendDataHandler();
    }, [props.customerId]);

    useEffect(() => {
        if (data && !isNaN(parseInt(activeDay))) {
            updateChartData(data, activeDay);
        }
    }, [activeHour])

    const getTrendDataHandler = async () => {
        var trendData = await getSensorYearTrend(props.customerId);
        setState(heatmapTypes.states.LOADING);
        if (trendData && trendData.length) {
            setData(trendData);
            updateChartData(trendData, activeDay);
            setState(heatmapTypes.states.DATA);
        } else {
            setState(heatmapTypes.states.NO_DATA);
        }
    };

    const updateChartData = (trendData, dayIndex) => {
        var dayData = getDataForDay(trendData, dayIndex);
        var updatedChartData = getChartData(dayData, dayIndex);
        setChartData(updatedChartData);
        updateHeatmap(dayData, activeHour);
    }

    const updateHeatmap = (dayData, hour) => {
        var hourData = getDataForHour(dayData, hour);
        props.actionHandler(heatmapTypes.actions.SENSOR_DATA, hourData);
    }

    const setActiveDayHandler = (dayIndex) => {
        updateChartData(data, dayIndex);
        setActiveDay(dayIndex);
    }

    return <Container>

        {state === heatmapTypes.states.DATA ? <>
            <WeekDaySelector
                activeDay={activeDay}
                setActiveDay={setActiveDayHandler}
            />
            <TrendHourChart
                data={chartData.data}
                labels={chartData.labels}
                activeHour={activeHour}
                setActiveHour={setActiveHour}
            />

        </> : <></>}

        {state === heatmapTypes.states.LOADING ? <>
            <LoadingSpinner />
        </> : <></>}
        {state === heatmapTypes.states.NO_DATA ? <>
            <p>No data</p>
        </> : <></>}
    </Container>
}

export default TrendModeContainer;

function getDataForDay(data, dayIndex) {
    return data.map(sensorTrend => {
        return ({
            sensorId: sensorTrend.ID,
            hourData: sensorTrend.data[dayIndex].hourData
        });
    })
}

function getDataForHour(dayData, hourIndex) {
    return dayData.map(sensor => {
        return ({
            sensorId: sensor.sensorId,
            averageDecibel: sensor.hourData[hourIndex]
        })
    })
}

function getChartData(dayData) {
    const hours = 24;
    var hourlySums = Array.from({ length: hours }, () => ({ count: 0, tot: 0 }));
    dayData.forEach((sensor, i) => {
        sensor.hourData.forEach((hourValue, j) => {
            if (hourValue) {
                hourlySums[j].tot += hourValue;
                hourlySums[j].count++;
            }
        });
    });
    var hourlyAverages = new Array(hours).fill(0);
    hourlySums.forEach((hour, i) => {
        if (hour.count > 0) {
            hourlyAverages[i] = hour.tot / hour.count;
        }
    });
    var labels = hourlySums.map((_, i) => i);
    return { data: hourlyAverages, labels: labels };
}