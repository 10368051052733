import React, { useState } from 'react';
import Dropdown from '../UiComponents/Dropdown';
import styled from 'styled-components';
import InputField from '../UiComponents/InputField';
import Button from '../UiComponents/Button';
import InnerCard from '../UiComponents/containers/InnerCard';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`
const My20 = styled.div`
    margin-top: 20px;
`


const networkTypes = [{
    value: 'WIFI',
    label: 'Wifi'
}, {
    value: 'ZIGBEE',
    label: 'Zigbee'
}];

const connectionTypes = [{
    value: 'ETHERNET',
    label: 'Ethernet'
}, {
    value: 'WIFI',
    label: 'Wifi'
}]

const InitialSetupCard = (props) => {

    const [popupShowing, setPopupShowing] = useState(false);

    function showPopup() {
        if (!popupShowing) {
            var popup = document.getElementById("netExplain");
            popup.classList.toggle("show");
            setPopupShowing(!popupShowing);
        }
    }

    function hidePopup() {
        if (popupShowing) {
            var popup = document.getElementById("netExplain");
            popup.classList.toggle("show");
            setPopupShowing(false);
        }
    }

    return (
        <Container onClick={() => hidePopup()}>

            <h3>Set up your system</h3>


            <h4>Select type of hub connection</h4>
            <div className="flex justify-center">
                <div>
                    <Dropdown items={connectionTypes} onClick={(item) => props.setConnectionType(item.value)} selectedItem={connectionTypes.find(i => i.value == props.connectionType)} />
                </div>
            </div>


            {props.connectionType === 'WIFI' ? <>
                <My20 />
                <InnerCard>
                    <Container>
                    <p>Please provide your network name and network password. <br />
                        Once you click 'Get Started' and pair with WavedHub, the setup proccess starts.
                    </p>

                    <br></br>

                    <div>
                        <div>
                            <label className="input-field-label" >Name of your network:</label>
                        </div>
                        <InputField className="waved-input-field" id="netName" />
                    </div>
                    <My20 />
                    <div>
                        <div>
                            <label className="input-field-label" >Password to the network:</label>
                        </div>
                        <InputField className="waved-input-field" id="netPass" />
                    </div>
                    </Container>
                </InnerCard>
            </> : <></>}


            <h4>Select type of sensor network</h4>
            <div className="flex justify-center">
                <div>
                    <Dropdown items={networkTypes} onClick={(item) => props.setNetworkType(item.value)} selectedItem={networkTypes.find(i => i.value == props.networkType)} />
                </div>
            </div>



            {props.networkType === 'ZIGBEE' ? <>
                <h4>Zigbee channel</h4>
                <div className="flex justify-center">
                    <ZigbeeChannelSelector selectedChannel={props.zigbeeChannel} setChannel={props.setZigbeeChannel} />
                </div>
            </> : <></>}


            <My20 />
            <My20 />
            <div>
                <Button primary onClick={() => props.setupHub()} >Get Started!</Button>
            </div>

        </Container>
    );
}

export default InitialSetupCard;

const zigbeeChannels = [11, 14, 15, 20, 25];

const ZigbeeChannelSelector = props => {
    const channelItems = zigbeeChannels.map(channel => ({ value: channel, label: channel }));
    return <div>
        <Dropdown items={channelItems} selectedItem={channelItems.find(i => i.value === props.selectedChannel) || {}} onClick={(item) => props.setChannel(item.value)} />
    </div>
}