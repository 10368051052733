import { useState } from "react";
import styled from "styled-components";
import theme from "../../../../UI/theme";
import Button from "../../../UiComponents/Button";
import Modal from "../../../UiComponents/Modal";
import CheckboxField from "../../fields/CheckBoxField";
import NumberField from "../../fields/NumberField";
import { DisplayField, editableColor, readOnlyColor, TableCell } from "../../fields/Styles";
import { FieldButton } from "../../fields/styling/FieldStyles";
import { HeaderRow, StyledTable } from "../../table/styles/TableStyles";
import { cellStates } from "../../table/tableTypes";
import { displayModes } from "../../utils/installationTypes";

const FieldContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const SourceConfigField = ({ sourceConfig, updateSourceConfig, sources }) => {
    const [showModal, setShowModal] = useState(false);


    const updateMapping = (sourceId, address) => {
        if (!isNaN(parseInt(address))) {
            const updatedMapping = { ...sourceConfig?.mapping, [sourceId]: parseInt(address) };
            updateSourceConfig({ ...sourceConfig, mapping: updatedMapping });
        } else if (!address) {
            removeMapping(sourceId);
        }
    }

    const removeMapping = (sourceId) => {
        const updatedMapping = { ...sourceConfig?.mapping };
        delete updatedMapping[sourceId];
        updateSourceConfig({ ...sourceConfig, mapping: updatedMapping });
    }

    const setAvailability = (sourceId, state) => {
        let updatedUnavailableSources = sourceConfig?.unavailableSources ? [...sourceConfig.unavailableSources] : [];
        if (state && !updatedUnavailableSources.includes(sourceId)) {
            updatedUnavailableSources.push(sourceId);
        } else {
            updatedUnavailableSources = updatedUnavailableSources.filter(id => id !== sourceId);
        }
        updateSourceConfig({ ...sourceConfig, unavailableSources: updatedUnavailableSources });
    }

    const resetToDefault = () => {
        updateSourceConfig(null);
    }

    const isConfigured = sourceConfig && (sourceConfig.mapping && Object.keys(sourceConfig.mapping).length) || sourceConfig?.unavailableSources?.length;
    return <>
        <FieldContainer>

            <div style={{ color: isConfigured ? editableColor : readOnlyColor }}>{isConfigured ? 'Configured' : 'Default'}</div>
            <div>
                <FieldButton tertiary small onClick={() => setShowModal(true)}>Edit</FieldButton>
            </div>
        </FieldContainer>
        <Modal show={showModal} close={() => setShowModal(false)}>

            <h3>Source Config</h3>
            <ConfigEditor
                mapping={sourceConfig?.mapping}
                updateMapping={updateMapping}
                unavailableSources={sourceConfig?.unavailableSources}
                setAvailability={setAvailability}
                sources={sources}
                closeModal={() => setShowModal(false)}
                isConfigured={isConfigured}
                resetToDefault={resetToDefault}
            />
        </Modal>
    </>
}

export default SourceConfigField;

const Container = styled.div`
    @media only screen and (max-width: ${theme.screenSizes.small - 1}px) {
        width: 100%
    }
    @media only screen and (min-width: ${theme.screenSizes.small}px) {
        min-width: 30em;
    }

    >table {
        margin: 10px 0;
    }
    >div>button  {
        margin-right: 12px;
    }
`

const ConfigEditor = ({ mapping, updateMapping, unavailableSources, setAvailability, sources, closeModal, isConfigured, resetToDefault }) => {

    return <>
        <Container>
            <StyledTable borderColor={theme.colors.dropdownGray}>
                <thead>
                    <HeaderRow backgroundColor={theme.colors.dropdownGray}>
                        <th>Source</th>
                        <th>Address</th>
                        <th>Mapped Address</th>
                        <th>Available</th>
                    </HeaderRow>
                </thead>
                <tbody>
                    {sources.map(source => {
                        const hasCustomMapping = (mapping && mapping[source.sourceId] !== undefined);
                        const isUnavailable = (unavailableSources && unavailableSources.includes(source.sourceId))
                        const cellState = hasCustomMapping ? cellStates.FORM : cellStates.NONE;
                        return <tr key={source.sourceId}>
                            <TableCell>{source.name}</TableCell>
                            <TableCell style={{ width: '50px' }}>
                                <DisplayField>{source.address}</DisplayField>
                            </TableCell>
                            <TableCell style={{ width: '50px' }}>
                                <NumberField
                                    value={mapping ? mapping[source.sourceId] : ''}
                                    setValue={(value) => updateMapping(source.sourceId, value)}
                                    cellState={cellState}
                                />
                            </TableCell>
                            <TableCell>
                                <CheckboxField
                                    checked={!isUnavailable}
                                    setValue={() => setAvailability(source.sourceId, !isUnavailable)}
                                    cellControl={{ cellState, displayMode: displayModes.TABLE }}
                                />
                            </TableCell>
                        </tr>
                    })}
                </tbody>
            </StyledTable>
            <div>
                <Button primary onClick={closeModal}>Ok</Button>
                {isConfigured ? <>
                    <Button secondary onClick={resetToDefault}>Reset to default</Button>
                </> : <></>}
            </div>
        </Container>
    </>
}