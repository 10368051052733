import SoundtrackYourBrandPlayer from './soundtrackYourBrand/SoundtrackYourBrandPlayer';
import SpotifyPlayer from './spotify/SpotifyPlayer';

function getPlayerProvider(playerInfo) {

    if (!playerInfo.type) {
        console.log('A PlayerProvider must have a type, no type given to getPlayerProvider()');
        return false;
    }
    switch (playerInfo.type) {
        case "SoundtrackYourBrand":
            return new SoundtrackYourBrandPlayer(playerInfo);
        case "Spotify":
            return new SpotifyPlayer(playerInfo);
        default: 
            return undefined
    }
}

async function checkAvailablePlayers(type, credentials=false) {
    const playerInfo = { type: type };
    const playerProider = getPlayerProvider(playerInfo);
    const availablPlayers = await playerProider.checkAvailablePlayers(credentials);
    return availablPlayers;
}

export { 
    getPlayerProvider,
    checkAvailablePlayers
}