import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import ExpandCollapseButton from '../../UiComponents/buttons/ExpandCollapseButton';
import Divider from '../../UiComponents/Divider';
import { TextMuted } from '../../UiComponents/text/StyledText';
import { DetailsContainer, DeviceContainer, DeviceError, DeviceIconContainer, DeviceList, DeviceMainContainer, IndicatorDot, NameContainer, SummaryContainer, StyledListItem, ListContainer, Flex, ThinDivider, ActionsContainer } from '../styling/DeviceStyles';
import { ZoneIcon } from '../styling/Icons';
import EditableProps from '../table/EditableProps';
import { getSortedGroups } from '../tableUtils';
import { actionTypes } from '../utils/installationTypes';
import AddObjectButton from './AddObjectButton';

const EditableList = ({
    objects,
    columns,
    idField,
    getListItemProps,
    saveObject,
    deleteObject,
    addObject,
    sortObjects,
    getActions
}) => {
    const groups = getSortedGroups(objects, sortObjects);

    const renderObject = (columns, object, index) => (
        <ListItem
            key={index}
            object={object}
            columns={columns}
            idField={idField}
            listItemProps={getListItemProps(object)}
            deleteObject={deleteObject}
            saveObject={saveObject}
            getActions={getActions}
        />
    )

    return <ListContainer>
        <DeviceList>
            {groups.map((group, ix) => <>
                <div key={ix}>
                    {ix > 0 && <ThinDivider />}
                    {group.title && <h4>{group.title}</h4>}
                    {group.objects.map((object, index) => renderObject(columns, object, index))}
                </div>
            </>)}
        </DeviceList>
    </ListContainer>
}

export default EditableList;


const ListItem = ({ object, columns, listItemProps, idField, saveObject, deleteObject, getActions }) => {
    const [detailed, setDetailed] = useState(false);

    const changeDetailed = () => {
        setDetailed(!detailed);
    }

    const actions = getActions ? getActions(object).filter(action => !action.hide) : [];

    const Icon = listItemProps.Icon;
    return <StyledListItem>
        <DeviceContainer>
            <DeviceMainContainer onClick={changeDetailed} >
                <SummaryContainer>
                    <DeviceIconContainer>
                        <Icon />
                    </DeviceIconContainer>
                    <div>
                        <NameContainer>
                            <div><span>{listItemProps.displayName} </span></div>
                            {listItemProps.displayStatus ? <>
                                <div>
                                    <TextMuted>{listItemProps.displayStatus}</TextMuted>
                                </div>
                            </> : <></>}

                        </NameContainer>
                    </div>
                </SummaryContainer>

                <Flex style={{marginRight: '4px'}}>
                    <div style={{marginRight: '8px'}} onClick={(e) => e.stopPropagation()}>
                        {renderActions(actions, actionTypes.PRIMARY)}
                    </div>
                    {listItemProps.statusColor ? <>
                        <IndicatorDot>
                            <div style={{ backgroundColor: listItemProps.statusColor }} ></div>
                        </IndicatorDot>
                    </> : <></>}
                    <ExpandCollapseButton showState={detailed} height={30} />
                </Flex>
            </DeviceMainContainer>

            <AnimateHeight duration={300} height={detailed ? 'auto' : 0}>

                <ThinDivider />

                {listItemProps.error ? <>
                    {listItemProps.error}
                </> : <></>}


                <ActionsContainer>
                    {renderActions(actions, actionTypes.SECONDARY)}
                </ActionsContainer>

                <DetailsContainer>
                    <EditableProps
                        columns={columns}
                        parentObject={object}
                        idField={idField}
                        saveObject={saveObject}
                        deleteObject={deleteObject}
                    />
                </DetailsContainer>

                {listItemProps.renderCustomComponent ? <>
                    <DetailsContainer>
                        {listItemProps.renderCustomComponent(object)}
                    </DetailsContainer>
                </> : <></>}
            </AnimateHeight>
        </DeviceContainer>
    </StyledListItem>
}


const renderActions = (actions, type) => {
    if (actions) {
        return actions.filter(action => type !== undefined ? action.type === type : true).map(action => action.render());
    } else {
        return <></>;
    }

}