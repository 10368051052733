import theme from "../../../UI/theme"

const statusColors = {
    GRAY: theme.colors.darkSpace60,
    GREEN: theme.colors.greenEnergy,
    YELLOW: theme.colors.mellowYellow,
    RED: theme.colors.raspberryRed
}

const status = {
    SERVER_NOT_CONNECTED: 0,
    HUB_NOT_CONNECTED: 1,
    SENSOR_NOT_CONNECTED: 2,
    CONNECTED: 3,
    PROCESSOR_NOT_CONNECTED: 4,
    PROCESSOR_ERROR: 5
};

const HUB_OLD_DATA_LIMIT = 10;

const getHubConnectedStatus = (hub) => {
    return (hub?.secondsSinceLastUpdate < HUB_OLD_DATA_LIMIT);
}

const getSensorConnectedStatus = (sensor) => {
    return sensor?.isConnected;
}

const getProcessorConnectedStatus = (processor) => {
    return processor?.isConnected;
}

const getProcessorErrorStatus = (processor) => {
    return !processor?.error;
}


const getHubStatus = (hub, serverStatus, processors, sensors) => {
    if (!serverStatus) {
        return status.SERVER_NOT_CONNECTED;
    } else if (!getHubConnectedStatus(hub)) {
        return status.HUB_NOT_CONNECTED;
    } else if (processors.filter(processor => !getProcessorConnectedStatus(processor)).length > 0) {
        return status.PROCESSOR_NOT_CONNECTED;
    } else if (processors.filter(processor => !getProcessorErrorStatus(processor)).length > 0) {
        return status.PROCESSOR_ERROR;
    } else if (sensors.filter(sensor => !getSensorConnectedStatus(sensor)).length > 0) {
        return status.SENSOR_NOT_CONNECTED;
    } else {
        return status.CONNECTED;
    }
}

const getHubStatusColor = (hubStatus) => {
    switch (hubStatus) {
        case status.SERVER_NOT_CONNECTED:
            return statusColors.GRAY;
        case status.HUB_NOT_CONNECTED:
            return statusColors.RED;
        case status.PROCESSOR_NOT_CONNECTED:
            return statusColors.RED;
        case status.PROCESSOR_ERROR:
            return statusColors.RED;
        case status.SENSOR_NOT_CONNECTED:
            return statusColors.YELLOW;
        case status.CONNECTED:
            return statusColors.GREEN;
        default:
            return statusColors.GRAY;
    }
}

const getHubDisplayStatus = (hubStatus) => {
    switch (hubStatus) {
        case status.SERVER_NOT_CONNECTED:
            return '';
        case status.HUB_NOT_CONNECTED:
            return 'Not connected';
        default:
            return 'Connected';
    }
}

const getProcessorStatus = (processor, hub, serverStatus) => {
    if (!serverStatus) {
        return status.SERVER_NOT_CONNECTED;
    } else if (!getHubConnectedStatus(hub)) {
        return status.HUB_NOT_CONNECTED
    } else if (!getProcessorConnectedStatus(processor)) {
        return status.PROCESSOR_NOT_CONNECTED;
    } else if (!getProcessorErrorStatus(processor)) {
        return status.PROCESSOR_ERROR;
    } else {
        return status.CONNECTED;
    }
}

const getProcessorStatusColor = (processorStatus) => {
    switch (processorStatus) {
        case status.SERVER_NOT_CONNECTED:
            return statusColors.GRAY;
        case status.HUB_NOT_CONNECTED:
            return statusColors.GRAY;
        case status.PROCESSOR_NOT_CONNECTED:
            return statusColors.RED;
        case status.PROCESSOR_ERROR:
            return statusColors.RED;
        case status.CONNECTED:
            return statusColors.GREEN;
        default:
            return statusColors.GRAY;
    }
}

const getProcessorDisplayStatus = (processorStatus) => {
    switch (processorStatus) {
        case status.SERVER_NOT_CONNECTED:
            return '';
        case status.HUB_NOT_CONNECTED:
            return '';
        case status.PROCESSOR_NOT_CONNECTED:
            return 'Not connected';
        case status.PROCESSOR_ERROR:
            return 'Error';
        default:
            return 'Connected';
    }
}

const getSensorStatus = (sensor, hub, serverStatus) => {
    if (!serverStatus) {
        return status.SERVER_NOT_CONNECTED;
    } else if (!getHubConnectedStatus(hub)) {
        return status.HUB_NOT_CONNECTED;
    } else if (!getSensorConnectedStatus(sensor)) {
        return status.SENSOR_NOT_CONNECTED
    } else {
        return status.CONNECTED;
    }
}

const getSensorStatusColor = (sensorStatus) => {
    switch (sensorStatus) {
        case status.SERVER_NOT_CONNECTED:
            return statusColors.GRAY;
        case status.HUB_NOT_CONNECTED:
            return statusColors.GRAY;
        case status.SENSOR_NOT_CONNECTED:
            return statusColors.RED;
        case status.CONNECTED:
            return statusColors.GREEN;
        default:
            return statusColors.GRAY;
    }
}

const getSensorDisplayStatus = (sensorStatus) => {
    switch (sensorStatus) {
        case status.SERVER_NOT_CONNECTED:
            return '';
        case status.HUB_NOT_CONNECTED:
            return '';
        case status.SENSOR_NOT_CONNECTED:
            return 'Not connected';
        case status.CONNECTED:
            return 'Connected';
        default:
            return '';
    }
}
const connectedStatus = status;
export {
    getHubConnectedStatus,
    getProcessorConnectedStatus,
    getProcessorErrorStatus,
    getSensorConnectedStatus,
    getHubStatus,
    getHubStatusColor,
    getHubDisplayStatus,
    getProcessorStatus,
    getProcessorStatusColor,
    getProcessorDisplayStatus,
    getSensorStatus,
    getSensorStatusColor,
    getSensorDisplayStatus,
    connectedStatus   
}