import { useContext } from "react";
import UserContext from "../hooks/UserContext";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import CustomerErrorsView from "./CustomerErrorsView";
import CustomerDetailsView from "./CustomerDetailsView";
import styled from "styled-components";
import theme from "../UI/theme";
import { FaChartBar, FaExclamationCircle, FaHdd } from "react-icons/fa";
import InvisibleLink from "../components/UiComponents/InvisibleLink";
import CustomerUseView from "./CustomerUseView";
import ZoneDetailsView from "./ZoneDetailsView";
import AdminDeviceView from "../components/admin/devices/AdminDeviceView";
import DownloadsView from "../components/admin/downloads/DownloadsView";
import WaDownload from "../components/UiComponents/Icons/WaDownload";

const SupportContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    width: fit-content;
    margin: 20px;

    @media only screen and (max-width: ${theme.screenSizes.small - 1}px) {
        grid-template-columns: 1fr;
        width: fill-available;
        justify-items: center;
        margin-bottom: 100px;
    }
`

const SupportTile = styled.div`
    width: 30vw;
    height: 30vw;
    max-width: 40vh;
    max-height: 40vh;
    background-color: ${theme.colors.darkSpace90};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    text-align: center;
    padding: 10px;

    :hover {
        background-color: ${theme.colors.grayHover};
        cursor: pointer;
    }

    svg {
        font-size: 30px;
        stroke: white;
    }
    
    @media only screen and (max-width: ${theme.screenSizes.small - 1}px) {
        width: 70vw;
        height: 70vw;
    }
`

const SupportView = props => {
    let match = useRouteMatch();
    const user = useContext(UserContext);

    if (user.user.role === 'admin' || user.user.role === 'impersonator') {
        return <div>
            <Switch>
                <Route exact path={`${match.path}/customererrors`}>
                    <CustomerErrorsView setCustomer={props.setCustomer} />
                </Route>
                <Route path={`${match.path}/customerdetails/:customerId/:zoneId`}>
                    <ZoneDetailsView />
                </Route>
                <Route path={`${match.path}/customerdetails/:customerId`}>
                    <CustomerDetailsView />
                </Route>
                <Route exact path={`${match.path}/customeruse`}>
                    <CustomerUseView setCustomer={props.setCustomer} />
                </Route>
                <Route exact path={`${match.path}/devices`}>
                    <AdminDeviceView />
                </Route>
                <Route exact path={`${match.path}/downloads`}>
                    <DownloadsView />
                </Route>
                <Route path={`${match.path}`}>
                    <SupportContainer>
                        <InvisibleLink to={"supportcenter/customererrors"}>
                            <SupportTile>
                                <h2>Customer Errors</h2>
                                <FaExclamationCircle />
                            </SupportTile>
                        </InvisibleLink>

                        <InvisibleLink to={"supportcenter/customeruse"}>
                            <SupportTile>
                                <h2>Customer Use</h2>
                                <FaChartBar />
                            </SupportTile>
                        </InvisibleLink>

                        <InvisibleLink to={"supportcenter/devices"}>
                            <SupportTile>
                                <h2>Devices</h2>
                                <FaHdd />
                            </SupportTile>
                        </InvisibleLink>

                        <InvisibleLink to={"supportcenter/downloads"}>
                            <SupportTile>
                                <h2>Downloads</h2>
                                <WaDownload />
                            </SupportTile>
                        </InvisibleLink>

                    </SupportContainer>
                </Route>
            </Switch>
        </div>
    } else {
        return <></>
    }
}

export default SupportView;