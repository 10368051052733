import axios from 'axios';
import { getHostname } from './server/server'

const hostname = getHostname();
const authPath = '/v2/auth';

var loginPath;
if (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION') {
    loginPath = 'https://api.waved.app';
} else {
    loginPath = hostname;
}

const createLocalUser = async (credentials) => {
    const result = await axios({
        method: "POST",
        url: `${hostname}${authPath}/createLocalUser`,
        data: { credentials },
        //withCredentials: true
    });
    if (result.data.created) {
        var login = await localAuthenticate(credentials);
        return login;
    }
}

const localAuthenticate = (credentials, tempRoute) => {

    /*
    var form = document.createElement("form");

    var element1 = document.createElement("input"); 
    var element2 = document.createElement("input");  

    form.method = "POST";
    if (tempRoute) {
        form.action = `${loginPath}${authPath}/login-local`;  
    } else {
        form.action = `${hostname}${authPath}/login-local`;  
    }


    element1.value=credentials.email;
    element1.name='email';
    form.appendChild(element1);  

    
    element2.value = credentials.password;
    element2.name='password';
    form.appendChild(element2);

    document.body.appendChild(form);
    form.submit();
    */

    return new Promise((resolve, reject) => {
        var formBody = urlEncode(credentials);
        fetch(`${hostname}${authPath}/login-local`, {
            credentials: 'include',
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
            body: formBody
        }).then(res => {
            resolve(res.status == 200);
        }).catch(e => { // login failed
            resolve(false);
        });
    });


}

const googleAuthenticate = async () => {
    window.location.replace(`${hostname}${authPath}/google`);
}

const fetchUser = async () => {
    const res = await axios.get(`${hostname}${authPath}/current-user`);
    const user = res.data;
    return (user);
}

const logoutUser = async () => {
    await axios.get(`${hostname}${authPath}/logout`);
    window.location.reload();
}


function urlEncode(obj) {
    var formBody = [];
    for (var property in obj) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(obj[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return formBody;
}

const getVerificationCode = async (email) => {
    var result = await axios.post(`${hostname}${authPath}/getVerificationCode`, { email });
    if (result.data.error) { console.log(result.data.error); }
    return result.data.status;
}

const verifyTypedCode = async (email, code) => {
    const result = await axios.get(`${hostname}${authPath}/verifyTypedCode?email=${email}&code=${code}`);
    if (result.data.status) {
        return result.data
    } else {
        console.log('Error while comparing verrification codes');
        return false;
    }
}

const changePassByVerifiedEmail = async (email, password, code) => {
    var result = await axios.post(`${hostname}${authPath}/changePassByVerifiedEmail`, { email, password, code });
    if (result.data.error) {
        console.log(result.data.error);
    }
    return result.data.status;
}

const changeEmail = async (customerId, email, password, newEmail) => {
    var result = await axios.post(`${hostname}${authPath}/changeEmail?customerId=${customerId}`, { email, password, newEmail });
    if (result.data.error) {
        console.log(result.data.error);
    }
    return result.data;
}

export {
    createLocalUser,
    localAuthenticate,
    googleAuthenticate,
    fetchUser,
    logoutUser,
    getVerificationCode,
    verifyTypedCode,
    changePassByVerifiedEmail,
    changeEmail
}

function createHash(string) {
    var hash = '';
    for (var i = 0; i < string.length; i++) {
        var char = string.charCodeAt(i);
        //hash = ((hash << 5) - hash) + char; 
        hash += (char + 5);
        if (i < string.length - 1) { hash += ',' };
    }
    return hash;
}

function decodeHash(string) {
    var arr = string.split(',');
    var decoded = '';
    arr.forEach(e => {
        decoded += String.fromCharCode(parseInt(e) - 5);
    })
    return decoded;
}