import React from 'react';
import Dropdown from '../../../../UiComponents/Dropdown';

const SourceSelectorDropdown = props => {
    return <>
        <Dropdown 
            small
            items={getItems(props.sourceSelectors)}
            selectedItem={getSelectedItem(props.zone.sourceSelectorId, props.sourceSelectors)}
            onClick={(item) => props.updateZoneHandler(props.zone.zoneId, 'sourceSelectorId', item.value)}
        />
    </>
}

export default SourceSelectorDropdown;

const getItems = (sourceSelectors) => {
    let items = sourceSelectors.map(sourceSelector => ({label: sourceSelector.name, value: sourceSelector.sourceSelectorId}));
    items.push({label: '--None--', value: null})
    return items;
}

const getSelectedItem = (selectedId, sourceSelectors) => {
    let sourceSelector = sourceSelectors.find(sourceSelector => sourceSelector.sourceSelectorId === selectedId);
    if (sourceSelector) {
        return {label: sourceSelector.name, value: sourceSelector.sourceSelectorId};
    } else {
        return {label: '--None--', value: null};
    }
}