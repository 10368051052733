import styled from "styled-components";
import theme from "../../UI/theme";
import ToggleButton from "../UiComponents/ToggleButton";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const Label = styled.label`
    margin-right: 10px;
    color: ${theme.colors.textGray};
`

const OpeningHoursToggle = props => {
    return <Container>
        <Label>Only show opening hours</Label>
        <ToggleButton {...props} />
    </Container>
}

export default OpeningHoursToggle;