import { useRef, useState } from "react";
import styled from "styled-components";
import useServerStatus from "../../hooks/useServerStatus";
import { isMobile } from "../../scripts/common";
import Breadcrumbs from "./Breadcrumbs";
import { getHubTableProps } from "./Hub/hubTableProps";
import InstallationTabSelector from "./InstallationTabSelector";
import { getProcessorTableProps } from "./Processor/processorTableProps";
import { getSensorTableProps } from "./Sensor/sensorTableProps";
import { getSourceTableProps } from "./Source/sourceTableProps";
import { getSourceSelectorTableProps } from "./SourceSelector/sourceSelectorTableProps";
import EditableTableContainer from "./table/EditableTableContainer";
import { displayModes } from "./utils/installationTypes";
import { getZoneTableProps } from "./Zone/zoneTableProps";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: fill-available;
    padding: 10px;
`

const SettingsContainer = ({
    customerId,
    hubs,
    processors,
    sources,
    sourceSelectors,
    zones,
    sensors,
    selectedTab,
    setSelectedTab,
    selectedItemHierarchy,
    setSelectedItemHierarchy
}) => {
    const [displayMode, setDisplayMode] = useState(isMobile() ? displayModes.LIST : displayModes.TABLE);
    const editableTableRef = useRef();
    const serverStatus = useServerStatus();

    const getTableProps = () => {
        switch (selectedTab.key) {
            case 'HUB':
                return getHubTableProps({ hubs, processors, sensors, customerId, serverStatus, displayMode })

            case 'PROCESSOR':
                return getProcessorTableProps({ processors, hubs, customerId, serverStatus, displayMode })

            case 'SOURCE':
                return getSourceTableProps({ sources, processors, customerId })

            case 'SOURCE_SELECTOR':
                return getSourceSelectorTableProps({ sourceSelectors, processors, sources, customerId })

            case 'ZONE':
                return getZoneTableProps({ customerId, zones, processors, sourceSelectors, hubs })

            case 'SENSOR':
                return getSensorTableProps({ sensors, zones, hubs, customerId, serverStatus, displayMode })
            default:
                return <></>
        }
    }

    return <Container>
        <Breadcrumbs
            selectedItemHierarchy={selectedItemHierarchy}
            setSelectedItemHierarchy={setSelectedItemHierarchy} />
        <InstallationTabSelector
            selectedTab={selectedTab}
            onClick={(newTab) => setSelectedTab(newTab)}
            editableTableRef={editableTableRef}
        />

        <EditableTableContainer
            {...getTableProps()}
            displayMode={displayMode}
            setDisplayMode={setDisplayMode}
            editableTableRef={editableTableRef}
        />

        
    </Container>
}

export default SettingsContainer;