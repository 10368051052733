import RoundButton from './RoundButton';
import {MdMoreHoriz} from 'react-icons/md';

const MoreButton = props => {

    return <RoundButton {...props}>
        <MdMoreHoriz />
    </RoundButton>
}

export default MoreButton;