import React, { useState } from 'react';
import Modal, { ModalContent } from '../../UiComponents/Modal';
import NewObjectForm from './NewObjectForm';
import { ListActionButton } from './styles/ActionStyles';
import { FaPlus } from 'react-icons/fa';

const AddObjectButton = ({ columns, onSubmit, objects, disabled, title }) => {
    const [showModal, setShowModal] = useState(false);

    const submitHandler = async (object, submitProps) => {
        await onSubmit(object, submitProps);
        setShowModal(false);
    }

    const cancelHandler = async () => {
        setShowModal(false);
    }

    return <>
        <ListActionButton disabled={disabled} secondary small onClick={() => setShowModal(true)}>
            <FaPlus />
            <label>New</label>
        </ListActionButton>
        {showModal ? <>
            <Modal show={showModal} close={() => setShowModal(false)}>
                <ModalContent>
                    <NewObjectForm
                        columns={columns}
                        objects={objects}
                        onSubmit={submitHandler}
                        onCancel={cancelHandler}
                        title={title}
                    />
                </ModalContent>
            </Modal>
        </> : <></>}
    </>
}

export default AddObjectButton;

