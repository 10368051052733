import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from '../../../../UI/theme';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { replaceZeroValues } from '../../../../scripts/common';

export const ZoneContainer = styled.div`
    height: inherit;
    
    ${props => props.flex && css`
        display: flex;
        align-items: center;
    `}
    
    canvas {
        width: 100%;
        max-width: 600px;
        max-height: 150px;
    }
`
const HIGHLIGHT_BAR_COLOR = theme.colors.crystalBlue;
const HIGHLIGHT_DATA_LABEL_COLOR = theme.colors.textGray;

const chartData = {
    labels: [],
    datasets: [
        {
            label: 'Decibel average (hourly)',
            type: 'bar',
            borderColor: `${theme.colors.crystalBlue}30`,
            fill: false,
            data: [],
            backgroundColor: `${theme.colors.crystalBlue}30`,
        },
        {
            label: 'Decibel trend',
            type: 'line',
            borderColor: `${theme.colors.crystalBlue}70`,
            borderDash: [5, 3],
            pointRadius: 0,
            tension: 0.5,
            fill: false,
            data: [],
        }]
};

const options = {
    maintainAspectRatio: false,
    scales: {
        x: {
            display: true,
            ticks: {
                maxTicksLimit: 1,
                color: theme.colors.textGray,
                maxRotation: 0
            },
            grid: {
                display: true,
                drawBorder: true,
                borderColor: `${theme.colors.textGray}20`,
                drawTicks: true,
                color: `${theme.colors.textGray}20`,
                drawOnChartArea: false,
            }
        },
        y: {
            display: true,
            type: 'linear',
            min: 30,
            max: 80,
            ticks: {
                maxTicksLimit: 2,
                stepSize: 25,
                color: theme.colors.textGray
            },
            grid: {
                display: true,
                drawBorder: true,
                borderColor: `${theme.colors.textGray}20`,
                drawTicks: true,
                color: `${theme.colors.textGray}20`,
                drawOnChartArea: false,
            }
        }
    },
    plugins: {
        legend: {
            display: false
        },
        datalabels: {
            display: false,
            color: HIGHLIGHT_DATA_LABEL_COLOR
        }
    },
    pointHitRadius: 7,
}

const chartConfig = {
    data: chartData,
    options,
    plugins: [ChartDataLabels]
}

const ActivityGraph = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);

    if (props.data && props.data.trend) {
        chartConfig.data.datasets[0].data = props.data.data;
        chartConfig.data.datasets[1].data = replaceZeroValues(props.data.trend);
        chartConfig.data.labels = props.data.labels;
        var ticksLim = getNTicks(props.data.trend.length);
        chartConfig.options.scales.x.ticks.maxTicksLimit = ticksLim;


        if (props.highlightIndex != undefined) {
            if (props.data.data[props.highlightIndex] >= chartConfig.options.scales.y.min) {
                chartConfig.data.datasets[0].datalabels = getHighlightDatalabels(props.highlightIndex);
                chartConfig.data.datasets[0].backgroundColor = getHighlightBackgroundColors(props.data?.data?.length, props.highlightIndex);
                adjustAxes(chartConfig, props.data.data[props.highlightIndex]);
            }
        }
        //chartConfig.data.datasets[0].borderColor = getHighlightBorderColors(props.data.data.length, props.highlightIndex);          
    } else {
        chartConfig.data.datasets[0].data = [];
        chartConfig.data.datasets[1].data = [];
    }



    var width;
    /* if (window.innerWidth <= theme.screenSizes.small) {
        width = Math.round(window.innerWidth * 0.6);
    } */

    if (chartInstance) {
        chartInstance.update();
    }

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            if (chartInstance) {
                chartInstance.destroy();
            }
            const newChartInstance = new Chart(chartContainer.current, chartConfig);
            setChartInstance(newChartInstance);
        }
    }, [chartContainer]);

    return (
        <canvas ref={chartContainer} width={width} />
    );
}

export default ActivityGraph;


function getNTicks(dataLen) {
    var n_ticks = 4;
    var ticksOptions = [3, 4, 5];
    ticksOptions.forEach(option => {
        if ((dataLen - 1) % (option - 1) === 0) {
            n_ticks = option;
        }
    });

    return n_ticks;
}

function getHighlightDatalabels(highlightIndex) {
    return {
        display: function (context) {
            var index = context.dataIndex;
            return index === highlightIndex;
        },
        formatter: function (context) {
            return Math.round(context);
        },
        align: 'end',
        anchor: 'end',
        font: {
            size: '15px'
        },
        padding: 10

    }
}

function getHighlightBackgroundColors(totalLength, highlightIndex) {
    const highlightColor = HIGHLIGHT_BAR_COLOR;
    const unHiglightedColor = `${theme.colors.crystalBlue}30`;
    var backgroundColors = Array(totalLength).fill(unHiglightedColor);
    backgroundColors[highlightIndex] = highlightColor;
    return backgroundColors
}

function getHighlightBorderColors(totalLength, highlightIndex) {
    const highlightColor = HIGHLIGHT_BAR_COLOR;
    const unHiglightedColor = theme.colors.crystalBlue;
    var backgroundColors = Array(totalLength).fill(unHiglightedColor);
    backgroundColors[highlightIndex] = highlightColor;
    return backgroundColors
}

function adjustAxes(config, value) {
    if (config.options.scales.y.max - 10 < value) {
        config.options.scales.y.max = value + 10;
    }
}