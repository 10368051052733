import React from 'react';
import styled from 'styled-components';
import theme from '../../../../UI/theme';

const StyledLink = styled.a`
    text-decoration: none;
    color: ${theme.colors.textGray};
    font-size: 14px;
    &:hover {
        text-decoration: underline;
    }
`;

const LocalAppLink = props => {
    const ipAddress = props.hub.ipAddress;
    const url = `http://${ipAddress}:4000`;
    return <div>
        <StyledLink href={url}>Go to local app </StyledLink>
        
    </div>
}

export default LocalAppLink;