import React from 'react';
import styled from 'styled-components';
import theme from '../../../UI/theme';

const defaultHeight = 400;

const StyledCanvasBox = styled.div`
    height: ${props => props.height ? props.height : defaultHeight}px;
    width: 200px;
    position: absolute;
    top: ${props => props.height ? props.height+40 : defaultHeight+40}px;
    left: 10px;
    margin-top: -${props => props.height ? props.height+40 : defaultHeight+40}px;
    z-index: 7;
    background-color: ${props=> props.backgroundColor || theme.colors.darkSpace90 ||'white'};
    padding: 20px;
    overflow-y: auto;
`

const CanvasBox = props => {
    return <StyledCanvasBox onWheel={(event) => {event.stopPropagation()}} backgroundColor={props.backgroundColor}>
            {props.children}
        </StyledCanvasBox>
}

export default CanvasBox;