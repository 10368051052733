import styled from "styled-components";
import theme from "../../../../UI/theme";

const DatePickerInput = styled.input`
    position: relative;
    color: transparent;
    background-color: transparent;
    border: none;
    font-size: 0;
    height: 100%;
    max-width: 100%;
    overflow: visible;
    width: fill-available;
    outline: none;

    &::-webkit-calendar-picker-indicator {
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 0;

        &:hover {
            cursor: pointer;
        }
    }

    &:after {
        position: absolute;
        visibility: visible;
        font-size: 13.3333px;
        font: 'Karelia';
        display: flex;
        justify-content: center;
        align-items: center;
        content: ${props => props.content ? props.content : ''};
        height: 25px;
        width: fill-available;
        padding: 1px 10px 4px;
        box-sizing: border-box;
        background-color: transparent;
        border: 1px solid ${theme.colors.grayBorder};
        border-radius: 104px;
        color: ${theme.colors.textGray};
        white-space: nowrap;
    }
`

export default DatePickerInput;