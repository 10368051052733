import React, { useEffect, useState, useContext } from 'react';
import styled from "styled-components";
import MusicPlayerSetup from '../musicPlayer/setup/MusicPlayerSetup'
import { useParams } from "react-router-dom";
import MusicDisplayParent from './MusicDisplayParent';
import UserContext from '../../hooks/UserContext';
import { getMusicPlayers } from '../../scripts/musicPlayer';

const StyledMusicContentView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const MusicContentView = (props) => {

    let {id} = useParams();
    const user = useContext(UserContext);
    const [musicPlayers, setMusicPlayers] = useState();
    const [playersAdded, setPlayersAdded] = useState(false);

    useEffect(() => {
        getData();
    },[playersAdded])

    const getData = async () => {
        try {
            const mp = await getMusicPlayers(id);
            if (mp.length) setMusicPlayers(mp);
        } catch (e) {

        }  
    } 
    return (
        <StyledMusicContentView>
            {musicPlayers? <>
                {musicPlayers.map(musicPlayer => {
                    return (
                        <MusicDisplayParent
                            customerId={id}
                            musicPlayer={musicPlayer}
                            key={musicPlayer.id}
                            settingsAvailable
                        />
                    )
                })}
            </>:<></>}
            {user.user.role === "admin"?
               <MusicPlayerSetup customerId={id} setPlayersAdded={setPlayersAdded}/>
            :<></>}
        </StyledMusicContentView>
    );
}

export default MusicContentView;