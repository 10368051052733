import React, { useEffect, useState } from 'react'
import { postEvent } from '../../../../scripts/event';
import AnimateHeight from 'react-animate-height';
import { TextMuted } from '../../../UiComponents/text/StyledText';
import { DetailsContainer, DeviceContainer, DeviceIconContainer, DetailsParagraph, SectionHeader, ButtonRowContainer } from './DeviceStyles';
import { HubIcon } from '../../../UiComponents/Icons';
import Divider from '../../../UiComponents/Divider';
import Button from '../../../UiComponents/Button';
import theme from '../../../../UI/theme';
import NetworkAnalysisResults from './NetworkAnalysis/NetworkAnalysisResults';
import NetworkInfo from './NetworkConfig/NetworkInfo';
import ProcessorList from './Processor/ProcessorList';
import useServerStatus from '../../../../hooks/useServerStatus';
import { getHubDisplayStatus, getHubStatus, getHubStatusColor } from '../../../installation/utils/connectionStatus';

const grey = '#A0A0A0';
const green = theme.colors.greenEnergy;
const yellow = theme.colors.mellowYellow;
const red = theme.colors.raspberryRed;

const HubCard = (props) => {

    const serverStatus = useServerStatus();
    const [detailed, setDetailed] = useState(false);
    const [hubStatus, setHubStatus] = useState();
    const [showNetworkResults, setShowNetworkResults] = useState(false);

    useEffect(() => {
        checkDevice();
    }, [props.hub, serverStatus]);


    const sendCommand = (command) => {
        postEvent(props.hub.hubId, props.customerId, command);
    }

    const changeDetailed = () => {
        setDetailed(!detailed);
    }

    const checkDevice = () => {
        const status = getHubStatus(props.hub, serverStatus, props.processors, props.sensors);
        setHubStatus(status);
    }

    const runNetworkAnalysis = () => {
        sendCommand("NETWORK_ANALYSIS");
        setShowNetworkResults(true);
    }

    return (
        <>
            <DeviceContainer>
                <div onClick={changeDetailed} className="sensor-flex">
                    <div className="sensor-left">
                        <DeviceIconContainer>
                            <HubIcon height={20} />
                        </DeviceIconContainer>
                        <div>
                            <div className="sensor-text" >
                                <div><span className="profile-field">Hub: {props.hub?.ipAddress?.split('.')?.[3]} </span></div>
                                <div><TextMuted>{getHubDisplayStatus(hubStatus)}</TextMuted></div>
                            </div>
                        </div>
                    </div>

                    <div className="indicator-dot" >
                        <div style={{ backgroundColor: getHubStatusColor(hubStatus) }} ></div>
                    </div>

                </div>

                <AnimateHeight duration={500} height={detailed ? 'auto' : 0}>

                    <DetailsContainer>
                        <Divider />

                        <ProcessorList
                            hub={props.hub}
                            processors={props.processors}
                            sourceSelectors={props.sourceSelectors}
                            customerId={props.customerId}
                            user={props.user}
                            refreshDevices={props.refreshDevices}
                            readOnly={props.readOnly}
                        />

                        {/* 
                        <ProcessorScanner customerId={props.customerId} hubId={props.hub.hubId} availableProcessors={props.hub.availableProcessors} /> 
                        */}

                        <Divider />

                        <DetailsParagraph>
                            <NetworkInfo
                                hub={props.hub}
                                customerId={props.customerId}
                                refreshDevices={props.refreshDevices}
                                readOnly={props.readOnly}
                            />
                        </DetailsParagraph>

                        <DetailsParagraph>
                            <span>Device details:</span>
                            <TextMuted> ID: {props.hub.hubId} </TextMuted>
                            {/* <MacInput mac={props.hub.processorMac} onSave={(newMac) => setProcessorMacHandler(newMac)} role={props.user.role} /> */}
                        </DetailsParagraph>

                        {showNetworkResults && props.user.role === 'admin' ?
                            <NetworkAnalysisResults sensors={props.sensors} />
                            : <></>}


                        <ButtonRowContainer>
                            {props.sensors?.some(s => s.networkType !== 'ZIGBEE') ? <>
                                <Button secondary onClick={() => sendCommand("RECONNECT_SENSORS")}>Reconnect sensors</Button>
                            </> : <></>}

                            {props.user.role === 'admin' ? <>
                                <Button secondary onClick={() => runNetworkAnalysis()}>Run network analysis</Button>

                                {showNetworkResults ?
                                    <Button secondary onClick={() => setShowNetworkResults(false)}>Hide results</Button>
                                    : <></>}
                            </> : <></>}

                        </ButtonRowContainer>
                    </DetailsContainer>
                </AnimateHeight>
            </DeviceContainer>
        </>
    )
}

export default HubCard;