import axios from 'axios';
import { getHostname } from './server/server';

const hostname = getHostname();
const path = '/v2/musicPlayer';

async function getMusicPlayers(customerId) {
    const res = await axios.get(`${hostname}${path}/musicPlayers?customerId=${customerId}`, { withCredentials: true });
    return res.data;
}

async function updateMusicPlayer(customerId, musicPlayer) {
    const res = await axios.put(`${hostname}${path}?customerId=${customerId}`, musicPlayer, { withCredentials: true });
    return res.data;
}

async function addMusicPlayer(customerId, musicPlayer) {
    const res = await axios.post(`${hostname}${path}?customerId=${customerId}`, musicPlayer, { withCredentials: true });
    return res.data;
}

async function deleteMusicPlayer(customerId, musicPlayerId) {
    const res = await axios.delete(`${hostname}${path}?customerId=${customerId}&musicPlayerId=${musicPlayerId}`, { withCredentials: true });
    return res.data;
}

export {
    getMusicPlayers,
    updateMusicPlayer,
    addMusicPlayer,
    deleteMusicPlayer
}