import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import theme from "../../../../UI/theme";
import ArrowButton from "../../../UiComponents/buttons/ArrowButton";
import Card from "../../../UiComponents/Card"
import InnerCard from "../../../UiComponents/containers/InnerCard";
import InvisibleLink from "../../../UiComponents/InvisibleLink";

const NavigationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    :hover {
        cursor: pointer;
    }

    h4 {
        margin: 16px 0;
    }
`

const StyledCard = styled(InnerCard)`
    padding: 6px 16px;
`

const AutoCalibrationSettingsOverview = props => {
    const match = useRouteMatch();

    return <Card>
        <h3>Automatic calibration</h3>

        <StyledCard>
            <InvisibleLink to={`${match.url}/autocalibration`}>
                <NavigationContainer>
                    <h4>Calibrate</h4>
                    <ArrowButton color={theme.colors.darkSpace60} />
                </NavigationContainer>
            </InvisibleLink>
        </StyledCard>
    </Card>
}

export default AutoCalibrationSettingsOverview;