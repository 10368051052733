import styled from 'styled-components';

const LocationList = styled.ul`
    list-style: none;
    padding: 0;

    li {
        margin-top: 40px;
    }

    li:first-child {
        margin-top: 0;
    }
`

export default LocationList;