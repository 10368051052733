import React, {useState} from 'react';
import styled from 'styled-components';
import ToastMessage from '../StyledComponents/ToastMessage';
import {FaExclamationCircle} from 'react-icons/fa';
import Modal from '../../../UiComponents/Modal';
import Button from '../../../UiComponents/Button';
import theme from '../../../../UI/theme';

const Container = styled.div`
    display: flex;
    align-items: center;
`

const IconContainer = styled.div`
    margin-right: 12px;
    color: ${theme.colors.raspberryRed};
`

const ModalContent = styled.div`
    padding: 10px;
    text-align: center;
    button {
        margin: 16px;
    }
`

const NoConnectedSensorsMessage = props => {
    const [showModal, setShowModal] = useState();
    return <>
        <ToastMessage onClick={() => setShowModal(true)}>
        <Container>
            <IconContainer>
            <FaExclamationCircle />
            </IconContainer>
            <div>
            No connected sensors
            </div>
        </Container>
        </ToastMessage>
        {showModal ? <>
            <Modal show={showModal} close={() => setShowModal(false)}>
                <ModalContent>
                    No sensors are connected. It might be a problem with your hub, try restarting it.
                    <div>
                    <Button primary onClick={() => setShowModal(false)}>Ok</Button>
                    </div>
                </ModalContent>
            </Modal>
        </>: <></>}
        </>
}

export default NoConnectedSensorsMessage;