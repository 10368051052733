import { displayModes } from "../utils/installationTypes"
import styled, { css } from 'styled-components';
import theme from "../../../UI/theme";
import { FaList, FaTable } from "react-icons/fa";
import { ListActionButton } from "./styles/ActionStyles";

const StyledButton = styled(ListActionButton)`    
    
    &:first-child {
        border-radius: 4px 0px 0px 4px;
    }
    &:last-child {
        border-radius: 0px 4px 4px 0px;
    }
    
    ${props => props.active && css`
        background-color: ${theme.colors.darkSpace80};
    `}
`

const Container = styled.div`
    display: flex;
    ${props => props.fitContainer && css`
        width: 100%;
        >button {
            width: 50%;
        }
    `}
`

const DisplayModeSelector = ({ displayMode, setDisplayMode, fitContainer }) => {

    return <Container fitContainer>
        <StyledButton small={!fitContainer} tertiary active={displayMode === displayModes.TABLE} onClick={() => setDisplayMode(displayModes.TABLE)}>
            <FaTable />
            {/* <label>Table</label> */}
        </StyledButton>
        <StyledButton small={!fitContainer} tertiary active={displayMode === displayModes.LIST} onClick={() => setDisplayMode(displayModes.LIST)}>
            <FaList />
            {/* <label>List</label> */}
        </StyledButton>
    </Container>
}

export default DisplayModeSelector;