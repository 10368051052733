import React from 'react';
//import pleaseWaitGif from '../images/pleaseWait.gif';

const SetupWaitCard = (props) => {
    return (
        <div className="view-container">
            <div className="card wait-card">
                {props.children}
                {/* <img className="gif-container" src={pleaseWaitGif}></img> */}
            </div>
        </div>
    );
}

export default SetupWaitCard;

