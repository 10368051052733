import React, { useState, useEffect, useContext } from 'react';
import { updateZone } from '../../../../scripts/zone';
import ZoneSettingsItem from './ZoneSettingsItem';
import styled from 'styled-components';
import Card from '../../../UiComponents/Card';
import HeaderContainer from '../../../UiComponents/containers/HeaderContainer';
import Button from '../../../UiComponents/Button';
import { getZoneModes } from '../../../../scripts/zoneModes';
import { postEvent } from '../../../../scripts/event';
import UserContext from '../../../../hooks/UserContext';

const List = styled.ul`
    list-style: none;
    padding: 0;
    margin-bottom: 0;
`

const ZoneSettings = props => {
    const [zoneModes, setZoneModes] = useState();
    const [editOrder, setEditOrder] = useState(false);
    const user = useContext(UserContext);

    useEffect(() => {
        getZoneModesHandler();
    }, [props.customerId]);

    const getZoneModesHandler = async () => {
        var zoneModesRes = await getZoneModes(props.customerId);
        if (zoneModesRes) {
            setZoneModes(zoneModesRes);
        }
    }

    const updateZoneAsync = async (zone, preventEvent = false) => {
        await updateZone(zone.customerId, zone.zoneId, zone);
        if (!preventEvent) {
            await postEvent(zone.hubId, zone.customerId, 'GET_ALL_ZONE_SETTINGS');
        }
    }

    const updateZoneHandler = (zoneId, updatedAttributes, preventEvent = false) => {
        if (props.readOnly) {
            return;
        }

        let oldZonesState = JSON.parse(JSON.stringify(props.zones));
        var newZonesState = JSON.parse(JSON.stringify(props.zones));
        var zoneToUpdate = newZonesState.find(zone => zone.zoneId === zoneId);
        if (zoneToUpdate) {
            Object.keys(updatedAttributes).forEach(key => {
                zoneToUpdate[key] = updatedAttributes[key];
            })
            props.setZones(newZonesState);

            try {
                let updatedZoneProps = (({ hubId, customerId }) => ({ ...updatedAttributes, zoneId, hubId, customerId }))(zoneToUpdate);
                updateZoneAsync(updatedZoneProps, preventEvent);
            } catch (error) {
                props.setZones(oldZonesState);
            }
        }
    }

    const updateMultipleZonesHandler = (zoneUpdates, hubId, preventEvent = false) => {
        if (props.readOnly) {
            return;
        }

        let oldZonesState = JSON.parse(JSON.stringify(props.zones));
        var newZonesState = JSON.parse(JSON.stringify(props.zones));

        var promises = [];

        zoneUpdates.forEach(zoneUpdate => {
            var zoneToUpdate = newZonesState.find(zone => zone.zoneId === zoneUpdate.zoneId);
            if (zoneToUpdate) {
                Object.keys(zoneUpdate).forEach(key => {
                    zoneToUpdate[key] = zoneUpdate[key];
                })

                let updatedZoneProps = (({ hubId, customerId }) => ({ ...zoneUpdate, zoneId: zoneUpdate.zoneId, hubId, customerId }))(zoneToUpdate);
                promises.push(new Promise(() => updateZone(props.customerId, zoneUpdate.zoneId, updatedZoneProps)));
            }
        })

        orderZones(newZonesState);
        props.setZones(newZonesState);
        Promise.all(promises).catch(() => { props.setZones(oldZonesState); orderZones(oldZonesState); });

        if (!preventEvent) {
            postEvent(hubId, props.customerId, 'GET_ALL_ZONE_SETTINGS');
        }
    }

    const changeOrder = (zone, increment) => {
        let zoneToChangePlaceWith = props.zones.find(z => z.orderIndex === zone.orderIndex + increment);
        if (zoneToChangePlaceWith) {
            updateMultipleZonesHandler([
                { zoneId: zone.zoneId, orderIndex: zone.orderIndex + increment },
                { zoneId: zoneToChangePlaceWith.zoneId, orderIndex: zoneToChangePlaceWith.orderIndex - increment }],
                zone.hubId
            );
        }
    }


    return <Card>
        <HeaderContainer>
            <h3>Zone settings </h3>
            <div>
                {editOrder ? <>
                    <Button small primary onClick={() => setEditOrder(false)}>Done</Button>
                </> : <>
                    <Button disabled={props.readOnly} small tertiary onClick={() => setEditOrder(true)}>
                        Edit order
                    </Button>
                </>}
            </div>
        </HeaderContainer>

        <List>
            {props.zones.map(zone => {
                return <ZoneSettingsItem
                    user={user}
                    customerId={props.customerId}
                    key={zone.zoneId}
                    zone={zone}
                    updateZoneHandler={updateZoneHandler}
                    zoneModes={zoneModes?.filter(mode => mode.zoneId === zone.zoneId)}
                    getZoneModes={getZoneModesHandler}
                    setZoneModes={setZoneModes}
                    editOrder={editOrder}
                    changeOrder={changeOrder}
                />
            })}
        </List>
    </Card>
}



export default ZoneSettings;

function orderZones(zones) {
    zones.sort((zone1, zone2) => zone1.orderIndex - zone2.orderIndex);
}