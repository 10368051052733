import styled from "styled-components";
import theme from "../../UI/theme";

export const SetupContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

export const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const Header = styled.label`
    font-size: 2rem;
`

export const InputFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${theme.fontSize.small};
    color: ${theme.colors.textGray};
`

export const RowContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`

export const InfoTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: ${theme.colors.textGray};
    font-size: ${theme.fontSize.small};
`