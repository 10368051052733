import React from 'react';
import styled, { css } from 'styled-components';
import theme from '../../../UI/theme';
import CountingNumber from '../../UiComponents/CountingNumber';

const Container = styled.div`
    /* background-color: ${theme.colors.textGray}20; */
    text-align: center;
    /* border-radius: 5px; */
    min-height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    /* margin: 10px 0; */
`

const Title = styled.h4`
    margin: 0;
    color: ${theme.colors.textGray};

    ${props => props.small && css`
        font-size: 0.9rem;
    `}
`

const Value = styled.h1`
    margin: 0;
    color: ${theme.colors.greenEnergy};

    ${props => props.small && css`
        font-size: 1.4rem;
    `}

    ${props => props.disabled && css`
        color: ${theme.colors.textGray};
    `}
`

const MetricContainer = styled.div`
    background-color: ${theme.colors.darkSpace80};
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    
    &:first-child {
        margin-bottom: 10px;
    }
`

const Label = styled.div`
    font-size: 0.7rem;

    ${props => props.disabled && css`
        color: ${theme.colors.textGray};
    `}
`

const InvalidValue = styled.div`
    color: ${theme.colors.textGray};
`

const ZoneKeyMetric = props => {
    return <Container {...props}>

        {props.metrics.map((metric, i) => {
            return <MetricContainer key={i}>
                <div>
                    <Title {...props}>{metric.title}</Title>
                    {!metric.invalid ? <>
                        <Value {...props} disabled={metric.disabled}>

                            {props.count && !isNaN(Number(metric.value)) ? <>
                                <CountingNumber value={Number(metric.value)} />
                            </> : <>
                                {metric.value}
                            </>}
                            <span>{metric.unit}</span>

                        </Value>
                    </> : <>
                        <InvalidValue>No data</InvalidValue>
                    </>}
                    {metric.label ? <>
                        <Label disabled={metric.disabled}>{metric.label}</Label>
                    </> : <></>}
                </div>
            </MetricContainer>
        })}


        {/* <Label {...props}>{props.label}</Label> */}

    </Container>
}

export default ZoneKeyMetric;