import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router";
import styled from "styled-components";
import UserInteractionsGraph from "../components/admin/customerDetails/graph/UserInteractionsGraph";
import UserInterationsTable from "../components/admin/customerDetails/UserInteractionsTable";
import TopNavigation from "../components/navigation/TopNavigation";
import DatePickerInput from "../components/UiComponents/datepicker/StyledComponents/DatePickerInput";
import ViewHeader from "../components/UiComponents/ViewHeader";
import UserContext from "../hooks/UserContext"
import { toLocalISOString } from "../scripts/common";
import Card from "../components/UiComponents/Card";
import theme from "../UI/theme";
import { getZones } from "../scripts/zone";
import { getCustomer, getOpeningHours } from "../scripts/datasource";
import UserInteractionsMetric from "../components/admin/customerDetails/UserInteractionsMetric";
import NoOfUserInteractionsGraph from "../components/admin/customerDetails/graph/NoOfUserInteractionsGraph";
import CustomerZoneList from "../components/admin/customerDetails/CustomerZoneList";
import { DateTime, Interval } from "luxon";
import { getOpeningHoursWithinInterval } from "../components/statistics/charts/chartUtils";
import { getUserInteractions } from "../scripts/userInteraction";
import { userInteractionTypes } from "../components/admin/customerDetails/userInteractionTypes";

const Container = styled.div`
    margin-bottom: 30vh;
`

const DatePickerContainer = styled.div`
    width: fill-available;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
    justify-items: center;
    background-color: ${theme.colors.darkSpace90};
    padding: 10px;
    margin-bottom: 20px;
`

const UserInteractionsContainer = styled.div`
    display: grid;
    width: 100%;
    height: 50vh;

    @media only screen and (min-width: ${theme.screenSizes.large}px) {
        grid-template-columns: 1fr auto;
    }

    @media only screen and (max-width: ${theme.screenSizes.large - 1}px) {
        height: 80vh;
        grid-template-rows: 1fr 1fr;
    }

    grid-gap: 10px;
    margin-top: 20px;
`

const ChartContainer = styled.div`
    width: 100%;
    height: 100%;

    @media only screen and (max-width: ${theme.screenSizes.large - 1}px) {
        margin: 10px 0;
    }
`

const TopContainer = styled.div`
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        flex-direction: column;
        height: fit-content;
    }
`

const CustomerDetailsView = props => {
    const user = useContext(UserContext);
    const { customerId } = useParams();
    const [fromDate, setFromDate] = useState(new Date((new Date()).setDate((new Date()).getDate() - 7)));
    const [toDate, setToDate] = useState(new Date());
    const [userInteractions, setUserInteractions] = useState();
    const [filteredUserInteractions, setFilteredUserInteractions] = useState();
    const [filter, setFilter] = useState(Object.keys(userInteractionTypes)?.map(key => userInteractionTypes[key].key));
    const [openingHours, setOpeningHours] = useState();
    const [zones, setZones] = useState();
    const [customer, setCustomer] = useState();


    useEffect(() => {
        getData(fromDate, toDate);
        getZonesHandler();
    }, []);

    const getData = async (from, to) => {
        if (customerId) {
            var interactions = await getUserInteractions(customerId, from, to);
            setUserInteractions(interactions);
            setFilteredUserInteractions(interactions?.filter(ui => filter.some(type => type === ui.setting)));
            var profile = await getCustomer(customerId);
            setCustomer(profile);

            var interval = Interval.fromDateTimes(DateTime.fromJSDate(fromDate), DateTime.fromJSDate(toDate));
            var customerOpeningHours = await getOpeningHours(customerId);
            var openingHoursWithinInterval = getOpeningHoursWithinInterval(customerOpeningHours, interval);
            setOpeningHours(openingHoursWithinInterval);
        }
    }

    const getZonesHandler = async () => {
        if (customerId) {
            var allZones = await getZones(customerId);
            setZones(allZones);
        }
    }

    const changeFromDate = (newDate) => {
        setFromDate(new Date(newDate));
        getData(new Date(newDate), toDate);
    }

    const changeToDate = (newDate) => {
        setToDate(new Date(newDate));
        getData(fromDate, new Date(newDate));
    }

    const updateFilter = (key) => {
        var newFilter;
        setFilter(oldFilter => {
            if (oldFilter?.some(type => type === key)) {
                newFilter = oldFilter.filter(type => type !== key);
            } else {
                newFilter = [...oldFilter, key];
            }
            setFilteredUserInteractions(userInteractions?.filter(ui => newFilter.some(type => type === ui.setting)));
            return newFilter;
        })
    }

    if (user.user.role === 'admin' || user.user.role === 'impersonator') {
        return <>
            <ViewHeader fitToSmallScreen={true}>
                <TopNavigation to={'/supportcenter/customeruse'} />
                <h3>{customer?.companyName}</h3>
            </ViewHeader>

            <Container>
                <DatePickerContainer>
                    <label>From</label>
                    <label>To</label>

                    <DatePickerInput
                        type={'date'}
                        content={`'${fromDate.toDateString()}'`}
                        max={(new Date()).toISOString().split('T')[0]}
                        value={toLocalISOString(fromDate).split('T')[0]}
                        onChange={(e) => changeFromDate(e.target.value)} />

                    <DatePickerInput
                        type={'date'}
                        content={`'${toDate.toDateString()}'`}
                        max={(new Date()).toISOString().split('T')[0]}
                        value={toLocalISOString(toDate).split('T')[0]}
                        onChange={(e) => changeToDate(e.target.value)} />
                </DatePickerContainer>

                <Card>
                    <h2>User interactions</h2>

                    {customerId ? <>

                        <TopContainer>
                            <UserInteractionsMetric userInteractions={userInteractions} filter={filter} updateFilter={updateFilter} />

                            <ChartContainer>
                                <NoOfUserInteractionsGraph
                                    userInteractions={filteredUserInteractions}
                                    from={fromDate}
                                    to={toDate} />
                            </ChartContainer>

                        </TopContainer>

                        <UserInteractionsContainer>
                            <div>
                                <UserInteractionsGraph
                                    data={{ userInteractions: filteredUserInteractions, openingHours: openingHours }}
                                    zones={zones}
                                    min={fromDate}
                                    max={toDate}
                                    ymax={zones?.length ? zones?.length : 1} />
                            </div>

                            <UserInterationsTable
                                userInteractions={filteredUserInteractions}
                                zones={zones} />
                        </UserInteractionsContainer>

                    </> : <></>}
                </Card>

                <CustomerZoneList zones={zones} />

            </Container>
        </>
    } else {
        return <></>;
    }
}

export default CustomerDetailsView;