import React from 'react';
import RangeSlider from '../../../UiComponents/RangeSlider';
import styled from 'styled-components';

const Container = styled.div`
    padding: 20px 0;
    margin-left: 12px;
`

const VolumeRangeSlider = props => {

    return <Container>
        <RangeSlider
            {...props}
            values={props.values}
            onChange={props.onChange}
        />
    </Container>
}

export default VolumeRangeSlider;