import React, { useContext } from 'react';
import SourceSelectorItem from './SourceSelectorItem';
import styled from 'styled-components';
import { updateSourceSelector } from '../../../scripts/sourceSelector';
import UserContext from '../../../hooks/UserContext';

const List = styled.ul`
    list-style: none;
    padding: 0;

    li {
        margin-top: 20px;
    }

    li:first-child {
        margin-top: 0;
    }
`

const SourceSelectors = props => {
    const user = useContext(UserContext);

    const setSourceSelectorAttributes = async (sourceSelectorId, updatedAttributes, save) => {
        if (user.isImpersonator && user.isImpersonating) {
            return;
        }
        var updatedSourceSelectors = JSON.parse(JSON.stringify(props.sourceSelectors));
        var updatedSourceSelector = updatedSourceSelectors.find(sourceSelector => sourceSelector.sourceSelectorId === sourceSelectorId);
        if (updatedSourceSelector) {
            Object.keys(updatedAttributes).forEach(key => {
                updatedSourceSelector[key] = updatedAttributes[key];
            });
        }
        props.setSourceSelectors(updatedSourceSelectors);

        if (save && updatedSourceSelector) {
            await updateSourceSelector(props.customerId, updatedSourceSelector);
        }
    }

    if (!props.sourceSelectors) {
        return <></>;
    }

    return <div>
        <List>
            {props.sourceSelectors.map(sourceSelector => {
                return <li key={sourceSelector.sourceSelectorId}>
                    <SourceSelectorItem 
                        customerId={props.customerId}
                        processor={props.processors.find(processor => processor.processorId === sourceSelector.processorId)}
                        sources={props.sources?.filter(source => source.processorId === sourceSelector.processorId)}
                        sourceSelector={sourceSelector}
                        setSourceSelectorAttributes={setSourceSelectorAttributes}
                    />
                </li>
            })}
        </List>
    </div>
}

export default SourceSelectors;