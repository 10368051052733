import styled from 'styled-components';
import theme from '../../UI/theme';
import { Link } from 'react-router-dom';

const List = styled.ul`
    list-style: none;
    padding: 0;
    display: flex;
    padding: 30px;
    margin: 0;

    @media only screen and (max-width: ${theme.screenSizes.medium-1}px) {
        justify-content: space-around;
    }

`
const MenuItem = styled.li`
    margin: 0 20px;
`
const Container = styled.div`
    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        position: fixed;
        top: 0;
        border-bottom: 2px solid ${theme.colors.darkSpace};
    }

    @media only screen and (max-width: ${theme.screenSizes.medium-1}px) {
        position: fixed;
        bottom: 0;
        border-top: 2px solid ${theme.colors.darkSpace};
    } 
    width: 100%;
    z-index: 100;
    background-color: ${props => props.backgroundColor || theme.colors.darkSpace90};
`
const StatusBanner = styled.div`
    background-color: ${theme.colors.darkSpace80};
    display: flex;
    justify-content: center;
    font-size: ${theme.fontSize.tiny}
`

const StyledLink = styled(Link)`
    text-decoration: none;
    color: white;
    font-size: 20px;
`;

const ItemTitle = styled.h3`
    @media only screen and (max-width: ${theme.screenSizes.medium-1}px) {
        display: none;
    }

    font-size: 18px;
    margin: 0;
    margin-left: 10px;
`

const InnerContainer = styled.div`
    display: flex;
    align-items: center;
`

const TopPlaceholder = styled.div`
    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        height: 95px;
    }
`

export {
    List,
    MenuItem,
    Container,
    StyledLink,
    ItemTitle,
    InnerContainer,
    TopPlaceholder,
    StatusBanner
};