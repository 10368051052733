import styled from "styled-components";
import Dropdown from "../../../UiComponents/Dropdown";
import hierarchyTypes from "./hierarchyTypes";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const ZoneHierarchyTypeSelector = props => {
    var hierarchyType = hierarchyTypes[Object.keys(hierarchyTypes).find(key => hierarchyTypes[key].value === props.hierarchyType)]?.display;
    var masterZone =  props.masterZones?.find(zone => zone.zoneId === props.masterID)?.zoneName;
    return <Container>
        <h4>Hierarchy type: {props.isEditing ?
            <Dropdown
                small
                items={Object.keys(hierarchyTypes).map(type => { return { label: hierarchyTypes[type].display, ...hierarchyTypes[type] } })}
                selectedItem={{ label: hierarchyType }}
                onClick={(newType) => { props.updateZoneHandler(props.zoneId, 'hierarchyType', newType.value) }} /> :
            hierarchyType
        } </h4>
        {props.hierarchyType === hierarchyTypes.SLAVE.value ?
            <h4>Master zone:  {props.isEditing ?
                <Dropdown
                    small
                    items={props.masterZones?.map(zone => { return { label: zone.zoneName, ...zone } })}
                    selectedItem={{ label: masterZone }}
                    onClick={(zone) => { console.log(zone); props.updateZoneHandler(props.zoneId, 'masterId', zone.zoneId); }} />
                : masterZone}</h4>
            : <></>}
    </Container>
}

export default ZoneHierarchyTypeSelector;