import React, { useEffect, useState } from 'react';
import Dropdown from '../../../../UiComponents/Dropdown';
import { TextMuted } from '../../../../UiComponents/text/StyledText';
import ProcessorScanner from './ProcessorScanner';
import Button from '../../../../UiComponents/Button';
import { processorTypes, connectionTypes } from './processorTypes';
import { hubConnectionTypes } from '../hubTypes';
import AudioSources from './AudioSource/AudioSources';
import { DetailsRowContainer, MT10Container, SectionHeader, WarningParagraph, ButtonRowContainer } from '../DeviceStyles';
import { sortByKey } from '../../../../../scripts/common';
import theme from '../../../../../UI/theme';
import ProcessorIpField from './ProcessorIPField';
import { updateProcessor } from '../../../../../scripts/processor';
import InputField from '../../../../UiComponents/InputField';
import { getProcessorDisplayName } from '../Processor/processorUtils';
import styled from 'styled-components';
import ReconnectProcessorButton from './ReconnectProcessorButton';
import DeleteProcessorButton from './DeleteProcessorButton';
import { postEvent } from '../../../../../scripts/event';
import SourceSelectorList from './SourceSelector/SourceSelectorList';
import ExpandCollapseButton from '../../../../UiComponents/buttons/ExpandCollapseButton';
import { getProcessorDisplayStatus, getProcessorStatus, getProcessorStatusColor } from '../../../../installation/utils/connectionStatus';
import useServerStatus from '../../../../../hooks/useServerStatus';

const Container = styled.div`    
    border: 1px solid ${theme.colors.darkSpace50};
    border-radius: 4px;
    padding: 10px;
    margin: 10px 0;
    :hover {
        cursor: pointer;
    }
`

const Flex = styled.div`
    display: flex;
    align-items: center;
`

const processorInterfaces = [{
    value: 'eth0',
    label: 'Ethernet'
}, {
    value: 'wlan0',
    label: 'Wifi'
}];


const AudioSystem = (props) => {
    const serverStatus = useServerStatus();
    const [editState, setEditState] = useState(false);
    const [showDetailed, setShowDetailed] = useState(false);
    const [updateState, setUpdateState] = useState('NONE');
    const [updatedProcessor, setUpdatedProcessor] = useState();

    const setEditStateHandler = (editState) => {
        if ((props.user.role == 'impersonator') && (props.user.customerId != props.customerId)) { return; }
        if (editState) {
            var currentProcessor = { ...props.processor };
            setUpdatedProcessor(currentProcessor);
        } else {
            setUpdatedProcessor({});
        }
        setEditState(editState);
    }

    async function updateProcessorHandler(field, value, save) {
        var processor = { ...updatedProcessor };
        processor[field] = value;
        setUpdatedProcessor(processor);
        setUpdateState('UPDATED');

        if (save) {
            saveProcessor(processor);
        }
    }

    async function updateSources(updatedSources, save) {
        var processor = { ...(editState ? updatedProcessor : props.processor), sources: updatedSources };
        if (save) {
            saveProcessor(processor);
        }
    }

    const saveProcessor = async (processor) => {
        try {
            await updateProcessor(props.customerId, processor);
            await postEvent(props.hub.hubId, props.customerId, 'UPDATE_PROCESSOR');
            await props.refreshDevices();
            setEditStateHandler(false);
            setUpdateState('SUCCESS');
            setTimeout(() => {
                setUpdateState('NONE');
            }, [1000]);
        } catch (err) {
            setUpdateState('FAILED');
        }
    }

    async function saveHandler() {
        saveProcessor(updatedProcessor);
    }

    var processorType = editState ? processorTypes[updatedProcessor.type] : processorTypes[props.processor.type];
    const processorStatus = getProcessorStatus(props.processor, props.hub, serverStatus);
    const displayStatus = getProcessorDisplayStatus(processorStatus);

    return <Container onClick={() => { !showDetailed && setShowDetailed(true) }}>
        <SectionHeader>
            <Flex>
                {editState ? <>
                    <InputField small width={150} value={updatedProcessor.name || ''} onChange={(e) => updateProcessorHandler('name', e.target.value)} />
                </> : <>
                    {getProcessorDisplayName(props.processor)}
                </>}

                <ExpandCollapseButton
                    showState={showDetailed}
                    onClick={() => setShowDetailed(s => !s)}
                    width={40}
                    height={25}
                />
                <Button small tertiary lightBorder disabled={props.readOnly} margin={0} onClick={(e) => { e.stopPropagation(); setEditStateHandler(c => !c) }}>
                    {editState ? 'Cancel' : 'Edit'}
                </Button>
            </Flex>
        </SectionHeader>

        {editState ? <>
            <Dropdown
                small
                items={sortByKey(Object.values(processorTypes), 'label')}
                onClick={(item) => {
                    updateProcessorHandler('type', item.value);
                }}
                selectedItem={processorType}>
            </Dropdown>

            {processorType?.connection === connectionTypes.TCP && props.hub.connectionType != hubConnectionTypes.ETHERNET ? <>
                <MT10Container>
                    <Dropdown
                        small
                        items={processorInterfaces}
                        onClick={(item) => {
                            updateProcessorHandler('interface', item.value);
                        }}
                        selectedItem={processorInterfaces.find(pi => pi.value === updatedProcessor.interface)}>
                    </Dropdown>
                </MT10Container>
            </> : <></>}
        </> : <>
            <TextMuted>{processorType?.label}</TextMuted>
        </>}

        {processorType ?
            <>
                {editState ? <>
                    {processorType.connection === connectionTypes.TCP ? <>
                        <MT10Container>
                            <DetailsRowContainer>
                                <ProcessorIpField hub={props.hub} processor={props.processor} updatedProcessor={updatedProcessor} updateProcessorHandler={updateProcessorHandler} />
                            </DetailsRowContainer>
                        </MT10Container>
                    </> : <></>}

                </> : <>
                    {processorType.connection === connectionTypes.TCP && props.hub.connectionType != hubConnectionTypes.ETHERNET ? <>
                        <br /><TextMuted>Interface: {props.processor.interface}</TextMuted>
                    </> : <></>}
                    {processorType.connection === connectionTypes.TCP ? <>
                        <br /><TextMuted>IP: {props.processor.ipAddress}</TextMuted>
                    </> : <></>}

                    {displayStatus ? <>
                        <DetailsRowContainer>
                            <div className="indicator-dot small left-align">
                                <div style={{ backgroundColor: getProcessorStatusColor(processorStatus) }} ></div>
                            </div>
                            <TextMuted>{getProcessorDisplayStatus(processorStatus)}</TextMuted>
                            {showDetailed ? <>
                                <div style={{marginLeft: '8px'}}>
                                    <ReconnectProcessorButton hub={props.hub} processor={props.processor} />
                                </div>
                            </> : <></>}

                        </DetailsRowContainer>
                    </> : <></>}



                    {props.processor.error ? <>
                        <WarningParagraph>
                            <span>{props.processor.error}</span>
                        </WarningParagraph>
                    </> : <></>}

                    {props.missingObjectAddresses > 0 ? <>
                        <WarningParagraph>
                            <span>Zone missing object address</span>
                        </WarningParagraph>
                    </> : <>
                    </>}

                </>}
            </> : <>
            </>}

        {editState ? <>
            {updateState === 'NONE' || updateState === 'UPDATED' ? <>
                <ButtonRowContainer>
                    <Button small primary onClick={saveHandler}>Save</Button>
                    <Button small secondary onClick={() => setEditStateHandler(false)}>Cancel</Button>
                    <DeleteProcessorButton processor={props.processor} refreshDevices={props.refreshDevices} />
                </ButtonRowContainer>
            </> : <></>}

            {updateState === 'FAILED' ? <>
                <p>Save failed</p>
                <ButtonRowContainer>
                    <Button primary onClick={() => saveHandler()}>Retry</Button>
                    <Button primary onClick={() => setEditStateHandler(false)}>Cancel</Button>
                </ButtonRowContainer>
            </> : <></>}

        </> : <></>}

        {showDetailed ? <>
            <AudioSources
                editState={editState}
                processor={props.processor}
                updatedProcessor={updatedProcessor}
                audioSources={editState ? updatedProcessor?.sources : props.processor.sources}
                hub={props.hub}
                updateSources={updateSources}
            />
        </> : <></>}


        {showDetailed ? <>
            <SourceSelectorList
                sourceSelectors={props.sourceSelectors}
                refreshDevices={props.refreshDevices}
                editState={editState}
                processor={props.processor}
            />
        </> : <></>}
    </Container>
}

export default AudioSystem;
