import { postEvent } from "../../../scripts/event";
import { addSourceSelector, updateSourceSelector } from "../../../scripts/sourceSelector";
import { saveObjects } from "../tableUtils";

const saveUpdatedSourceSelectors = async (changedSourceSelectors, sourceSelectors, processors, customerId) => {
    prepareForSave(changedSourceSelectors, sourceSelectors);
    const uniqueHubIds = getUniqueHubIds(changedSourceSelectors, processors);
    await saveObjects(changedSourceSelectors, uniqueHubIds, customerId, 'UPDATE_SOURCE_SELECTORS', (customerId, object) => updateSourceSelector(customerId, object));
}

const addNewSourceSelector = async (props, sourceSelectors, processors, customerId) => {
    try {
        const processor = processors.find(processor => processor.processorId === props.processorId);
        const hubId = processor.hubId;
        const newSourceSelector = {
            ...props,
            customerId,
            orderIndex: sourceSelectors.length ? Math.max(...sourceSelectors.map(s => s.orderIndex)) + 1 : 1
        }
        await addSourceSelector(customerId, newSourceSelector);
        await postEvent(hubId, customerId, 'UPDATE_SOURCE_SELECTORS');
    } catch (err) {
        console.log('Failed to add new sourceSelector', err);
    }
}

const getAvailableSources = (sourceSelector, sources) => {
    const unavailableSources = getUnavailableSourceIds(sourceSelector);
    return sources?.filter(source => !unavailableSources.includes(source.sourceId));
}

const getUnavailableSourceIds = (sourceSelector) => {
    return sourceSelector.sourceConfig?.unavailableSources || [];
}


export {
    saveUpdatedSourceSelectors,
    addNewSourceSelector,
    getAvailableSources
}

const getUniqueHubIds = (sourceSelectors, processors) => {
    const uniqueHubIds = [];
    sourceSelectors.forEach(sourceSelector => {
        const processor = processors.find(processor => processor.processorId === sourceSelector.processorId);
        if (processor && !uniqueHubIds.includes(processor.hubId)) {
            uniqueHubIds.push(processor.hubId);
        }
    })
    return uniqueHubIds;
}

const prepareForSave = (changedSourceSelectors, sourceSelectors) => {
    changedSourceSelectors.forEach(changedSourceSelector => {
        const sourceSelector = sourceSelectors.find(sourceSelector => sourceSelector.sourceSelectorId === changedSourceSelector.sourceSelectorId);
        if (sourceSelector && !changedSourceSelector.processorId) {
            changedSourceSelector.processorId = sourceSelector.processorId;
        }
    })
}