import Button from "../../../UiComponents/Button";
import { useState } from "react";
import InputField from "../../../UiComponents/InputField";
import styled from "styled-components";
import { TextMuted } from "../../../UiComponents/text/StyledText";
import TimeInput from "../../../UiComponents/TimeInput";
import ScheduleDays from "./ScheduleDays";
import Checkbox from "../../../UiComponents/Checkbox";
import { createSchedule, updateSchedule } from "../../../../scripts/schedules";
import scheduleTypes from "./scheduleTypes";
import theme from "../../../../UI/theme";
import { FaExclamationTriangle, FaAngleDown, FaAngleUp, FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
import Radio from "../../../UiComponents/Radio";
import ButtonContainer from "../../../UiComponents/containers/ButtonContainer";
import MultipleSelector from "../../../UiComponents/MultipleSelector";
import hierarchyTypes from "../device/hierarchyTypes";

const Container = styled.div`
    max-height: 80vh;
    overflow-y: auto;
    padding: 0 10px;
`

const ColContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: start;
    margin-top: 10px;
    // padding: 10px;
    // border: 1px solid ${theme.colors.textGray};
    height: 100%;
`

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const RadioContainer = styled(RowContainer)`
    display: flex;
    flex-direction: row;
    margin: 0 0 10px 0;

    >div:not(:first-of-type) {
        margin-left: 10px;
    }
`

const MT10Container = styled.div`
    margin-top: 3px;
`

const Header = styled.h3`
    margin-bottom: 0;
`

const ClickableText = styled(TextMuted)`
    display: block;
    cursor: pointer;
`

const ErrorMessage = styled.label`
    display: flex;
    align-items: center;
    color: ${theme.colors.raspberryRed};
    margin: 0 0 10px 0;
`

const emptySchedule = {
    scheduleTypes: [],
    zones: [],
    days: [],
    isRegulating: false,
    vibeChange: 0,
    mute: false,
    moodSustain: false,
    displayName: '',
    time: '12:00'
}

const scheduleTypeSelectorItems = [
    { key: scheduleTypes.types.VIBE, display: "Change vibe" },
    { key: scheduleTypes.types.REGULATION, display: "Turn regulation on/off" },
    { key: scheduleTypes.types.MUTE, display: "Mute" },
    { key: scheduleTypes.types.MOOD_SUSTAIN, display: "Mood sustain" }];

const ScheduleForm = props => {
    const [schedule, setSchedule] = useState(props.schedule ?? { ...emptySchedule, customerId: props.customerId });
    const [time, setTime] = useState(toInputTime(props.schedule?.time) ?? '12:00');
    const [selectedSchedules, setSelectedSchedules] = useState(scheduleTypeSelectorItems.filter(item => props.schedule?.scheduleTypes?.some(type => type === item.key)) ?? []);
    const [errorMessage, setErrorMessage] = useState();

    const selectZoneHandler = (zone) => {
        setSchedule(prev => {
            var newZones = [...prev.zones];
            if (newZones.includes(zone)) {
                newZones = newZones.filter(z => z !== zone);
            } else {
                newZones.push(zone);
            }
            return { ...prev, zones: newZones };
        });
    }

    const changeScheduleTypeHandler = (newType) => {
        setSelectedSchedules(prev => {
            if (prev?.some(scheduleType => scheduleType.key === newType.key)) {
                return prev.filter(scheduleType => scheduleType.key !== newType.key);
            } else {
                var newScheduleTypes = [...prev];
                newScheduleTypes.push(newType);

                if (newType.key === scheduleTypes.types.VIBE) {
                    setSchedule({ ...schedule, vibeChange: 0 });
                }
                if (newType.key === scheduleTypes.types.REGULATION) {
                    setSchedule({ ...schedule, isRegulating: true });
                }
                if (newType.key === scheduleTypes.types.MUTE) {
                    setSchedule({ ...schedule, mute: true });
                }
                if (newType.key === scheduleTypes.types.MOOD_SUSTAIN) {
                    setSchedule({ ...schedule, moodSustain: true });
                }

                return newScheduleTypes;
            }
        })
    }

    const saveScheduleHandler = async () => {
        var newSchedule = {
            customerId: schedule.customerId,
            scheduleTypes: selectedSchedules.map(type => type.key),
            displayName: schedule.displayName,
            time: toScheduleTime(time),
            zones: [...schedule.zones],
            days: [...schedule.days],
        }

        if (schedule.scheduleId) {
            newSchedule.scheduleId = schedule.scheduleId;
        }

        if (selectedSchedules.some(type => type.key === scheduleTypes.types.VIBE)) {
            newSchedule.vibeChange = schedule.vibeChange;
        }
        if (selectedSchedules.some(type => type.key === scheduleTypes.types.REGULATION)) {
            newSchedule.isRegulating = +schedule.isRegulating;
        }
        if (selectedSchedules.some(type => type.key === scheduleTypes.types.MUTE)) {
            newSchedule.mute = +schedule.mute;
        }
        if (selectedSchedules.some(type => type.key === scheduleTypes.types.MOOD_SUSTAIN)) {
            newSchedule.moodSustain = +schedule.moodSustain;
        }

        if (newSchedule.scheduleId) {
            var res = await updateSchedule(props.customerId, newSchedule);
        } else {
            var res = await createSchedule(props.customerId, newSchedule);
        }
        if (res.valid === undefined && res.error === undefined) {
            props.setShowScheduleModal(false);
            await props.refreshSchedules();
        } else {
            setErrorMessage(res.error);
        }
    }

    return <Container>
        <Header>Schedule Name</Header>
        <InputField placeholder='Closing time' value={schedule.displayName} onChange={(e) => setSchedule({ ...schedule, displayName: e.target.value })} />

        <Header>Time</Header>
        <TimeInput value={time} onChange={(e) => setTime(e.target.value)} />

        <Header>Days</Header>
        <ScheduleDays editable days={schedule.days} onSelectDay={(newDays) => setSchedule({ ...schedule, days: newDays })} />

        <Header>Zones</Header>
        {props.zones?.filter(zone => zone.hierarchyType !== hierarchyTypes.MASTER.value).map((zone, index) => {
            if (zone.hide) {
                return;
            }
            return <RowContainer key={index}>
                <Checkbox checked={schedule.zones?.includes(zone.zoneId)} onChange={() => { selectZoneHandler(zone.zoneId); }} />
                <ClickableText onClick={() => { selectZoneHandler(zone.zoneId); }} >&nbsp;{zone.zoneName}</ClickableText>
            </RowContainer>
        })}

        <Header>Schedule actions</Header>
        <MT10Container>
            <MultipleSelector editable items={scheduleTypeSelectorItems} selectedItems={selectedSchedules} onSelectItem={(selectedItem) => changeScheduleTypeHandler(selectedItem)} />
        </MT10Container>

        <ColContainer>
            {selectedSchedules.some(type => type.key === scheduleTypes.types.VIBE) ?
                <>
                    <TextMuted>Vibe</TextMuted>

                    <RadioContainer>
                        <Radio name="vibe" checked={schedule.vibeChange === -2} onChange={() => setSchedule({ ...schedule, vibeChange: -2 })} />
                        <TextMuted><FaAngleDoubleDown /></TextMuted>
                        <Radio name="vibe" checked={schedule.vibeChange === -1} onChange={() => setSchedule({ ...schedule, vibeChange: -1 })} />
                        <TextMuted><FaAngleDown /></TextMuted>
                        <Radio name="vibe" checked={schedule.vibeChange === 1} onChange={() => setSchedule({ ...schedule, vibeChange: 1 })} />
                        <TextMuted><FaAngleUp /></TextMuted>
                        <Radio name="vibe" checked={schedule.vibeChange === 2} onChange={() => setSchedule({ ...schedule, vibeChange: 2 })} />
                        <TextMuted><FaAngleDoubleUp /></TextMuted>
                        <Radio name="vibe" checked={schedule.vibeChange === 0} onChange={() => setSchedule({ ...schedule, vibeChange: 0 })} />
                        <TextMuted>Reset</TextMuted>
                    </RadioContainer>
                </>
                : <></>}

            {selectedSchedules.some(type => type.key === scheduleTypes.types.REGULATION) ?
                <>
                    <TextMuted>Regulation</TextMuted>

                    <RadioContainer>
                        <Radio name="regulation" checked={schedule.isRegulating} onChange={() => setSchedule({ ...schedule, isRegulating: true })} />
                        <TextMuted>On</TextMuted>
                        <Radio name="regulation" checked={!schedule.isRegulating} onChange={() => setSchedule({ ...schedule, isRegulating: false })} />
                        <TextMuted>Off</TextMuted>
                    </RadioContainer>
                </>
                : <></>}

            {selectedSchedules.some(type => type.key === scheduleTypes.types.MUTE) ?
                <>
                    <TextMuted>Music</TextMuted>

                    <RadioContainer>
                        <Radio name="mute" checked={schedule.mute} onChange={() => setSchedule({ ...schedule, mute: true })} />
                        <TextMuted>Mute</TextMuted>
                        <Radio name="mute" checked={!schedule.mute} onChange={() => setSchedule({ ...schedule, mute: false })} />
                        <TextMuted>Unmute</TextMuted>
                    </RadioContainer>
                </>
                : <></>}

            {selectedSchedules.some(type => type.key === scheduleTypes.types.MOOD_SUSTAIN) ?
                <>
                    <TextMuted>Mood sustain</TextMuted>

                    <RadioContainer>
                        <Radio name="moodsustain" checked={schedule.moodSustain} onChange={() => setSchedule({ ...schedule, moodSustain: true })} ></Radio>
                        <TextMuted>On</TextMuted>
                        <Radio name="moodsustain" checked={!schedule.moodSustain} onChange={() => setSchedule({ ...schedule, moodSustain: false })} ></Radio>
                        <TextMuted>Off</TextMuted>
                    </RadioContainer>
                </> : <></>}

        </ColContainer>

        {errorMessage ? <ErrorMessage><FaExclamationTriangle />&nbsp;{errorMessage}</ErrorMessage> : <></>}

        <ButtonContainer>
            <Button primary onClick={() => saveScheduleHandler()}>Save</Button>
            <Button secondary onClick={() => props.setShowScheduleModal(false)}>Cancel</Button>
        </ButtonContainer>

    </Container>
}

export default ScheduleForm;

function toScheduleTime(timeInput) {
    if (timeInput && timeInput.length === 5) {
        return `${timeInput.substring(0, 2)}${timeInput.substring(3, 5)}`;
    } else {
        return null;
    }
}

function toInputTime(scheduleTime) {
    if (scheduleTime?.length === 4) {
        return `${scheduleTime.substring(0, 2)}:${scheduleTime.substring(2, 4)}`;
    } else {
        return undefined;
    }
}