import React, { useState, useEffect, useRef } from 'react';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { getUuid } from '../../../scripts/common';
import { TableCell } from '../fields/Styles';
import { getSortedGroups } from '../tableUtils';
import { displayModes } from '../utils/installationTypes';
import DeleteButton from './DeleteButton';
import { HeaderRow, StyledTable } from './styles/TableStyles';
import { cellStates } from './tableTypes';

const EditableTable = ({ objects, updateObjects, isEdited, columns, idField, deleteObject, sortObjects }) => {

    const [markedCell, _setMarkedCell] = useState({ x: 0, y: 0 });
    const [cellState, _setCellState] = useState(cellStates.NONE);
    const cellStateRef = useRef(cellState);
    const markedCellRef = useRef(markedCell);
    const callbacks = useRef([]);
    const outsideClickRef = useOutsideClick(outsideClickHandler);

    const setCellState = (state) => {
        cellStateRef.current = state;
        _setCellState(state);
    }

    const setMarkedCell = (coordinates) => {
        markedCellRef.current = coordinates;
        _setMarkedCell(coordinates);
    }

    const navigateToCell = (coordinates) => {
        setCellState(cellStates.NONE);
        _setMarkedCell(coordinates);
    }

    const addCallback = (fn) => {
        const id = getUuid();
        callbacks.current.push({ id, fn });
        return id;
    }

    const removeCallback = (id) => {
        callbacks.current = callbacks.current.filter(callback => callback.id !== id);
    }

    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        return () => {
            window.removeEventListener("keydown", downHandler);
        }
    }, [objects?.length]);


    const downHandler = (event) => {
        const key = event.key;
        for (let i = 0; i < callbacks.current.length; i++) {
            const handled = callbacks.current[i].fn(event);
            if (handled) {
                return;
            }
        }

        if (isArrowKey(key) && markedCellRef.current !== null && cellStateRef.current === cellStates.NONE) {
            event.preventDefault();
            navigateToCell(current => getNewCoordinates(current, key, objects.length, columns.length));
        } else if (markedCellRef.current !== null && key === 'Enter') {
            event.preventDefault();
            if (cellStateRef.current === cellStates.ACTIVE) {
                setCellState(cellStates.NONE)
            } else {
                setCellState(cellStates.ACTIVE)
            }
        }
    }

    function outsideClickHandler(event) {
        setMarkedCell(null);
    }

    const onCellClick = (ix, iy) => {
        setMarkedCell({ x: ix, y: iy });
        setCellState(cellStates.ACTIVE);
    }


    const renderObject = (columns, object, iy) => {
        const updateObject = (updatedProps) => updateObjects(object[idField], updatedProps);
        return <tr key={iy}>
            {columns.map((column, ix) => {
                if (!column.hide) {
                    const marked = markedCell && ix === markedCell.x && iy === markedCell.y;
                    const cellControl = { cellState: getCellState(marked, cellState), addCallback, removeCallback, displayMode: displayModes.TABLE };
                    const isUpdated = isEdited(object[idField], column.key);
                    const requiredState = column.getRequiredState && column.getRequiredState(object);
                    return <TableCell
                        key={ix}
                        marked={marked}
                        updated={isUpdated}
                        requiredState={requiredState}
                        cellState={getCellState(marked, cellState)}
                        onClick={() => onCellClick(ix, iy)}
                    >
                        {column.render(
                            object,
                            updateObject,
                            cellControl
                        )}
                    </TableCell>
                } else {
                    return <></>
                }
            })}
            {deleteObject ? <>
                <td>
                    <DeleteButton onClick={async () => await deleteObject(object)} />
                </td>
            </> : <></>}
        </tr>
    }

    const groups = getSortedGroups(objects, sortObjects);
    const sortedObjects = groups.reduce((prev, curr) => prev.concat(curr.objects), []);

    return <>
        <div>
            <StyledTable>
                <thead>
                    <HeaderRow>
                        {columns.map((column, ix) => {
                            if (!column.hide) {
                                return <th key={ix} style={{ width: column.width }}>
                                    {column.displayName}
                                </th>
                            } else {
                                return <></>
                            }
                        })}
                        {deleteObject ? <>
                            <th style={{ width: '60px' }}>Delete</th>
                        </> : <></>}
                    </HeaderRow>
                </thead>
                <tbody ref={outsideClickRef}>
                    {sortedObjects?.map((object, iy) => renderObject(columns, object, iy))}
                </tbody>
            </StyledTable>
        </div>
    </>
}

export default EditableTable;


const isArrowKey = (key) => {
    return ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown'].includes(key);
}

const getNewCoordinates = (current, key, nrows, ncols) => {
    const newCoordinates = { ...current };
    switch (key) {
        case 'ArrowRight':
            newCoordinates.x = Math.min(ncols - 1, newCoordinates.x + 1);
            break;
        case 'ArrowLeft':
            newCoordinates.x = Math.max(0, newCoordinates.x - 1);
            break;
        case 'ArrowDown':
            newCoordinates.y = Math.min(nrows - 1, newCoordinates.y + 1);
            break;
        case 'ArrowUp':
            newCoordinates.y = Math.max(0, newCoordinates.y - 1);
            break;
    }

    return newCoordinates;
}

const getCellState = (marked, cellState) => {
    return marked ? cellState : cellStates.NONE;
}

