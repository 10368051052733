import { useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import styled from "styled-components";
import { getZones } from "../../scripts/zone";
import ViewSelector from "../live/venue/ViewSelector";
import venueTypes from "../live/venueTypes";
import Card from "../UiComponents/Card";
import OpeningHoursToggle from "./OpeningHoursToggle";
import PeriodPicker from "./PeriodPicker";
import trendTypes from "./trendTypes";
import VenueStatistics from "./VenueStatistics";
import ZoneStatistics from "./ZoneStatistics";
import FadeInContainer from '../../components/UiComponents/Animations/FadeInContainer';
import HeatmapView from "../../views/MiniHeatmap";
import { useContext } from "react";
import UserContext from "../../hooks/UserContext";

const HeaderCard = styled(Card)`
    margin-bottom: 20px;
`

const trendPeriods = Object.keys(trendTypes.periods)
    .filter(period => period != trendTypes.periods.MONTH && period != trendTypes.periods.YEAR)
    .map(period => { return { key: trendTypes.periods[`${period}`], display: period.charAt(0).toUpperCase() + period.slice(1).toLowerCase() } });

const StatisticsContainer = props => {
    const user = useContext(UserContext);
    const [zones, setZones] = useState([]);
    const [trendPeriod, setTrendPeriod] = useState(trendPeriods[1]);
    const [date, setDate] = useState(new Date());
    const [useOpeningHours, setUseOpeningHours] = useState(false);

    let tabs = resolveTabs(zones?.length > 1);
    const [selectedTab, setSelectedTab] = useState(tabs.find(tab => tab.key === venueTypes.viewTabs.VENUE));

    useEffect(() => {
        onLoadHandler();
    }, [])

    const onLoadHandler = async () => {
        if (props.customerId) {
            var venueZones = await getZones(props.customerId);
            setZones(venueZones);
        }
    }

    const setTrendPeriodHandler = (newPeriod) => {
        if (newPeriod.key === trendTypes.periods.WEEK && useOpeningHours) {
            setUseOpeningHours(false);
        }
        setTrendPeriod(newPeriod);
    }

    return <>
        {(zones?.length > 1) ? <>
            <ViewSelector
                tabs={tabs}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab} />
        </> : <></>}

        <FadeInContainer>

            {selectedTab.key !== venueTypes.viewTabs.MAP ? <>
                <PeriodPicker
                    period={trendPeriod}
                    setPeriod={setTrendPeriodHandler}
                    periods={trendPeriods}
                    date={date}
                    setDate={setDate} />

                <AnimateHeight duration={500} height={trendPeriod.key === trendTypes.periods.DAY ? 'auto' : 0}>
                    <HeaderCard>
                        <OpeningHoursToggle checked={useOpeningHours} onToggle={(val) => { setUseOpeningHours(val); }} />
                    </HeaderCard>
                </AnimateHeight>
            </> : <></>}

            {selectedTab.key === venueTypes.viewTabs.VENUE ? <>
                <VenueStatistics
                    customerId={props.customerId}
                    hierarchyLevel={trendTypes.hierarchyLevels.VENUE}
                    date={date} trendPeriod={trendPeriod.key}
                    useOpeningHours={useOpeningHours} />
            </> : <></>}

            {selectedTab.key === venueTypes.viewTabs.ZONES ? <>
                <ZoneStatistics
                    customerId={props.customerId}
                    hierarchyLevel={trendTypes.hierarchyLevels.ZONE}
                    zones={zones}
                    date={date}
                    trendPeriod={trendPeriod.key}
                    useOpeningHours={useOpeningHours} />
            </> : <></>}

            {selectedTab.key === venueTypes.viewTabs.MAP ? <>
                <HeatmapView customerId={props.customerId} user={user} />
            </> : <></>}
        </FadeInContainer>
    </>
}

export default StatisticsContainer;

const resolveTabs = (hasMultipleZones) => {
    let tabs = [{
        display: 'Venue',
        key: venueTypes.viewTabs.VENUE
    }];

    if (hasMultipleZones) {
        tabs.push({
            display: 'Zones',
            key: venueTypes.viewTabs.ZONES
        });
    }

    tabs.push({
        display: 'Map',
        key: venueTypes.viewTabs.MAP
    })

    return tabs;
}